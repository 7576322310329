import dayjs from 'dayjs';
import ja from 'dayjs/locale/ja';
import en from 'dayjs/locale/en';
import ko from 'dayjs/locale/ko';
import zhcn from 'dayjs/locale/zh-cn';
import zhtw from 'dayjs/locale/zh-tw';
import th from 'dayjs/locale/th';
import ru from 'dayjs/locale/ru';


const LOCALES = {
  "ja": ja,
  "en": en,
  "ko": ko,
  "zh-cn": zhcn,
  "zh-tw": zhtw,
  "th": th,
  "ru": ru,
}

export function formatDate(date, format="YYYY-MM-DD") {
  // 日付を整形する
  return date && dayjs(date).format(format);
}

export function dayjsLocale(localeKey) {
  // 日付表示のロケール設定
  const locale = LOCALES[localeKey];
  locale && dayjs.locale(locale);
}
