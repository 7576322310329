import {
  IntentCategory,
  Intent,
  IntentDetail,
  UserSay,
  Word,
  IntentDetailKeyword,
  IntentLabel,
} from '../model/intent'
import {KeywordCategory, Keyword, KeywordValue} from '../model/keyword'
import { RestApi } from '../util/rest-api'
import { I18n } from '../util/i18n'
import { v4 as uuidv4 } from 'uuid'
import { VIEW_URL } from '../resource/urls'
import axios from "axios/index";
import copyToClipboard from "../util/copy-url"
import SynchronizeController from './synchronize-controller'
import cookies from '../util/get-intent-keyword-from-cookie'
import {TestChatController} from "./test-chat-controller";
import { cloneDeep } from 'lodash'

class IntentEditorController {
  constructor(apiUrl, language, supportedLanguages, isStaff, userType, allowWebhook, i18nContext, excelExportUrl,
              intent_name, feedback_intent_dict, agentOptions, available_platform, default_platform, auto_input_predict, test_chat_platform, agent_gid) {
    this.agentOptions = agentOptions
    this.available_platform = available_platform
    this.default_platform = default_platform
    this.auto_input_predict = auto_input_predict
    this.i18n = new I18n(i18nContext)
    this.apiUrl = apiUrl
    this.excelExportUrl = excelExportUrl
    this.isStaff = (isStaff.toLowerCase() === 'true')
    this.userType = userType
    this.allowWebhook = allowWebhook
    this.categoryModel = IntentCategory
    this.intentCategoryApi = new RestApi(apiUrl.IntentCategory, IntentCategory)
    this.keywordCategoryApi = new RestApi(apiUrl.KeywordCategory, KeywordCategory)
    this.intentApi = new RestApi(apiUrl.Intent, Intent)
    this.intentsCopyToAgent = apiUrl.IntentsCopyToAgent
    this.multiIntentDeleteApi = apiUrl.DeleteMultiIntent
    this.intentDetailApi = new RestApi(apiUrl.IntentDetail, IntentDetail)
    this.intentDetailMultipleDeleteApi = new RestApi(apiUrl.IntentDetailMultipleDelete, IntentDetail)
    this.intentDetailSearchApi = new RestApi(apiUrl.IntentDetailSearch, IntentDetail)
    this.intentDetailKeywordApi = new RestApi(apiUrl.intentDetailKeyword, IntentDetailKeyword)
    this.allIntentApi = new RestApi(apiUrl.AllIntent, Intent)
    this.keywordApi = new RestApi(apiUrl.Keyword, Keyword)
    this.allKeywordApi = new RestApi(apiUrl.AllKeyword, Keyword)
    this.keywordValueApi = new RestApi(apiUrl.KeywordValue, KeywordValue)
    this.intentLabelApi = new RestApi(apiUrl.intentLabel, IntentLabel)
    this.importURL = apiUrl.importIntent
    this.synchronizeController = new SynchronizeController(apiUrl, this.i18n)
    this.selectedPlatformAPI = apiUrl.selectedPlatform
    this.userSaysSchronizeApi = apiUrl.userSaysSynchronize
    this.multiIntentUpdateLabelApi = apiUrl.UpdateLabelMultiIntent

    this.prebuiltKeywords = []
    this.language = language
    this.supportedLanguages = supportedLanguages  // 切替可能な言語一覧
    this.categories = []
    this.allCategoriesFlat = []
    this.selectedCategory = null  // 選択中のカテゴリ
    this.displayCategory = null // インテンツを表示しているカテゴリ
    this.isAllSelected = true
    this.currentDisplayedCategory = null
    this.intents = []
    this.allIntents = []
    this.selectedIntent = null  // 選択中のインテント
    this.selectedIntentDetail = null  // 選択中のインテント詳細
    this.selectedUserSay = null
    this.selectedResponseMessage = null
    this.selectedKeywordCategory = null  // 選択中のキーワードカテゴリ
    this.questionSearchResult = []
    this.desc = true
    this.categorydesc = true
    this.selectedLanguageIntentDetail = null
    this.intentName = intent_name
    this.isMounted = true
    this.intentDetailKeywordList = []
    this.keywordFullNameById={}
    this.intentDetailKeywordListBySearch = []
    this.intentCategoryPath = {} // IntentItem: Get tree list categories
    this.feedbackIntentDict = feedback_intent_dict

    this.testChatController = new TestChatController(apiUrl, supportedLanguages, new I18n(i18nContext), available_platform, test_chat_platform)

    this.cloneDeepSelectedIntentDetail = null
    this.TryItNowApi = apiUrl.TryItNow
    this.keywordBoundCount = 0
    this.agentGid = agent_gid
    this.listIntentLabels = []
    this.selectedIntentLabel = null
  }

  ready() {
    this.loadCategories()
      .then(()=>{ this.loadIntents() })
    this.loadIntentLabelOptions()
  }

  /**
   * Use for transfer input prediction
   * @param csrfToken
   * @returns {Promise<any>}
   */

  inputPredictionTransfer(csrfToken=null) {
    const options = {
      headers: {'X-CSRFToken': csrfToken}
    }
    return new Promise((resolve, reject) =>
      axios.post(this.userSaysSchronizeApi, {}, options)
        .then(res => resolve(res)).catch(err => reject(err))
      )
  }

  /**
   * make keyword full name by id
   */
  keywordPathById(){
    this.keywordCategoryApi.list()
      .then((instance)=>{
        const category =this.createCategoryPathIndex(instance)
        this.allKeywordApi.list()
          .then((keywords)=>{
            for(const  keyword of keywords){
              this.keywordFullNameById[keyword.id] = '@'+category[keyword.categoryId]+'_'+keyword.name
            }
          })
      })
  }
  /**
  * Export keyword
  */
  makeDownloadUrl(id){
    return VIEW_URL.ExportIntent.replace('<categoryId>', id)
  }
  /**
  * Import Json
  */
  importJson(data, importMode, duplicateIntents, csrfToken=null){
    let sendOptions = {}
    let options={}
    let this2 = this
    let id = 0
    Object.assign(sendOptions, options, {
      headers: {'X-CSRFToken': csrfToken}
    })
    if(this.selectedCategory !== null){
      id = this.selectedCategory.id
    }
    let params = {data: data, selectedCategoryId: id, mode: importMode, duplicateIntents:JSON.stringify(duplicateIntents)}
    let promise = new Promise((resolve, reject) => {
      axios.post(
        this.importURL,
        params,
        sendOptions
      )
        .then(()=>{
          this2.ready()
          resolve()
        })
        .catch((error)=>{
          console.log(error)
          reject(error)
        })
    })
    return [true, this.i18n.t("general.saving"), promise, importMode > 0 ? 0 : 1]
  }

  /**
   * Sorting
   */
  dynamicSort(property) {
    let sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        if(sortOrder == -1){
            return b[property].localeCompare(a[property]);
        }else{
            return a[property].localeCompare(b[property]);
        }
    }
  }

  /**
   * カテゴリ編集時の選択カテゴリ検索
   */
  cnt_category_id(array1, array2) {
    for (var item of array1){
      if(array2.id == item.id){
        var result = item;
        return result;
      }
      else if(item.subcategories.length != 0){
        var result2 = this.cnt_category_id(item.subcategories, array2)
        if(result2 != null){
          return result2;
        }
      }
    }
  }

  /**
   * 子カテゴリ追加時の選択カテゴリ検索
   */
  cnt_subcategory_id(array1, array2) {
    for (var item of array1){
      if(array2.parentId == item.id){
        var result = item.subcategories[item.subcategories.length - 1];
        return result;
      }
      else if(item.subcategories.length != 0){
        var result2 = this.cnt_subcategory_id(item.subcategories, array2)
        if(result2 != null){
          return result2;
        }
      }
    }
  }

  getAllCategoriesFlat(categories) {
    let i = 0;
    while (i < categories.length) {
      this.allCategoriesFlat.push(categories[i])
      this.getAllCategoriesFlat(categories[i].subcategories)
      i ++
    }
  }

  getCategoryById(categoryId) {
    this.allCategoriesFlat = []
    this.getAllCategoriesFlat(this.categories)

    let mask = this.allCategoriesFlat.map(item => item.id === categoryId)
    let selectedCategory = this.allCategoriesFlat.filter((item, i) => mask[i])[0]

    return selectedCategory
  }

  resetPreview() {
    this.prebuiltKeywords = []
  }

  /**
   * カテゴリ一覧取得
   */
  loadCategories() {
    return new Promise((resolve, reject)=>{
      this.intentCategoryApi.list()
      .then((instances) => {
        // IntentItem: Get tree list categories
        this.intentCategoryPath = this.createCategoryPathIndex(instances)
        // 初回アクセス時と削除操作時をはじく
        if (this.selectedCategory != null) {
          // 子カテゴリ
          if(this.selectedCategory.parentId != null){
            // 編集
            if (this.selectedCategory.id != null) {
              this.selectedCategory = this.cnt_category_id(instances, this.selectedCategory)
            }
            // 追加
            else {
              this.selectedCategory = this.cnt_subcategory_id(instances, this.selectedCategory)
              // インテント一覧更新
              this.loadIntents()
            }
            this.currentDisplayedCategory = this.selectedCategory
          }
          // 親カテゴリ
          else {
            // 編集
            if (this.categories.length == instances.length) {
              this.selectedCategory = this.cnt_category_id(instances, this.selectedCategory)
            }
            // 追加
            else {
              this.selectedCategory = this.cnt_category_id(instances, this.selectedCategory)
              this.currentDisplayedCategory = this.selectedCategory
              // インテント一覧更新
              this.loadIntents()
            }
          }
        }
        this.categories = instances
        this.categories.sort(this.dynamicSort("name"))
        resolve()
      })
      .catch((error) => {
        console.log(error)
      })
    })
  }

  /**
   * インテント一覧取得
   */
  loadIntents() {
    // インテント選択解除
    this.selectedIntent = null
    // インテント詳細選択解除
    this.selectedUserSay = null
    this.selectedResponseMessage = null
    this.desc = true

    let api = this.currentDisplayedCategory ? this.intentApi : this.allIntentApi
    let api2 = this.allIntentApi
    // 選択されているカテゴリがallかどうか
    let params = this.currentDisplayedCategory ? {category_id: this.selectedCategory.id} : {}
    let params2 = {}
    api.list({params: params})
      .then((instances) => {
        this.intents = instances
        // ページがロードした時だけ、cookieからIntent情報を取得する
        if (this.isMounted) {
          if(this.intentName === 'None') {
            this.loadIntentsFromCookie()
          } else {
            document.cookie = "intentId=" + null + "; path=/"
            let intent_name_list = this.intentName.split('_')
            this.intentName = intent_name_list.splice(-1,1)[0]
            let category = intent_name_list.join('_')
            let cat_id = null
            let category_path = this.createCategoryPathIndex(this.categories)
            for( let p in category_path){
              if(category_path[p] === category){
                cat_id = p
                break
              }
            }
            if(this.intents.length > 0) {
              this.intents = this.intents.filter(intent => intent.categoryId == cat_id)
              let intent = this.intents.filter(intent => intent.name == this.intentName)
              this.selectIntent(intent[0])
            }
          }
          this.isMounted = false
        }
        if(this.intents.length > 0) {
          this.intents.sort(this.dynamicSort("name"))
        }
      })
      .catch((error) => {
        console.log(error)
      })
    api2.list({params: params2})
      .then((instances) => {
        this.allIntents = instances
      })
      .catch((error) => {
        console.log(error)
      })
  }
  /**
   * インテント詳細取得
   */
  loadIntentDetail() {
    this.selectedIntentDetail = null
    this.selectedUserSay = null
    this.selectedResponseMessage = null

    this.intentDetailApi.list({
      params: {
        intent_id: this.selectedIntent.id,
        language: this.language
      }
    })
    .then((instances) => {
      if (instances.length == 0) {
        // データがない場合は空のIntentDetailを生成
        this.selectedIntentDetail = new IntentDetail
        this.keywordBoundCount = 0
      } else {
        // データを取得できた場合はリストに1件だけ入っているので先頭の要素を使う
        this.selectedIntentDetail = instances[0]
        this.cloneDeepSelectedIntentDetail = cloneDeep(instances[0])
        this.loadIntentDetailKeyword()
        this.loadTotalKeywordBoundForIntent()
      }
    })
    .catch((error) => {
      console.error(error)
    })
  }

  loadIntentDetailKeyword(){
    this.intentDetailKeywordApi.list({
      params: {
        intent_detail_id: this.selectedIntentDetail.id
      }
    })
      .then((instance)=>{
        this.intentDetailKeywordList = instance
      })
  }

  loadIntentDetailKeywordById(id){
    this.intentDetailKeywordApi.list({
      params: {
        keyword_id: id
      }
    })
      .then((instance)=>{
        this.intentDetailKeywordList = instance
      })
  }

  selectCategory(category) {
    this.selectedIntentDetail = null
    this.selectedIntentLabel = null
    if (!this.selectedCategory && this.selectedCategory === category) {
      return
    }
    //intent選択時に親カテゴリの選択を残すための画面表示用
    this.currentDisplayedCategory = category
    // 現在どのカテゴリの内容が表示されているかを示す。allSelectedの場合、nullになる
    this.displayCategory = category
    // カテゴリがnullであればAllが選択されている状態
    this.isAllSelected = !category
    // カテゴリを選択
    this.selectedCategory = category
    // reset keyword bound count
    this.keywordBoundCount = 0
    document.cookie = "intentId="+null+"; path=/"
    // 選択したカテゴリのインテント一覧を読み込む
    this.loadIntents()
  }

  /**
   * カテゴリを保存
   */
  saveCategory(category, csrfToken=null) {
    let promise = this.intentCategoryApi.save(
      category,
      csrfToken
    )
    .then((instance) => {
      // 保存したキーワードカテゴリを選択
      if (!category.id){
        category.id = instance.id
      }
      this.selectCategory(category)

      // カテゴリ一覧を更新
      this.loadCategories()
    })
    this.isAllSelected = false
    return [true, this.i18n.t("general.saving"), promise]
  }

  /**
   * カテゴリを削除
   */
  deleteCategory(category, csrfToken=null) {
    // メソッドgetAllCategoriesFlagを一時的に利用する
    let tempAllCategoriesFlat = this.allCategoriesFlat
    this.allCategoriesFlat = []
    this.getAllCategoriesFlat(this.categories.filter(ct=>!ct.scenarioId))
    let categoriesFlat = this.allCategoriesFlat
    this.allCategoriesFlat = tempAllCategoriesFlat
    let index = categoriesFlat.findIndex(ct=> ct.id === category.id)
    index = index - 1
    let promise = this.intentCategoryApi.destroy(
      category,
      csrfToken,
      {
        params: {
          id: category.id
        }
      }
    )
    .then(() => {
      this.keywordBoundCount = 0
      // インテント詳細選択解除
      this.selectedUserSay = null
      this.selectedResponseMessage = null
      // インテント選択解除
      this.selectedIntent = null
      // インテント一覧の初期化
      this.intents = []
      // インテントカテゴリ一覧を更新
      this.loadCategories()
      if (index >= 0) {
        this.selectedCategory = categoriesFlat[index]
      } else {
        this.selectedCategory = null
      }
    })
    return [true, this.i18n.t("general.saving"), promise]
  }

  /**
   * キーワードを保存
   */
  saveKeyword(keyword, csrfToken=null) {
    let promise = this.keywordApi.save(
      keyword,
      csrfToken,
      {
        params: {
          category_id: keyword.categoryId
        }
      }
    )
    this.isAllSelected = false
    return [true, this.i18n.t("general.saving"), promise]
  }

  /**
   * 存在しない場合はキーワード値を保存
   */
  saveKeywordValue(keywordValue,csrfToken=null){
    this.keywordValueApi.list({
      params: {
        keyword_id: keywordValue.keywordId,
        language: this.language
      }
    })
      .then((instances)=>{
        let isSynonym = false
        for (let instance of instances){
          if(instance.synonym){
            if(instance.synonym.indexOf(keywordValue.value)>=0){
              isSynonym=true
              break
            }
          }
        }
        if (!isSynonym) {
          this.keywordValueApi.save(
            keywordValue,
            csrfToken,
            {
              params: {
                keyword_id: keywordValue.keywordId,
                language: this.language
              }
            }
          )
        }
    })
  }

  /**
   * インテントを保存
   */
  saveIntent(intent, csrfToken=null){
    let isEditing = !!intent.id
    let selectedCategoryBefore = this.selectedCategory
    // intentのcategoryIdが変更されたかを示すフラグ
    let isParentCategoryChanged = false
    if(!isEditing){
      // 追加の場合、追加したところを特定する
      this.selectedCategory = this.getCategoryById(intent.categoryId)
      if (!this.isAllSelected) {
        this.currentDisplayedCategory = this.selectedCategory
      }
    }
    else{
      //　編集の場合、特定せず
      this.selectedCategory = this.displayCategory
      this.currentDisplayedCategory = this.displayCategory
      if(selectedCategoryBefore && selectedCategoryBefore.id !== intent.categoryId){
        isParentCategoryChanged = true
      }
    }
    let promise = this.intentApi.save(
      intent,
      csrfToken,
      {
        params: {
          category_id: intent.categoryId
        }
      }
    )
    .then((instance) => {
      // 新規の場合
      if (intent.id==null) {
        // InstanceDetailへのアクセスのためidだけ先にコピー
        intent.id = instance.id
        // インテント詳細選択解除
        this.selectedUserSay = null
        this.selectedResponseMessage = null
        // 一覧に追加
        this.intents.push(intent)
        // duplicationチェックのために追加しておく。
        this.allIntents.push(intent)
      } else {
        // 編集の場合
        this.allIntents = this.allIntents.map(element => {
          if (element.id == intent.id) {
            // カテゴリが変わったことを反映し、duplicationチェック時に活用
            element = intent
          }
          return element
        })
      }
      Object.assign(intent, instance)
      // loadIntentsで初期化する前に保存
      let userSay = this.selectedUserSay
      let responseMessage = this.selectedResponseMessage
      if(!isEditing){
        // 追加は前と同じ
        // インテンツ一覧を更新
        this.loadIntents()
        // インテントを選択状態にする
        this.selectIntent(intent)
        this.displayCategory = this.selectedCategory
      }
      else{
        let tempFlag = false
        if(isParentCategoryChanged){
          // categoryが変更された場合、変更先は表示されているカテゴリまたはサブカテゴリであるかどうか判断する
          tempFlag = this.selectedCategory&&this.isIdInCategory([this.selectedCategory], intent.categoryId)
        }
        let intentIndex = 0
        let nextIntent = null
        // this.loadIntents()は非同期のメソッドなので、実行する前に、次のエレメントを取っておく
        if(this.displayCategory && !tempFlag && isParentCategoryChanged){
          // allSelectedじゃなく、カテゴリが変更されて、変更先がサブカテゴリじゃない場合しか次のエレメントを取っておく必要がない
          // 編集されたエレメントの位置を特定
          this.intents.some((it, index)=>{
            if(it.id === intent.id){
              intentIndex = index
            }
          })
          // 位置によって、指すところを変える
          if(this.intents.length <= 1){}
          else if(intentIndex === this.intents.length - 1){
            nextIntent = this.intents[this.intents.length - 2]
          }
          else if(intentIndex < this.intents.length - 1){
            nextIntent = this.intents[intentIndex + 1]
          }
        }
        // インテンツ一覧を更新
        this.loadIntents()
        if(!this.displayCategory || !isParentCategoryChanged || tempFlag ){
          // allSelectedの場合、カテゴリが変更されてない場合、変更先がサブカテゴリの場合、編集されたintent自体を指す
          this.selectIntent(intent)
          // インテント詳細を選択状態にする
          this.selectUserSay(userSay)
          this.selectResponseMessage(responseMessage)
        }
        else if(nextIntent){
          // 次のエレメントが存在する場合、次のエレメントを指す
          this.selectIntent(nextIntent)
        }
        else{
          this.selectedIntentDetail = null
        }
      }
    })
    return [true, this.i18n.t("general.saving"), promise]
  }

  isIdInCategory(categories, categoryId){
    let result = categories.some((ct)=>{
      if(ct.id === categoryId)
        return true
      else if(ct.subcategories) {
        return this.isIdInCategory(ct.subcategories, categoryId)
      }
      else
        return false
    })
    return result
  }

  /**
   * インテント詳細を保存
   */
  saveIntentDetail(intentDetail, csrfToken=null) {
    let promise = this.intentDetailApi.save(
      intentDetail,
      csrfToken,
      {
        params: {
          intent_id: this.selectedIntent.id,
          language: this.language
        }
      }
    )
    .then((instance) => {
      Object.assign(intentDetail, instance)
      this.cloneDeepSelectedIntentDetail = cloneDeep(instance)
      this.loadTotalKeywordBoundForIntent()
    })
      .catch(err => {
        this.selectedIntentDetail = cloneDeep(this.cloneDeepSelectedIntentDetail)
        console.log(err)
      })
    // 連続で編集すると古いままになるためuserSayの選択をnullにする。
    this.selectedUserSay = null
    // responseMessageも同じ問題が存在するので、同じく選択をnullにする
    this.selectedResponseMessage = null
    return [true, this.i18n.t("general.saving"), promise]
  }

  allIntentDetailPlatformUpdate(selectedPlatform, platformFlag, csrfToken=null){
    let sendOptions = {}
    let options = {}
    let data = JSON.stringify({selected_platform : selectedPlatform, platform_flag : platformFlag, language : this.language})
    let params = {data: data}
    Object.assign(sendOptions, options, {
      headers: {'X-CSRFToken': csrfToken}
    })
    let promise = new Promise((resolve, reject) => {
      axios.post(
        this.selectedPlatformAPI,
        params,
        sendOptions
      )
        .then((instance) => {
          this.loadIntentDetail()
          resolve(instance)
        })
        .catch(error => reject(error))
    })
    return [true, this.i18n.t("general.saving"), promise]
  }

  /**
   * インテント選択
   */
  selectIntent(intent) {
    // 選択中なら何もしない
    if(this.selectedIntent == intent){
      return
    }
    if(!intent) {
      // nullが選択されたら、全部リセット
      this.selectedIntent = null
      this.selectedIntentDetail = null
      this.selectedUserSay = null
      this.selectedResponseMessage = null
      return
    }
    this.keywordBoundCount = 0
    // 選択されたintentのカテゴリを選択中にする
    this.selectedCategory = this.getCategoryById(intent.categoryId)
    this.isAllSelected = false
    this.currentDisplayedCategory = this.selectedCategory
    this.selectedIntentLabel = null

    // intentを選択
    this.selectedIntent = intent
    document.cookie = "intentId="+intent.id+"; path=/"
    // intentDetailの選択を解除
    this.selectedUserSay = null
    this.selectedResponseMessage = null

    // intent詳細をロード
    this.loadIntentDetail()
  }

  /**
   * インテントを削除
   */
  deleteIntent(intent, csrfToken=null) {
    // duplicationチェック用に更新する
    let index = this.intents.filter(it=>!it.scenarioId).findIndex(it=>it.id === intent.id)
    index -= 1
    this.allIntents = this.allIntents.filter(item => item.id != intent.id)

    let promise = this.intentApi.destroy(
      intent,
      csrfToken,
      {
        params: {
          id: intent.id
        }
      }
    )
    .then(() => {
      this.keywordBoundCount = 0
      // 表示されているdisplayCategoryを保持
      let tempCategory = this.selectedCategory
      this.selectedCategory = this.displayCategory
      this.currentDisplayedCategory = this.displayCategory
      // インテント一覧更新
      document.cookie = "intentId="+null+"; path=/"
      this.loadIntents()
      if(index >= 0){
        this.selectIntent(this.intents.filter(it=>!it.scenarioId)[index])
      }
      else{
        this.selectedIntent = null
        this.currentDisplayedCategory = tempCategory
      }
    })

    this.selectedCategory = this.currentDisplayedCategory

    return [true, this.i18n.t("general.saving"), promise]
  }

  resolveCategoryPath(category, categoryIdToCategory) {
    if (category.parentId == null) {
      return [category]
    } else {
      const parentCategory = categoryIdToCategory[category.parentId]
      return this.resolveCategoryPath(parentCategory, categoryIdToCategory).concat([category])
    }
  }

  /**
   * カテゴリ名のインデックス生成
   */
  createCategoryPathIndex(categories) {
    let flat = IntentCategory.categoriesAsFlat(categories)
    // categoryId -> categoryを作成
    let categoryIdToCategory = {}
    for (const [nestedLevel, category] of flat) {
      categoryIdToCategory[category.id] = category
    }
    // category -> カテゴリツリーのパス生成
    let categoryIdToPath = {}
    for (const [nestedLevel, category] of flat) {
      const categoryPath = this.resolveCategoryPath(category, categoryIdToCategory)
      categoryIdToPath[category.id] = categoryPath.map((c) => {return c.name}).join("_")
    }
    return categoryIdToPath
  }


  /**
   * キーワードカテゴリ一覧取得
   */
  loadKeywordCategories() {
    return this.keywordCategoryApi.list()
    .then((instances) => {
      this.keywordCategories = this.createKeywordCategoriesData(instances)
    })
    .catch((error) => {
      console.log(error)
    })
  }

  /**
   * カテゴリデータ
   */
  createKeywordCategoriesData(categories) {
    // ドロップダウンのカテゴリツリー生成
    let resultKeywordCategories = [{
      value: null,
      text: "---"
    }]
    let flat = KeywordCategory.categoriesAsFlat(categories)
    for (const [nestedLevel, category] of flat) {
      let indent = ""
      for (let i = 0; i < nestedLevel; i++) {
        indent += " » "
      }
      resultKeywordCategories.push({
        value: category,
        text: indent + category.name
      })
    }
    return resultKeywordCategories
  }

  /**
   * キーワードカテゴリを保存
   */
  saveKeywordCategory(category, csrfToken=null) {
    let promise = this.keywordCategoryApi.save(category, csrfToken)
    return [true, this.i18n.t("general.saving"), promise]
  }

  /**
   * UserSayElement保存
   */
  saveUserSay(userSay, csrfToken=null) {
    // idがnullなら追加
    if (userSay.id == null) {
      userSay.id = uuidv4()
      userSay.language = this.language
      this.selectedIntentDetail.data.addUserSay(userSay)
    }
    // インテントを保存する
    return this.saveIntentDetail(this.selectedIntentDetail, csrfToken)
  }

  /**
   * 渡されたintentDetailsをapiで保存する
   */
  updateSearchedUserSay(intentDetails, csrfToken=null) {
    let promises = []
    intentDetails.forEach((intentDetail, index) => {

      let promise = new Promise(resolve => {
        this.intentDetailApi.save(
          intentDetail,
          csrfToken,
          {
            params: {
              intent_id: intentDetail.intentId,
              language: this.language
            }
          }
        )
          .then((instance) => {
            Object.assign(intentDetail, instance)
            resolve()
          })
      })
      promises.push(promise)
    })

    return [true, this.i18n.t("general.saving"), promises]
  }

  /**
   * Save All UserSays
   */

  saveAllUserSays(userSays, csrfToken=null) {
    this.selectedIntentDetail.data.userSays = userSays
    this.saveIntentDetail(this.selectedIntentDetail, csrfToken)
  }

  /**
   * ResponseMessage保存
   */
  saveResponseMessage(responseMessage, csrfToken=null) {
    // idがnullなら追加
    if (responseMessage.id == null) {
      responseMessage.id = uuidv4()
      responseMessage.language = this.language
      this.selectedIntentDetail.data.addResponseMessage(responseMessage)
    }
    // インテントを保存する
    return this.saveIntentDetail(this.selectedIntentDetail, csrfToken)
  }

  /**
   * Save All ResponseMessage
   */
  saveAllResponseMessage(responseMessages, csrfToken=null){
    this.selectedIntentDetail.data.responseMessages = responseMessages
    this.saveIntentDetail(this.selectedIntentDetail, csrfToken)
  }

  /**
   * UserSayを選択
   */
  selectUserSay(userSay) {
    this.selectedUserSay = userSay
  }

  /**
   * UserSayを削除
   */
  deleteUserSay(csrfToken) {
    if (this.selectedIntent==null || this.selectedUserSay==null) {
      return
    }
    const index = this.selectedIntentDetail.data.userSays.indexOf(this.selectedUserSay)
    // 対象のUserSayを削除
    this.selectedIntentDetail.data.userSays.splice(index, 1)
    this.selectedUserSay = null
    return this.saveIntentDetail(this.selectedIntentDetail, csrfToken)
  }
  /**
   * delete multiple userSay
   */
  deleteMultipleUserSay(userSays=null, csrfToken){
    if (this.selectedIntent==null) {
      return
    }
    userSays.forEach(userSay => {
      const index = this.selectedIntentDetail.data.userSays.indexOf(userSay)
      this.selectedIntentDetail.data.userSays.splice(index, 1)
    })
    let promise = this.intentDetailMultipleDeleteApi.save(
      this.selectedIntentDetail,
      csrfToken,
      {
        params: {
          intent_id: this.selectedIntent.id,
          language: this.language
        }
      }
    )
      .then((instance) => {
        Object.assign(this.selectedIntentDetail, instance)
        this.loadTotalKeywordBoundForIntent()
      })
    // 連続で編集すると古いままになるためuserSayの選択をnullにする。
    this.selectedUserSay = null
    // responseMessageも同じ問題が存在するので、同じく選択をnullにする
    this.selectedResponseMessage = null
    return [true, this.i18n.t("general.saving"), promise]
  }

  /**
   * ResponseMessageを選択
   */
  selectResponseMessage(responseMessage) {
    this.selectedResponseMessage = responseMessage
  }

  /**
   * ResponseMessageを削除
   */
  deleteResponseMessage(csrfToken) {
    if (this.selectedIntent==null || this.selectedResponseMessage==null) {
      return
    }
    const index = this.selectedIntentDetail.data.responseMessages.indexOf(this.selectedResponseMessage)
    // 対象のUserSayを削除
    this.selectedIntentDetail.data.responseMessages.splice(index, 1)
    this.selectedResponseMessage = null
    return this.saveIntentDetail(this.selectedIntentDetail, csrfToken)
  }

  /**
   * 質問の部分一致検索を呼び出す
   */
  searchQuestion(searchString, intentCategory=null) {
    //　データベースから取り出さなくて、既存のcategoriesとallIntentsを利用する
    let allCategories = this.allCategoriesToArray([], this.categories)
    let allIntents = this.allIntents
    let categoryId = null
    if(intentCategory){
      categoryId = intentCategory.id
    }
    this.intentDetailSearchApi.list({
      params: {
        language: this.language,
        search_string: JSON.stringify(searchString),
        category_id: categoryId
      }
    })
    .then((intentDetails) => {
      this.questionSearchResult = intentDetails.map(intentDetail => {
        let intent = allIntents.filter(it=>it.id === intentDetail.intentId)[0]
        let names = []
        names.push(intent.name)
        names = this.allCategoryNamesToArray(names, allCategories, intent.categoryId)
        return {
          intentDetailId: intentDetail.id,
          intentDetail: intentDetail,
          title: names.reverse().join(' >> ')
        }
      })
      // Find meta name using searchString
      let meta = null
      let flag = false
      for(let intentDetail of intentDetails){
        if('userSays' in intentDetail.data){
          for(let userSay of intentDetail.data['userSays']){
            for(let word of userSay.data){
              if(word.text == searchString[0]){
                meta = word.meta
                flag = true
                break
              }
            }
          }
        }
        if(flag){
          break
        }
      }
    //  find keyword_id using meta name
      let keyword_id = null
      for(let index in this.keywordFullNameById){
        if(this.keywordFullNameById[index] === meta){
          keyword_id = index
          break
        }
      }
      // load searchString intentDetail and keyword dependency
      if(keyword_id !== null){
        this.loadIntentDetailKeywordBySearch(keyword_id)
      }
      else{
        this.intentDetailKeywordListBySearch = []
      }
    })
    .catch((error) => {
      console.log(error)
    })
  }
  /**
   * Load intentdetail and keyword dependency according to search string
   */
  loadIntentDetailKeywordBySearch(id){
    this.intentDetailKeywordApi.list({
      params: {
        keyword_id:id
      }
    })
      .then((instance)=>{
        this.intentDetailKeywordListBySearch = instance
      })
  }
  /**
   * カテゴリをflatにする
   */
  allCategoriesToArray(allCategories, categories){
    categories.forEach((category)=>{
      allCategories.push({id: category.id, name: category.name, parentId: category.parentId})
      if(category.subcategories)
        this.allCategoriesToArray(allCategories, category.subcategories)
    })
    return allCategories
  }

  /**
   * サブカテゴリも含めた全カテゴリの名前をリストにする
   */
  allCategoryNamesToArray(names, allCategories, categoryId){
    let category = allCategories.filter(ct => ct.id === categoryId)[0]
    names.push(category.name)
    if(category.parentId)
      names = this.allCategoryNamesToArray(names, allCategories, category.parentId)
    return names
  }

  /**
   * 質問一括登録
   */
  saveMultipleUserSays(userSayText, csrfToken=null) {
    // userSayTextの中身を、行区切りでlistに格納
    let textArray = userSayText.split(/\r\n|\r|\n/);
    textArray = textArray.filter(item=>item!="")
    textArray.forEach((text, index) => {
      let userSay = new UserSay()
      userSay.data = [new Word(text)]
      userSay.id = uuidv4()
      userSay.language = this.language
      this.selectedIntentDetail.data.addUserSay(userSay)
    })

    return this.saveIntentDetail(this.selectedIntentDetail, csrfToken)
  }

  /**
   * 他言語のuserSaysを参照する機能でロードするときに呼び出し
   */
  loadSelectedLanguageUserSays(language_code) {
    if (!language_code) {
      this.selectedLanguageIntentDetail = null
      return
    }
    this.intentDetailApi.list({
      params: {
        intent_id: this.selectedIntent.id,
        language: language_code
      }
    })
    .then((instances) => {
      if (instances.length == 0) {
        this.selectedLanguageIntentDetail = new IntentDetail
      } else {
        this.selectedLanguageIntentDetail = instances[0]
      }
    })
    .catch((error) => {
      console.log(error)
    })
  }

  copyUserSays(intentDetail) {
    let userSaysTexts = intentDetail.data.userSays.map(userSay => {
      return userSay.text
    }).join('\n')
    copyToClipboard(userSaysTexts)
    let message = this.i18n.t('question.copiedUserSays')
    alert(message)
  }
  copyToAgent(intentIds, targetAgent, csrfToken=null) {
    let data = JSON.stringify({ids: intentIds, targetAgent: targetAgent})
    let sendOptions = {}
    let options={}
    let this2 = this
    Object.assign(sendOptions, options, {
      headers: {'X-CSRFToken': csrfToken}
    })
    let params = {data: data}
    let promise = new Promise((resolve, reject) => {
      axios.post(
        this.intentsCopyToAgent,
        params,
        sendOptions
      )
      .then(()=>{
        resolve()
      })
      .catch((error)=>{
        console.log(error)
        reject(error)
      })
    })
    return [true, this.i18n.t("general.saving"), promise]
  }

  deleteMultiIntent(intentIds, csrfToken=null){
    let sendOptions = {}
    let options={}
    Object.assign(sendOptions, options, {
      headers: {'X-CSRFToken': csrfToken}
    })
    let promise = new Promise((resolve, reject) => {
      axios.post(
        this.multiIntentDeleteApi,
        {intent_ids: intentIds},
        sendOptions
      )
      .then(()=>{
        this.allIntents = this.allIntents.filter(item => !intentIds.includes(item.id))
        this.intents = this.intents.filter(item => !intentIds.includes(item.id))
        resolve()
      })
      .catch((error)=>{
        console.log(error)
        reject(error)
      })
    })
    return [true, this.i18n.t("general.saving"), promise]
  }

  /**
   * synchronizeControllerを呼び出してdialogflowへの同期を開始する
   */
  startSynchronize(csrfToken=null, isCard) {
    let synchResult = this.synchronizeController.synchronize(csrfToken, isCard)
    return synchResult
  }

  /**
   * 全インテントのフィードバックを有効・無効に設定する
   */
  toggleAllFeedback(configureFeedbackForm, csrfToken=null) {
    return new Promise((resolve, reject) => {
      let sendOptions = {}
      let options = {}
      Object.assign(sendOptions, options, {
        headers: {'X-CSRFToken': csrfToken}
      })
      // rest-apiのラッパーでは複数更新が難しいため、直接axios.postを呼ぶ
      axios.post(
        this.apiUrl.Intents,
        {configure_feedback_form: configureFeedbackForm},
        sendOptions
      )
      .then(() => {
        return this.loadCategories()
      })
      .then(() => {
        return this.loadIntents()
      })
      .then(() => {
        resolve()
      })
      .catch((error) => {
        console.log(error)
        reject(error)
      })
    })
  }

  /**
   * Import Intents from excel file preview
   */
  importIntentsFromExcelPreview(file, csrfToken=null){
    let sendOptions = {}
    Object.assign(sendOptions,{
      headers: {'X-CSRFToken': csrfToken, 'Content-Type': 'multipart/form-data'}
    })
    let promise = new Promise((resolve, reject) => {
      axios.post(
        this.apiUrl.ImportIntentFromExcelPreview,
        file,
        sendOptions
      )
        .then((instance) => {
          resolve(instance)
        })
        .catch(error => reject(error))
    })
    return [true, this.i18n.t("general.saving"), promise]
  }
  /**
   * Import Intents from excel file preview
   */
  importIntentsFromExcelSave(file, csrfToken=null){
    let sendOptions = {}
    Object.assign(sendOptions,{
      headers: {'X-CSRFToken': csrfToken, 'Content-Type': 'multipart/form-data'}
    })
    let promise = new Promise((resolve, reject) => {
      axios.post(
        this.apiUrl.ImportIntentFromExcelSave,
        file,
        sendOptions
      )
        .then((instance) => {
          resolve(instance)
        })
        .catch(error => reject(error))
    })
    return [true, this.i18n.t("general.saving"), promise]
  }
  /**
   * load intent list using Cookie
   * while doing reload through browser
   */
  loadIntentsFromCookie(){
    const api = this.intentApi
    const prevIntentId = cookies.getIntentIdFromCookie(document.cookie)
    if (prevIntentId) {
      const selectedIntent = this.intents.filter(intent => parseInt(intent.id) === parseInt(prevIntentId))
      if (selectedIntent.length) {
        this.intents = []
        const intentCategoryId = {category_id: selectedIntent[0].categoryId}
        api.list({params: intentCategoryId})
            .then((instances) => {
              this.intents = instances
            })
            .catch((error) => {
              console.log(error)
            })
        this.selectIntent(selectedIntent[0])
      } else {
        document.cookie = "intentId=" + null + "; path=/"
      }
    }
  }

  /**
   * Get current agent ID from server
   *
   * @param agentID
   * @returns {Promise<any>}
   */
  hasChangedAgent(agentID) {
    return new Promise((resolve, reject) => {
      axios.get(this.apiUrl.IdentifyAgentApi,{
        params: {
          agent_id: agentID ? agentID : ''
        }
      }).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
    })
  }

  /**
   * Detect locale language
   * @returns {*}
   */
  getLanguageCode() {
    return !this.language ? this.i18n.context.locale : this.language
  }

  /**
   * Detect Intent for checking agent state
   * @param param
   * @param csrfToken
   * @returns {Promise<unknown>}
   */
  checkAgentState(param, csrfToken='') {
    let sendOptions = {}
    let options = {}
    let params = {
      user_say: param.text,
      language: param.language
    }
    Object.assign(sendOptions, options, {
      headers: {'X-CSRFToken': csrfToken}
    })
    return new Promise((resolve, reject) => {
      axios.post(
        this.TryItNowApi,
        params,
        sendOptions
      ).then((instance) => {
        let result = false
        const response = JSON.parse(instance.data)
        if("intent" in response) {
          result = true
        }
        resolve(result)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  /**
   * Get list of files through pagination
   *
   * @param params
   * @returns {Promise<unknown>}
   */
  getFileContents(params) {
    return new Promise((resolve, reject) => {
      axios.get(this.apiUrl.ListFileContents,{
        params: {
          page: params.page
        }
      }).then(function(response) {
        // handle success
        resolve(response.data)
      }).catch(function(error) {
        // handle error
        reject(error)
        console.log(error)
      })
    })
  }

  /**
   * count total keyword bound for a intent
   */
  loadTotalKeywordBoundForIntent(){
    return new Promise((resolve, reject) => {
      axios.get(this.apiUrl.keywordBoundCount,{
          params: {
            intent_id: this.selectedIntent ? this.selectedIntent.id : null
          }
        }
      )
        .then((instance) => {
          this.keywordBoundCount = instance.data.total_keyword_bound
          resolve(instance)
        })
        .catch(function(error){
          reject(error)
          console.log(error)
        })
    })
  }

  loadIntentLabelOptions() {
    this.intentLabelApi.list().then(instance => this.listIntentLabels = instance)
  }
  getIntentLabelOptions() {
    this.intentLabelOptions = []
    if(this.listIntentLabels) {
      this.listIntentLabels.forEach(intentLabel => {
        this.intentLabelOptions.push({
          "value": intentLabel.id,
          "text": intentLabel.name
        })
      })
    }
  }

  setIntentLabelBgColor(intentLabel) {
    let cssString = ""
    if(intentLabel) {
      if(intentLabel.bgcolor !== "#FFFFFF") {
        cssString = "background: "+ intentLabel.bgcolor
        cssString += "; color: #FFFFFF"
      } else {
        cssString = "background: "+ intentLabel.bgcolor
        cssString += "; color: #000000"
      }
    }
    return cssString
  }
  getIntentsByIntentLabel(intentLabel) {
    if(intentLabel && this.allIntents) {
      this.intents = this.allIntents.filter(intent => {
        return JSON.parse(JSON.stringify(intent.intentLabelIds)).includes(intentLabel.id)
      })
    }
  }

  updateLabelMultiIntent(form, csrfToken=null){
    let sendOptions = {}
    let options={}
    Object.assign(sendOptions, options, {
      headers: {'X-CSRFToken': csrfToken}
    })
    const formData = {
      intent_ids: form['intentIds'],
      intent_label_ids: form['intentLabelIds']
    }
    let promise = new Promise((resolve, reject) => {
      axios.post(
        this.multiIntentUpdateLabelApi,
        formData,
        sendOptions
      )
      .then(() =>{
        resolve(this.loadIntents())
      })
      .catch((error)=>{
        console.log(error)
        reject(error)
      })
    })
    return [true, this.i18n.t("general.saving"), promise]
  }

  selectIntentLabel(intentLabel) {
    let result = false
    if(this.selectedIntentLabel) {
      this.isAllSelected = false
      this.currentDisplayedCategory = null
      this.selectedIntent = null
      this.selectedCategory = false // enable to select All category
      result = intentLabel.id === this.selectedIntentLabel.id
    }
    return result
  }
}

export {
  IntentEditorController
}
