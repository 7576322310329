<template>
  <div
    class="d-flex flex-column px-0 border h-100"
    id="chat-log-area"
  >
    <div
      v-if="sessionChatLogs && sessionChatLogs.length >= 1"
      :style="{ overflowY: 'scroll', flexGrow: 1 }"
      ref="chatLog"
    >
      <div class="load-older-message-area">
        <div class="text-center">
          <div v-if="!isLoadingOlderMessage">
            <button
              class="btn btn-xs btn-light button-text"
              @click="onClickLoadOlderMessage"
            >
              <i class="mr-1 fas fa-redo"></i>
              <span>{{ $t("history.readOldLog") }}</span>
            </button>
            <button
              class="ml-2 btn btn-xs btn-danger button-text pc-hidden"
              @click="cancelSelectedOperatorSession"
            >
              <i class="mr-1 fas fa-times"></i>
              <span>{{ $t("history.returnSessionList") }}</span>
            </button>
          </div>

          <div v-else class="text-primary">
            {{ $t("history.loading") }}
            <b-spinner
              variant="primary"
              type="grow"
              style="width: 0.5rem; height: 0.5rem"
            />
            <b-spinner
              variant="warning"
              type="grow"
              style="width: 0.5rem; height: 0.5rem"
            />
            <b-spinner
              variant="success"
              type="grow"
              style="width: 0.5rem; height: 0.5rem"
            />
          </div>
        </div>
      </div>

      <div
        v-for="(sessionChatLog, cidx) in sessionChatLogs"
        :key="`${cidx}`"
        class="col-12 px-1"
      >
        <!-- 日付 -->
        <div
          v-if="isDateDisplayed(cidx)"
          class="d-flex align-items-center justify-content-center text-nowrap"
        >
          <div :style="{ borderTop: '1px solid #dddddd', width: '37%' }" />
          <div class="info-message px-4">
            {{ sessionChatLog.insertDateTime | moment("YYYY-MM-DD") }}
          </div>
          <div :style="{ borderTop: '1px solid #dddddd', width: '37%' }" />
        </div>

        <!-- 質問の描画 -->
        <div
          v-if="sessionChatLog.logType === 'usersay'"
          class="row flex-column w-100 my-2 ml-1"
        >
          <div class="d-flex align-items-center mr-5">
            <b-avatar size="2em" variant="success"></b-avatar>
            <div class="ml-1 px-2 chat-massage-user" style="overflow-wrap: anywhere">
              {{ sessionChatLog.text }}
            </div>
            <div class="d-flex h-100 align-items-end ml-1 mt-2">
              <span
                class="pointer-cursor"
                v-b-tooltip.hover.bottom
                :title="chatTime(sessionChatLog.insertDateTime)"
                :style="{ color: '#888888', fontSize: '0.8em' }"
              >
                {{ formatTime(sessionChatLog.insertDateTime) }}
              </span>
            </div>
          </div>

          <!-- 翻訳文 -->
          <translation-line
            :translation="translatedText(sessionChatLog.text, sessionChatLog)"
            type="query_text"
          >
          </translation-line>
        </div>
        <!-- ファイル -->
        <div
          v-else-if="sessionChatLog.logType === 'file_usersay'"
          class="row flex-column w-100 my-2 ml-1"
        >
          <div class="d-flex align-items-center mr-5">
            <b-avatar size="2em" variant="success"></b-avatar>
            <div v-if="sessionChatLog.url" class="mt-2 ml-1">
              <b-img
                class="pointer-cursor"
                thumbnail
                fluid
                style="max-width: 200px; max-height: 200px"
                :src="sessionChatLog.url"
                alt="Uploaded Image"
                v-show="isImage(sessionChatLog.url)"
                @click="openFile(sessionChatLog.url)"
              />

              <video
                class="pointer-cursor"
                v-show="isVideo(sessionChatLog.url)"
                :src="sessionChatLog.url"
                width="120"
                height="120"
                controls
              />

              <div
                v-show="isFile(sessionChatLog.url)"
                class="text-info pointer-cursor"
                @click="openFile(sessionChatLog.url)"
              >
                <i class="far fa-file" style="width: 100px; height: 100px"></i>
              </div>
            </div>
            <div class="d-flex h-100 align-items-end ml-1 mt-2">
              <span
                class="pointer-cursor"
                v-b-tooltip.hover.bottom
                :title="chatTime(sessionChatLog.insertDateTime)"
                :style="{ color: '#888888', fontSize: '0.8em' }"
              >
                {{ formatTime(sessionChatLog.insertDateTime) }}
              </span>
            </div>
          </div>
        </div>

        <!-- 回答の描画 -->
        <div v-else class="d-flex justify-content-end w-100 my-2">
          <div
            v-if="sessionChatLog.intentName === operatorDisplayName.START"
            class="info-message d-flex justify-content-center w-100"
          >
            <span
              class="pointer-cursor"
              v-b-tooltip.hover.bottom
              :title="chatTime(sessionChatLog.insertDateTime)"
            >
              {{ $t("history.operatorModeStart") }}
            </span>
          </div>

          <!-- 呼び出し中はインテント名がCALLINGではないためchat-responseで表示を行う。 -->
          <div
            v-else-if="sessionChatLog.intentName === operatorDisplayName.FINISH"
            class="info-message d-flex justify-content-center w-100"
          >
            <span
              class="pointer-cursor"
              v-b-tooltip.hover.bottom
              :title="chatTime(sessionChatLog.insertDateTime)"
            >
              {{ $t("history.operatorModeEnd") }}
            </span>
          </div>

          <div
            v-else-if="sessionChatLog.logType !== 'usersay' && session"
            class="d-flex align-items-center ml-5 mr-1"
          >
            <div class="d-flex flex-column align-items-end mr-1">
              <!-- オペレーターからのメッセージであることを示すアイコン -->
              <b-avatar size="2em" variant="primary">
                <i class="fas fa-headset"></i>
              </b-avatar>
              <!-- メッセージの送信時刻 -->
              <span
                class="pointer-cursor"
                v-b-tooltip.hover.bottom
                :title="chatTime(sessionChatLog.insertDateTime)"
                :style="{ color: '#888888', fontSize: '0.8em' }"
              >
                {{ formatTime(sessionChatLog.insertDateTime) }}
              </span>
            </div>

            <!-- 各種回答描画 -->
            <div class="chat-response">
              <!-- テキストやカードなどの回答表示領域 -->
              <div v-if="sessionChatLog.logType === 'text'">
                <div v-if="sessionChatLog.text" class="mb-1 message-container">
                  <div class="message-bg-grey" style="white-space: pre-line; overflow-wrap: anywhere">
                    <span>{{ sessionChatLog.text }}</span>
                  </div>

                  <translation-line
                    :translation="translatedText(sessionChatLog.text, sessionChatLog)"
                    type="response_text"
                  ></translation-line>
                </div>
              </div>

              <div v-else-if="sessionChatLog.logType === 'quick_replies'">
                <div class="message-bg-grey">
                  <div class="card-header">
                    <div>
                      {{ sessionChatLog.text }}
                    </div>
                    <translation-line
                      :translation="translatedText(sessionChatLog.text, sessionChatLog)"
                      type="response_text"
                    ></translation-line>
                  </div>

                  <div class="card-button-container">
                    <div
                      v-for="(option, idx) in sessionChatLog.options"
                      :key="idx"
                      class="ml-2"
                    >
                      <div class="d-flex align-items-center">
                        <div>
                          {{ option }}
                        </div>
                      </div>

                      <translation-line
                        :translation="translatedText(option, sessionChatLog)"
                        type="response_card_content">
                      </translation-line>
                    </div>
                  </div>
                </div>
              </div>

              <div v-else-if="sessionChatLog.logType === 'card'">
                <div class="message-bg-grey">
                  <div class="card-header">
                    <div>
                      {{ sessionChatLog.title }}
                    </div>
                    <translation-line
                      :translation="translatedText(sessionChatLog.title, sessionChatLog)"
                      type="response_card_content"
                    ></translation-line>

                    <div class="card-subtitle">
                      {{ sessionChatLog.subtitle }}
                    </div>
                    <translation-line
                      :translation="translatedText(sessionChatLog.subtitle, sessionChatLog)"
                      type="response_card_content"
                    ></translation-line>
                  </div>

                  <b-img
                    v-if="sessionChatLog.imageUrl"
                    thumbnail
                    fluid
                    :src="sessionChatLog.imageUrl"
                    alt="Image"
                  />

                  <div class="card-button-container">
                    <div
                      v-for="(button, idx) in sessionChatLog.buttons"
                      :key="idx"
                      class="ml-2"
                    >
                      <div class="d-flex align-items-center">
                        <div>
                          {{ button.text }}
                        </div>
                        <a
                          v-if="button.url"
                          :href="button.url"
                          target="_blank"
                          class="text-dark"
                        >
                          <i class="fas fa-external-link-alt ml-3"></i>
                        </a>
                      </div>

                      <translation-line
                        :translation="translatedText(button.text, sessionChatLog)"
                        type="response_card_content"
                      ></translation-line>
                    </div>
                  </div>
                </div>
              </div>

              <div v-else-if="sessionChatLog.logType === 'image'">
                <div class="message-bg-grey">
                  <b-img
                    thumbnail
                    fluid
                    :src="sessionChatLog.url"
                    alt="Image"
                  />
                </div>
              </div>

              <div v-else-if="sessionChatLog.logType === 'audio'">
                <div class="message-bg-grey">
                  <audio controls style="width: 100%; max-height: 200px">
                    <source :src="sessionChatLog.src" />
                  </audio>
                </div>
              </div>

              <div v-else-if="sessionChatLog.logType === 'video'">
                <div class="message-bg-grey">
                  <video
                    controls
                    :poster="sessionChatLog.previewSrc"
                    width="100%"
                    height="200"
                  >
                    <source :src="sessionChatLog.src" />
                  </video>
                </div>
              </div>
            </div>
          </div>

          <!-- TODO -->
          <div v-else-if="false" class="noResponseMessage mb-1">
            {{ $t("history.webhookActionWithoutResponse") }}
          </div>

          <div
            v-else-if="
              sessionChatLog.intentName === operatorDisplayName.OPERATOR_MODE
            "
            class="message-not-show mb-1"
          />
        </div>
      </div>
    </div>

    <!-- メッセージ送信ボタン -->
    <div
      v-if="sessionChatLogs && sessionChatLogs.length >= 1"
      class="border-horizontal text-center pt-2 pb-1"
    >
      <b-button
        variant="success"
        @click="openCreateAnswerModal"
        style="width: 40%"
      >
        <i class="fas fa-edit"></i>
        {{ $t('history.reply') }}
      </b-button>
      <b-button
        v-if="isScrollToBottomButtonVisible"
        pill
        class="ml-4"
        :title="$t('history.chatLogScrollButtonMsg')"
        @click="chatLogScrollToBottom"
      >
        <i class="fas fa-arrow-down"></i>
      </b-button>
    </div>

    <send-message-to-user
      v-if="session"
      ref="sendMessageToUserModal"
      :platform="session.platform"
      :sessionId="session.sessionId"
      :ui-language-code="uiLanguageCode"
      :current-chat-language-code="currentChatLanguageCode"
      :user-meta="userMeta"
      @send-push-message="
        $emit(
          'on-push-message-confirm',
          $event,
          isToBeOperator,
          isSetOperatorStatusActive,
          allowUserNotification && isUserNotificationEnabled
        )
      "
      @on-call-translate="onCallTranslate($event)"
    >
      <template v-slot:operator>
        <div>
          {{ $t("history.MakeYourselfTheOperatorInChargeAfterRepliying") }}:
          <b-form-checkbox
            switch
            v-model="isToBeOperator"
            :disabled="isDisabledToBeOperatorSwitch"
          ></b-form-checkbox>
        </div>
        <div>
          {{ $t("history.StartOperatorSession") }}:
          <b-form-checkbox
            v-model="isSetOperatorStatusActive"
            switch
          ></b-form-checkbox>
        </div>

        <!-- ユーザーへのメール通知用のチェックボックス。ユーザーメタのメールアドレスがあるときのみ表示 -->
        <div v-if="allowUserNotification && userMeta && userMeta.email">
          {{ $t("history.SendNotificationToUser") }}:
          <b-form-checkbox
            v-model="isUserNotificationEnabled"
            switch
          />
        </div>
      </template>
    </send-message-to-user>
  </div>
</template>

<script>
import { truncateText } from "../../util/truncate-text";
import OperatorHandlingSessionStatusIcon from "./OperatorHandlingSessionStatusIcon.vue";
import TranslationLine from "./TranslationLine.vue";
import { operatorDisplayName } from "../../util/operatorStatus";

export default {
  components: { OperatorHandlingSessionStatusIcon, TranslationLine },
  name: "MessageSection",
  props: [
    "sessionChatLogs",
    "session",
    "bookmarks",
    "translationLogs",
    "uiLanguageCode",
    "controller",
    "userMeta",
    "allowUserNotification"
  ],
  data() {
    return {
      operatorDisplayName: operatorDisplayName,
      isLoadingOlderMessage: false,
      isToBeOperator: true,
      isSetOperatorStatusActive: true,
      isUserNotificationEnabled: true,
      chatLogScrollTop: 0,
      chatLogScrollHeight: 0,
    };
  },
  /**
   * 初期表示時に一番下のログにスクロールする。
   */
  mounted() {
    this.$nextTick(() => {
      /** 
       * 遅延を入れないとスクロールが中途半端な位置になる
       * TODO: 正確なscrollHeightが取得できるタイミングでスクロールするように修正
      */
      setTimeout(() => {
        const element = this.$refs.chatLog;
        if (element) {
          this.chatLogScrollHeight = element.scrollHeight;
          element.addEventListener("scroll", () => {
            this.chatLogScrollTop = this.$refs.chatLog?.scrollTop ?? 0;
          });
        }
        this.chatLogScrollToBottom();
      }, 300);
    });
  },
  computed: {
    chatTime() {
      return (date) => {
        return `${date.getHours()}:${("0" + date.getMinutes()).slice(-2)}`;
      };
    },
    isImage() {
      return (path) => {
        const [extension, ..._nameParts] = path.split(".").reverse();
        return ["jpg", "jpeg", "png"].includes(extension);
      };
    },
    isVideo() {
      return (path) => {
        const [extension, ..._] = path.split(".").reverse();
        return extension === "mp4";
      };
    },
    isFile() {
      return (path) => {
        const [extension, ..._] = path.split(".").reverse();
        return !["mp4", "jpeg", "jpg", "png"].includes(extension);
      };
    },
    /** テナントに所属しないユーザーは担当者自動設定ができない */
    isDisabledToBeOperatorSwitch() {
      const nowUserId = Number(this.controller.nowUser);
      const findNowUser = this.controller.operators.filter(
        (operator) => operator.id === nowUserId
      );
      if (findNowUser.length > 0) {
        return false;
      } else {
        this.isToBeOperator = false;
        return true;
      }
    },
    currentChatLanguageCode() {
      const chatLogs = this.sessionChatLogs || [];
      if (chatLogs.length > 0) {
        return this.sessionChatLogs.slice(-1)[0].languageCode
      } else {
        return null;
      }
    },
    /** スクロールボタンを表示するかどうか */
    isScrollToBottomButtonVisible() {
      return this.chatLogScrollTop + (this.$refs.chatLog?.clientHeight ?? 0) < this.chatLogScrollHeight;
    },
    translatedText() {
      return (text, sessionChatLog) => {
        const translateKey = `${sessionChatLog.languageCode}__${text}`;
        const translated = this.translationLogs[translateKey];
        if (translated && !translated.is_same_language && translated.text !== text) {
          return translated.text;
        }
        return "";
      };
    }
  },
  methods: {
    openFile(url) {
      window.open(url);
    },
    truncateText: truncateText,
    /** 回答作成モーダルを開く */
    openCreateAnswerModal() {
      this.$refs.sendMessageToUserModal.showSendMessageForm();
    },
    /** 古いメッセージの読み込みが押されたときの処理 */
    onClickLoadOlderMessage() {
      this.isLoadingOlderMessage = true;
      this.$emit("on-click-load-older-message");
    },
    /**
     * 古いメッセージの読み込みが終わったときの処理。
     * 親コンポネントからrefで呼び出す。
     * 読み込み中のアニメーションを停止する。
     * @param {boolean} existsOlderMessage 今より古いメッセージが存在するか。存在しない場合にメッセージを出すように使用。
     */
    onCompleteLoadOlderMessage(existsOlderMessage) {
      this.isLoadingOlderMessage = false;
      if (!existsOlderMessage) {
        // これ以上古いメッセージがないことを表示。
        this.$bvToast.toast(this.$t("There is no older message"), {
          autoHideDelay: 4000,
          toaster: "b-toaster-bottom-right",
          variant: "warning",
          noCloseButton: true,
        });
      } else {
        setTimeout(() => {
          this.chatLogScrollHeight = this.$refs.chatLog?.scrollHeight ?? 0;
        }, 300);
      }
    },
    /**
     * ログの日付を表示すべきかを返す。
     * ある日の一番最初のログなら日付を表示すべき。
     */
    isDateDisplayed(idx) {
      if (idx === 0) {
        // 一番最初のログなので日付は表示
        return true;
      }

      const logDate = new Date(this.sessionChatLogs[idx].insertDateTime);
      const previousLogDate = new Date(
        this.sessionChatLogs[idx - 1].insertDateTime
      );
      // 年月日が完全一致しなければある日付の最初のログとなるためtrue
      return !(
        logDate.getFullYear() === previousLogDate.getFullYear() &&
        logDate.getMonth() === previousLogDate.getMonth() &&
        logDate.getDate() === previousLogDate.getDate()
      );
    },
    formatTime(date, start = 11, end = 16) {
      return `${date.getHours()}:${("0" + date.getMinutes()).slice(-2)}`;
    },
    onCallTranslate($event) {
      this.$emit('on-call-translate', $event, this);
    },
    // 親コンポーネントから呼び出されているので消さないように注意
    applyTranslationResult(params) {
      this.$refs.sendMessageToUserModal.applyTranslationResult(params);
    },
    displayTranslationText(chatLogLanguageCode) {
      if (chatLogLanguageCode === "zh-tw") {
        chatLogLanguageCode = "zh-hant";
      } else if (chatLogLanguageCode === "zh-cn") {
        chatLogLanguageCode = "zh-hans";
      }
      return this.uiLanguageCode != chatLogLanguageCode;
    },
    cancelSelectedOperatorSession() {
      // スマートフォン操作用: セッションの選択を解除する
      this.$emit("cancel-selected-operator-session");
    },
    chatLogScrollToBottom() {
      // 一番下のログにスクロール
      const element = this.$refs.chatLog;
      if (element) {
        element.scrollTop = this.chatLogScrollHeight;
      }
    },
  },
  watch: {
    /**
     * メッセージ更新があれば一番下にスクロールする。
     */
    sessionChatLogs: function () {
      if (this.isLoadingOlderMessage) {
        // 古いメッセージ読み込み処理時にはスクロールしない
        return
      }

      this.$nextTick(() => {
        setTimeout(() => {
          this.chatLogScrollHeight = this.$refs.chatLog?.scrollHeight ?? 0;
          this.chatLogScrollToBottom();
        }, 300);
      });
    },
  },
};
</script>

<style scoped>
.chat-response >>> .message-bg-grey {
  background-color: #b2e281;
  clear: right;
  max-width: 100%;
  padding: 0.3rem;
  border-radius: 8px;
}

.border-horizontal {
  border-top: 1px solid #dee2e6;
  border-bottom: 5px solid #dee2e6;
}

.message-bg-orange {
  background-color: #ffa500;
  font-style: italic;
  max-width: 68%;
  padding-left: 0.5rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  margin-bottom: 8px;
  margin-top: 8px;
  border-radius: 8px;
}

.load-older-message-area {
  background-color: #f9ecbd;
  padding-top: 2px;
  padding-bottom: 4px;
}

.info-message {
  color: #888888;
  font-size: 0.8em;
}

.chat-massage-user {
  background-color: #007bff;
  padding: 0.2rem;
  border-radius: 8px;
  color: white;
}

@media only screen and (max-width: 640px) {
  #chat-log-area {
    /*
      * ヘッダー: 56px
      * マージン: 49px
    */
    height: calc(100svh - 56px - 49px) !important;
  }
}
</style>
