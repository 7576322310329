<template>
  <datalist id="datalist-common-request-headers">
    <option value="Accept"></option>
    <option value="Accept-Charset"></option>
    <option value="Accept-Encoding"></option>
    <option value="Accept-Language"></option>
    <option value="Authorization"></option>
    <option value="Cache-Control"></option>
    <option value="Connection"></option>
    <option value="Content-Length"></option>
    <option value="Cookie"></option>
    <option value="Host"></option>
    <option value="If-Modified-Since"></option>
    <option value="If-None-Match"></option>
    <option value="Origin"></option>
    <option value="Referer"></option>
    <option value="Transfer-Encoding"></option>
    <option value="User-Agent"></option>
    <option value="X-Forwarded-For"></option>
    <option value="X-Requested-With"></option>
  </datalist>
</template>

<script>
export default {
  name: 'HTTPRequestHeaderDataList',
}
</script>
