import Vue from 'vue'
import { RedirectorLogController } from "./controller/redirector-log"

import RedirectorLog from "./components/RedirectorLog.vue"
import { formatDate } from './util/dayjs'


export const appRedirectorLog = (i18n) => {
  // TODO: Vue3対応あり(MOBOT-3295を確認)
  Vue.filter('moment', formatDate);
  
  const controller = new RedirectorLogController(
    window.requestContext.userType,
    window.i18nContext,
    window.requestContext.logs
  )

  Vue.component('redirector-log', RedirectorLog)

  new Vue({
    el: '#app-redirector-log',
    i18n: i18n,
    data: {
      controller: controller
    }
  })
}
