<template>
<div>
  <!--会話リストの最後の項目をダブルクリックすると-->
  <!--ポップアップしたダイアログの文字がドラッグの状態になるのでb-modalの位置を変更-->
  <b-modal
    :title="modalTitle"
    ref="intentModal"
    header-class="bg-info text-light"
    body-class="text-info"
    no-close-on-esc
    no-close-on-backdrop
    @shown="modalFocus"
    @hidden="modalHidden">
    <div>
      <div>
        {{ $t('category.title') }}：
      </div>
      <model-select
        :options="modalParentCategories"
        :is-error="!modalParentCategoryId"
        v-model="selectIntentCategory"
        placeholder="Select Category">
      </model-select>
      <div>
        {{ $t('intent.intentLabel') }}：
      </div>
      <b-form-group>
        <b-form-checkbox-group v-model="modalIntentLabels" :options="$parent.controller.intentLabelOptions"></b-form-checkbox-group>
      </b-form-group>
      <div>
        {{ $t('intent.name') }}:
      </div>
      <form @submit.prevent="editIntentSubmit">
        <b-form-input
          name="intent-name"
          type="text"
          ref="focusInput"
          v-model="modalIntentName"
          :maxlength="max"
          :state="modalIntentName ? isValidModalIntentName : null">
        </b-form-input>
      </form>
      <hr class="mt-2 mb-0"/>
      <div @click="modalIntentUsingEvents = !modalIntentUsingEvents" class="pointer-cursor">
        {{ $t('intent.events') }}
        <span class="float-right">
          <span class="float-none pl-1" v-show="!modalIntentUsingEvents"><i class="fas fa-caret-down"></i></span>
          <span class="float-none pl-1" v-show="modalIntentUsingEvents"><i class="fas fa-caret-up"></i></span>
        </span>
      </div>
      <b-collapse id="intent-events-block" v-model="modalIntentUsingEvents">
        <div>
          <form-tags
            :tags="modalIntentEvents"
            :excludeList="excludeEvents"
            :rule="/^[a-zA-Z0-9-_]+$/g"
            :duplicated-message="$t('intent.eventDuplicated')"
            :built-in-message="$t('intent.eventBuiltIn')"
            :invalid-message="$t('intent.eventInvalid')"
            :placeholder="$t('intent.eventName')"
          >
          </form-tags>
        </div>
      </b-collapse>
      <hr class="my-1" />
      <b-form-checkbox class="intent-checkbox"
        v-model="fallBackChecked"
        :disabled="welcomeIntentChecked">
        {{ $t('intent.fallback') }}
      </b-form-checkbox>
      <b-form-checkbox class="intent-checkbox"
        v-model="welcomeIntentChecked"
        :disabled="fallBackChecked">
        {{ $t('intent.welcome') }}
      </b-form-checkbox>
      <hr class="my-1" />
      <div v-show="allowWebhook">
        <b-form-checkbox class="intent-checkbox"
          v-model="enableWebhookChecked">
          {{ $t('intent.enableWebhook') }}
        </b-form-checkbox>
      </div>
      <div>
        <b-form-checkbox class="intent-checkbox"
          v-model="isFeedbackActive"
          :disabled="isFeedbackResponse">
          {{ $t('intent.enableFeedback') }}
        </b-form-checkbox>
      </div>
      <div>
        <b-form-checkbox class="intent-checkbox"
          v-model="enableWebhookForSlotFillingChecked">
          {{ $t('intent.enableSlotFillingWebhook') }}
        </b-form-checkbox>
      </div>
      <div>
        <b-form-checkbox class="intent-checkbox"
          v-model="disableRedirectorChecked">
          {{ $t('intent.disableRedirector') }}
        </b-form-checkbox>
      </div>
    </div>
    <hr class="my-1" />
    <div v-if="selectedIntent">
      <label>{{ $t('intent.titleShort') + 'ID : ' }}</label>
      <span style="color: gray;">{{ selectedIntent.intentId }}</span>
    </div>
    <modal-footer
      slot="modal-footer"
      :okDisabled="!(modalParentCategoryId && isValidModalIntentName)"
      @ok="editIntentOk"
      @cancel="editIntentCancel">
    </modal-footer>
  </b-modal>

  <delete-confirm-modal
    ref="deleteIntentConfirmModal"
    :title="$t('intent.deleteTitle')"
    :bodyMessage="$t('intent.deleteConfirmMessage', {intent: this.selectedIntent && this.selectedIntent.name})"
    :bodySubMessage="[$t('category.deleteOtherLanguagesWarning')]"
    @ok="deleteIntent"
    @key-press-enter="pressEnterKey"
    @cancel="deleteIntentCancel"
    @hidden="modalHidden">
  </delete-confirm-modal>

  <b-modal
    ref="multiOperationConfirmModal"
    :title="modalTitle"
    header-class="bg-info text-light"
    body-class="text-info"
    no-close-on-esc
    no-close-on-backdrop
    @ok="copyToAgent"
  >
    <span>{{ modalOperation }}:</span>
    <model-select
      class="mb-1"
      :options="agentOptions"
      v-model="selectAgent"
      :placeholder="$t('intent.selectTargetAgent')">
    </model-select>
    <span>{{$t('intent.selectedIntents')}}:</span>
    <ul class="intent">
      <li style="cursor: default" v-for="(category, index) in categoriesFlat.filter(ct=>multiSelectedIntents.map(msi => {return msi.categoryId}).indexOf(ct.id) > -1)">
        <span>{{ category.name }}</span>
        <ul class="intent">
          <intent-item
          class="intent-item"
          style="cursor: default;"
          v-for="(intent, index) in multiSelectedIntents.filter(msi=>msi.categoryId === category.id)"
          :key="index"
          :index="index"
          :intent="intent"
          :lengthOverLimit="lengthOverIntentIds.indexOf(intent.id) !== -1"
          :feedbackIntentDict="feedbackIntentDict"
          :userType="userType"
          :isForConfirm="multiSelecting">
          </intent-item>
        </ul>
      </li>
    </ul>
    <span>total: {{multiSelectedIntents.length}}</span>
    <modal-footer
      slot="modal-footer"
      :ok-disabled="!itemAgent.value"
      @ok="copyToAgent"
      @cancel="$refs.multiOperationConfirmModal.hide()">
    </modal-footer>
  </b-modal>

  <!--Delete multiple intents modal-->
  <b-modal
    :title="$t('intent.deleteTitle')"
    ref="deleteMultiIntentConfirmModal"
    header-class="bg-danger text-light"
    body-class="text-info"
    ok-variant="danger"
    no-close-on-backdrop
    @keydown.native.enter=""
    >
    <div>
      <span>{{$t('intent.selectedIntents')}}:</span>
      <ul class="intent">
        <li style="cursor: default" v-for="(category, index) in categoriesFlat.filter(ct=>multiSelectedIntents.map(msi => {return msi.categoryId}).indexOf(ct.id) > -1)">
          <span class="font-weight-bold">{{ category.name }}</span>
          <ul class="intent">
            <intent-item
            class="intent-item"
            style="cursor: default;"
            v-for="(intent, index) in multiSelectedIntents.filter(msi=>msi.categoryId === category.id)"
            :key="index"
            :index="index"
            :intent="intent"
            :lengthOverLimit="lengthOverIntentIds.indexOf(intent.id) !== -1"
            :feedbackIntentDict="feedbackIntentDict"
            :userType="userType"
            :isForConfirm="multiSelecting">
            </intent-item>
          </ul>
        </li>
      </ul>
      <div>
        <small class="text-danger">※{{$t('category.deleteOtherLanguagesWarning')}}</small>
      </div>
    </div>
    <modal-footer
      slot="modal-footer"
      :ok-title="$t('general.continue')"
      :cancel-title="$t('general.abort')"
      @ok="deleteMultipleIntent"
      @cancel="$refs.deleteMultiIntentConfirmModal.hide()">
    </modal-footer>
  </b-modal>

  <!--Update multiple intents modal-->
  <b-modal
    :title="$t('intent.intentLabel')"
    ref="updateMultiIntentConfirmModal"
    header-class="bg-info text-light"
    body-class="text-dark"
    ok-variant="info"
    no-close-on-backdrop
    @keydown.native.enter=""
    >
    <div>
      <div>
        {{ $t('intent.intentLabel') }}：
      </div>
      <b-form-group>
        <b-form-checkbox-group v-model="modalMultiIntentLabels" :options="$parent.controller.intentLabelOptions"></b-form-checkbox-group>
      </b-form-group>
      <span>{{$t('intent.selectedIntents')}}:</span>
      <ul class="intent">
        <li style="cursor: default" v-for="(category, index) in categoriesFlat.filter(ct=>multiSelectedIntents.map(msi => {return msi.categoryId}).indexOf(ct.id) > -1)">
          <span class="font-weight-bold">{{ category.name }}</span>
          <ul class="intent">
            <intent-item
            class="intent-item"
            style="cursor: default;"
            v-for="(intent, index) in multiSelectedIntents.filter(msi=>msi.categoryId === category.id)"
            :key="index"
            :index="index"
            :intent="intent"
            :lengthOverLimit="lengthOverIntentIds.indexOf(intent.id) !== -1"
            :feedbackIntentDict="feedbackIntentDict"
            :userType="userType"
            :isForConfirm="multiSelecting">
            </intent-item>
          </ul>
        </li>
      </ul>
    </div>
    <modal-footer
      slot="modal-footer"
      :ok-title="$t('general.continue')"
      :cancel-title="$t('general.abort')"
      @ok="updateLabelMultipleIntent"
      @cancel="$refs.updateMultiIntentConfirmModal.hide()">
    </modal-footer>
  </b-modal>

  <error-modal
    ref="errorModal"
    :message="errorMessage"
    @keypress.native.enter="showLastDisplayedModal"
    @ok="showLastDisplayedModal">
  </error-modal>

  <div class="column-caption my-1 p-1 font-weight-bold pointer-cursor" v-b-toggle.intent_collapse @click = "folded = !folded">
    <span class="float-none pl-1" v-show="folded"><i class="fas fa-caret-down"></i></span>
    <span class="float-none pl-1" v-show="!folded"><i class="fas fa-caret-up"></i></span>
    <span class="pl-1">{{ $t('intent.titleShort') }}</span>
    <b-button
      v-if="desc"
      class="btn btn-success btn-xs float-right" v-b-toggle.intent_collapse
      @click="ordering"
      @click.prevent="folded = !folded">{{$t('intent.desc')}}</b-button>
    <b-button
      v-else
      class="btn btn-success btn-xs float-right" v-b-toggle.intent_collapse
      @click="ordering"
      @click.prevent="folded = !folded">{{$t('intent.asc')}}</b-button>
    <div style="clear: both"></div>
  </div>
  <b-collapse visible id="intent_collapse">
    <div class="row mb-1" v-if="userType != 'viewer'">
      <div class="col-auto mr-auto">
        <button
          class="btn btn-success button-text mr-1"
          v-show="!multiSelecting"
          @click="addIntent">
          <i class="fa fa-plus"></i>
          <span>{{ $t('buttons.add') }}</span>
        </button>

        <button
          class="btn btn-success button-text mr-1"
          v-show="!multiSelecting"
          @click="editIntent"
          v-bind:disabled= "!selectedIntent">
          <i class="fa fa-edit"></i>
          <span>{{ $t('buttons.edit') }}</span>
        </button>

        <button
          class="btn btn-success button-text mr-1"
          v-show="!multiSelecting"
          @click="deleteIntentConfirm"
          v-bind:disabled="!selectedIntent">
          <i class="fas fa-trash-alt"></i>
          <span>{{ $t('buttons.delete') }}</span>
        </button>
        <button
          class="btn btn-success button-text mr-1"
          :class="{'btn-danger': multiSelecting}"
          v-if="isStaff"
          @click="toggleMultiSelect">
          <i class="fas fa-check"></i>
          <span>{{ multiSelecting ? $t('buttons.cancel') : $t('buttons.multiSelect') }}</span>
        </button>
        <button
          class="btn btn-success button-text mr-1"
          :class="{'btn-danger': isAllSelected}"
          v-if="isStaff"
          v-show="multiSelecting&&this.intents.length"
          @click="toggleAllSelect">
          <i class="fas fa-arrows-alt-h"></i>
          <span>{{ isAllSelected ? $t('buttons.clearAll') : $t('buttons.selectAll') }}</span>
        </button>
        <button
          class="btn btn-success button-text mr-1"
          v-show="multiSelecting"
          @click="deleteMultipleIntentConfirm"
          :disabled="!multiSelectedIntents.length">
          <i class="fas fa-trash-alt"></i>
          <span>{{ $t('buttons.delete') }}</span>
        </button>
        <button
          class="btn btn-success button-text mr-1"
          v-show="multiSelecting"
          @click="updateLabelMultipleIntentConfirm"
          :disabled="!multiSelectedIntents.length">
          <i class="fas fa-bullseye"></i>
          <span>{{ $t('intent.intentLabel') }}</span>
        </button>
      </div>
      <div class="col-auto">
        <b-navbar-nav class="dropdown-toggle-none">
          <b-nav-item-dropdown class="m-auto" right>
            <template slot="button-content">
              <i class="fas fa-ellipsis-v dropdown-icon mr-3"></i>
            </template>
            <b-dropdown-item href="/agent/intent/search">
              {{ $t('history.search') }}
            </b-dropdown-item>
            <b-dropdown-item
              :title="$t('intent.selectedIntentDataImport')"
              href="#"
              @click="$emit('import-json')"
              v-bind:disabled="!selectedCategory">
              {{ $t('intent.import')}}
            </b-dropdown-item>
            <b-dropdown-item
              :title="$t('intent.selectedIntentDataExport')"
              href="#"
              @click="$emit('download-json', selectedCategory.id)"
              v-bind:disabled="!selectedCategory">
              {{ $t('intent.export') }}
            </b-dropdown-item>
            <b-dropdown-item
              :title="$t('intent.setFeedback')"
              @click="$emit('show-feedback-modal')"
              v-bind:disabled="!selectedCategory">
              {{ $t('intent.configureFeedback') }}
            </b-dropdown-item>
            <b-dropdown-item
              :title="$t('intent.setCopyIntentToAgent')"
              href="#"
              @click="copyToConfirm"
              v-if="isStaff"
              v-bind:disabled="!multiSelectedIntents.length || !multiSelecting">
              {{ $t('buttons.copyTo') }}
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </div>
    </div>
    <b-form-input
      type="text"
      :placeholder="$t('intent.search')"
      v-model="searchWord"
      :formatter="format">
    </b-form-input>
    <ul
      class="intent scroll-intent"
      tabindex="-1"
      ref="focusOn"
      id="scrollY_Intent"
      @keyup.delete="userType!='viewer'&&selectedIntent ? deleteIntentConfirm() : null">
      <intent-item
        class="intent-item"
        v-for="(intent, index) in allIntent"
        :key="index"
        :index="index"
        :intent="intent"
        :multiSelected="multiSelectedIntents.some(msi=>intent.id === msi.id)"
        :selected-intent="selectedIntent"
        :lengthOverLimit="lengthOverIntentIds.indexOf(intent.id) !== -1"
        :searchWord="searchWord"
        :userType="userType"
        :listCategories="intentCategoryPath"
        :selectedCategory="selectedCategory"
        :feedbackIntentDict="feedbackIntentDict"
        :listIntentLabels="$parent.controller.listIntentLabels"
        @select-intent="selected"
        @edit-intent="editIntent"
        @delete-intent="deleteIntentConfirm">
      </intent-item>
    </ul>
  </b-collapse>
</div>
</template>

<script>
import IntentItem from './IntentItem.vue'
import {Intent} from '../model/intent'
import {I18n} from "../util/i18n"
import { ModelSelect } from 'vue-search-select'
import { illegalExp } from '../util/illegalRegex'

export default {
  props: [
    'categoryJoin',
    'intents',
    'allIntents',
    'selectedCategory',
    'selectedIntent',
    'categoryModel',
    'categories',
    'desc',
    'isStaff',
    'userType',
    'allowWebhook',
    'intentCategoryPath',
    'feedbackIntentDict',
    'agentOptions'
  ],
  data() {
    const i18n =  new I18n(window.i18nContext)
    const multiOperationMessage = {
      TITLE: {
        COPY: i18n.t('intent.copyToAgent')
      },
      OPERATION: {
        COPY: i18n.t('intent.selectTargetAgent')
      }
    }
    return {
      modalTitle: "",
      modalIntent: null,
      modalIntentName: '',
      modalIntentUsingEvents: false,
      modalIntentEvents: [],
      modalIntentCategoryId: null,
      modalParentCategoryId: null,
      modalParentCategories:[],
      fallBackChecked: false,
      welcomeIntentChecked: false,
      enableWebhookChecked: false,
      isFeedbackActive: false,
      isFeedbackResponse: false,
      i18n: i18n,
      searchWord: '',
      lengthOverIntentIds: [],
      max:100,
      tempIndex:0,
      changeIndexFlag:false,
      arrowKeyFlag:0,
      itemCategory: {value:'', text:''},
      folded:false,
      isModalShown:false,
      isModalOK:false,
      scrollY:0,　           //縦のスクロールバー位置
      scrollY_Calc_Num1:34,　//矢印でアイテム選択中、アイテムがスクロールで隠れないようにスクロール速度調整のための数値（計算用）
      scrollY_Calc_Num2:35,
      scrollY_Calc_Num3:36,
      errorMessage: "",
      multiSelecting: false,
      tempSelectedIntent: null,
      multiSelectedIntents: [],
      multiOperationMessage: multiOperationMessage,
      modalOperation: null,
      targetAgent: null,
      excludeEvents: [
          'fallback', 'welcome', 'feedback',
          'feedback_worst', 'feedback_bad', 'feedback_normal', 'feedback_good', 'feedback_best'
      ],
      itemAgent: { value: null, text: '' },
      enableWebhookForSlotFillingChecked: false,
      disableRedirectorChecked: false,
      modalIntentLabels: [],
      modalMultiIntentLabels: [],
    }
  },components: {
    IntentItem, ModelSelect
  },
  computed: {
    allIntent(){
      this.lengthOverIntentIds = []
      for(let intent of this.intents){
          if ((intent.name + this.categoryJoin[intent.categoryId]).length >= 100) {
              this.lengthOverIntentIds.push(intent.id)
          }
      }
      return this.intents
    },
    isValidModalIntentName() {
      return this.isIntentNameValid(this.modalIntentName)
    },
    categoriesFlat() {
      return this.categoryModel.categoriesAsFlat(this.categories).map(ar=> {
        return {
          id: ar[1].id,
          name: '>> '.repeat(ar[0]) + ar[1].name
        }
      })
    },
    isAllSelected() {
      return !this.intents.some(it => this.multiSelectedIntents.findIndex(msi => it.id === msi.id) <= -1)
    },
    selectIntentCategory: {
      get: function() {
        return this.itemCategory;
      },
      set: function(category) {
        this.itemCategory = category;
        if(category){
          this.modalParentCategoryId = category.value;
        }
      }
    },
    selectAgent: {
      get: function() {
        return this.itemAgent;
      },
      set: function(agent) {
        this.itemAgent = agent;
        this.targetAgent = agent.value;
      }
    }
  },
  methods: {
    selected(intent) {
      for(let i = 0; i < this.intents.length; i++){
        if(this.intents[i].id == intent.id){
          this.scrollY = i*this.scrollY_Calc_Num1
        }
      }
      this.changeIndexFlag = true
      this.arrowKeyFlag = 2
      this.isModalShown= false
      this.$emit('modalPopupCheck-flag',this.isModalShown)
      this.$emit('arrowKey-flag',this.arrowKeyFlag)
      if (this.multiSelecting) {
        const index = this.multiSelectedIntents.findIndex(msi => msi.id === intent.id)
        if (index > -1) {
          this.multiSelectedIntents.splice(index, 1)
        } else {
          this.multiSelectedIntents.push(intent)
        }
        return
      }

      this.$emit('select-intent',intent)
      return intent == this.selectedIntent
    },
    toggleMultiSelect(){
      this.itemAgent = { value: null, text: ''}
      this.multiSelecting = !this.multiSelecting
      if(this.multiSelecting) {
        this.startMultiSelect()
      } else {
        this.cancelMultiSelect()
      }
    },
    startMultiSelect() {
      this.tempSelectedIntent = this.selectedIntent
      this.$emit('select-intent', null)
    },
    cancelMultiSelect(){
      if (this.tempSelectedIntent && this.intents.some(msi=>msi.id === this.tempSelectedIntent.id)) {
        this.$emit('select-intent', this.tempSelectedIntent)
      }
      this.tempSelectedIntent = null
      this.multiSelectedIntents = []
    },
    onArrowUp(intent){
      for(let i = 0; i < this.intents.length; i++){
        if(this.intents[i].id == intent.id){
          if(this.changeIndexFlag == true){
            this.tempIndex = i -1
            this.changeIndexFlag = false
            if(this.tempIndex < 0){return}
          }
          else{
            if(this.tempIndex <= 0){
              return
            }else{
              this.tempIndex = this.tempIndex -1
            }
          }
        }
      }
      this.scrollY = this.scrollY - this.scrollY_Calc_Num3
      document.getElementById("scrollY_Intent").scrollTop = this.scrollY
      intent = this.intents[this.tempIndex]
      this.$emit('select-intent',intent)
    },
    onArrowDown(intent){
      for(let i = 0; i < this.intents.length; i++){
        if(this.intents[i].id == intent.id){
          if(this.changeIndexFlag == true){
            this.tempIndex = i +1
            this.changeIndexFlag = false
            if(this.tempIndex > this.intents.length-1){return}
          }
          else{
            if(this.tempIndex >= this.intents.length-1){
              return
            }else{
              this.tempIndex = this.tempIndex+1
            }
          }
        }
      }
      document.getElementById("scrollY_Intent").scrollTop = this.scrollY
      this.scrollY = this.scrollY + this.scrollY_Calc_Num2
      intent = this.intents[this.tempIndex]
      this.$emit('select-intent',intent)
    },
    onArrowRight(intent){
      this.tempIndex = 0
      this.scrollY = 0
      document.getElementById("scrollY_Intent").scrollTop = this.scrollY
      this.$emit('select-intent',intent)
      this.$refs.focusOn.focus()
    },
    onArrowLeft(intent){
      for(let i = 0; i < this.intents.length; i++){
        if(this.intents[i].id == intent.id){
          this.tempIndex = i
        }
      }
      this.$emit('select-intent',intent)
      this.$refs.focusOn.focus()
    },
    isValidLifespanValueInput(lifespan) {
      return String(lifespan).match(/^[0-9]{1,4}$/) != null
    },
    ordering() {
      let orderOption = this.desc ? '-name' : 'name'
      this.$emit('intent-order', orderOption)
    },
    createCategoriesData() {
      // ドロップダウンのカテゴリツリー生成
      let categories = [{
        value: null,
        text: "---"
      }]
      let flat = this.categoryModel.categoriesAsFlat(this.categories)
      for (const [nestedLevel, category] of flat) {
        let indent = ""
        for (let i = 0; i < nestedLevel; i++) {
          indent += " » "
        }
        categories.push({
          value: category.id,
          text: indent + category.name
        })
      }
      categories.shift()
      return categories
    },
    addIntent() {
      this.isModalShown= true
      this.$emit('modalPopupCheck-flag', this.isModalShown)
      this.modalTitle = this.i18n.t('intent.add')
      this.modalIntent = new Intent()
      this.modalIntentName = ''
      this.modalIntentUsingEvents = false
      this.modalIntentEvents = []
      this.fallBackChecked = false
      this.welcomeIntentChecked = false
      this.isFeedbackActive = false
      this.modalIntentCategoryId = this.selectedCategory == null ? null : this.selectedCategory.id
      this.modalParentCategories = this.createCategoriesData()
      this.modalParentCategoryId = this.modalIntentCategoryId
      this.enableWebhookChecked = false
      this.enableWebhookForSlotFillingChecked = false
      this.disableRedirectorChecked = false
      let text = ''
      for(let categories of this.modalParentCategories){
        if(this.modalParentCategoryId == categories.value){
          text = categories.text
        }
      }
      this.itemCategory = {value: this.modalParentCategoryId, text: text}
      this.modalIntentLabels = []
      this.$parent.controller.getIntentLabelOptions()
      this.$refs.intentModal.show()
    },
    editIntent() {
      this.isModalShown= true
      this.$emit('modalPopupCheck-flag', this.isModalShown)
      this.$parent.controller.getIntentLabelOptions()
      this.modalTitle = this.i18n.t('intent.edit')
      this.modalIntent = this.selectedIntent
      this.modalIntentName = this.modalIntent.name
      this.modalIntentUsingEvents = this.modalIntent.events && this.modalIntent.events.length > 0
      this.modalIntentEvents = this.modalIntentUsingEvents ? [].concat(this.modalIntent.events) : []
      this.fallBackChecked = this.modalIntent.fallbackIntent
      this.welcomeIntentChecked = this.modalIntent.welcomeIntent
      this.enableWebhookChecked = this.modalIntent.enableWebhook
      this.isFeedbackActive = this.modalIntent.isFeedbackActive
      this.isFeedbackResponse = this.modalIntent.isFeedbackResponse
      this.modalIntentCategoryId = this.modalIntent.categoryId
      this.modalParentCategories = this.createCategoriesData()
      this.modalParentCategoryId = this.selectedCategory ? this.selectedCategory.id : null
      this.enableWebhookForSlotFillingChecked = this.modalIntent.enableWebhookForSlotFilling
      this.disableRedirectorChecked = this.modalIntent.disableRedirector
      this.modalIntentLabels = JSON.parse(JSON.stringify(this.selectedIntent.intentLabelIds))
      let text=''
      for(let categories of this.modalParentCategories){
        if(this.modalParentCategoryId == categories.value){
          text = categories.text
          break
        }
      }
      this.itemCategory ={value:this.modalParentCategoryId, text:text}
      this.$refs.intentModal.show()
    },
    pressEnterKey(){
      this.$refs.intentModal.hide()
    },
    deleteIntent(){
      // enter連打を防ぐ
      if(this.isModalOK)
        return null
      this.modalIntent = this.selectedIntent
      this.$emit('delete-intent', this.modalIntent)
      this.isModalOK = true
    },
    deleteIntentConfirm() {
      if(this.selectedIntent==null){
        return
      }
      this.isModalShown= true
      this.$emit('modalPopupCheck-flag',this.isModalShown)
      this.$refs.deleteIntentConfirmModal.show()
    },
    isIntentNameValid(intentName) {
      // dialogflowでは最初の空白が取り消される以外はどんな文字でも使用可能。ただし、１００文字以内
      // dialogflowでスラッシュを使ったintentが複数でスラッシュ以降の名前が全部同じの場合、データ内容が1個のintentに結合されることがあるためスラッシュを禁止する。
      return intentName.trim().match(/^[^_/]+$/) != null
    },
    isDuplicate(beforeModalIntentName) {
      let sameNameIntents = this.allIntents.filter(intent => intent.categoryId == this.modalIntent.categoryId && intent.name == this.modalIntent.name)
      if (sameNameIntents.length == 0) {
        // 同名のintentが同カテゴリにない場合
        return false
      } else {
        // 同名のintentが同カテゴリにある場合
        if (this.modalIntent.id) {
          // 編集の場合
          if(sameNameIntents.some(intent => intent.id != this.modalIntent.id) == true){
            this.modalIntent.name = beforeModalIntentName
            return true
          }
        } else {
          // 新規追加の場合
          return true
        }
      }
    },
    existsWelcomeIntent() {
      let welcomeIntent = this.allIntents.filter(intent => intent.welcomeIntent)
      if (welcomeIntent.length == 0) {
        // welcome intentが1つも登録されていない場合
        return false
      } else {
        // 既にwelcome intentが登録されていた場合
        if (this.modalIntent.id == welcomeIntent[0].id) {
          // 現在編集中のintentが元々welcome intentだった場合
          return false
        } else {
          // 元々別のintentがwelcomeの場合
          return this.modalIntent.welcomeIntent
        }
      }
    },
    existsFallbackIntent() {
      let fallbackIntent = this.allIntents.filter(intent => intent.fallbackIntent)
      if (fallbackIntent.length == 0) {
        // fallback intentが1つも登録されていない場合
        return false
      } else {
        // 既にfallback intentが登録されていた場合
        if (this.modalIntent.id == fallbackIntent[0].id) {
          // 現在編集中のintentが元々fallback intentだった場合
          return false
        } else {
          // 元々別のintentがfallbackの場合
          return this.modalIntent.fallbackIntent
        }
      }
    },
    editIntentSubmit(){
      if(this.modalParentCategoryId && this.isValidModalIntentName){
        return this.editIntentOk()
      }
      return false
    },
    editIntentOk() {
      /**
       * modalからのenterでのsubmitはボタンの無効化で弾けないので、validationを行う
       * invalidな時、modal上ではformが赤くなっているので、警告表示はそちらでOKとする
       */
      if (!this.isIntentNameValid(this.modalIntentName)) {
        return
      }
      let beforeModalIntentName = this.modalIntent.name //DBでは保存されないが重複になった名前が画面リストに表示される。重複になったら画面表示は変更しないようにするたの変数
      this.modalIntent.name = this.modalIntentName.trim()
      this.modalIntent.name = this.modalIntent.name.replace(illegalExp, "")
      this.modalIntent.categoryId = this.modalParentCategoryId
      this.modalIntent.fallbackIntent = this.fallBackChecked
      this.modalIntent.welcomeIntent = this.welcomeIntentChecked
      this.modalIntent.enableWebhook = this.enableWebhookChecked
      this.modalIntent.isFeedbackActive = this.isFeedbackActive
      this.modalIntent.isFeedbackResponse = this.isFeedbackResponse
      this.modalIntent.events = this.modalIntentEvents
      this.modalIntent.enableWebhookForSlotFilling = this.enableWebhookForSlotFillingChecked
      this.modalIntent.disableRedirector = this.disableRedirectorChecked
      this.modalIntent.intentLabelIds = JSON.parse(JSON.stringify(this.modalIntentLabels))
      if (this.isDuplicate(beforeModalIntentName)) {
        this.errorMessage = this.i18n.t('intent.duplicate')
        this.$refs.errorModal.show()
        return
      }
      if (this.existsWelcomeIntent()) {
        // modalのintentのwelcomeが残ってしまうのでfalseにしておく
        this.errorMessage = this.i18n.t('intent.duplicateWelcomeIntent')
        this.modalIntent.welcomeIntent = false
        this.$refs.errorModal.show()
        return
      }
      if (this.existsFallbackIntent()) {
        // modalのintentのfallbackが残ってしまうのでfalseにしておく
        this.errorMessage = this.i18n.t('intent.duplicateFallbackIntent')
        this.modalIntent.fallbackIntent = false
        this.$refs.errorModal.show()
        return
      }
      this.isModalShown= false
      this.$emit('modalPopupCheck-flag',this.isModalShown)
      this.$refs.intentModal.hide()

      this.$emit('save-intent', this.modalIntent)
      this.isModalOK = true
    },
    format(value, event) {
      return value.toLowerCase()
    },
    showLastDisplayedModal() {
      if (this.modalTitle === this.i18n.t('intent.add')) {
        this.addIntent()
      } else if (this.modalTitle === this.i18n.t('intent.edit')) {
        this.editIntent()
      }
      this.$refs.errorModal.hide()
      this.modalFocus()
    },
    modalFocus(){
      if(this.modalParentCategoryId == null){
        return
      } else {
        this.$refs.focusInput.focus()
      }
    },
    editIntentCancel(){
      this.isModalShown = false
      this.$emit('modalPopupCheck-flag',this.isModalShown)
      this.$refs.intentModal.hide()
    },
    deleteIntentCancel(){
      this.isModalShown = false
      this.$emit('modalPopupCheck-flag',this.isModalShown)
    },
    modalHidden(){
      this.$refs.focusOn.focus()
      if(!this.isModalOK){
        if(this.selectedIntent)
          this.selected(this.selectedIntent)
        else{
          // intent選択していない状態で追加ボタンを押してまたキャンセルする場合
          this.arrowKeyFlag = 1
          this.$emit('arrowKey-flag',this.arrowKeyFlag)
        }
      }
      this.isModalOK = false
    },
    copyToConfirm () {
      this.modalTitle = this.multiOperationMessage.TITLE.COPY
      this.modalOperation = this.multiOperationMessage.OPERATION.COPY
      this.$refs.multiOperationConfirmModal.show()
    },
    copyToAgent() {
      this.$emit('copy-to-agent', this.multiSelectedIntents.map(msi=>{return msi.id}), this.targetAgent)
    },
    toggleAllSelect() {
      const allSelected = this.isAllSelected
      this.intents.forEach(it => {
        const intentIndex = this.multiSelectedIntents.findIndex(msi => msi.id === it.id)
        if (allSelected && intentIndex > -1) {
          this.multiSelectedIntents.splice(intentIndex, 1)
        }
        else if (!allSelected && intentIndex <= -1) {
          this.multiSelectedIntents.push(it)
        }
      })
    },
    deleteMultipleIntentConfirm(){
      this.$refs.deleteMultiIntentConfirmModal.show()
    },
    deleteMultipleIntent(){
      this.$emit('delete-multiple-intent', this.multiSelectedIntents.map(msi=>{return msi.id}))
      this.$refs.deleteMultiIntentConfirmModal.hide()
      this.multiSelectedIntents = []
    },
    updateLabelMultipleIntentConfirm() {
      this.$parent.controller.getIntentLabelOptions()
      this.modalMultiIntentLabels = []
      this.$refs.updateMultiIntentConfirmModal.show()
    },
    updateLabelMultipleIntent() {
      const form = {
        'intentIds': this.multiSelectedIntents.map(msi=>{return msi.id}),
        'intentLabelIds': this.modalMultiIntentLabels
      }
      this.$emit('update-label-multiple-intent', form)
      this.$refs.updateMultiIntentConfirmModal.hide()
      this.multiSelectedIntents = []
    }
  },
  watch: {
    'selectedIntent'(){
      if (!this.selectedIntent)
        return
      this.multiSelecting = false
      this.tempSelectedIntent = null
      this.cancelMultiSelect()
    }
  }
}
</script>
