<template>
  <div>
    <!-- Modal -->
    <b-modal
      scrollable
      :title="modalTitle"
      ref="ruleModal"
      header-class="bg-info text-light"
      body-class="text-info">
      <div v-if="modalRule" class="modal-webhook">
        <form @submit.prevent="editRuleOk">
          <div>
            {{ $t('webhook.target') }}:
          </div>
          <b-form-select
            v-model="modalRule.target"
            :options="controller.ruleTargets"
            :state="modalRule.target ? true : false">
          </b-form-select>

          <div>
            {{ $t('webhook.targetParameter') }}:
          </div>
          <b-form-input
            type="text"
            v-model="modalRule.targetParameter"
            :placeholder="targetParameterPlaceholder"
            :disabled="isDisabledTargetParameter">
          </b-form-input>
          <div class="mt-1" v-if="!isDisabledTargetParameter">
            <b-card>
              <p>
                {{ $t('webhook.sTargetIntent') }} :
              </p>
              <model-select
                class="mb-1"
                v-model="selectIntentCategory"
                :options="controller.categories"
                :placeholder="this.i18n.t('webhook.categoryName')">
              </model-select>
              <model-select
                class="mb-1"
                v-model="controller.itemIntent"
                :options="controller.intents"
                :placeholder="this.i18n.t('webhook.sIntentName')">
              </model-select>
              <b-btn
                class="float-right btn btn-success"
                @click="addTargetParameter"
                :disabled= "!(controller.itemCategory.value && controller.itemIntent.value)">
                {{ $t('buttons.add') }}
              </b-btn>
            </b-card>
          </div>
          <div style="clear: both"></div>
          <div>
            {{ $t('webhook.action') }}:
          </div>
          <b-form-select
            v-model="modalRule.action"
            :options="controller.ruleActions"
            :state="modalRule.action ? true : false">
          </b-form-select>

          <div>
            {{ $t('webhook.actionParameter') }}:
          </div>
          <b-form-input
            type="text"
            v-model="modalRule.actionParameter"
            :placeholder="actionParameterPlaceholder"
            :disabled="!!isDisabledActionParameter">
          </b-form-input>
          <div class="mt-1" v-if="isDisabledActionParameter == 'horoscope'">
            <b-card>
              <form class="form-inline">
                <div>{{ $t('webhook.horoscope_option') }}：</div>
                <b-form-radio-group
                  v-model="modalRule.option"
                  :options="controller.ruleOptions">
                </b-form-radio-group>
              </form>
              <form class="form-inline">
                <div>{{ $t('webhook.horoscope_option_probability') }}：</div>
                <b-form-radio-group
                  v-model="modalRule.optionProbability"
                  :disabled="modalRule.option == 'off'"
                  :options="controller.ruleProbabilities">
                </b-form-radio-group>
              </form>
              <div>LINK：</div>
              <b-form-input
                v-model="modalRule.optionLink"
                class="mb-2"
                type="url"
                :placeholder="$t('answer.enterURL')"
                :disabled="modalRule.option == 'off'"
              >
              </b-form-input>
              <div>{{ $t('answer.typeImage') }}：</div>
              <div class="input-group mb-1">
                <b-form-input
                  v-model="modalRule.optionImage"
                  type="url"
                  :placeholder="$t('answer.enterImageURL')"
                  :disabled="modalRule.option == 'off'"
                >
                </b-form-input>
                <div class="input-group-append">
                  <a
                    class="btn btn-info"
                    target="_blank"
                    :title="$t('answer.preView')"
                    :href="modalRule.optionImage"
                    :disabled="modalRule.option == 'off'"
                  ><span class="fas fa-eye mt-1"/>
                  </a>
                </div>
              </div>
              <div>{{ $t('answer.typeText') + '(' + modalRule.optionText.length + ' / 400)' }}：</div>
              <b-form-textarea
                v-model="modalRule.optionText"
                class="mb-2"
                maxlength="400"
                :placeholder="$t('answer.typeText')"
                :rows="3"
                :max-rows="6"
                :disabled="modalRule.option == 'off'">
              </b-form-textarea>
              <b-btn
                class="float-right btn btn-success"
                @click="addOptionParameter"
                :disabled= "!(modalRule.option=='on' && modalRule.optionProbability && (modalRule.optionLink || modalRule.optionImage || modalRule.optionText))">
                {{ $t('buttons.add') }}
              </b-btn>
            </b-card>
          </div>
          <div class="mt-1" v-if="isDisabledActionParameter == 'weather'">
            <b-card>
              {{'地方を選択'}} :
              <b-form-select
                class="mb-1"
                v-model="modalRule.region"
                :options="controller.ruleRegions">
              </b-form-select>
              {{'地域を選択'}} :
              <b-form-select
                class="mb-1"
                v-model="modalRule.area"
                :options="selectArea">
              </b-form-select>
              <b-btn
                class="float-right btn btn-success"
                @click="addAreaParameter"
                :disabled= "!(modalRule.region && modalRule.area)">
                {{ $t('buttons.add') }}
              </b-btn>
            </b-card>
          </div>
          <div class="mt-1" v-if="isDisabledActionParameter === 'ifttt_event'">
            <b-card>
              <div>Api Key ({{$t('webhook.required')}}):</div>
              <b-form-input
                required
                v-model="iftttEvent.api_key"
                class="mb-2"
                :state="!!iftttEvent.api_key"
                placeholder="api_key">
              </b-form-input>
              <div>{{ $t('webhook.event_name') }} ({{$t('webhook.required')}}):</div>
              <b-form-input
                required
                v-model="iftttEvent.event_name"
                class="mb-2"
                :state="!!iftttEvent.event_name"
                :placeholder="$t('webhook.event_name')">
              </b-form-input>
              <div>Value3 ({{$t('webhook.optional')}})
                <b-badge pill variant="info"
                   v-b-tooltip.hover :title="$t('webhook.optionalParameter')"
                >
                  <i class="fas fa-info"></i>
                </b-badge>:
              </div>
              <b-form-textarea
                v-model="iftttEvent.value3"
                class="mb-2"
                maxlength="400"
                :placeholder="$t('answer.typeText')"
                :rows="2"
                :max-rows="4">
              </b-form-textarea>
              <b-btn
                class="float-right btn btn-success"
                @click="addIftttParameter"
                :disabled= "!(iftttEvent.api_key && iftttEvent.event_name)">
                {{ $t('buttons.add') }}
              </b-btn>
            </b-card>
          </div>
        </form>
      </div>
      <modal-footer
        slot="modal-footer"
        :ok-disabled="!controller.validateRule(modalRule)"
        @ok="editRuleOk"
        @cancel="$refs.ruleModal.hide()">
      </modal-footer>
    </b-modal>

    <error-modal
      ref="errorModal"
      :message="message"
      :isCard="isCard"
      :cardErrorMsg="cardErrorMsg"
      :isBotBroken="isBotBroken"
      :botBrokenMessage="botBrokenMessage"
      @train="startSynchronize">
    </error-modal>

    <progress-modal
      ref="progressModal"
      :message="message">
    </progress-modal>

    <delete-confirm-modal
      :title="$t('webhook.deleteRuleTitle')"
      ref="deleteRuleConfirmModal"
      :bodyMessage="$t('webhook.deleteRuleConfirmMessage', {target: this.modalRule ? this.modalRule.target : null, action: this.modalRule ? this.modalRule.action : null })"
      @keydown.native.enter="deleteRule"
      @ok="deleteRule">
    </delete-confirm-modal>

    <success-modal
      ref="syncCompletedModal"
      :title="$t('synchronize.result')"
      :message="$t('synchronize.completed')"
      @key-press-enter="closeSyncCompletedModal">
    </success-modal>

    <div class="row mt-1 mb-1 col-md-12">
      <!-- Start Synchronize -->
      <synchronize
        class="mr-1"
        :userType="controller.userType"
        @start-synchronize="startSynchronize">
      </synchronize>
      <!-- Log -->
      <a
        class="btn btn-success"
        href="webhook/log">
        <i class="fas fa-history"></i>
        {{ $t('webhook.logManagement') }}
      </a>
    </div>


    <!-- Title -->
    <div class="row">
      <div class="col-md-12">
        <div class="m-1 p-1">
          <h6 class="border-bottom mb-0">{{ $t('webhook.transferRulesTitle') }}</h6>
        </div>
      </div>
    </div>
    <!-- CreateNew -->
    <div class="row m-1">
      <button
        v-if="!isViewer"
        class="btn btn-success mr-2"
        @click="addRule">
        <i class="fas fa-plus-circle"></i>
        {{ $t('webhook.createNew') }}
      </button>
      <span><strong>{{ $t('webhook.signatureKey') }}: </strong> {{controller.signature_key}}</span>
    </div>
    <!-- Column -->
    <div class="row ml-1 mr-1">
      <div class="col-md-2 column border p-2">
        {{ $t('webhook.target') }}
      </div>
      <div class="col-md-3 column border p-2">
        {{ $t('webhook.targetParameter') }}
      </div>
      <div class="col-md-2 column border p-2">
        {{ $t('webhook.action') }}
      </div>
      <div class="col-md-4 column border p-2">
        {{ $t('webhook.actionParameter') }}
      </div>
      <div class="col-md-1 column border p-2">
      </div>
    </div>
    <!-- Rules -->
    <div class="webhook">
      <draggable v-model="draggableRules" v-bind:disabled="isViewer" handle=".handle">
        <div v-for="(rule, index) in draggableRules">
          <div class="row ml-1 mr-1 border-bottom">
            <div class="col-md-2 p-2 border-left">
              {{ $t('webhook.' + rule.target) }}
            </div>
            <div class="col-md-3 p-2 border-left">
              <span v-if="rule.targetParameter" v-html="rule.targetParameter"></span>
              <span v-else>{{$t('webhook.notSet')}}</span>
              <span v-show="rule.hasWarning()">
                <br><small class="text-warning"><i class="fas fa-exclamation-triangle"></i> {{ rule.warningMessage }}</small>
              </span>
            </div>
            <div class="col-md-2 p-2 border-left">
              {{ $t('webhook.' + rule.action) }}
            </div>
            <div class="col-md-4 p-2 border-left action-parameter">
              {{ rule.actionParameter || $t('webhook.notSet') }}
            </div>
            <div class="col-md-1 p-2">
              <div v-if="!isViewer">
                <button
                  :title="$t('buttons.edit')"
                  class="btn btn-info mr-1"
                  @click="editRule(rule)">
                  <i class="fas fa-edit"></i>
                </button>
                <button
                  :title="$t('buttons.delete')"
                  class="btn btn-danger mr-1"
                  @click="deleteRuleConfirm(rule)">
                  <i class="fas fa-trash-alt"></i>
                </button>
                <button class="btn btn-success handle">
                  <i class="fas fa-arrows-alt"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </draggable>
    </div>
    <test-chat
      v-if="controller.testChatController"
      :controller="controller.testChatController"
    >
    </test-chat>
    <b-alert
      v-if="inputTransportResult.message"
      fade
      :show="3"
      dismissible
      :variant="inputTransportResult.type"
      @dismissed="inputResetMessage"
      class="stick-to-bottom"
    >
      {{ inputTransportResult.message }}
    </b-alert>
  </div>
</template>

<script>
  import csrfToken from '../util/csrf-token'
  import {I18n} from '../util/i18n'
  import { SynchronizeStatus } from '../model/synchronize'
  import { RuleArea } from '../model/webhook'
  import { ModelSelect } from 'vue-search-select';

  export default {
    props: ['controller'],
    components: { ModelSelect },
    data() {
      return {
        message: '',
        errorMsg:'',
        modalTitle: '',
        modalRule: null,
        i18n: new I18n(window.i18nContext),
        isCard:false,
        cardErrorMsg:[],
        isBotBroken:false,
        botBrokenMessage:'',
        iftttEvent:{
          api_key:null,
          event_name:null,
          value3: '',
        },
        inputTransportResult: {
          message: null,
          type: null
        }
      }
    },
    computed: {
      isSynchronizing: function () {
        return this.controller.synchronizeController.isSynchronizing
      },
      synchronizeStatusMessage: function () {
        return this.controller.synchronizeController.synchronizeStatusMessage
      },
      draggableRules: {
        get() {
          const rules = this.controller.rules;
          if(rules) {
            rules.map(rule => {
              if(rule.targetParameter && this.controller.intentsWithFullPath.length > 0) {
                if (!this.controller.intentsWithFullPath.includes(rule.targetParameter)) {
                  rule.addWarning(this.i18n.t('webhook.targetParameterWarningMessage'));
                }
              }
            })
          }
          return rules
        },
        set(reorderedRules) {
          this.controller.updatePriorities(
            reorderedRules,
            csrfToken.getCsrfTokenFromCookie(document.cookie)
          )
        }
      },
      isDisabledTargetParameter() {
        return this.controller.isDisabledTargetParameter(this.modalRule)
      },
      targetParameterPlaceholder() {
        return this.controller.targetParameterPlaceholder(this.modalRule)
      },
      isDisabledActionParameter() {
        if(this.modalRule.action=='horoscope_by_dob' && this.modalRule.option=='off'){
          this.modalRule.actionParameter = 'off,null,null,null,null'
        }
        else if(this.modalRule.action=='horoscope_by_dob' && this.modalRule.option=='on' && this.modalRule.actionParameter == 'off,null,null,null,null'){
          this.modalRule.actionParameter = null
        }
        return this.controller.isDisabledActionParameter(this.modalRule)
      },
      actionParameterPlaceholder() {
        return this.controller.actionParameterPlaceholder(this.modalRule)
      },
      isViewer() {
        return this.controller.isViewer()
      },
      selectArea(){
        this.modalRule.area = null
        switch (this.modalRule.region){
          case 'hokkaido':
            this.modalRule.area = RuleArea.WAKKANAI
            return this.controller.ruleAreas.slice(0,16)
            break
          case 'n_tohoku':
            this.modalRule.area = RuleArea.AOMORI
            return this.controller.ruleAreas.slice(16,29)
            break
          case 's_tohoku':
            this.modalRule.area = RuleArea.YAMAGATA
            return this.controller.ruleAreas.slice(29,44)
            break
          case 'kanto':
            this.modalRule.area = RuleArea.MITO
            return this.controller.ruleAreas.slice(44,62)
            break
          case 'chubu_hokuriku':
            this.modalRule.area = RuleArea.NIIGATA
            return this.controller.ruleAreas.slice(62,75)
            break
          case 'chubu_others':
            this.modalRule.area = RuleArea.KOFU
            return this.controller.ruleAreas.slice(75,92)
            break
          case 'kinki':
            this.modalRule.area = RuleArea.TSU
            return this.controller.ruleAreas.slice(92,109)
            break
          case 'chugoku':
            this.modalRule.area = RuleArea.MATSUE
            return this.controller.ruleAreas.slice(109,124)
            break
          case 'shikoku':
            this.modalRule.area = RuleArea.TAKAMATSU
            return this.controller.ruleAreas.slice(124,134)
            break
          case 'n_kyushu':
            this.modalRule.area = RuleArea.FUKUOKA
            return this.controller.ruleAreas.slice(134,152)
            break
          case 's_kyushu':
            this.modalRule.area = RuleArea.MIYAZAKI
            return this.controller.ruleAreas.slice(152,164)
            break
          case 'okinawa':
            this.modalRule.area = RuleArea.NAHA
            return this.controller.ruleAreas.slice(164,171)
            break
          default:
            break
        }
      },
      inputSuccessMessage() {
        return (count) => this.$t('inputCompletion.transferSucceeded') + count
      },
      selectIntentCategory: {
        get: function() {
          return this.controller.itemCategory;
        },
        set: function(category) {
          this.controller.itemCategory = category;
          this.controller.itemIntent = {value:null, text:''};
          if(category.value){
            this.controller.loadIntents(category.value);
          }
        }
      },
    },
    methods: {
      async inputPredictionTransfer () {
        try {
          const result = await this.controller.inputPredictionTransfer(csrfToken.getCsrfTokenFromCookie(document.cookie))
          this.inputTransportResult.message = this.inputSuccessMessage(result.data)
          this.inputTransportResult.type = 'success'
        } catch (e) {
          this.inputTransportResult.message =  e.response.data instanceof Array ? e.response.data[0] : e.response.data.detail
          this.inputTransportResult.type = 'danger'
        }
      },
      inputResetMessage () {
        this.inputTransportResult = {
          message: null,
          type: null
        }
      },
      showProgress(message) {
        this.isCard = false
        this.isBotBroken = false
        this.botBrokenMessage = ''
        this.message = message
        this.$refs.progressModal.show()
      },
      hideProgress() {
        this.$refs.progressModal.hide()
      },
      showError(message, isCard=false) {
        if(!isCard){this.message = message}
        this.isCard = isCard
        this.$refs.errorModal.show()
      },
      addRule() {
        this.controller.itemCategory = {value:null, text:''}
        this.controller.itemIntent = {value:null, text:''}
        this.modalTitle = this.i18n.t('webhook.newRule')
        this.modalRule = this.controller.newRule()
        this.iftttEvent.api_key=null
        this.iftttEvent.event_name=null
        this.iftttEvent.value3=''
        this.$refs.ruleModal.show()
      },
      editRule(rule) {
        this.controller.itemCategory = {value:null, text:''}
        this.controller.itemIntent = {value:null, text:''}
        this.modalTitle = this.i18n.t('webhook.editRule')
        // 編集用にコピー
        this.modalRule = Object.assign(
          this.controller.newRule(),
          rule)
        if(this.modalRule.action == 'horoscope_by_dob'){
          let parameters = this.modalRule.actionParameter.replace(/null/g, '')
          parameters = parameters.replace(/undefined/g, '').split(',')
          this.modalRule.option = parameters[0]
          if(parameters[1]==''){
            parameters[1] = '5%'
          }
          this.modalRule.optionProbability = parameters[1]
          this.modalRule.optionLink = parameters[2]
          this.modalRule.optionImage = parameters[3]
          // テキスト部分は全て結合
          let array = []
          for(let i = 4; i < parameters.length; i++){
            array.push(parameters[i])
          }
          this.modalRule.optionText = array.join(',')
        }
        else if(this.modalRule.action == 'ifttt_event'){
          let all_events = this.modalRule.actionParameter.split(',')
          this.iftttEvent.api_key = all_events[0]
          this.iftttEvent.event_name = all_events[1]
          this.iftttEvent.value3 = all_events[2]
        }

        this.$refs.ruleModal.show()
      },
      editRuleOk() {
        if (!this.controller.validateRule(this.modalRule)) {
          return
        }
        let result, message, promise
        [result, message, promise] = this.controller.saveRule(
          this.modalRule,
          csrfToken.getCsrfTokenFromCookie(document.cookie)
        )
        if (!result) {
          this.showError(message)
        } else {
          this.showProgress(message)
          const self = this
          promise.then(self.hideProgress)
            .catch(err => {
              self.hideProgress()
              self.showError(err)
            })
        }
        this.$refs.ruleModal.hide()
      },
      deleteRuleConfirm(rule) {
        this.modalRule = rule
        this.$refs.deleteRuleConfirmModal.show()
      },
      deleteRule() {
        let result, message, promise
        [result, message, promise] = this.controller.deleteRule(
          this.modalRule,
          csrfToken.getCsrfTokenFromCookie(document.cookie)
        )
        if (!result) {
          this.showError(message)
        } else {
          this.showProgress(message)
          promise.then(this.hideProgress)
        }
      },
      addTargetParameter(){
        let catId = this.controller.itemIntent.value.categoryId
        let intentName = this.controller.itemIntent.text
        let categoryName = this.controller.categoryPathIndex[catId]
        this.modalRule.targetParameter = categoryName+'_'+intentName
      },
      addOptionParameter(){
        let option = this.modalRule.option
        if(option == 'on'){
          let probability = this.modalRule.optionProbability
          let link = this.modalRule.optionLink
          let image = this.modalRule.optionImage
          let text = this.modalRule.optionText
          this.modalRule.actionParameter = option+','+probability+','+link+','+image+','+text
        }else{
          this.modalRule.actionParameter = option+',null,null,null,null'
        }
      },
      addIftttParameter(){
        this.modalRule.actionParameter = this.iftttEvent.api_key+','+this.iftttEvent.event_name+
                ','+this.iftttEvent.value3
      },
      startSynchronize(isCard=false) {
        this.showProgress("...")
        // 同期を開始したときにpromiseを受け取り、そのpromiseから同期が開始したか、メッセージを受け取る
        let self = this
        this.controller.startSynchronize(
          csrfToken.getCsrfTokenFromCookie(document.cookie),
          isCard
        )
          .then((result) => {
            if (result.wasSuccessful) {
              self.showProgress(result.message)
            } else {
              self.hideProgress()
              self.showError(result.message)
            }
          })
          .catch((error) => {
            self.hideProgress()
            if( 'message' in error.response.data){
              self.showError(error.response.data.message)
            }else{
              this.cardErrorMsg = error.response.data
              self.showError(this.cardErrorMsg, true)
            }
          })
      },
      closeSyncCompletedModal() {
        this.$refs.syncCompletedModal.hide()
      },
      addAreaParameter(){
        let areaName
        let areaCode = this.modalRule.area
        for(let key in this.controller.ruleAreas){
          if(areaCode == this.controller.ruleAreas[key].value){
            // 目視確認用
            areaName = this.controller.ruleAreas[key].text
          }
        }
        this.modalRule.actionParameter = areaName+','+areaCode
      },
      checkAgentAlive() {
        const query = {
          'text': '@#$%^aduwenaf--1389371',
          'language':this.controller.getLanguageCode()
        }
        const token = csrfToken.getCsrfTokenFromCookie(document.cookie)
        this.controller.checkAgentState(query, token).then(response => {
          this.isBotBroken = false
          if(!response){
            this.botBrokenMessage = this.$t("intent.agentBrokenMessage")
          }
        }).catch(error => {
          console.log('[intentEditor->checkAgentAlive]: ', error)
          this.isBotBroken = false
          this.botBrokenMessage = this.$t("intent.agentBrokenMessage")
        })
      }
    },
    watch: {
      isSynchronizing: {
        handler: function(isSynchronizing) {
          // 同期終了時にプログレスを閉じる
          if (!isSynchronizing) {
            this.hideProgress()
          }
        }
      },
      synchronizeStatusMessage: {
        handler: function(synchronizeStatusMessage) {
          // ステータスメッセージが変更されたらmessageに同期
          if (synchronizeStatusMessage != null) {
            this.message = synchronizeStatusMessage
            if(SynchronizeStatus.ERROR == this.controller.synchronizeController.synchronizeCode){
              this.hideProgress()
              this.isBotBroken = true
              this.showError(this.message)
              this.checkAgentAlive()
            }
            else if(SynchronizeStatus.BUSY == this.controller.synchronizeController.synchronizeCode){
              this.hideProgress()
              this.showError(this.message)
            }
            else if(SynchronizeStatus.FINISH == this.controller.synchronizeController.synchronizeCode){
              this.hideProgress()
              this.$refs.syncCompletedModal.show()
              if(this.controller.auto_input_predict){
                this.inputPredictionTransfer()
              }
            }
          }
        }
      }
    }
  }
</script>
<style scoped>
  .stick-to-bottom {
    position: fixed;
    bottom: 0;
    right: 0;
  }
</style>
