<template>
  <div
    class="text-secondary text-size-sm"
    v-if="translation || isTranslating"
  >
    <div v-if="isTranslating" title="Translating...">
      <b-spinner
        style="width: 0.5rem; height: 0.5rem"
        v-for="variant in variants"
        :variant="variant"
        :key="variant"
        type="grow"
      />
    </div>
    {{ translation }}
  </div>
</template>

<script>
export default {
  name: 'HistoryLogTranslationLine',
  props: ['translation', 'isTranslating'],
  data() {
    return {
      variants: ["primary", "danger", "success"],
    }
  }
}
</script>
