<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="m-1 p-1">
          <h6 class="border-bottom pb-1">{{ $t('fileManagement.uploadNew') }}</h6>
          <div class="form-group">
            <b-form-file
              id="file"
              name="file"
              v-model="file"
              ref="file"
              :state="Boolean(file)"
              :placeholder="$t('fileManagement.chooseFile')"
              :accept=fileTypes>
            </b-form-file>
            <div class="mt-2 mb-2">
              <small><i class="fas fa-exclamation-circle"></i> supported file types: <span v-for="type in fileTypes.replace(/\s+/g, '').split(',')"> *{{type}} </span> </small><br />
              <small><i class="fas fa-exclamation-circle"></i> {{ $t('fileManagement.maxFileSizeWarn') }}</small>
            </div>
            <div class="mt-2 mb-2">
              {{ $t('fileManagement.selectedFile') }}: {{file && file.name}}
            </div>

            <button
              :disabled="isButtonDisabled"
              type="submit"
              class="btn btn-success"
              @click.prevent="submitFile">
              {{ $t('fileManagement.upload') }}
            </button>

            <div v-if="imageData || videoData" class="mt-2 mb-2">
              <b-img v-show="imageData" id="uploaded-img" :src="imageData" fluid :alt="file && file.name"></b-img>
              <video v-show="videoData" id="uploaded-video" :src="videoData"></video>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h6 class="border-bottom pb-1">{{ $t('fileManagement.uploadNew') }}</h6>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-lg-2 col-md-3 mb-4" v-for="content in listFileContents">
            <div class="card h-100">
              <img v-if="isImageFile(content.file)"
                :src="content.file"
                class="card-img-top"
                onerror="this.src='/static/assets/no-preview-image.jpg'">
              <div v-else
                class="card-img-top h-50 text-white bg-black d-flex justify-content-center align-items-center">
                <h3>{{ $t('fileManagement.noPreview') }}</h3>
              </div>
              <div class="card-body d-flex justify-content-center align-items-end">
                <button class="btn btn-sm btn-outline-dark mr-2"
                        v-if="controller.userType !== 'viewer'"
                        @click.prevent="controller.copyURL(content.file)">
                  <i class="fas fa-copy"></i>
                </button>
                <button class="btn btn-sm btn-outline-danger mr-2"
                        v-if="controller.userType !== 'viewer'"
                        @click.prevent="deleteFileConfirm(content)">
                  <i class="fas fa-trash-alt"></i>
                </button>
                <button class="btn btn-sm btn-outline-info"
                        @click.prevent="openFileDetail(content)">
                  <i class="fas fa-info"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <pagination :params="controller.params"
                    :num_page="controller.num_page"
                    :callback="loadFileContents"></pagination>
      </div>
    </div>
    <!-- File Delete Modal-->
    <b-modal
      :title="$t('fileManagement.deleteFile')"
      ref="deleteFileConfirmModal"
      header-class="bg-info text-light"
      body-class="text-info"
      no-close-on-backdrop>
      <div>
        {{ $t('fileManagement.deleteWarning') }}
      </div>
      <modal-footer
        slot="modal-footer"
        @ok="deleteFile"
        @cancel="$refs.deleteFileConfirmModal.hide()">
      </modal-footer>
    </b-modal>
    <!-- File Detail Modal-->
    <b-modal size="xl"
      :title="$t('fileManagement.detailFile')"
      ref="detailFileModal"
      header-class="bg-info text-light"
      hide-footer>
      <div class="row">
        <div class="col-md-6">
          <h5 class="title"><u>Intents</u>:</h5>
          <div class="list-group"
               v-if="typeof fileExtraInfo.extra === 'object'">
            <a class="list-group-item list-group-item-action"
               v-for="fileExtraInfo in fileExtraInfo.extra"
               v-bind:href="goToIntentDetail(fileExtraInfo.intent)" target="_blank">
              <p class="mb-1 text-info">
                {{fileExtraInfo.scenario ? $t('scenario.title')+': '+fileExtraInfo.scenario : fileExtraInfo.intent}}
              </p>
              <small class="text-muted"><i class="far fa-calendar-alt"></i>
                {{fileExtraInfo.registered | moment("YYYY-MM-DD") }}
              </small>
            </a>
          </div>
          <div class="alert alert-warning"
               v-if="typeof fileExtraInfo.extra === 'string'">
            {{fileExtraInfo.extra}}
          </div>
        </div>
        <div class="col-md-6">
          <div class="card mb-3">
            <div class="card-body p-1">
              <div class="d-flex justify-content-between" id="copyModalText">
                <p class="text-break text-muted font-italic">{{fileExtraInfo.file}}</p>
                <button class="btn btn-secondary"
                      v-if="controller.userType !== 'viewer'"
                      @click.prevent="modalCopyToClipboard(fileExtraInfo.file)">
                  <i class="fas fa-copy"></i>
                </button>
              </div>
            </div>
          </div>
          <div v-html="bindMediaHTML(fileExtraInfo.file)"></div>
        </div>
      </div>
    </b-modal>
    <error-modal
      ref="errorModal"
      :message="alertMessage">
    </error-modal>
  </div>
</template>

<script>
  import { checkFileTypeImage } from '../util/file-type'

  export default {
    props: ['controller'],
    data() {
      return {
        file: null,
        imageData: null,
        videoData: null,
        pdfData: null,
        fileTypes: ".jpeg, .jpg, .png, .gif, .mp4, .pdf",
        alertMessage: '',
        listFileContents: [],
        targetFile: {},
        fileExtraInfo: ''
      }
    },
    methods:{
      submitFile() {
        if(this.file) {
          const form = new FormData()
          form.append('file', this.file)
          this.controller.uploadFile(form).then(response => {
            if(response){
              window.location.reload()
            }
          }).catch(error => {
            console.log(error)
          })
        }
      },
      showErrorModal(message) {
        this.alertMessage = message
        this.$refs.errorModal.show()
      },
      deleteFileConfirm(file) {
        this.targetFile = ''
        this.targetFile = file
        this.$refs.deleteFileConfirmModal.show()
      },
      deleteFile() {
        this.$refs.deleteFileConfirmModal.hide()
        this.controller.deleteFile(this.targetFile).then(response => {
          if(response){
            window.location.reload()
          }
        }).catch(error => {
          console.log(error)
        })
      },
      loadFileContents() {
        this.controller.getFileContents().then(response => {
          this.listFileContents = response.results
          this.controller.num_page = response.num_page
        }).catch(error => {
          console.log(error)
        })
      },
      openFileDetail(content) {
        this.fileExtraInfo = {
          'extra': this.controller.i18n.t('fileManagement.searching')+'...',
          'file': content.file
        }
        this.controller.searchRelatedIntents(content.file).then(foundIntents => {
          if(foundIntents.length > 0){
            this.fileExtraInfo.extra = this.controller.i18n.t('fileManagement.loading')+'...'
            this.controller.loadAllIntentWithFullPath().then(listIntents => {
              const relatedIntents = []
              foundIntents.forEach(foundIntent => {
                if(listIntents[foundIntent.intentId]) {
                  const scenerioIntent = this.controller.allIntents.find(intent => {
                    return intent.id === foundIntent.intentId ? intent.scenarioId : ''
                  })
                  if(scenerioIntent) {
                    this.controller.getScenarioInfo(scenerioIntent.scenarioId).then(scenario => {
                      if(scenario) {
                        relatedIntents.push({
                          'registered': foundIntent.registered,
                          'intent': listIntents[foundIntent.intentId],
                          'scenario': scenario.name
                        })
                      }
                    })
                  } else {
                    relatedIntents.push({
                      'registered': foundIntent.registered,
                      'intent': listIntents[foundIntent.intentId],
                      'scenario': ''
                    })
                  }
                }
              })
              this.fileExtraInfo.extra = relatedIntents
            })
          } else {
            this.fileExtraInfo.extra = this.controller.i18n.t('fileManagement.notFoundRelatedIntent')
          }
        })
        this.$refs.detailFileModal.show()
      },
      goToIntentDetail(intentName){
        if(intentName) {
          const language = this.controller.getLanguageCode()
          const currentUrl = location.href
          const regex = RegExp('agent.+')
          const destination = `agent/${language}/intent?intent_name=${intentName}`
          return currentUrl.match(regex) ? currentUrl.replace(regex, destination) : destination
        }
        return 'javascript:void(0)'
      },
      bindMediaHTML(file) {
        if(!file){
          return false
        }
        let mediaHTML = ''
        const fileName = file.split('/').pop()
        const fileType = fileName.split('.').pop()
        if(fileType === 'mp4'){
          mediaHTML = '<div class="embed-responsive embed-responsive-16by9">\n' +
            '            <video controls>\n' +
            '              <source src="'+file+'" type="video/mp4">\n' +
            '              Your browser does not support HTML5 video.\n' +
            '            </video>\n' +
            '          </div>'
        } else if(fileType === 'jpg' ||
          fileType === 'jpeg' ||
          fileType === 'png' ||
          fileType === 'gif') {
          mediaHTML = '<img src="'+file+'" class="img-fluid rounded"\n' +
            ' onerror="this.src=\'/static/assets/no-preview-image.jpg\'"/>'
        }else if(fileType === 'pdf') {
          mediaHTML = '<iframe src="'+file+'" height="500px" width="100%"\n' +
            ' onerror="this.src=\'/static/assets/no-preview-image.jpg\'"/>'
        } else {
          mediaHTML = '<div class="alert alert-danger">\n' +
            '            Your browser does not support HTML5 media files.\n' +
            '          </div>'
        }
        return mediaHTML
      },
      modalCopyToClipboard(string) {
        const el = document.createElement('textarea'); // Create a <textarea> element
        el.value = string; // Set its value to the string which want copied
        el.setAttribute('readonly', ''); // Make it readonly to be tamper-proof
        el.style.position = 'absolute';
        el.style.left = '-9999px'; // Move outside the screen to make it invisible
        document.getElementById('copyModalText').appendChild(el); // Append the <textarea> element to the HTML document
        el.select(); // Select the <textarea> content
        document.execCommand('copy'); // Copy - only works as a result of a user action (e.g. click events)
        document.getElementById('copyModalText').removeChild(el); // Remove the <textarea> element
        alert(this.controller.i18n.t('fileManagement.copiedURL'))
      },
      bytesToMegaBytes(bytes) {
        const kb = 1024
        const mb = kb * 1024
        return Math.round(bytes/mb);
      },
      isImageFile(file) {
        return checkFileTypeImage(file)
      }
    },
    mounted: function() {
      this.loadFileContents()
    },
    watch: {
      file: function () {
        // MIME types in JS
        // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types#Image_types
        const imageMimeTypes = ['image/jpeg', 'image/png', 'image/gif']
        const videoMimeTypes = ['video/mp4']
        const pdfMimeTypes = ['application/pdf']
        if (this.file) {
          const fileType = this.file.type
          const fileSize = this.file.size
          if(this.bytesToMegaBytes(fileSize) > 30) {
            this.imageData = null
            this.videoData = null
            this.pdfData = null
            this.file = null
            this.showErrorModal(this.controller.i18n.t('fileManagement.maxFileSizeAlert'))
            return false
          }
          const reader = new FileReader();
          reader.onload = (e) => {
            if(imageMimeTypes.includes(fileType)) {
              this.videoData = null
              this.pdfData = null
              this.imageData = e.target.result;
            } else if(videoMimeTypes.includes(fileType)) {
              this.imageData = null
              this.pdfData = null
              this.videoData = e.target.result;
            } else if(pdfMimeTypes.includes(fileType)){
              this.imageData = null
              this.videoData = null
              this.pdfData = e.target.result
            } else {
              this.imageData = null
              this.videoData = null
              this.pdfData = null
              this.file = null
              this.showErrorModal(this.controller.i18n.t('fileManagement.invalidFilesAlert'))
            }
          }
          reader.readAsDataURL(this.file)
        } else {
          this.imageData = null
          this.videoData = null
          this.pdfData = null
        }
      }
    },
    computed: {
      isButtonDisabled() {
        return !this.file
      }
    }
  }
</script>
