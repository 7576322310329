import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import VueI18n from 'vue-i18n'
import Vuelidate from 'vuelidate'
import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/regular'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/brands'

import "./style/main.scss"

import { appAgent } from './agent.js'
import { appAnalytics } from './analytics.js'
import { appApiService } from './api-service.js'
import { appDashboardRoleManage } from './dashboard-role-manage.js'
import { appDashboardRole } from './dashboard-role.js'
import { appDashboard } from './dashboard.js'
import { appDfAgentSwitch } from './df-agent-switch.js'
import { appFeedbackHistory } from './feedback-history.js'
import { appFeedback } from './feedback.js'
import { appFileManagement } from './file-management.js'
import { appHistoryOperatorSession } from './history-operator-session.js'
import { appHistory } from './history.js'
import { appHome } from './home.js'
import { appImportFromFile } from './import-from-file.js'
import { appInputCompletion } from './input-completion.js'
import { appIntentCheckDetail } from './intent-check-detail.js'
import { appIntentCheck } from './intent-check.js'
import { appIntentLabel } from './intent-label.js'
import { appIntentSearch } from './intent-search.js'
import { appIntent } from "./intent.js"
import { appKeyword } from './keyword.js'
import { appRedirectorLog } from './redirector-log.js'
import { appRedirector } from './redirector.js'
import { appRoleManagement } from './role-management.js'
import { appScenarioEditor } from './scenario-editor.js'
import { appScenarioIndex } from './scenario-index.js'
import { appSessionConversation } from './session-conversation.js'
import { appSpeechContext } from './speech-context.js'
import { appSyncLog } from './sync-log.js'
import { appUserMetaEditSchema } from './user-meta-edit-schema.js'
import { appUserMetaManagement } from './user-meta-management.js'
import { appWebhookLog } from './webhook-log.js'
import { appWebhook } from './webhook.js'
import { appFAQContentManagement } from './faq-content-management.js'
import { appFAQDatasetManagement } from './faq-dataset-management.js'
import { appFAQClientManagement } from './faq-client-management.js'
import { appUserNotificationSettings } from './user-notification-settings.js'

import IconConfig from './components/icons/IconConfig.vue'
import IconWarning from './components/icons/IconWarning.vue'
import IconUsersCog from './components/icons/IconUsersCog.vue'

import ConditionEditor from './components/ConditionEditor.vue'
import ConditionPreview from './components/ConditionPreview.vue'

import HTTPRequestHeaderDataList from './components/common/HTTPRequestHeaderDataList.vue'


Vue.use(BootstrapVue)
Vue.use(VueI18n)
Vue.use(Vuelidate)

// Iconは全体共通で登録
Vue.component('icon-config', IconConfig)
Vue.component('icon-warning', IconWarning)
Vue.component('icon-users-cog', IconUsersCog)

Vue.component('condition-editor', ConditionEditor)
Vue.component('condition-preview', ConditionPreview)

// Datalistは全体共通で登録
Vue.component('http-request-header-data-list', HTTPRequestHeaderDataList)

if (process.env.BUILD_MODE === 'development') {
  Vue.config.devtools = true;
}

// i18n
const i18n = new VueI18n(window.i18nContext)

// navigation bar
new Vue({
  el: '#navbar',
  i18n: i18n
})

if (document.getElementById('app')) {
  new Vue({
    el: '#app',
    i18n: i18n
  })
}
if (document.getElementById("app-agent")) {
  appAgent(i18n)
}
if (document.getElementById("app-analytics")) {
  appAnalytics(i18n)
}
if (document.getElementById("app-api-service")) {
  appApiService(i18n)
}
if (document.getElementById("app-dashboard-role-manage")) {
  appDashboardRoleManage(i18n)
}
if (document.getElementById("app-dashboard-role")) {
  appDashboardRole(i18n)
}
if (document.getElementById("app-dashboard")) {
  appDashboard(i18n)
}
if (document.getElementById("app-df-agent-switch")) {
  appDfAgentSwitch(i18n)
}
if (document.getElementById("app-feedback-history")) {
  appFeedbackHistory(i18n)
}
if (document.getElementById("app-feedback")) {
  appFeedback(i18n)
}
if (document.getElementById("app-file-management")) {
  appFileManagement(i18n)
}
if (document.getElementById("app-history-operator-session")) {
  appHistoryOperatorSession(i18n)
}
if (document.getElementById("app-history")) {
  appHistory(i18n)
}
if (document.getElementById("app-home")) {
  appHome(i18n)
}
if (document.getElementById("app-import-from-file")) {
  appImportFromFile(i18n)
}
if (document.getElementById("app-input-completion")) {
  appInputCompletion(i18n)
}
if (document.getElementById("app-intent-check-detail")) {
  appIntentCheckDetail(i18n)
}
if (document.getElementById("app-intent-check")) {
  appIntentCheck(i18n)
}
if (document.getElementById("app-intent-label")) {
  appIntentLabel(i18n)
}
if (document.getElementById("app-intent-search")) {
  appIntentSearch(i18n)
}
if (document.getElementById("app-intent")) {
  appIntent(i18n)
}
if (document.getElementById("app-keyword")) {
  appKeyword(i18n)
}
if (document.getElementById("app-redirector-log")) {
  appRedirectorLog(i18n)
}
if (document.getElementById("app-redirector")) {
  appRedirector(i18n)
}
if (document.getElementById("app-role-management")) {
  appRoleManagement(i18n)
}
if (document.getElementById("app-scenario-editor")) {
  appScenarioEditor(i18n)
}
if (document.getElementById("app-scenario-index")) {
  appScenarioIndex(i18n)
}
if (document.getElementById("app-session-conversation")) {
  appSessionConversation(i18n)
}
if (document.getElementById("app-speech-context")) {
  appSpeechContext(i18n)
}
if (document.getElementById("app-sync-log")) {
  appSyncLog(i18n)
}
if (document.getElementById("app-user-meta-edit-schema")) {
  appUserMetaEditSchema(i18n)
}
if (document.getElementById("app-user-meta-management")) {
  appUserMetaManagement(i18n)
}
if (document.getElementById("app-webhook-log")) {
  appWebhookLog(i18n)
}
if (document.getElementById("app-webhook")) {
  appWebhook(i18n)
}
if (document.getElementById("app-faq-content-management")) {
  appFAQContentManagement(i18n)
}
if (document.getElementById("app-faq-dataset-management")) {
  appFAQDatasetManagement(i18n)
}
if (document.getElementById("app-faq-client-management")) {
  appFAQClientManagement(i18n)
}
if (document.getElementById("app-user-notification-settings")) {
  appUserNotificationSettings(i18n)
}
