<template>
  <div class="session-list">
    <div v-if="sessionListTrack" class="conversation-loading">
      <b-spinner style="width: 4rem; height: 4rem;" label="Loading..." variant="primary"></b-spinner>
    </div>
    <div v-else-if="filteredSessionInfo.length >= 1">
      <div class="text-center p-2 bg-secondary text-white sticky-top">
        {{selectedDate | moment("Y-MM-DD")}} &nbsp; {{$t('history.conCount')}}: {{filteredSessionInfo.length}}

        <div class="pagination-area">
          <div v-if="getTotalPageNumber > 1" class="bg-light rounded">
            <b-button
              class="px-2 py-1"
              variant="outline-primary"
              @click="pageIdx--"
              :disabled="pageIdx === 1"
            ><i class="fas fa-chevron-left"></i></b-button>
          </div>
          <span
            class="mx-1 p-1"
            style="font-size: 1.1rem;"
          >{{ `Page ${pageIdx} / ${getTotalPageNumber}` }}</span>
          <div v-if="getTotalPageNumber > 1" class="bg-light rounded">
            <b-button
              class="px-2 py-1"
              variant="outline-primary"
              @click="pageIdx++"
              :disabled="pageIdx === getTotalPageNumber"
            ><i class="fas fa-chevron-right"></i></b-button>
          </div>
        </div>
      </div>

      <div
        v-for="(sessionInfo, idx) in filteredSessionsByPage"
        class="item pointer-cursor"
        :class="{selected: selectedSessionId == sessionInfo.sessionId}"
        @click="loadSessionConversation(sessionInfo.sessionId)"
        :key="idx">
        <div class="platform">
          <platform-icon :platform="sessionInfo.platform"></platform-icon>
        </div>
        <div class="detail">
          <div class="main">
            <span class="time-text">
              {{ formatTime(sessionInfo.startTime) }} - {{ formatTime(sessionInfo.endTime) }}
            </span>
            <span class="message-count float-right">
              <b-badge v-show="getOperatorSessionStatus(sessionInfo.sessionId)===status.ACTIVE" pill
                       variant="success"
                       :title="$t('history.operatorSupportActivate')"><i class="fas fa-headset"></i>
              </b-badge>
              <b-badge v-show="getOperatorSessionStatus(sessionInfo.sessionId)===status.CALLING" pill
                       variant="warning"
                       :title="$t('history.operatorSupportWait')"><i class="fas fa-exclamation-circle"></i>
              </b-badge>
              {{ sessionInfo.count }}
            </span>
          </div>
          <div
            class="session-id"
            :style="{color: colorFromString(sessionInfo.sessionId)}"
          >
            {{ getUserMetaFullName(sessionInfo.sessionId, sessionInfo.platform) || sessionInfo.sessionId }}
          </div>
          <div class="language-list" v-for="(lang, idx) in sessionInfo.languageInUse" :key="`langIdx-${idx}`">
            <b-badge v-if="'ja' === lang" pill variant="secondary" :title="$t('languageSelector.japanese')">{{ $t('languageSelector.ja') }}</b-badge>
            <b-badge v-if="'en' === lang" pill variant="secondary" :title="$t('languageSelector.english')">{{ $t('languageSelector.en') }}</b-badge>
            <b-badge v-if="'ko' === lang" pill variant="secondary" :title="$t('languageSelector.korean')">{{ $t('languageSelector.ko') }}</b-badge>
            <b-badge v-if="'zh-cn' === lang" pill variant="secondary" :title="$t('languageSelector.chineseSimplified')">{{ $t('languageSelector.zhCn') }}</b-badge>
            <b-badge v-if="'zh-tw' === lang" pill variant="secondary" :title="$t('languageSelector.chineseTraditional')">{{ $t('languageSelector.zhTw') }}</b-badge>
            <b-badge v-if="'th' === lang" pill variant="secondary" :title="$t('languageSelector.thai')">{{ $t('languageSelector.th') }}</b-badge>
            <b-badge v-if="'ru' === lang" pill variant="secondary" :title="$t('languageSelector.russian')">{{ $t('languageSelector.ru') }}</b-badge>
            <b-badge v-if="'vi' === lang" pill variant="secondary" :title="$t('languageSelector.vietnamese')">{{ $t('languageSelector.vi') }}</b-badge>
            <b-badge v-if="'id' === lang" pill variant="secondary" :title="$t('languageSelector.indonesian')">{{ $t('languageSelector.id') }}</b-badge>
            <b-badge v-if="'uk' === lang" pill variant="secondary" :title="$t('languageSelector.ukrainian')">{{ $t('languageSelector.uk') }}</b-badge>
            <b-badge v-if="'ne' === lang" pill variant="secondary" :title="$t('languageSelector.nepali')">{{ $t('languageSelector.ne') }}</b-badge>
            <b-badge v-if="'bn' === lang" pill variant="secondary" :title="$t('languageSelector.bengali')">{{ $t('languageSelector.bn') }}</b-badge>
            <b-badge v-if="'fr' === lang" pill variant="secondary" :title="$t('languageSelector.french')">{{ $t('languageSelector.fr') }}</b-badge>
            <b-badge v-if="'es' === lang" pill variant="secondary" :title="$t('languageSelector.spanish')">{{ $t('languageSelector.es') }}</b-badge>
            <b-badge v-if="'mn' === lang" pill variant="secondary" :title="$t('languageSelector.mongolian')">{{ $t('languageSelector.mn') }}</b-badge>
            <b-badge v-if="'my' === lang" pill variant="secondary" :title="$t('languageSelector.burmese')">{{ $t('languageSelector.my') }}</b-badge>
            <b-badge v-if="'pt' === lang" pill variant="secondary" :title="$t('languageSelector.portuguese')">{{ $t('languageSelector.pt') }}</b-badge>
            <b-badge v-if="'pt-br' === lang" pill variant="secondary" :title="$t('languageSelector.portugueseBrazil')">{{ $t('languageSelector.ptbr') }}</b-badge>
            <b-badge v-if="'si' === lang" pill variant="secondary" :title="$t('languageSelector.sinhala')">{{ $t('languageSelector.si') }}</b-badge>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="text-center p-2 bg-secondary text-white sticky-top">
        {{selectedDate | moment("Y-MM-DD")}} &nbsp; {{$t('history.conCount')}}: {{filteredSessionInfo.length}}
      </div>
      <div class="no-conversation">
        {{ $t('history.noConversation') }}
      </div>
    </div>
  </div>
</template>

<script>
import {operatorStatus} from '../util/operatorStatus';
import {colorFromString} from '../util/word-color'

export default {
  props: [
    'filteredSessionInfo', 'sessionId', 'selectedLanguage',
    'sessionListTrack', 'selectedDate', 'operatorSession', 'controller',
  ],
  data() {
    return {
      selectedSessionId: this.sessionId,
      temp_language : null,
      status: operatorStatus,
      // ページネーションの現在のページ番号
      pageIdx: 1,
      // セッション一覧のページネーションの単位
      sessionsPerPage: 20,
      // 初回読み込みフラグ。セッションID指定で開かれた場合にページネーションを調整するために利用。
      isFirstLoad: true
    }
  },
  computed: {
    getOperatorSessionStatus() {
      return (session_id) => {
        let operator_session = this.operatorSession.filter((session) => {
          return session.session_id === session_id
        })
        if (operator_session.length > 0){
          operator_session = operator_session[operator_session.length-1]
          return operator_session.status
        }
        return false
      }
    },
    /**
     * ページネーションの最大ページ数を取得。
     * （セッション数 / ページあたりのセッション数）を切り上げたもの。
     */
    getTotalPageNumber() {
      return Math.ceil(this.filteredSessionInfo.length / this.sessionsPerPage)
    },
    /**
     * セッション一覧をページネーションのためにスライスして返す。
     * セッションID指定でページがロードされた場合の初期処理も行う。
     */
    filteredSessionsByPage() {
      // 初回読み込み時にセッションID指定の場合にページネーションを調整する。
      if (this.isFirstLoad && this.selectedSessionId) {
        this.isFirstLoad = false

        const sessionIdx = this.filteredSessionInfo.findIndex(session => session.sessionId == this.selectedSessionId)
        if (sessionIdx != -1) {
          // 指定されたセッションがない場合は無視する。
          // セッションの順番は0-indexedなので1足した数字をページあたりセッション数で割ったものを切り上げする。
          this.pageIdx = Math.ceil((sessionIdx + 1) / this.sessionsPerPage)
        }
      }

      return this.filteredSessionInfo.slice(
        (this.pageIdx - 1) * this.sessionsPerPage,
        this.pageIdx * this.sessionsPerPage)
    }
  },
  methods: {
    setSessionId(){
      this.selectedSessionId = this.sessionId
    },
    loadSessionConversation(sessionId) {
      if(this.temp_language !== this.selectedLanguage){
          this.setSessionIdLanguage(sessionId)
      }else{
        if(this.selectedSessionId !== sessionId){
          this.setSessionIdLanguage(sessionId)
        }
      }
    },
    setSessionIdLanguage(sessionId){
      this.selectedSessionId = sessionId
      this.temp_language = this.selectedLanguage
      this.$emit('load-session-conversation', sessionId)
    },
    formatTime(time) {
      time = time.slice(11, 16)
      if (time[0] == '0') {
        time = time.slice(1)
      }
      return time
    },
    getUserMetaFullName(sessionId, platform) {
      // ユーザーメタのフルネームを取得する
      let userMeta = null;
      if (this.controller.userMetaManager) {
        userMeta = this.controller.userMetaManager.getMatchedUserMeta(sessionId, platform);
      }
      return userMeta?.fullName || null;
    },
    colorFromString: colorFromString
  }
}
</script>
