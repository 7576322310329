<template>
  <div>
    <div>
      <div class="row ml-5 mb-3">
        <!-- クライアント追加ボタン -->
        <button
          class="btn btn-success button-text mr-1"
          @click="openAddClientModal"
        >
          <i class="fa fa-plus"></i>
          <span>{{ $t("faq.addClient") }}</span>
        </button>
        <!-- ローダー追加ボタン -->
        <button
          class="btn btn-success button-text mr-1 ml-3"
          @click="openAddLoaderModal"
        >
          <i class="fa fa-plus"></i>
          <span>{{ $t("faq.addLoader") }}</span>
        </button>
      </div>

      <!-- クライアント一覧 -->
      <b-table
        :fields="fields"
        :items="
          controller.clients.map((c) => ({
            ...c,
            _showDetails: true,
          }))
        "
        outlined
        bordered
        head-variant="light"
      >
        <template #cell(url)="{ item }">
          <div class="d-flex justify-content-center">
            <a :href="`https://faq.obotai.com/c/${item.cid}/`" target="_blank">
              https://faq.obotai.com/c/{{ item.cid }}/
            </a>
            <div
              class="pointer-cursor ml-2"
              @click="copyToClipboard(`https://faq.obotai.com/c/${item.cid}/`)"
            >
              <i class="fas fa-copy"></i>
            </div>
          </div>
        </template>

        <!-- 編集ボタン -->
        <template #cell(edit)="data">
          <div class="pointer-cursor" @click="openEditClientModal(data.item)">
            <i class="fa fa-edit"></i>
          </div>
        </template>

        <!-- 削除ボタン -->
        <template #cell(delete)="data">
          <div class="pointer-cursor" @click="openDeleteClientModal(data.item)">
            <i class="fa fa-trash-alt"></i>
          </div>
        </template>

        <!-- ローダー一覧 -->
        <template
          #row-details="row"
          class="row p-0"
          :style="{ backgroundColor: '#f8f9fa', border: 'none' }"
        >
          <div
            class="pl-5 p-0 m-0"
            :style="{ backgroundColor: '#f8f9fa', border: 'none' }"
          >
            <b-table
              :fields="
                [
                  { key: 'uid', label: 'UID' },
                  { key: 'name', label: $t('faq.managementName') },
                  { key: 'action', label: $t('faq.operation') },
                  { key: 'edit', label: $t('buttons.edit') },
                  { key: 'delete', label: $t('buttons.delete') },
                ].map((i) => {
                  return {
                    ...i,
                    class: 'text-center',
                  };
                })
              "
              :items="
                controller.loaders.filter((l) => l.client === row.item.id)
              "
              outlined
              bordered
              head-variant="light"
              show-empty
            >
              <template #empty="scope">
                <span>このクライアントにはローダーがありません。</span>
              </template>

              <template #cell(action)="{ item }">
                <b-button
                  class="primary"
                  @click="copyToClipboard(getScriptTag(item.uid))"
                >
                  {{ $t("faq.copyScriptTag") }}<i class="fas fa-copy"></i>
                </b-button>
              </template>

              <!-- 編集ボタン -->
              <template #cell(edit)="data">
                <div
                  class="pointer-cursor"
                  @click="openEditLoaderModal(data.item)"
                >
                  <i class="fa fa-edit"></i>
                </div>
              </template>

              <!-- 削除ボタン -->
              <template #cell(delete)="data">
                <div
                  class="pointer-cursor"
                  @click="openDeleteLoaderModal(data.item)"
                >
                  <i class="fa fa-trash-alt"></i>
                </div>
              </template>
            </b-table>
          </div>
        </template>
      </b-table>
    </div>

    <!-- Modals -->
    <error-modal ref="errorModal" :message="message" />

    <progress-modal ref="progressModal" :message="message" />

    <!-- クライアント追加モーダル -->
    <b-modal
      :title="$t('faq.addClient')"
      ref="createClientModal"
      header-class="bg-info text-light"
      body-class="text-info"
      no-close-on-esc
      no-close-on-backdrop
    >
      <div>
        <div class="mt-3">{{ $t("faq.managementName") }}</div>
        <b-form-group :invalid-feedback="createClientNameErrorMessage">
          <b-form-input
            v-model="createClientModalData.name"
            class="mt-1 ml-1"
            name="name"
            type="text"
            :state="!createClientNameErrorMessage"
          />
        </b-form-group>
      </div>

      <modal-footer
        slot="modal-footer"
        :okDisabled="checkCreateClientModal()"
        @ok="createClient"
        @cancel="$refs.createClientModal.hide()"
      />
    </b-modal>

    <!-- クライアント編集モーダル -->
    <b-modal
      :title="$t('faq.editClient')"
      ref="editClientModal"
      header-class="bg-info text-light"
      body-class="text-info"
      no-close-on-esc
      no-close-on-backdrop
    >
      <div v-if="controller.selectedClient">
        <div>
          <h5>CID</h5>
          <div>
            {{ controller.selectedClient.cid }}
          </div>
        </div>

        <h4>{{ $t("faq.basicConfiguration") }}</h4>

        <div>
          <div class="mt-3">{{ $t("faq.managementName") }}</div>
          <b-form-group :invalid-feedback="editClientNameErrorMessage">
            <b-form-input
              v-model="editClientModalData.name"
              class="mt-1 ml-1"
              name="name"
              type="text"
              :state="!editClientNameErrorMessage"
            />
          </b-form-group>
        </div>

        <div>
          <div class="row m-3">
            <b-button
              :disabled="editClientModalData.editType === 'basic'"
              @click="onSelectClientEditType('basic')"
            >
              {{ $t("faq.simpleConfiguration") }}
            </b-button>
            <b-button
              class="ml-1"
              :disabled="editClientModalData.editType === 'advanced'"
              @click="onSelectClientEditType('advanced')"
            >
              {{ $t("faq.advancedConfiguration") }}
            </b-button>
          </div>

          <!-- 簡易設定 -->
          <div v-if="editClientModalData.editType === 'basic'">
            <div class="mt-3">{{ $t("faq.title") }}</div>
            <b-form-input
              :value="JSON.parse(editClientModalData.data ?? '{}').title ?? ''"
              class="mt-1 ml-1"
              name="name"
              type="text"
              @input="onInputTitle($event)"
            />
          </div>

          <!-- 高度な設定 -->
          <div v-else>
            <div>
              <h5>{{ $t("faq.configurationData") }}</h5>
              <textarea v-model="editClientModalData.data" class="w-100" />

              <h5>{{ $t("faq.additionalCSS") }}</h5>
              <textarea v-model="editClientModalData.style" class="w-100" />

              <h5>{{ $t("faq.cssFilePath") }}</h5>
              <b-form-input
                v-model="editClientModalData.css_path"
                class="w-100"
              />
            </div>
          </div>
        </div>
      </div>

      <modal-footer
        slot="modal-footer"
        :okDisabled="checkEditClientModal()"
        @ok="updateClient"
        @cancel="$refs.editClientModal.hide()"
      />
    </b-modal>

    <!-- ローダー編集モーダル -->
    <b-modal
      :title="$t('faq.editLoader')"
      ref="editLoaderModal"
      header-class="bg-info text-light"
      body-class="text-info"
      no-close-on-esc
      no-close-on-backdrop
    >
      <div v-if="controller.selectedLoader">
        <div>
          <h5>UID</h5>
          {{ controller.selectedLoader.uid }}
        </div>

        <h4>{{ $t("faq.basicConfiguration") }}</h4>

        <div>
          <div class="mt-3">{{ $t("faq.managementName") }}</div>
          <b-form-group :invalid-feedback="editLoaderNameErrorMessage">
            <b-form-input
              v-model="editLoaderModalData.name"
              class="mt-1 ml-1"
              name="name"
              type="text"
              :state="!editLoaderNameErrorMessage"
            />
          </b-form-group>
        </div>

        <div>
          <!-- 高度な設定 -->
          <h4>{{ $t("faq.advancedConfiguration") }}</h4>
          <div>
            <div>
              <h5>{{ $t("faq.configurationData") }}</h5>
              <textarea v-model="editLoaderModalData.data" class="w-100" />

              <h5>{{ $t("faq.additionalCSS") }}</h5>
              <textarea v-model="editLoaderModalData.style" class="w-100" />
            </div>
          </div>
        </div>
      </div>

      <modal-footer
        slot="modal-footer"
        :okDisabled="checkEditLoaderModal()"
        @ok="updateLoader"
        @cancel="$refs.editLoaderModal.hide()"
      />
    </b-modal>

    <!-- ローダー追加モーダル -->
    <b-modal
      :title="$t('faq.addLoader')"
      ref="createLoaderModal"
      header-class="bg-info text-light"
      body-class="text-info"
      no-close-on-esc
      no-close-on-backdrop
    >
      <div>{{ $t("faq.client") }}</div>
      <model-select
        v-model="createLoaderModalData.client"
        :options="
          [
            {
              text: '---',
              value: null,
            },
          ].concat(
            controller.clients.map((c) => ({
              text: c.name,
              value: c.id,
            }))
          )
        "
        placeholder="Select Client"
      />
      <div v-if="createLoaderClientErrorMessage">
        <span class="text-danger">{{ createLoaderClientErrorMessage }}</span>
      </div>
      <div>
        <div class="mt-3">{{ $t("faq.managementName") }}</div>
        <b-form-group :invalid-feedback="createLoaderNameErrorMessage">
          <b-form-input
            v-model="createLoaderModalData.name"
            class="mt-1 ml-1"
            name="name"
            type="text"
            :state="!createLoaderNameErrorMessage"
          />
        </b-form-group>
      </div>

      <modal-footer
        slot="modal-footer"
        :okDisabled="checkCreateLoaderModal()"
        @ok="createLoader"
        @cancel="$refs.createLoaderModal.hide()"
      >
      </modal-footer>
    </b-modal>

    <!-- クライアント削除モーダル -->
    <delete-confirm-modal
      ref="deleteClientConfirmModal"
      :title="$t('faq.deleteClient')"
      :bodyMessage="
        $t('faq.deleteClientMessage', {
          client_name: controller.selectedClient?.name,
        })
      "
      @ok="deleteClient"
      @key-press-enter="$refs.deleteClientConfirmModal.hide()"
    />

    <!-- ローダー削除モーダル -->
    <delete-confirm-modal
      ref="deleteLoaderConfirmModal"
      :title="$t('faq.deleteLoader')"
      :bodyMessage="
        $t('faq.deleteLoaderMessage', {
          loader_name: controller.selectedLoader?.name,
        })
      "
      @ok="deleteLoader"
      @key-press-enter="$refs.deleteLoaderConfirmModal.hide()"
    />
  </div>
</template>

<script>
import { I18n } from "../util/i18n";
import copyToClipboard from "../util/copy-url";
import { ModelSelect } from "vue-search-select";

export default {
  props: ["controller"],
  components: { ModelSelect },
  data() {
    const i18n = new I18n(window.i18nContext);
    return {
      message: "",
      fields: [
        { key: "cid", label: "CID" },
        { key: "name", label: this.$t("faq.managementName") },
        { key: "URL", label: "URL" },
        { key: "edit", label: this.$t("buttons.edit") },
        { key: "delete", label: this.$t("buttons.delete") },
      ].map((i) => {
        return {
          ...i,
          class: "text-center",
        };
      }),
      /** クライアント作成時データ */
      createClientModalData: {
        /** 管理名 */
        name: "",
      },
      /** ローダー作成時データ */
      createLoaderModalData: {
        /** 管理名 */
        name: "",
        client: {
          text: "---",
          value: null,
        },
      },
      /** クライアント編集用データ */
      editClientModalData: {
        editType: "basic",
        /** 管理名 */
        name: "",
        data: "{}",
        /** 追加CSS */
        style: "",
        css_path: "",
      },
      /** ローダー編集用データ */
      editLoaderModalData: {
        /** 管理名 */
        name: "",
        data: "{}",
        /** 追加CSS */
        style: "",
      },
      /** モーダルエラーメッセージ */
      createClientNameErrorMessage: "",
      editClientNameErrorMessage: "",
      createLoaderNameErrorMessage: "",
      createLoaderClientErrorMessage: "",
      editLoaderNameErrorMessage: "",
    };
  },
  methods: {
    showError(message) {
      this.message = message;
      this.$refs.errorModal.show();
    },
    showProgress(message) {
      this.message = message;
      this.$refs.progressModal.show();
    },
    hideProgress() {
      this.$refs.progressModal.hide();
    },
    /**
     * カテゴリ更新処理
     * 完了後コールバックでモーダルを閉じる
     */
    async updateDataset() {
      await this.controller.updateDataset(this.modalData);

      this.modalData = {
        active: false,
        priority: 0,
      };

      this.$refs.datasetModal.hide();
    },
    /**
     * クライアント編集モーダルを開く
     */
    openEditClientModal(client) {
      this.controller.selectedClient = client;

      const { style, name, data, css_path } = this.controller.selectedClient;

      this.editClientModalData = {
        editType: "basic",
        /** 管理名 */
        name,
        data: JSON.stringify(data ?? "{}"),
        style,
        css_path,
      };

      this.$refs.editClientModal.show();
    },
    /**
     * クライアント編集モーダルでタイトルが入力されたときの処理
     */
    onInputTitle(title) {
      const data = JSON.parse(this.editClientModalData.data ?? "{}");
      data.title = title;

      this.editClientModalData.data = JSON.stringify(data);
    },
    /**
     * クライアント作成モーダルを開く
     */
    openAddClientModal() {
      // 管理名の初期値を設定
      this.createClientModalData = {
        name: `${this.$t("faq.client")}${this.controller.clients.length + 1}`,
      };

      // モーダルを表示
      this.$refs.createClientModal.show();
    },
    /**
     * ローダー作成モーダルを開く
     */
    openAddLoaderModal() {
      // ローダーの初期値を設定
      this.createLoaderModalData = {
        // ローダー名は「ローダー + ローダー数 + 1」
        name: `${this.$t("faq.loader")}${this.controller.loaders.length + 1}`,
        client: {
          text: "---",
          value: null,
        },
      };

      // モーダルを表示
      this.$refs.createLoaderModal.show();
    },
    /**
     * クライアント追加処理
     */
    async createClient() {
      try {
        await this.controller.createClient(this.createClientModalData);
      } catch (error) {
        this.showError(error.response.data);
      }

      this.createClientModalData = {
        name: "",
      };

      this.$refs.createClientModal.hide();
    },
    /**
     * ローダー追加処理
     */
    async createLoader() {
      try {
        await this.controller.createLoader(this.createLoaderModalData);
      } catch (error) {
        this.showError(error.response.data);
      }

      this.createLoaderModalData = {
        name: "",
        client: {
          text: "---",
          value: null,
        },
      };

      this.$refs.createLoaderModal.hide();
    },
    /**
     * クライアント削除モーダル表示処理
     */
    openDeleteClientModal(item) {
      this.controller.selectedClient = item;

      this.$refs.deleteClientConfirmModal.show();
    },
    /**
     * ローダー削除モーダル表示処理
     */
    openDeleteLoaderModal(item) {
      this.controller.selectedLoader = item;

      this.$refs.deleteLoaderConfirmModal.show();
    },
    /**
     * クライアント削除処理
     */
    async deleteClient() {
      try {
        await this.controller.deleteClient();
      } catch (error) {
        this.showError(error.response.data);
      }

      this.controller.selectedClient = null;

      this.$refs.deleteClientConfirmModal.hide();
    },
    /**
     * ローダー削除処理
     */
    async deleteLoader() {
      try {
        await this.controller.deleteLoader();
      } catch (error) {
        this.showError(error.response.data);
      }

      this.controller.selectedLoader = null;

      this.$refs.deleteLoaderConfirmModal.hide();
    },
    /**
     * クライアント更新処理
     */
    async updateClient() {
      try {
        await this.controller.updateClient(this.editClientModalData);
      } catch (error) {
        this.showError(error.response.data);
      }

      this.$refs.editClientModal.hide();
    },
    /**
     * ローダー更新処理
     */
    async updateLoader() {
      try {
        await this.controller.updateLoader(this.editLoaderModalData);
      } catch (error) {
        this.showError(error.response.data);
      }

      this.$refs.editLoaderModal.hide();
    },
    /**
     * クライアント編集タイプ選択時の処理
     */
    onSelectClientEditType(type) {
      if (type === "basic") {
        // 簡易設定に切替時。dataがJSON形式でなくなっている場合があるので初期化する
        const { style, data } = this.controller.selectedClient;
        this.editClientModalData.data = JSON.stringify(data);

        this.editClientModalData.style = style;
      } else {
        // 高度な設定に切替時
      }

      this.editClientModalData.editType = type;
    },
    /**
     * ローダー編集モーダルを開く
     */
    openEditLoaderModal(loader) {
      this.controller.selectedLoader = loader;

      const { style, name, data } = loader;

      this.editLoaderModalData = {
        name,
        data: JSON.stringify(data ?? "{}"),
        style,
      };

      this.$refs.editLoaderModal.show();
    },
    /**
     * スクリプトタグを取得する
     */
    getScriptTag(uid) {
      // 直接scriptタグを記載すると@click内で関数にわたすときにビルドエラーとなるためエスケープする。コピー処理内で解除する
      return `<!-- <span class="obotai-faq-activator">Open FAQ</span>のようにclassを指定した要素を配置してください -->
&lt;script defer src="https://faq.obotai.com/loader/?uid=${uid}"&gt;&lt;/script&gt;`;
    },
    /**
     * パラメータで指定された値をクリップボードにコピーする
     * 成功時のトースト表示
     */
    copyToClipboard(value) {
      // scriptタグ部分のescapeを解除
      value = value.replace(/&lt;/g, "<").replace(/&gt;/g, ">");

      copyToClipboard(value);

      this.$bvToast.toast(this.$t("faq.copied"), {
        variant: "success",
        solid: true,
      });
    },
    /** モーダルバリデーション */
    checkClientName(clientName) {
      if (!clientName) {
        return { state: false, message: this.$t("faq.managementNameRequired") };
      } else if (clientName.length > 50) {
        return {
          state: false,
          message: this.$t("faq.managementNameMaxLength"),
        };
      }
      return { state: true, message: "" };
    },
    checkCreateClientModal() {
      const { state, message } = this.checkClientName(
        this.createClientModalData.name
      );
      this.createClientNameErrorMessage = message;
      return !state;
    },
    checkEditClientModal() {
      const { state, message } = this.checkClientName(
        this.editClientModalData.name
      );
      this.editClientNameErrorMessage = message;
      return !state;
    },
    checkLoaderName(loaderName) {
      if (!loaderName) {
        return { state: false, message: this.$t("faq.managementNameRequired") };
      }
      return { state: true, message: "" };
    },
    checkCreateLoaderModal() {
      let clientState = false;
      const { state, message } = this.checkLoaderName(
        this.createLoaderModalData.name
      );
      this.createLoaderNameErrorMessage = message;
      if (!this.createLoaderModalData.client.value) {
        this.createLoaderClientErrorMessage = this.$t("faq.clientRequired");
      } else {
        this.createLoaderClientErrorMessage = "";
        clientState = true;
      }
      return !state || !clientState;
    },
    checkEditLoaderModal() {
      const { state, message } = this.checkLoaderName(
        this.editLoaderModalData.name
      );
      this.editLoaderNameErrorMessage = message;
      return !state;
    },
  },
};
</script>

<style scoped>
.selected-language {
  border: 3px solid #b9bbbe;
  color: #fff;
}
.table td {
  min-width: 120px;
}
</style>
