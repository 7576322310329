<template>
  <li :title="liTitle">
    <div
      class="label my-1 p-1"
      :class="{selected: selected || multiSelected}"
      :style="!isForConfirm && isVisible"
      @click="!isForConfirm && $emit('select-intent', intent)"
      @dblclick="!isForConfirm && userType!='viewer' && !multiSelected ? $emit('edit-intent', intent) : null"
      @keyup.delete="!isForConfirm && userType!='viewer' && !multiSelected ? $emit('delete-intent', intent) : null"
      tabindex="-1">
      <span v-if="intent.fallbackIntent || intent.welcomeIntent || intent.isFeedbackResponse || intent.enable_webhook || intent.isFeedbackActive">
        <span v-if="intent.fallbackIntent">
          <icon-fallback></icon-fallback>
        </span>
        <span v-if="intent.welcomeIntent">
          <icon-welcome></icon-welcome>
        </span>
        <span v-if="feedbackIntentDict && intent.isFeedbackResponse">
          <span v-if="feedbackIntentDict[intent.id] === '4'">
            <icon-feedback-best></icon-feedback-best>
          </span>
          <span v-if="feedbackIntentDict[intent.id] === '3'">
            <icon-feedback-good></icon-feedback-good>
          </span>
          <span v-if="feedbackIntentDict[intent.id] === '2'">
            <icon-feedback-average></icon-feedback-average>
          </span>
          <span v-if="feedbackIntentDict[intent.id] === '1'">
            <icon-feedback-bad></icon-feedback-bad>
          </span>
          <span v-if="feedbackIntentDict[intent.id] === '0'">
            <icon-feedback-worst></icon-feedback-worst>
          </span>
        </span>
        <!-- 本番でもそもそも何もアイコンが表示されてない？
        <span v-show="intent.enable_webhook">
          <i class="fas fa-plug"></i>
        </span>
        -->
        <span v-if="intent.isFeedbackActive" title="Feedback activated">
          <icon-feedback></icon-feedback>
        </span>
      </span>
      {{ intent.name }}
      <div class="d-inline" v-for="intentLabelId in intent.intentLabelIds">
        <span
          class="badge mx-1"
          v-if="getIntentLabelNameByIntent(intentLabelId)"
          :style="setIntentLabelView(getIntentLabelNameByIntent(intentLabelId))">
          {{ getIntentLabelNameByIntent(intentLabelId)["name"] }}
        </span>
      </div>
      <span v-b-tooltip.hover.html.right="'Intent name exceeds <strong>100</strong> characters'" v-if="lengthOverLimit">
        <i class="fas fa-exclamation-triangle" style="color:#ff5c09"></i>
      </span>
      <b-button v-if="selected" class="btn btn-success btn-xs float-right"
        @click="copyUrl(intent.name, intent.categoryId)"
        @click.stop="$emit('select-intent', intent)"
        title="Copy Intent Url">
        <i class="fas fa-copy"></i>
      </b-button>
    </div>
  </li>
</template>

<script>
  import copyToClipboard from '../util/copy-url'
  export default {
    props: [
      'lengthOverLimit',
      'intent',
      'selectedIntent',
      'index', 'searchWord',
      'userType',
      'multiSelected',
      'isForConfirm',
      'listCategories',
      'selectedCategory',
      'feedbackIntentDict',
      'listIntentLabels'
    ],
    data(){
      return{
        showCopyUrl: false
      }
    },
    computed: {
      isVisible() {
        if (this.searchWord === '') {
          return {}
        }

        let lowercaseIntent = this.intent.name.toLowerCase()
        return lowercaseIntent.includes(this.searchWord) ? {} : {display: 'none'}
      },
      selected() {
        return this.selectedIntent ? this.intent.id === this.selectedIntent.id : false
      },
      liTitle() {
        if(this.selectedCategory !== null){
          if(!this.selectedCategory || this.selectedCategory.subcategories && this.selectedCategory.subcategories.length <= 0){
            return ''
          }
        }
        const tree = this.listCategories[this.intent.categoryId]
        return tree.replace(/_/gi, " > ") // replace the underscore for tree view.
      }
    },
    methods:{
      copyUrl(name, id){
        let url = location.toString().replace(location.search, "")
        const intentName = encodeURI(this.listCategories[id]) + '_' + encodeURI(name)
        const intentEditorController = this.$parent.$parent.$parent.$parent.controller
        url = url + '?intent_name=' + intentName + '&agent_gid=' + intentEditorController.agentGid
        copyToClipboard(url)
        alert("Copied intent URL to clipboard")
      },
      getIntentLabelNameByIntent(intentLabelId) {
        return this.listIntentLabels ? this.listIntentLabels.find(intentLabel => {
          return intentLabel.id === intentLabelId
        }) : ""
      },
      setIntentLabelView(intentLabel) {
        return this.$parent.$parent.$parent.controller.setIntentLabelBgColor(intentLabel)
      }
    }
  }
</script>
<style scoped>
  .popover {
    left: 150px !important;
}
</style>
