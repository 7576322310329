<template>
  <div class="analytics-chart">
    <div v-if="isDisplayGraph" :style="{height: '25rem'}">
      <Pie :data="data" :options="options" />
    </div>
    <div v-else class="text-center pt-2" :style="{color: '#666666'}">
      <div class="mb-4 font-weight-bold" :style="{fontSize: graphTitleFontSize}">{{ graphTitle }}</div>
      <div>表示可能なデータがありません。</div>
    </div>
  </div>
</template>

<script>
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend
} from 'chart.js';
import { Pie } from 'vue-chartjs';

ChartJS.register(ArcElement, Tooltip, Legend);

export default {
  props: ['data', 'options'],
  components: {Pie},
  data() {
    return {
      graphTitle: this.options.plugins.title.text,
      graphTitleFontSize:`${this.options.plugins.title.font.size}px`,
    }
  },
  computed: {
    isDisplayGraph() {
      return !this.data.datasets[0].data.every((data) => data === 0);
    }
  },
}
</script>
