import Vue from 'vue'
import {UrlBuilder} from "./util/url-builder"
import {API_URL} from "./resource/urls"
import { IntentLabelController } from "./controller/intent-label"

import IntentLabel from "./components/IntentLabel.vue"
import ProgressModal from './components/ProgressModal.vue'
import ErrorModal from './components/ErrorModal.vue'
import DeleteConfirmModal from './components/DeleteConfirmModal.vue'
import ModalFooter from './components/ModalFooter.vue'
import ColorPickSlider from './components/ColorPickSlider.vue'


export const appIntentLabel = (i18n) => {
  const controller = new IntentLabelController(
    (new UrlBuilder(API_URL)).build(),
    window.i18nContext,
    window.requestContext
  )

  Vue.component('color-pick-slider', ColorPickSlider)
  Vue.component('intent-label', IntentLabel)
  Vue.component('delete-confirm-modal', DeleteConfirmModal)
  Vue.component('progress-modal', ProgressModal)
  Vue.component('error-modal', ErrorModal)
  Vue.component('modal-footer', ModalFooter)

  new Vue({
    el: '#app-intent-label',
    i18n: i18n,
    data: {
      controller: controller
    },
    mounted() {
      this.$nextTick(() => {
        controller.ready()
      })
    }
  })
}
