<template>
  <!-- 位置情報表示コンポネント -->
  <div>
    <div>
      <div
        v-if="chatItem.logType === 'location'"
        :class="chatItem.location ? 'gmap-message' : 'message-bg-blue'"
      >
        <div>
          <!-- TODO 翻訳を入れてあげる -->
          LOCATION
        </div>

        <div>
          <small
            class="pointer-cursor"
            v-if="chatItem.latitude && chatItem.longitude"
            @click="copyLocationInfo(chatItem.latitude, chatItem.longitude)"
            v-b-tooltip.hover
            title="Click to copy"
          >
            <!-- 緯度経度の表示 -->
            <i class="fas fa-map-marker-alt"></i>
            {{
              `${truncateNumber(chatItem.latitude)}, ${truncateNumber(
                chatItem.longitude
              )}`
            }}
            <i class="far fa-copy ml-4"></i>
          </small>

          <!-- google mapからの位置情報送信の場合は住所も表示する。 -->
          <div class="d-flex justify-content-end">
            <small>
              <!-- google mapから送信されたことを示す -->
              <div v-if="chatItem.source">
                <i class="fa fa-info" style="min-width: 16px"></i>
                {{ chatItem.source }}
              </div>

              <!-- 住所 -->
              <div v-if="chatItem.address" style="max-width: 170px">
                <i class="fas fa-at mt-1" style="min-width: 16px"></i>
                {{ chatItem.address }}
              </div>
            </small>
          </div>
        </div>

        <!-- google map表示 -->
        <div v-if="chatItem.latitude && chatItem.longitude" class="gmap-canvas">
          <a
            :href="getMapURL(chatItem.latitude, chatItem.longitude)"
            target="_blank"
          >
            <img
              :src="getMapImage(chatItem.latitude, chatItem.longitude)"
              class="img-responsive"
            />
          </a>
        </div>
      </div>
    </div>

    <!-- 送信ユーザー情報（LINE Group利用時のみ表示） -->
    <div v-if="chatItem.lineGroupId && chatItem.sessionId" class="text-right text-secondary">
      <span
        class="pointer-cursor"
        v-b-tooltip.hover.bottom.html
        :title="chatItem.sessionId"
      >
        <span
          v-if="
            userMetaManager.allSessionUserMeta.find(
              (meta) => meta.lineId === chatItem.sessionId
            )?.fullName
          "
        >
          {{
            userMetaManager.allSessionUserMeta.find(
              (meta) => meta.lineId === chatItem.sessionId
            )?.fullName
          }}
        </span>

        <span v-else>
          {{ chatItem.sessionId.slice(0, 12) }}...
        </span>
      </span>
    </div>

    <!-- 質問送信時刻表示 -->
    <div class="text-right text-secondary">
      <span
        class="pointer-cursor"
        v-b-tooltip.hover.bottom.html
        :title="messageTooltipRight(chatItem)"
      >
        {{ getTimeFromDate(chatItem.insertDateTime) }}
      </span>
    </div>
  </div>
</template>

<script>
import GoogleMap from "../../util/google-map";
import copyToClipboard from "../../util/copy-url";

export default {
  props: ["chatItem", "clientIdFilterEnabled", "userMetaManager",],
  computed: {
    messageTooltipRight() {
      // 右側メッセージ（質問）のツールチップで、ClientIDも見れるように
      return (chatItem) => {
        let html = "";
        if (this.clientIdFilterEnabled) {
          html += `ClientId: ${chatItem.clientId ?? "-"}</br>`;
        }
        return `${html}${this.getFullTime(this.chatItem.insertDateTime)}`;
      };
    },
    getFullTime() {
      return (time) => {
        return this.getTimeFromDate(time).replace("T", " ");
      };
    },
  },
  methods: {
    /**
     * TODO ??
     */
    getTimeFromDate(date) {
      return `${date.getHours()}:${("0" + date.getMinutes()).slice(-2)}`;
    },
    copyLocationInfo(latitude, longitude) {
      const locPos = `${latitude}, ${longitude}`;
      copyToClipboard(locPos);
      alert(this.$t("fileManagement.copiedURL"));
    },
    truncateNumber(number) {
      return parseFloat(number).toFixed(4);
    },
    getMapImage(latitude, longitude) {
      const gmap = new GoogleMap(latitude, longitude);
      return gmap.image();
    },
    getMapURL(latitude, longitude) {
      const gmap = new GoogleMap(latitude, longitude);
      return gmap.url();
    },
  },
};
</script>

<style scoped>
.message-bg-blue {
  padding: 5px;
  display: inline-block;
  border-radius: 8px;
}
</style>
