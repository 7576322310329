<template>
  <div class="conversation-history">
    <div class="row">
      <conversation-history-search-filter
        class="col-md-3"
        :selectedDate="controller.selectedDate"
        :selectedPlatforms="controller.selectedPlatforms"
        :allDate="controller.allDate"
        :insertDate="controller.insertDate"
        :platforms_icon="controller.platforms_icon"
        :supported_languages="controller.supported_languages"
        :sessionIdSearchResult="controller.sessionIdSearchResult"
        :sessionIdIsLoad="controller.sessionIdIsLoad"
        @month-selected="onMonthSelected"
        @date-selected="onDateSelected"
        @toggle-platform-selection="togglePlatformSelection"
        @toggle-all-platform-selection="toggleAllPlatformSelection"
        @on-sort-element-selected="onSortElementSelected"
        @on-sort-order-selected="onSortOrderSelected"
        @on-welcome-filter-changed="onWelcomeFilterChanged"
        @language-type-selection="languageTypeSelection"
        @reset-session-id-search-result="controller.sessionIdSearchResult = []"
        @filter-search-session-id="filterSearchSessionId"
      />

      <conversation-history-session-list
        ref="sessionList"
        class="col-md-2 no-padding"
        :filteredSessionInfo="controller.filteredSessionInfo"
        :sessionId="controller.sessionId"
        :selectedLanguage="controller.selectedLanguage"
        :sessionListTrack="controller.sessionListTrack"
        :selectedDate="controller.selectedDate"
        :operatorSession="controller.operatorSession"
        :controller="controller"
        @load-session-conversation="loadSessionConversation"
      />

      <conversation-history-log
        class="col-md-5"
        :selectedPlatform="
          controller.selectedSessionInfo &&
          controller.selectedSessionInfo.platform
        "
        :logs="controller.logs"
        :ui_language="controller.ui_language"
        :debug="controller.debug"
        :translation-object="controller.translationObject"
        :redirector-access-logs="controller.redirector_access_log"
        :is-translating="controller.isTranslating"
        :operatorSession="controller.operatorSession"
        :sessionId="
          controller.selectedSessionInfo &&
          controller.selectedSessionInfo.sessionId
        "
        :logId="controller.logId"
        :allow_operator_handling="controller.allow_operator_handling"
        :client-id-filter-enabled="controller.clientIdFilterEnabled"
        :selected-client-id="controller.selectedClientId"
        :controller="controller"
        :userMetaManager="controller.userMetaManager"
        @select-client-id="selectClientId"
      />

      <conversation-history-information
        ref="conversationHistoryInformation"
        class="col-md-2"
        :selectedSessionInfo="controller.selectedSessionInfo"
        :userMetaManager="controller.userMetaManager"
        :userType="controller.userType"
        :operatorSession="controller.operatorSession"
        :allow_operator_handling="controller.allow_operator_handling"
        :uiLanguageCode="controller.ui_language"
        :current-chat-language-code="
          (controller.logs.slice(-1)[0] || {}).languageCode || 'en'
        "
        @send-push-message="sendPushMessage"
        @copy-session-url="copySessionURL"
        @operator-support-update="operatorSupportUpdate"
        @on-call-translate="onCallTranslate"
      />
    </div>

    <error-modal ref="errorModal" :message="message" />

    <progress-modal ref="progressModal" :message="message" />

    <b-alert
      :show="dismissCountDown"
      fade
      dismissible
      :variant="dismissVariant"
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
      class="stick-to-bottom"
    >
      <p>{{ disMissMessage }}</p>
    </b-alert>
  </div>
</template>

<script>
import csrfToken from "../util/csrf-token";
import { cloneDeep } from "lodash";
export default {
  props: ["controller"],
  data() {
    return {
      message: "",
      dismissSecs: 3,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      disMissMessage: "",
      dismissVariant: "info",
    };
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(message, variant = "info") {
      this.disMissMessage = message;
      this.dismissVariant = variant;
      this.dismissCountDown = this.dismissSecs;
    },
    loadSessionConversation(sessionId) {
      // 選択されたセッションIDの会話内容をDBから取得する
      this.controller.deepCloneSelectedDateUp = cloneDeep(
        this.controller.selectedDate
      );
      this.controller.deepCloneSelectedDateDown = cloneDeep(
        this.controller.selectedDate
      );
      this.controller.existsPreviousLog = true;
      this.controller.existsNextLog = true;
      if (!this.controller.isScrollTop) {
        this.controller.isLoadingConversation = true;
        this.controller
          .loadSessionConversation(
            sessionId,
            csrfToken.getCsrfTokenFromCookie(document.cookie)
          )
          .then(() => {
            this.controller.isLoadingConversation = false;
            this.scrollToBottom();
          })
          .catch(() => {
            this.controller.isLoadingConversation = false;
          });
      }
    },
    onMonthSelected(year, month) {
      this.controller.year = year;
      this.controller.month = month;
      this.controller.loadAllDate(year, month);
    },
    onDateSelected(year, month, day) {
      this.controller.loadSessionsByDate(year, month, day);
      this.$refs.sessionList.setSessionId();
    },
    togglePlatformSelection(platformName) {
      this.controller.togglePlatformSelection(platformName);
    },
    toggleAllPlatformSelection() {
      this.controller.toggleAllPlatformSelection();
    },
    onSortElementSelected(value) {
      this.controller.onSortElementSelected(value);
    },
    onSortOrderSelected(value) {
      this.controller.onSortOrderSelected(value);
    },
    onWelcomeFilterChanged(value) {
      this.controller.onWelcomeFilterChanged(value);
    },
    copySessionURL(sId) {
      this.controller.copySessionURL(sId);
    },
    languageTypeSelection(language) {
      this.controller.languageTypeSelection(language);
      this.controller.filterByLanguage();
    },
    filterSearchSessionId(value) {
      this.controller.filterSearchSessionId(
        value,
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      );
    },
    showProgress(message) {
      this.message = message;
      this.$refs.progressModal.show();
    },
    hideProgress() {
      this.$refs.progressModal.hide();
    },
    showError(message) {
      this.message = message;
      this.$refs.errorModal.show();
    },
    sendPushMessage(responseMessage) {
      let result, message, promise;
      [result, message, promise] = this.controller.sendPushMessageToUser(
        responseMessage,
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      );
      if (!result) {
        this.showError(message);
      } else {
        this.showProgress(message);
        let self = this;
        promise
          .then(() => {
            this.hideProgress();
            const self = this;
            setTimeout(function () {
              // reload the session conversation list
              if ("selectedSessionId" in self.controller) {
                self.loadSessionConversation(self.controller.selectedSessionId);
              }
            }, 3000);
          })
          .catch(function (error) {
            self.showError(error.response.data[0]);
            self.hideProgress();
          });
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const obj = document.getElementById("log");
        if (obj) {
          obj.scrollTop = obj.scrollHeight;
        }
      });
    },
    operatorSupportUpdate(status) {
      if (this.controller.allow_operator_handling) {
        let result, message, promise;
        [result, message, promise] = this.controller.operatorSupportUpdate(
          status,
          csrfToken.getCsrfTokenFromCookie(document.cookie)
        );
        if (!result) {
          this.showError(message);
        } else {
          this.showProgress(message);
          let self = this;
          promise
            .then(() => {
              this.controller.loadOperatorSession();
              this.hideProgress();
            })
            .catch(function (error) {
              self.showError(error.response.data[0]);
              self.hideProgress();
            });
        }
      }
    },
    selectClientId(value) {
      this.controller.selectClientId(value);
    },
    async onCallTranslate(params) {
      // api側で必要になるのでdummyのid
      const id = "translation";
      // translationのapiが受け付けている形に加工してapi呼び出し
      const response = await this.controller.translateText(
        [
          {
            id,
            language_code: params.fromLanguage,
            query_text: params.originalText,
            fulfillment_text: "",
          },
        ],
        params.toLanguage
      );

      this.$refs.conversationHistoryInformation.applyTranslationResult({
        idx: params.idx,
        translatedText:
          response.query_text.find((t) => t.id === id)?.text ?? "",
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.controller.setShowAlertFunc(this.showAlert);
    });
  },
};
</script>
<style scoped>
.stick-to-bottom {
  position: fixed;
  bottom: 0;
  right: 0;
}
.alert {
  height: 50px !important;
}
</style>
