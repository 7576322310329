<template>
  <!-- テキスト形式の回答表示用コンポネント -->
  <div v-if="chatItem.text" class="mb-1 message-container">
    <div>
      <span class="message-bg-grey" style="white-space: pre-line">{{
        chatItem.text
      }}</span>

      <!-- 翻訳表示領域 -->
      <chat-item-translation-line
        v-if="translationObject"
        :translation="translatedText"
        type="response_text">
      </chat-item-translation-line>
    </div>

    <chat-item-bottom-detail
      :chat-item="chatItem"
      :redirector-access-logs="redirectorAccessLogs"
    />
  </div>
</template>

<script>
export default {
  props: ["chatItem", "redirectorAccessLogs", "translationObject"],
  computed: {
    translatedText: {
      cache: false,
      get() {
        if (!this.translationObject) {
          return "";
        }
        const translateKey = `${this.chatItem.languageCode}__${this.chatItem.text}`;
        const translated = this.translationObject[translateKey];
        if (translated && !translated.is_same_language && translated.text !== this.chatItem.text) {
          return translated.text;
        }
        return "";
      }
    }
  }
};
</script>

<style scoped>
.message-container {
  display: flex;
  flex-direction: column;
}

.message-bg-grey {
  padding: 5px;
  display: inline-block;
  border-radius: 8px;
}
</style>
