import Vue from 'vue'

import UserNotificationSettings from './components/UserNotificationSettings.vue'
import CodeEditor from './components/CodeEditor.vue'

export const appUserNotificationSettings = (i18n) => {
  Vue.component('user-notification-settings', UserNotificationSettings)
  Vue.component('code-editor', CodeEditor)

  new Vue({
    el: '#app-user-notification-settings',
    i18n: i18n,
  })
}
