<template>
  <div v-if="!translation"></div>
  <div v-else-if="isQueryText"
    class="p-1 text-success"
  >
    {{ translation }}
  </div>
  <div
    v-else-if="isResponseText || isResponseCardContent"
    class="p-1 text-success text-size-sm-right">
    {{ translation }}
  </div>
</template>

<script>
export default {
  name: 'ChatItemTranslationLine',
  props: ['translation', 'type'],
  computed: {
    isQueryText() {
      return this.type === 'query_text';
    },
    isResponseText() {
      return this.type === 'response_text';
    },
    isResponseCardContent() {
      return this.type === 'response_card_content';
    },
  }
}
</script>
