<template>
  <div>
    <!-- 画面上部フィルター領域 -->
    <div class="container-fluid">
      <form @submit.prevent="" class="mb-2">
        <div class="row">
          <div class="col-md-3 pt-3">
            <b-form-select v-model="controller.platform">
              <option
                v-for="platform in controller.supported_platforms"
                :key="platform.value"
                :value="platform.value"
              >
                {{ $t(platform.text) }}
              </option>
            </b-form-select>
          </div>

          <div class="col-md-3 pt-3">
            <b-form-select
              v-model="controller.conversation_type"
              :options="conversarion_type_options"
            />
          </div>

          <div class="col-md-3 pt-3">
            <b-form-select v-model="controller.language">
              <option
                v-for="language in controller.supported_languages"
                :key="language.value"
                :value="language.value"
              >
                {{ $t(language.text) }}
              </option>
            </b-form-select>
          </div>

          <div class="col-md-3 pt-3">
            <history-calendar :date="controller.date" />
          </div>
        </div>

        <div class="row">
          <div class="col-md-3 pt-3">
            <input
              type="text"
              class="form-control"
              :placeholder="$t('history.sessionPlaceholder')"
              v-model="controller.session_id"
            />
          </div>

          <div class="col-md-3 pt-3" v-if="controller.clientIdFilterEnabled">
            <input
              type="text"
              class="form-control"
              placeholder="Client ID"
              v-model="controller.client_id"
            />
          </div>

          <div class="col-md-3 pt-3">
            <input
              type="text"
              class="form-control"
              :placeholder="$t('history.intentPlaceholder')"
              v-model="controller.intentName"
            />
          </div>

          <div class="col-md-3 pt-3">
            <b-form-select
              v-model="controller.feedback"
              :options="feedback_options"
            />
          </div>

          <div class="col-md-3 pt-2">
            <div v-b-popover.hover.bottom="$t('history.wlcMessageCheck')">
              <b-form-checkbox v-model="controller.welcome_message" switch>
                {{ $t("history.wlcMessage") }}
              </b-form-checkbox>
            </div>

            <div v-b-popover.hover.bottom="$t('history.webhookMessageCheck')">
              <b-form-checkbox v-model="controller.webhook_message" switch>
                {{ $t("history.webhookMessage") }}
              </b-form-checkbox>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 pt-3">
            <button @click="search" class="btn btn-success mr-1">
              {{ $t("history.search") }}
            </button>
            <button @click="openExportModal" class="btn btn-info mr-1">
              {{ $t("buttons.export") }}
            </button>
            <button
              class="btn btn-secondary"
              v-if="controller.selectedIntentOptions.length"
              @click="openSelectedIntentModal"
            >
              {{ $t("history.listOpenBtn") }}
              <span v-show="controller.selectedListChanged"
                ><i class="fas fa-circle"></i
              ></span>
            </button>
          </div>
        </div>
      </form>
    </div>

    <h5>{{ $t("history.convHistory") }}</h5>

    <!-- table表示領域 -->
    <div v-if="controller.hasLogs">
      <table class="table table-striped table-detail td-align">
        <thead>
          <tr>
            <th class="cell-detail-sm">{{ $t("history.date") }}</th>
            <th class="cell-detail-sm">{{ $t("history.userQuery") }}</th>
            <th
              :class="{
                'cell-detail-lg': !controller.sentiment_analysis_status,
                'cell-detail-md': controller.sentiment_analysis_status,
              }"
            >
              {{ $t("history.botAnswer") }}
            </th>
            <th class="cell-detail-md">{{ $t("history.conName") }}</th>
            <th class="cell-detail-sm">{{ $t("history.chatUi") }}</th>
            <th class="cell-detail-md">
              {{ $t("history.sessionId") }}
              <span
                v-if="controller.clientIdFilterEnabled"
                style="font-size: small"
              >
                (Client ID)
              </span>
            </th>
            <th
              class="cell-detail-sm"
              v-if="controller.sentiment_analysis_status"
            >
              {{ $t("history.sentiment") }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(item, index) in controller.logs"
            :key="item.id"
            :class="{
              'table-danger': 'input.unknown' === item.action,
            }"
          >
            <!-- 日時 -->
            <td>{{ item.insert_date_time | moment("YYYY-MM-DD HH:mm:ss") }}</td>

            <!-- 質問表示領域 -->
            <td
              class="cell-scroll-x inline"
              :id="`popover-gmap-${item.id}`"
              tabindex="0"
            >
              {{ item.query_text }}
              <i
                v-if="item.is_encrypted"
                :title="$t('history.EncryptedMessage')"
                class="fa fa-lock"
              ></i>
              <div v-if="item.uploaded_file">
                <b-img
                  class="pointer-cursor"
                  thumbnail
                  fluid
                  :src="item.uploaded_file"
                  alt="Uploaded Image"
                  v-show="isImage(item.uploaded_file)"
                  @click="openFile(item.uploaded_file)"
                />

                <video
                  class="pointer-cursor"
                  v-show="isVideo(item.uploaded_file)"
                  :src="item.uploaded_file"
                  width="120"
                  height="120"
                  controls
                />

                <div
                  v-show="isfile(item.uploaded_file)"
                  class="text-info pointer-cursor"
                  @click="openFile(item.uploaded_file)"
                >
                  <i
                    class="far fa-file"
                    style="width: 100px; height: 100px"
                  ></i>
                </div>
              </div>

              <span
                v-if="item.location"
                class="badge badge-pill badge-success pointer-cursor"
              >
                <i class="fas fa-info"></i>
              </span>

              <b-popover
                :target="`popover-gmap-${item.id}`"
                triggers="focus"
                placement="top"
                v-if="item.location"
              >
                <template v-slot:title>
                  <span
                    class="pointer-cursor"
                    @click="copyLocationInfo(item)"
                    v-b-tooltip.hover
                    title="Click to copy"
                  >
                    <i class="fas fa-map-marker-alt"></i>
                    {{ truncateNumber(item.location.latitude) }},
                    {{ truncateNumber(item.location.longitude) }}
                    <i class="far fa-copy ml-4"></i>
                  </span>
                </template>

                <div>
                  <a :href="getMapURL(item)" target="_blank">
                    <img :src="getMapImage(item)" class="img-responsive" />
                  </a>
                </div>

                <div class="mt-3">
                  <div v-if="item.location.source">
                    <i class="fa fa-info" style="min-width: 16px"></i>
                    <span>
                      {{ item.location.source }}
                    </span>
                  </div>
                  <div v-if="item.location.address" class="d-flex mt-1">
                    <i class="fas fa-at mt-1" style="min-width: 16px"></i>
                    <div class="ml-1" style="max-width: 170px">
                      {{ item.location.address }}
                    </div>
                  </div>
                </div>
              </b-popover>

              <history-log-translation-line
                v-if="translationShown(item)"
                :translation="showTranslatedQueryText(item)"
                :isTranslating="controller.isTranslating"
              ></history-log-translation-line>
            </td>

            <!-- 回答表示領域 -->
            <td class="cell-scroll-x">
              <span v-b-toggle="'collapse-' + item.id" class="pointer-cursor">
                {{ item.fulfillment_text }}

                <b-badge
                  pill
                  variant="primary"
                  v-if="item.all_messages && item.all_messages.length"
                >
                  <i
                    class="fa fa-caret-down when-closed"
                    aria-hidden="true"
                  ></i>
                  <i class="fa fa-caret-up when-opened" aria-hidden="true"></i>
                </b-badge>
              </span>

              <b-collapse :id="'collapse-' + item.id">
                <div
                  v-for="(chatItem, chatItemIdx) in item.all_messages"
                  :key="chatItemIdx"
                  class="chat-items-container"
                >
                  <!-- テキストやカードなどの回答表示領域 -->
                  <div v-if="chatItem.logType === 'text'">
                    <div v-if="chatItem.text" class="mb-1 message-container">
                      <div
                        class="message-bg-grey"
                        style="white-space: pre-line"
                      >
                        <span>{{ chatItem.text }}</span>
                      </div>
                    </div>
                  </div>

                  <div v-else-if="chatItem.logType === 'quick_replies'">
                    <div class="message-bg-grey">
                      <div class="card-header">
                        <div>
                          {{ chatItem.text }}
                        </div>
                      </div>

                      <div class="card-button-container">
                        <div
                          v-for="(option, idx) in chatItem.options"
                          :key="idx"
                          class="ml-2"
                        >
                          <div class="d-flex align-items-center">
                            <div>
                              {{ option }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-else-if="chatItem.logType === 'card'">
                    <div class="message-bg-grey">
                      <div class="card-header">
                        <div>
                          {{ chatItem.title }}
                        </div>

                        <div class="card-subtitle">
                          {{ chatItem.subtitle }}
                        </div>
                      </div>

                      <b-img
                        v-if="chatItem.imageUrl"
                        thumbnail
                        fluid
                        :src="chatItem.imageUrl"
                        alt="Image"
                      />

                      <div class="card-button-container">
                        <div
                          v-for="(button, idx) in chatItem.buttons"
                          :key="idx"
                          class="ml-2"
                        >
                          <div class="d-flex align-items-center">
                            <div>
                              {{ button.text }}
                            </div>
                            <a
                              v-if="button.url"
                              :href="button.url"
                              target="_blank"
                              class="text-dark"
                            >
                              <i class="fas fa-external-link-alt ml-3"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-else-if="chatItem.logType === 'image'">
                    <div class="message-bg-grey">
                      <b-img thumbnail fluid :src="chatItem.url" alt="Image" />
                    </div>
                  </div>

                  <div v-else-if="chatItem.logType === 'audio'">
                    <div class="message-bg-grey">
                      <audio controls style="width: 100%; max-height: 200px">
                        <source :src="chatItem.src" />
                      </audio>
                    </div>
                  </div>

                  <div v-else-if="chatItem.logType === 'video'">
                    <div class="message-bg-grey">
                      <video
                        controls
                        :poster="chatItem.previewSrc"
                        width="100%"
                        height="200"
                      >
                        <source :src="chatItem.src" />
                      </video>
                    </div>
                  </div>
                  <div v-if="chatItem.calledIntentName" class="text-size-sm" v-b-tooltip.hover.bottom="getCallIntentTooltip(chatItem)">
                    <a
                      :href="getIntentPagePath(chatItem.calledIntentName, item.language_code)"
                      target="_blank"
                    >
                      {{ chatItem.calledIntentName }}
                    </a>
                  </div>
                </div>
              </b-collapse>

              <history-log-translation-line
                v-if="translationShown(item)"
                :translation="showTranslatedFulfilmentText(item)"
                :isTranslating="controller.isTranslating"
              ></history-log-translation-line>
            </td>

            <!-- intent名 -->
            <td
              class="cell-scroll-x"
              v-b-popover.hover.bottom="item.display_name"
            >
              <input
                type="checkbox"
                v-model="controller.selectedIntentOptions"
                :value="item.id"
                @change="appendToTemp(item)"
              />
              <a
                :href="getIntentPagePath(item.display_name, item.language_code)"
                target="_blank"
              >
                {{ item.display_name }}
              </a>
            </td>

            <td>{{ item.platform }}</td>

            <!-- session情報列 -->
            <history-log-session
              :query-date="item.insert_date_time | moment('Y-MM-DD')"
              :sessionId="item.session_id"
              :platform="item.platform"
              :userMetaManager="controller.userMetaManager"
              :index="index"
              :logId="item.log_id"
              :client-id="item.client_id"
              :client-id-shown="controller.clientIdFilterEnabled"
            />

            <!-- 感情分析結果列 -->
            <td v-if="controller.sentiment_analysis_status">
              <span
                v-show="item.sentiment.score || item.sentiment.magnitude"
                v-b-popover.hover.bottom="$t('history.sentimentInfo')"
              >
                {{ item.sentiment.score }} <strong>, </strong
                >{{ item.sentiment.magnitude }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="row mt-1">
        <div class="col">
          <pagination
            :params="controller.params"
            :num_page="controller.num_page"
            :callback="loadHistoryData"
          />
        </div>
      </div>
    </div>

    <div v-else class="text-center mb-3">
      <b-spinner
        style="width: 5rem; height: 5rem"
        label="Loading..."
        variant="primary"
      />
    </div>

    <!-- selected log list modal -->
    <b-modal
      size="lg"
      id="modal-scrollable modal-lg"
      ref="modal"
      :title="$t('history.logsList')"
      header-class="bg-info text-light"
      body-class="text-info"
      no-close-on-backdrop
      scrollable
    >
      <div class="container">
        <div class="mb-2">
          <button
            @click="SelectedIntentExport('CSV')"
            class="btn btn-primary mr-1"
            :disabled="controller.selectedIntentOptions.length === 0"
          >
            {{ $t("history.csvBtn") }}
          </button>
          <button
            @click="SelectedIntentExport('Excel')"
            class="btn btn-info"
            :disabled="controller.selectedIntentOptions.length === 0"
          >
            {{ $t("history.excelBtn") }}
          </button>
        </div>

        <ul class="list-group overflow-auto">
          <li
            class="list-group-item"
            v-for="item in selectedLogs"
            :key="item.id"
          >
            <div class="row">
              <div class="col-md-6 wrap-overflow">
                {{ item.query_text }}
              </div>

              <div class="col-md-5 wrap-overflow">
                <a
                  :href="
                    getIntentPagePath(item.display_name, item.language_code)
                  "
                  target="_blank"
                >
                  {{ item.display_name }}
                </a>
              </div>

              <div class="col-md-1">
                <button
                  class="btn btn-xs btn-danger"
                  @click="removeSelectedIntentItem(item.id)"
                >
                  <i class="fas fa-trash-alt"></i>
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div slot="modal-footer" class="w-100">
        <div class="float-right">
          <b-button
            class="mr-1"
            variant="primary"
            :disabled="!controller.selectedListChanged"
            @click="saveCheckedHistories"
          >
            {{ $t("feedback.submit") }}
          </b-button>

          <b-button @click="$refs.modal.hide()">
            {{ $t("history.close") }}
          </b-button>
        </div>
      </div>
    </b-modal>

    <!-- START Export Modal-->
    <b-modal
      id="modal-scrollable modal-lg"
      ref="exportModal"
      :title="$t('buttons.export')"
      header-class="bg-info text-light"
      body-class="text-dark"
      no-close-on-backdrop
    >
      <div class="container">
        <b-form-group
          class="mb-2"
          label-cols="4"
          :label="$t('history.fileType') + ' :'"
        >
          <b-form-radio-group
            size="sm"
            v-model="controller.modalExportForm.fileType"
            :options="fileTypeOptions"
            :state="exportFormFileTypeState"
            button-variant="outline-secondary"
            buttons
          />

          <b-form-invalid-feedback :state="exportFormFileTypeState">
            {{ $t("webhook.required") }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-checkbox
          v-model="controller.modalExportForm.intentDivision"
          switch
        >
          {{ $t("history.intentDivision") }}
        </b-form-checkbox>

        <b-form-checkbox
          v-model="controller.modalExportForm.skipAnswerDetail"
          switch
        >
          {{ $t("history.skipAnswerDetail") }}
          <small class="text-muted"
            >({{ $t("history.skipAnswerDetailHelp") }})</small
          >
        </b-form-checkbox>
      </div>

      <div slot="modal-footer" class="w-100">
        <div class="float-right">
          <b-button
            variant="info"
            @click="handleModalExportForm"
            :disabled="!exportFormFileTypeState"
          >
            <i class="fas fa-download"></i> {{ $t("history.download") }}
          </b-button>
          <b-button @click="$refs.exportModal.hide()">
            {{ $t("history.close") }}
          </b-button>
        </div>
      </div> </b-modal
    ><!-- END Export Modal-->
  </div>
</template>

<script>
import csrfToken from "../util/csrf-token";
import HistoryLogSession from "./HistoryLogSession.vue";
import HistoryLogTranslationLine from "./HistoryLogTranslationLine.vue";
import PlatformDataResource from "../resource/platforms";
import GoogleMap from "../util/google-map";
import copyToClipboard from "../util/copy-url";

export default {
  name: "HistoryLog",
  props: ["controller"],
  components: {
    HistoryLogSession,
    HistoryLogTranslationLine
  },
  data() {
    return {
      // for loading animation
      variants: ["primary", "danger", "success"],
      platform_options: null,
      conversarion_type_options: [
        {
          value: null,
          text: this.controller.i18n.messages.history.chooseConType,
        },
        {
          value: "all",
          text: this.controller.i18n.messages.history.allConversation,
        },
        {
          value: "input.unknown",
          text: this.controller.i18n.messages.history.noConversation,
        },
      ],
      feedback_options: [
        {
          value: null,
          text: this.controller.i18n.messages.history.chooseFeedback,
        },
        {
          value: "FEEDBACK_WORST",
          text: this.controller.i18n.messages.history.feedbackWorst,
        },
        {
          value: "FEEDBACK_BAD",
          text: this.controller.i18n.messages.history.feedbackBad,
        },
        {
          value: "FEEDBACK_NORMAL",
          text: this.controller.i18n.messages.history.feedbackNormal,
        },
        {
          value: "FEEDBACK_GOOD",
          text: this.controller.i18n.messages.history.feedbackGood,
        },
        {
          value: "FEEDBACK_BEST",
          text: this.controller.i18n.messages.history.feedbackBest,
        },
        {
          value: "FEEDBACK_FREETEXT",
          text: this.controller.i18n.messages.history.feedbackFreetext,
        },
      ],
      fileTypeOptions: [
        { text: "CSV", value: "CSV" },
        { text: "Excel", value: "Excel" },
      ],
    };
  },
  created() {
    const platformResource = new PlatformDataResource();
    this.platform_options = platformResource.HistoryOptions.map((option) => ({
      value: option.value,
      text: this.$t(option.translation),
    }));
  },
  computed: {
    selectedLogs() {
      return this.controller.selectedLogs;
    },
    isImage() {
      return (path) => {
        const [extension, ..._] = path.split(".").reverse();
        return extension === "jpg" || extension === "jpeg";
      };
    },
    isVideo() {
      return (path) => {
        const [extension, ..._] = path.split(".").reverse();
        return extension === "mp4";
      };
    },
    isfile() {
      return (path) => {
        const [extension, ..._] = path.split(".").reverse();
        return (
          extension !== "mp4" && extension !== "jpeg" && extension !== "jpg"
        );
      };
    },
    exportFormFileTypeState() {
      return !!this.controller.modalExportForm.fileType;
    },
    translationShown() {
      return (logItem) => {
        return !this.controller.debug && (  // デバッグモードでない場合、かつ
          this.controller.splitTranslationEnabled ||  // 分割翻訳が適用されている場合、または
          this.controller.ui_language !== logItem.language_code  // UI言語とログの言語が異なる場合
        );
      }
    }
  },
  methods: {
    openFile(url) {
      window.open(url);
    },
    showTranslatedQueryText(item) {
      const matchedResult = this.controller.queryTextTranslationResults.find(
        (translationResult) => translationResult.id === item.id
      );
      if (matchedResult && !matchedResult.is_same_language && matchedResult.text !== item.query_text) {
        return matchedResult.text;
      }
      return "";
    },
    showTranslatedFulfilmentText(item) {
      const matchedResult = this.controller.responseTranslationResults.find(
        (translationResult) => translationResult.id === item.id
      );
      // 翻訳結果がログの回答文と異なる場合のみ翻訳結果を表示
      if (matchedResult && !matchedResult.is_same_language && matchedResult.text !== item.fulfillment_text) {
        return matchedResult.text;
      }
      return "";
    },
    search() {
      this.controller.params.page = 1;
      this.controller.loadHistoryLog();
    },
    loadHistoryData: function () {
      this.controller.loadHistoryLog();
    },
    logExport(name) {
      this.controller.downloadLog(name);
    },
    SelectedIntentExport(name) {
      this.controller.selectedIntentDownloadLog(
        name,
        this.controller.selectedIntentOptions
      );
    },
    getIntentPagePath(intentName, language_code) {
      return `/agent/${language_code}/intent?intent_name=${intentName}`;
    },
    openSelectedIntentModal() {
      this.$refs.modal.show();
    },
    removeSelectedIntentItem(item) {
      let index = this.controller.selectedIntentOptions.indexOf(item);
      this.controller.selectedIntentOptions.splice(index, 1);
    },
    getRichMessage(message) {
      return JSON.stringify(JSON.parse(message), null, 2);
    },
    appendToTemp(log) {
      this.controller.appendToTemp(log);
    },
    saveCheckedHistories() {
      this.controller.saveAllCheckedHistories(
        csrfToken.getCsrfTokenFromCookie(document.cookie)
      );
      this.$refs.modal.hide();
    },
    getMapImage(item) {
      const location = item.location;
      const gmap = new GoogleMap(location.latitude, location.longitude);
      return gmap.image();
    },
    getMapURL(item) {
      const location = item.location;
      const gmap = new GoogleMap(location.latitude, location.longitude);
      return gmap.url();
    },
    truncateNumber(number) {
      return parseFloat(number).toFixed(4);
    },
    copyLocationInfo(item) {
      const location = item.location;
      const locPos = `${location.latitude}, ${location.longitude}`;
      copyToClipboard(locPos);
      alert(this.$t("fileManagement.copiedURL"));
    },
    openExportModal() {
      this.controller.modalExportForm = [];
      this.$refs.exportModal.show();
    },
    handleModalExportForm() {
      const fileType = this.controller.modalExportForm.fileType;
      if (fileType) {
        this.controller.downloadLog(fileType);
        this.$refs.exportModal.hide();
      }
    },
    getCallIntentTooltip(chatItem) {
      return this.$t("history.AnswerFromOtherCalledIntent") + `: ${chatItem.calledIntentName}`
    },
  },
};
</script>

<style scoped>
.wrap-overflow {
  overflow-wrap: break-word;
}
.text-size-sm {
  font-size: x-small;
}
.collapsed > .badge > .when-opened,
:not(.collapsed) > .badge > .when-closed {
  display: none;
}

.message-bg-grey {
  background-color: #ccc;
  max-width: 100%;
  padding: 0.2rem;
  border-radius: 8px;
}

.chat-items-container {
  padding: 10px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
</style>
