<template>
  <div>
    <input type="range" min="0" max="360" v-model="hue" class="mb-3 intent-label-color-slider">
    <div class="d-flex align-items-center" :style="{ height: '24px' }">
      <div
        v-for="(color, colorIdx) in colors"
        role="button"
        @click="selectColor(colorIdx)"
        :style="{
          background: `hsl(${color.h}, ${color.s}%, ${color.l}%)`,
          width: '100px',
          height: colorIdx === selectedColor ? '18px' : '12px',
          margin: colorIdx < colors.length-1 && '0 1px 0 0',
          borderRadius: (
            colorIdx === selectedColor ? '3px'
            : colorIdx === 0 ? '3px 0 0 3px'
            : colorIdx == colors.length-1 ? '0 3px 3px 0'
            : '0'
          ),
        }"
      ></div>
    </div>
  </div>
</template>

<script>
const SATURATION = 50;
const LIGHTNESS = [80, 65, 50, 35, 20];

export default {
  props: ["labelColor"],
  data() {
    return {
      selectedColor: null,
      hue: "0",
    }
  },
  mounted() {
    if (this.labelColor !== "#FFFFFF") {
      const hsl = this.colorCodeToHsl(this.labelColor);
      this.hue = String(hsl.h);
      this.selectedColor = LIGHTNESS.indexOf(hsl.l);
    }
  },
  computed: {
    colors() {
      return LIGHTNESS.map((l) => {
        return {
          h: this.hue,
          s: SATURATION,
          l: l,
        }
      })
    }
  },
  methods: {
    selectColor(colorIdx=null) {
      // カラーを選択したときの処理
      if (typeof colorIdx === "number") {
        this.selectedColor = colorIdx;
      }
      this.$emit("on-select-color", {
        color: this.hslToColorCode(this.colors[this.selectedColor]),
      });
    },
    hslToColorCode({h, s, l}) {
      // HSLをカラーコードに変換
      let red, green, blue;
      const hue = Number(h)        / 360;
      const saturation = Number(s) / 100;
      const lightness  = Number(l)  / 100;
      if (saturation === 0) {
        red   = lightness;
        green = lightness;
        blue  = lightness;
      } else {
        let q, p;
        if (lightness < 0.5) {
          q = lightness * (1 + saturation);
        } else {
          q = lightness + saturation - lightness * saturation;
        }
        p = 2 * lightness - q;

        red   = this.hueToRgb(p, q, hue + 1 / 3);
        green = this.hueToRgb(p, q, hue);
        blue  = this.hueToRgb(p, q, hue - 1 / 3);
      }
      return `#${Math.round(red * 255).toString(16)}${Math.round(green * 255).toString(16)}${Math.round(blue * 255).toString(16)}`.toUpperCase();
    },
    hueToRgb(p, q, t) {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) {
        p += (q - p) * 6 * t;
      } else if (t < 1 / 2) {
        p = q;
      } else if (t < 2 / 3) {
        p += (q - p) * (2 / 3 - t) * 6;
      }
      return p;
    },
    colorCodeToHsl(colorCode) {
      // カラーコードをHSLに変換
      const red = parseInt(colorCode.substr(1, 2), 16) / 255;
      const green = parseInt(colorCode.substr(3, 2), 16) / 255;
      const blue = parseInt(colorCode.substr(5, 2), 16) / 255;
      const max = Math.max(red, green, blue);
      const min = Math.min(red, green, blue);
      const lightness = (max + min) / 2;
      let saturation;
      const diff = max - min;
      if (lightness > 0.5) {
        saturation = diff / (2 - max - min);
      } else {
        saturation = diff / (max + min);
      }
      let hue;
      if (max === red) {
        hue = (green - blue) / diff;
      } else if (max === green) {
        hue = 2 + (blue - red) / diff;
      } else {
        hue = 4 + (red - green) / diff;
      }
      return  {
        h: Math.round((hue / 6) * 360),
        s: Math.round(saturation * 100),
        l: Math.round(lightness  * 100),
      };
    }
  },
  watch: {
    hue: function () {
      // カラー選択中にスライダーを動かしたときの処理
      if (typeof this.selectedColor === "number") {
        this.selectColor();
      }
    },
  },
}
</script>
