import { I18n } from "../util/i18n";
import csrfToken from "../util/csrf-token";
import axios from "axios";
import { operatorStatus } from "../util/operatorStatus";
import { RestApi } from "../util/rest-api";
import { AgentState, AgentOperatorSessionConfig, AgentHolidays } from "../model/agent";
import {
  ChatItem,
  OperatorHandlingSession,
  Operator,
  OperatorHandlingSessionBookmark,
  OperatorHandlingSessionEventLog,
} from "../model/history";
import { UserMetaManagementController } from "./user-meta-management";
import { SessionUserMeta } from "../model/user-meta";
import { ResponseType } from "../resource/responses";

/**
 * セッション選択時にメッセージを読み込む際の対象の指定用の定数
 */
const SessionChatLogsByDateTarget = {
  LATEST: "1",
  PREVIOUS: "2",
};

class OperatorSessionController {
  constructor(apiUrl, i18nContext, requestContext, userAgent) {
    /** すべてのデータが読み終わってから画面をコンポネントを描画し始めるためのフラグ */
    this.isReady = false;
    this.apiUrl = apiUrl;
    this.i18n = new I18n(i18nContext);
    this.userAgent = userAgent;

    /* 利用可能言語 */
    this.supportedLanguages = requestContext.supportedLanguages;

    /* 翻訳チャットのみUI */
    this.showOnlyTranslationChat = requestContext.showOnlyTranslationChat;
    /* オペレーター利用可能状態制御を許可 */
    this.allowOperatorAvailableControl = requestContext.allowOperatorAvailableControl;

    /**
     * ページネーションで取得したオペレーター対応セッションの配列
     * @type {OperatorHandlingSession[]}
     */
    this.operatorHandlingSessions = [];
    /** セッション一覧のページネーション番号 */
    this.sessionListPageIdx = 1;
    this.filteredSessions = [];
    /**
     * 選択中のセッションの会話ログの配列
     * @type {ChatItem[]}
     */
    this.sessionChatLogs = [];
    // 選択中のセッションのクライアントID
    this.sessionClientID = null;
    // フィルターに該当するセッションの総数
    this.totalSessionCount = null;
    /**
     * 選択中のオペレーター対応セッション
     * @type {OperatorHandlingSession | null}
     */
    this.selectedOperatorHandlingSession = null;
    this.sessionChatLogByDateApi = new RestApi(
      this.apiUrl.SessionChatLogsByDate,
      ChatItem
    );
    this.SessionUserMetaAPI = new RestApi(
      apiUrl.SessionUserMeta,
      SessionUserMeta
    );
    this.userMetaManager = requestContext.allowUserMeta
      ? new UserMetaManagementController(apiUrl)
      : null;
    this.uiLanguageCode = requestContext.uiLanguageCode || "en";

    this.timeInterval = null;
    this.selectedPlatform = null;
    // 担当者一覧
    this.operators = [];
    this.sortCondition = null;
    this.filterCondition = {
      /** query parameterでオペレーター対応セッションIDしている場合がある */
      operatorHandlingSessionId: new URLSearchParams(
        window.location.search
      ).get("ohsid"),
    };

    /** セッションのカウント詳細 */
    this.sessionCount = {
      active: 0,
      inactive: 0,
      calling: 0,
    };

    this.operatorListAPI = new RestApi(this.apiUrl.operatorList, Operator);
    this.operatorHandlingSessionBookmarkListAPI = new RestApi(
      this.apiUrl.operatorHandlingSessionBookmarkList,
      OperatorHandlingSessionBookmark
    );
    /**
     * ブックマークの配列
     * @type {OperatorHandlingSessionBookmark[]}
     */
    this.bookmarks = [];
    this.selectedSessionOperator = {};
    /** 選択中のオペレーター対応セッションの履歴の配列 */
    this.selectedOperatorHandlingSessionEventLogs = [];
    /** 翻訳対象と結果のオブジェクト */
    this.translationLogs = {};
    /** Sessionに保存した検索条件 */
    this.selectedOperatorHandlingStatusList = null;
    this.selectedOperators = null;
    this.selectedPlatforms = null;
    this.bookmarkConditions = null;
    this.sortConditionSaved = null;
    this.operatorHandlingSessionIdSaved = null;
    // ソート、フィルターが適用されているか
    this.sortAndFiltering = false;
    this.isResetOperatorSessionFilter = false;
    /** 現在のオペレーター */
    this.nowUser = requestContext.nowUser;
    /** 翻訳チャットPubSubタイムスタンプ */
    this.translationChatPusSubTimeStamp = null;

    /** 利用者への通知ができるかどうか */
    this.allowUserNotification = requestContext.allowUserNotification;

    /* エージェント状態関連 */
    this.agentState = null;

    /* エージェントオペレーターモード設定 */
    this.agentOperatorSessionConfig = null;

    /* エージェント休日 */
    this.agentHolidays = null;

    /* OperatorSession.vue コンポーネントインスタンス */
    this.operatorSessionComp = null;
  }

  async ready() {
    await this.loadOperators();
    await this.loadOperatorSession();
    await this.loadBookmarks();
    await this.loadAgentState();
    await this.loadAgentOperatorSessionConfig();
    await this.loadAgentHolidays();
    if (this.operatorHandlingSessions.length >= 1 && !this.userAgent?.match(/iPhone|Android.+Mobile/)) {
      // 初回表示時は一番上のセッションを選択状態にする。
      await this.onSelectSession({
        operatorHandlingSessionId: this.operatorHandlingSessions[0].id,
      });
    }
    if (this.userMetaManager) {
      await this.userMetaManager.loadAllUserMetaMap();
    }
    this.isReady = true;
  }

  /**
   * メッセージの翻訳結果一覧を取得。
   */
  async loadTranslations() {
    let postData = {
      data: this.sessionChatLogs,
      platform: this.selectedOperatorHandlingSession.platform,
    };
    const response = await axios.post(
      `${this.apiUrl.Translation}session/`,
      postData
    );
    // すでに取得済みの翻訳結果を消さないようにマージする。
    this.translationLogs = { ...this.translationLogs, ...response.data };
  }

  async translateText(logs, toLanguage) {
    const response = await axios.post(this.apiUrl.Translation, {
      logs,
      to_language: toLanguage,
    });
    return response.data;
  }

  /**
   * csrfトークンを取得し返却
   */
  get csrfToken() {
    return csrfToken.getCsrfTokenFromCookie(document.cookie);
  }

  /**
   * オペレーター対応セッションの読み込みAPIの呼び出し。
   * @returns
   */
  async callLoadOperatorSessionAPI() {
    const options = {
      headers: {
        "X-CSRFToken": this.csrfToken,
      },
    };
    const data = {
      page: this.sessionListPageIdx,
      platform: this.selectedPlatform,
      sort_condition: this.sortCondition,
      filter_condition: this.filterCondition,
      is_reset_operator_session_filter: this.isResetOperatorSessionFilter,
    };
    return await axios.post(this.apiUrl.operatorSessionList, data, options);
  }

  /**
   * 指定したオペレーター対応セッションを取得。
   * @returns
   */
  async fetchOperatorSessionById(OperatorHandlingSessionId) {
    const options = {
      headers: {
        "X-CSRFToken": this.csrfToken,
      },
    };
    const data = {
      filter_condition: {
        operatorHandlingSessionId: OperatorHandlingSessionId,
      },
      is_save_filter_condition: false,
    };
    return await axios.post(this.apiUrl.operatorSessionList, data, options);
  }

  /**
   * オペレーター対応セッションの検索条件をリセット。
   * @returns
   */
  async resetOperatorSessionFilter() {
    this.sessionListPageIdx = 1;
    this.selectedOperatorHandlingStatusList = null;
    this.selectedOperators = null;
    this.selectedPlatforms = null;
    this.bookmarkConditions = null;
    this.sortConditionSaved = null;
    this.operatorHandlingSessionIdSaved = null;
    this.isResetOperatorSessionFilter = true;
    this.sortCondition = null;
    this.filterCondition = {};
    await this.loadOperatorSession();
    this.sortAndFiltering = false;
    this.isResetOperatorSessionFilter = false;
    if (!this.filterCondition.operatorHandlingSessionId) {
      const url = new URL(window.location.href);
      url.searchParams.delete("ohsid");
      window.history.pushState({}, '', url);
    }
  }

  /**
   * オペレーター対応セッションのイベント読み込みAPIを呼び出し。
   */
  async callOperatorHandlingSessionEventAPI() {
    const options = {
      headers: {
        "X-CSRFToken": this.csrfToken,
      },
    };
    return await axios.get(
      `${this.apiUrl.operatorHandlingSessionEventList}?operator_handling_session_id=${this.selectedOperatorHandlingSession.id}`,
      options
    );
  }

  /**
   * オペレーター対応セッションの読み込み
   * @param {object} params
   * @param {number} params.pageIdx ページ番号
   * @param {string} params.sortCondition ソート条件の文字列
   * @param {object} params.filterCondition フィルター条件のオブジェクト
   */
  async loadOperatorSession(
    { pageIdx, sortCondition, filterCondition } = { pageIdx: 1 }
  ) {
    // 現在のセッション一覧のページ番号を更新（表示用はコンポネント側にある）
    this.sessionListPageIdx = pageIdx;
    // ソート、フィルター条件は渡された場合のみ更新する。
    if (sortCondition) {
      this.sortCondition = sortCondition;
    }
    if (filterCondition) {
      this.filterCondition = filterCondition;
    }

    const response = await this.callLoadOperatorSessionAPI();

    this.operatorHandlingSessions = response.data.data.map((d) =>
      OperatorHandlingSession.fromData(d)
    );

    // ユーザーメタの取得より後に作成されたセッションがあれば、もう一度ユーザーメタを取得する。
    if (this.userMetaManager) {
      let newestCreated = null;
      this.operatorHandlingSessions.forEach((session) => {
        const created = new Date(session.createdAt);
        if (!newestCreated || newestCreated < created) {
          newestCreated = created;
        }
      });
      if (!this.userMetaManager.latestLoaded || this.userMetaManager.latestLoaded < newestCreated) {
        await this.userMetaManager.loadAllUserMetaMap();
      }
    }
    this.totalSessionCount = response.data.count;
    this.filteredSessions = this.operatorHandlingSessions;
    this.sessionCount.active = response.data.active_session_count;
    this.sessionCount.inactive = response.data.inactive_session_count;
    this.sessionCount.calling = response.data.calling_session_count;
    if (response.data.filter_condition !== null) {
      this.selectedOperatorHandlingStatusList =
        response.data.filter_condition.selectedOperatorHandlingStatusList;
      this.selectedOperators = response.data.filter_condition.selectedOperators;
      this.selectedPlatforms = response.data.filter_condition.selectedPlatforms;
      this.bookmarkConditions =
        response.data.filter_condition.bookmarkConditions;
      this.operatorHandlingSessionIdSaved =
        response.data.filter_condition.operatorHandlingSessionId;
    }
    if (response.data.sort_condition !== null) {
      this.sortConditionSaved = response.data.sort_condition;
    }
    if (response.data.sort_and_filtering !== null) {
      this.sortAndFiltering = response.data.sort_and_filtering;
    }
  }

  /**
   * オペレーター対応セッションの条件フィルター処理
   * @param {object} params
   * @param {number} params.pageIdx ページ番号
   * @param {string} params.sortCondition ソート条件の文字列
   * @param {object} params.filterCondition フィルター条件のオブジェクト
   * @param {boolean} params.isResetFilter 条件リセットのフラグ
   */
  async updateOperatorSessionFilter(
    { pageIdx=1, sortCondition, filterCondition, isResetFilter }
  ) {
    if (isResetFilter) {
      await this.resetOperatorSessionFilter();
      return;
    }
    await this.loadOperatorSession({ pageIdx, sortCondition, filterCondition });
  }

  /**
   * 選択中のセッションを更新する。
   */
  async updateSelectedSession() {
    const response = await this.fetchOperatorSessionById(
      this.selectedOperatorHandlingSession.id
    );
    const nowSelectOperatorHandlingSessionList = response.data.data.map((d) =>
      OperatorHandlingSession.fromData(d)
    );
    if (nowSelectOperatorHandlingSessionList.length > 0) {
      this.selectedOperatorHandlingSession =
        nowSelectOperatorHandlingSessionList[0];
    }
    const sessionChatLogs = await this.sessionChatLogByDateApi.list({
      params: {
        session_id: this.selectedOperatorHandlingSession.sessionId,
        target: SessionChatLogsByDateTarget.LATEST,
      },
    });
    // 選択中のセッションのChatLogの配列を格納
    this.setSessionChatLogs(sessionChatLogs.map((i) => {
      i.fulfillmentMessages = i.allMessages;
      return i;
    }));

    if (this.selectedOperatorHandlingSession.operatorUserId) {
      this.selectedSessionOperator = this.operators.find(
        (o) => o.id === this.selectedOperatorHandlingSession.operatorUserId
      );
    } else {
      this.selectedSessionOperator = {};
    }
    // 選択中のオペレーター対応セッションのイベントを取得
    const operatorHandlingSessionEventResponse =
      await this.callOperatorHandlingSessionEventAPI();
    this.selectedOperatorHandlingSessionEventLogs =
      operatorHandlingSessionEventResponse.data.map((i) =>
        OperatorHandlingSessionEventLog.fromData(i)
      );
    // 翻訳を取得
    await this.loadTranslations();
  }

  /**
   * セッション選択時の処理を行う。
   * 選択されたセッションのメッセージを読み込む。
   * @param {object} params
   * @param {string} params.operatorHandlingSessionId オペレーター対応セッションのID
   */
  async onSelectSession({ operatorHandlingSessionId }) {
    this.selectedOperatorHandlingSession = this.operatorHandlingSessions.find(
      (s) => s.id === operatorHandlingSessionId
    );

    const sessionChatLogs = await this.sessionChatLogByDateApi.list({
      params: {
        session_id: this.selectedOperatorHandlingSession.sessionId,
        target: SessionChatLogsByDateTarget.LATEST,
      },
    });

    // 選択中のセッションのChatLogの配列を格納
    this.setSessionChatLogs(sessionChatLogs);

    //
    if (this.selectedOperatorHandlingSession.operatorUserId) {
      this.selectedSessionOperator = this.operators.find(
        (o) => o.id === this.selectedOperatorHandlingSession.operatorUserId
      );
    } else {
      this.selectedSessionOperator = {};
    }

    // 選択中のオペレーター対応セッションのイベントを取得
    const operatorHandlingSessionEventResponse =
      await this.callOperatorHandlingSessionEventAPI();
    this.selectedOperatorHandlingSessionEventLogs =
      operatorHandlingSessionEventResponse.data.map((i) =>
        OperatorHandlingSessionEventLog.fromData(i)
      );

    // 翻訳を取得
    await this.loadTranslations();
  }

  /**
   * 選択中のセッションのユーザーメタオブジェクトを返す
   */
  get selectedSesssionUserMeta() {
    if (!this.userMetaManager || !this.selectedOperatorHandlingSession) {
      return null;
    }
    const sessionId = this.selectedOperatorHandlingSession.sessionId;
    const platform = this.selectedOperatorHandlingSession.platform;
    return this.userMetaManager.getMatchedUserMeta(sessionId, platform);
  }

  /**
   * Push file message to user according to selected session ID
   * @param responseMessage
   * @param csrfToken
   * @returns [Promise<T>]
   */
  async sendFileMessageToUser(responseMessage) {
    let formData = new FormData();
    formData.append("file", responseMessage.data.file);
    // 送信用responseMessageをコピー
    let payload = JSON.parse(JSON.stringify(responseMessage));
    payload.data.file = null;
    formData.append("payload", JSON.stringify(payload));
    return axios.post(this.apiUrl.pushMessageUrl, formData, this.sendOptions);
  }

  /**
   * Send Push message to user according to selected session ID
   * @param responseMessage
   * @returns [Promise<T>]
   */
  async sendPushMessageToUser(responseMessage) {
    if (
      responseMessage["isToBeOperator"] &&
      this.selectedOperatorHandlingSession.operatorUserId !==
        Number(this.nowUser)
    ) {
      await this.updateOperatorSetting({ operatorUserId: this.nowUser });
    }
    if (
      responseMessage["isSetOperatorStatusActive"] &&
      this.selectedOperatorHandlingSession.status === operatorStatus.CALLING
    ) {
      await this.updateOperatorHandlingSessionStatusToActive();
    }
    responseMessage["client_id"] = this.sessionClientID;
    responseMessage["ohsid"] = this.selectedOperatorHandlingSession.id;
    if (responseMessage.type === ResponseType.SEND_FILE) {
      await this.sendFileMessageToUser(responseMessage);
    } else {
      // API呼び出しのみ
      await axios.post(
        this.apiUrl.pushMessageUrl,
        responseMessage,
        this.sendOptions
      );
    }
  }

  /**
   * オペレーター対応セッションのノートを保存する。
   * @param {object} data emitで渡ってくるデータ
   * @param {string} data.note 保存するノート
   */
  async saveNote(data) {
    // 保存API呼び出し
    const updatedData = await this.callOperatorSessionNoteUpdateAPI(
      data
    );
    // 既存のノートを更新
    this.updateExistOperatorHandlingSession(updatedData, "note");
  }

  /**
   * オペレーター対応セッションのノートの更新API呼び出し
   * @param {object} data emitで渡ってくるデータ
   * @param {string} data.note 保存するノート
   * @returns { OperatorHandlingSession }
   */
  async callOperatorSessionNoteUpdateAPI(data) {
    // TODO: 呼び出し元でエラー処理をする
    try {
      const response = await axios.post(
        this.apiUrl.operatorSessionNoteUpdate,
        {
          operator_handling_session_id: this.selectedOperatorHandlingSession.id,
          note: data.note,
        },
        this.sendOptions
      );
      return OperatorHandlingSession.fromData(
        response.data.operator_handling_session
      );
    } catch (e) {
      console.error(e);
    }
  }

  get sendOptions() {
    let sendOptions = {};
    let options = {};
    Object.assign(sendOptions, options, {
      headers: { "X-CSRFToken": this.csrfToken },
    });
    return sendOptions;
  }

  /**
   * テナントのオペレータ権限のあるユーザーを取得
   */
  async loadOperators() {
    this.operators = await this.operatorListAPI.list();
  }

  /**
   * テナントのオペレータ権限のあるユーザーを取得
   */
  async loadBookmarks() {
    this.bookmarks = await this.operatorHandlingSessionBookmarkListAPI.list();
  }

  /**
   * 担当オペレーター設定を更新
   * @param {string} operatorUserId
   */
  async callOperatorSettingUpdateAPI(operatorUserId) {
    // TODO: 呼び出し元でエラー処理をする
    try {
      const response = await axios.post(
        this.apiUrl.operatorSessionOperatorSettingUpdate,
        {
          operator_handling_session_id: this.selectedOperatorHandlingSession.id,
          operator_user_id: operatorUserId,
        },
        this.sendOptions
      );
      return OperatorHandlingSession.fromData(
        response.data.operator_handling_session
      );
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * 担当オペレーターを更新
   * @param {object} params
   * @param {number} params.operatUserId
   */
  async updateOperatorSetting({ operatorUserId }) {
    // API呼び出し
    const updatedData = await this.callOperatorSettingUpdateAPI(operatorUserId);
    // 既存のオペレーター対応セッションの更新
    this.updateExistOperatorHandlingSession(updatedData, "operatorUserId");
  }

  /**
   * セッションステータスを更新
   * @param {object} updateData
   * @param {number} updateData.session_id
   * @param {string} updateData.platform
   * @param {number} updateData.status
   */
  async callOperatorSessionStatusUpdateAPI(updateData) {
    // TODO: 呼び出し元でエラー処理をする
    try {
      const response = await axios.post(
        this.apiUrl.operatorSessionUpdate,
        updateData,
        this.sendOptions
      );
      return OperatorHandlingSession.fromData(response.data);
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * 選択中のセッションのステータスを現在のステータスに応じて更新
   * 呼び出し中は対応中に、対応中は完了にする。
   */
  async updateOperatorHandlingSessionStatus() {
    let toStatus;
    switch (this.selectedOperatorHandlingSession.status) {
      case operatorStatus.ACTIVE:
        // 対応中なら完了にする。
        toStatus = operatorStatus.INACTIVE;
        break;
      case operatorStatus.CALLING:
        // 呼び出し中なら対応中にする。
        toStatus = operatorStatus.ACTIVE;
        break;
    }

    const updatedData = await this.callOperatorSessionStatusUpdateAPI({
      session_id: this.selectedOperatorHandlingSession.sessionId,
      platform: this.selectedOperatorHandlingSession.platform,
      status: toStatus,
    });
    // 既存のオペレーター対応セッションの更新
    this.updateExistOperatorHandlingSession(updatedData, "status");
  }

  /**
   * メッセージ送信後（Push Message）状態を対応中に変更
   */
  async updateOperatorHandlingSessionStatusToActive() {
    const updatedData = await this.callOperatorSessionStatusUpdateAPI({
      session_id: this.selectedOperatorHandlingSession.sessionId,
      platform: this.selectedOperatorHandlingSession.platform,
      status: operatorStatus.ACTIVE,
    });
    // 既存のオペレーター対応セッションの更新
    this.updateExistOperatorHandlingSession(updatedData, "status");
  }

  /**
   * 既存のオペレーター対応セッションの更新処理
   * オペレーター対応セッションを更新後に実行することで画面の表示が変わる
   * @param {OperatorHandlingSession} updatedData
   * @param {string|null} updatedField
  */
  updateExistOperatorHandlingSession (updatedData, updatedField=null) {
    if (!updatedData) return;
    if (!updatedField) {
      this.operatorHandlingSessions = this.operatorHandlingSessions.map(
        (session) => {
          if (session.id === updatedData.id) {
            this.selectedOperatorHandlingSession = updatedData;
            session = updatedData;
            return session;
          }
          return session;
        }
      );
      return;
    }
    this.operatorHandlingSessions = this.operatorHandlingSessions.map(
      (session) => {
        if (session.id === updatedData.id) {
          const updatedFieldValue = updatedData[updatedField];
          this.selectedOperatorHandlingSession[updatedField] = updatedFieldValue;
          session[updatedField] = updatedFieldValue;
          return session;
        }
        return session;
      }
    );
  }

  /**
   * 選択中のセッションのブックマーク状態を切り替える。
   */
  async toggleBookmark() {
    const data = {
      operator_handling_session_id: this.selectedOperatorHandlingSession.id,
    };

    const response = await axios.post(
      this.apiUrl.operatorSessionToggleBookmark,
      data,
      this.sendOptions
    );

    // 既存のオペレーター対応セッションの更新
    if (response.data.operator_session_bookmark) {
      // ブックマークがONになった場合、既存のブックマークの配列に追加
      this.bookmarks.push(
        OperatorHandlingSessionBookmark.fromData(
          response.data.operator_session_bookmark
        )
      );
    } else {
      // ブックマークがオフになった場合、既存のブックマークの配列から削除
      this.bookmarks = this.bookmarks.filter(
        (b) =>
          b.operatorHandlingSessionId !==
          this.selectedOperatorHandlingSession.id
      );
    }
  }

  /**
   * 選択中のセッションの前の日付のメッセージを読み込む。
   */
  async loadOlderMessage() {
    const currentDate = new Date(this.sessionChatLogs[0].insertDateTime);
    // API呼び出し
    const sessionChatLogs = await this.sessionChatLogByDateApi.list({
      params: {
        session_id: this.selectedOperatorHandlingSession.sessionId,
        // 現在表示中のログよりも前の日付のログを表示。
        target: SessionChatLogsByDateTarget.PREVIOUS,
        // 現在表示中のログの最も古い日付を基準として渡す。
        year: currentDate.getFullYear(),
        month: currentDate.getMonth() + 1,
        date: currentDate.getDate(),
      },
    });

    // 選択中のセッションのChatLogの配列を格納
    // 古いメッセージなので配列の先頭にいれる必要がある。
    this.setSessionChatLogs(sessionChatLogs.concat(this.sessionChatLogs));

    // 翻訳を取得
    await this.loadTranslations();
  }

  /**
   * Get list of files through pagination
   *
   * @param params
   * @returns {Promise<unknown>}
   */
  getFileContents(params) {
    return new Promise((resolve, reject) => {
      axios.get(this.apiUrl.ListFileContents,{
        params: {
          page: params.page
        }
      }).then(function(response) {
        // handle success
        resolve(response.data)
      }).catch(function(error) {
        // handle error
        reject(error)
        console.log(error)
      })
    })
  }

  /**
   * オペレーター対応セッションのイベント読み込みAPIを呼び出し。
   */
  async getPubsubServiceInfo() {
    const options = {
      headers: {
        "X-CSRFToken": this.csrfToken,
      },
    };
    return await axios.get(this.apiUrl.pubsubServiceInfo, options);
  }

  /**
   * 履歴データをセットし、履歴からclient_idを特定する
   */
  setSessionChatLogs(chatLogs) {
    this.sessionChatLogs = chatLogs;
    if (Array.isArray(this.sessionChatLogs)) {
      const reversed = Array.from(this.sessionChatLogs).reverse();
      for (const log of reversed) {
        if (log.clientId && log.clientId !== "") {
          this.sessionClientID = log.clientId;
          break;
        }
      }
    }
  }

  /**
   * セッションの選択をキャンセル（SPのセッションを閉じる時の処理）
   */
  cancelSelectedOperatorSession() {
    this.selectedOperatorHandlingSession = null;
    this.sessionChatLogs = [];
    this.sessionClientID = null;
    this.selectedSessionOperator = {};
    this.selectedOperatorHandlingSessionEventLogs = [];
    this.translationLogs = {};
  }

  async loadAgentState() {
    if (!this.allowOperatorAvailableControl) { return; }

    const response = await axios.get(this.apiUrl.agentState);
    this.agentState = AgentState.fromData(response.data);
  }

  async updateOperatorBusyLevel(operatorBusyLevel) {
    this.showProgress(this.i18n.t("general.saving"));
    await axios.patch(this.apiUrl.agentState, {
      operator_busy_level: operatorBusyLevel,
    }, {
      headers: {
        "X-CSRFToken": this.csrfToken,
      },
    });
    this.hideProgress();
  }

  async loadAgentOperatorSessionConfig() {
    if (!this.allowOperatorAvailableControl) { return; }

    const response = await axios.get(this.apiUrl.agentOperatorSessionConfig);
    this.agentOperatorSessionConfig = AgentOperatorSessionConfig.fromData(response.data);
  }

  async updateOperatorAvailableConditions(conditions, force=false) {
    this.showProgress(this.i18n.t("general.saving"));
    await axios.patch(this.apiUrl.agentOperatorSessionConfig, {
      available_conditions: conditions,
      force: force
    }, {
      headers: {
        "X-CSRFToken": this.csrfToken,
      },
    });
    this.hideProgress();
  }

  async updateOperatorWorkdays(workdays) {
    this.showProgress(this.i18n.t("general.saving"));
    await axios.patch(this.apiUrl.agentOperatorSessionConfig, {
      workdays: workdays,
    }, {
      headers: {
        "X-CSRFToken": this.csrfToken,
      },
    });
    this.hideProgress();
  }

  async loadAgentHolidays() {
    if (!this.allowOperatorAvailableControl) { return; }

    const response = await axios.get(this.apiUrl.agentHolidays);
    this.agentHolidays = AgentHolidays.fromData(response.data);
  }

  async updateOperatorHolidays({ data, includeJapaneseHolidays }) {
    this.showProgress(this.i18n.t("general.saving"));
    await axios.patch(this.apiUrl.agentHolidays, {
      data, include_japanese_holidays: includeJapaneseHolidays
    }, {
      headers: {
        "X-CSRFToken": this.csrfToken,
      },
    });
    this.hideProgress();
  }

  async updateOperatorResponseMessages(messages) {
    this.showProgress(this.i18n.t("general.saving"));
    await axios.patch(this.apiUrl.agentOperatorSessionConfig, {
      messages: messages,
    }, {
      headers: {
        "X-CSRFToken": this.csrfToken,
      },
    });
    this.hideProgress();
  }

  async checkAgentOperatorAvailable(conditions, datetimeStr) {
    this.showProgress("...");
    const response = await axios.post(this.apiUrl.agentOperatorAvailableCheck, {
      available_conditions: conditions,
      dt: datetimeStr
    }, {
      headers: {
        "X-CSRFToken": this.csrfToken,
      },
    });
    this.hideProgress();
    return response.data;
  }

  bindOperatorSessionComponent(comp) {
    this.operatorSessionComp = comp;
  }

  showProgress(message) {
    if (this.operatorSessionComp) {
      this.operatorSessionComp.showProgress(message);
    }
  }

  hideProgress() {
    if (this.operatorSessionComp) {
      this.operatorSessionComp.hideProgress();
    }
  }
}

export { OperatorSessionController };
