<template>
  <!-- クイックリプライ形式の回答表示用コンポネント -->
  <div class="mb-1">
    <div class="message-bg-grey">
      <div class="card-header">
        <div>
          {{ chatItem.text }}

          <!-- 翻訳表示領域 -->
          <chat-item-translation-line
            v-if="translationObject"
            :translation="translatedText(chatItem.text, chatItem.languageCode)"
            type="response_card_content"
          ></chat-item-translation-line>
        </div>
      </div>

      <div class="card-button-container">
        <div v-for="(option, idx) in chatItem.options" :key="idx" class="ml-2">
          <div class="d-flex align-items-center">
            <div>
              {{ option }}

              <!-- 翻訳表示領域 -->
              <chat-item-translation-line
                v-if="translationObject"
                :translation="translatedText(option, chatItem.languageCode)"
                type="response_card_content"
              ></chat-item-translation-line>
            </div>
          </div>
        </div>
      </div>
    </div>

    <chat-item-bottom-detail
      :chat-item="chatItem"
      :redirector-access-logs="redirectorAccessLogs"
    />
  </div>
</template>

<script>
export default {
  props: ["chatItem", "redirectorAccessLogs", "translationObject"],
  computed: {
    translatedText() {
      return (text, languageCode) => {
        if (!this.translationObject) {
          return "";
        }
        const translateKey = `${languageCode}__${text}`;
        let translated = this.translationObject[translateKey];
        if (translated && !translated.is_same_language && translated.text !== text) {
          return translated.text;
        }
        return "";
      }
    }
  }
};
</script>
