import { I18n } from "../util/i18n";
import { RestApi } from "../util/rest-api";
import { ChatLog, ChatLogDate, ChatItem } from "../model/history";
import { RedirectorAccessLog } from "../model/redirector";
import copyToClipboard from "../util/copy-url";
import { SessionUserMeta } from "../model/user-meta";
import { UserMetaManagementController } from "./user-meta-management";
import PlatformDataResource from "../resource/platforms";
import axios from "axios";
import getToken from "../util/csrf-token";
import { isEqual, cloneDeep, pickBy } from "lodash";
import { operatorStatus, operatorStatusReason } from "../util/operatorStatus";
import { ResponseType } from "../resource/responses";

export default class ConversationHistoryController {
  constructor(apiUrl, i18nContext, requestContext) {
    this.supported_languages = requestContext.supported_languages;
    this.language_dict = Object.assign(
      {},
      ...this.supported_languages.map((x) => ({ [x.value]: x.code }))
    );
    const platformResource = new PlatformDataResource();
    this.allPlatforms = requestContext.all_platforms;
    this.platforms_icon = pickBy(
      platformResource.PlatformIcons,
      function (platform) {
        return (
          requestContext.all_platforms_dict[platform.name] === platform.name
        );
      }
    );
    this.apiUrl = apiUrl;
    this.userType = requestContext.userType;
    this.chatLogApi = new RestApi(this.apiUrl.ChatLog, ChatLog);
    this.chatLogJSONApi = new RestApi(this.apiUrl.ChatLogJSON, ChatItem);
    this.chatLogDateApi = new RestApi(this.apiUrl.ChatLogDate, ChatLogDate);
    this.SessionUserMetaAPI = new RestApi(
      apiUrl.SessionUserMeta,
      SessionUserMeta
    );
    this.RedirectorAccessLogAPI = new RestApi(
      apiUrl.allRedirectUrls + "access-log/",
      RedirectorAccessLog
    );
    // 今日の日付をデフォルトで選択しておく
    this.selectedDate = new Date();
    /** TODO comment */
    this.deepCloneSelectedDateUp = new Date();
    this.deepCloneSelectedDateDown = new Date();
    this.i18n = new I18n(i18nContext);
    this.selectedSessionInfo = null;
    this.selectedDateLogs = [];
    this.logs = [];
    /** セッション一覧 */
    this.originalSessionInfo = [];
    // TODO comment
    this.filteredSessionInfo = [];
    this.selectedPlatforms = JSON.parse(JSON.stringify(this.allPlatforms));
    this.selectedSortElement = "endTime";
    this.selectedSortOrder = "desc";
    this.isWelcomeOnlyConversationVisible = false;
    this.allDate = [];
    // query paramから取得するsession_id
    this.sessionId = requestContext.sessionId;
    this.sessionURL = requestContext.url + "?session_id=";
    this.insertDate = requestContext.insertDate;
    this.selectedLanguage = null;
    this.year = null;
    this.month = null;
    this.day = null;
    // TODO 変数名をわかりやすくする
    // セッション一覧のロードが終わったらfalseになる。ローディングアニメーション用表示判定。
    this.sessionListTrack = true;
    // TODO comment
    const now = new Date();
    this.today = new Date(now.getFullYear(), now.getMonth() - 1, 0, 0, 0);
    this.timeInterval = null;
    this.polling_time = 15000;
    this.count_polling_time = 0;
    this.deepCloneOriginalSessionInfo = [];
    this.selectedSessionId = null;
    this.userMetaManager = requestContext.allowUserMeta
      ? new UserMetaManagementController(apiUrl)
      : null;
    this.debug = requestContext.debug;
    this.ui_language = requestContext.ui_language;
    this.isTranslating = false;
    // 翻訳APIの結果のオブジェクト。キーが原文、バリューが翻訳結果。
    this.translationObject = {};
    this.isScrollTop = false;
    this.isLoadingConversation = false;
    this.redirector_access_log = [];
    this.operatorSession = [];
    this.logId = requestContext.logId;
    this.allow_operator_handling = requestContext.allow_operator_handling;
    this.existsPreviousLog = true;
    this.existsNextLog = true;
    this.isScrollBottom = false;
    this.showAlertFunc = null;
    this.sessionIdSearchResult = [];
    this.sessionIdIsLoad = false;
    // TODO comment
    this.latestLoadedLogs = [];

    this.selectedClientId = null;
    this.clientIdFilterEnabled = requestContext.enable_client_id_filter;
    this.splitTranslationEnabled = requestContext.enable_split_translation;
  }

  /**
   * デフォルトで今日の日付が選択されているので、セッション一覧を読み込み
   */
  ready() {
    this.loadSessionsByDate(
      this.selectedDate.getFullYear(),
      this.selectedDate.getMonth() + 1,
      this.selectedDate.getDate()
    );
    this.loadAllDate(
      this.selectedDate.getFullYear(),
      this.selectedDate.getMonth() + 1
    );
    if (this.allow_operator_handling) {
      this.loadOperatorSession();
      // Fetch operator session for every 3 sec
      // setInterval(() => {
      //   this.loadOperatorSession()
      // }, 3000)
    }
    if (this.userMetaManager) {
      this.userMetaManager.loadAllUserMetaMap();
    }
  }
  /**
   * Copy session url to clipboard
   */
  copySessionURL(sId) {
    copyToClipboard(this.sessionURL + sId);

    let message = this.i18n.t("history.copiedSessionURL");
    alert(message);
  }

  loadAllDate(year, month) {
    this.chatLogDateApi
      .list({
        params: {
          year: year,
          month: month,
          welcome: this.isWelcomeOnlyConversationVisible,
          language: this.selectedLanguage,
          platforms: this.selectedPlatforms,
        },
      })
      .then((instance) => {
        this.allDate.splice(0, this.allDate.length);
        for (let i = 0; i < instance.length; i++) {
          this.allDate.push(new Date(instance[i].date));
        }
      });
  }

  loadOperatorSession() {
    const timeout = 2999;
    const CancelToken = axios.CancelToken;
    const options = {
      headers: {
        "X-CSRFToken": getToken.getCsrfTokenFromCookie(document.cookie),
      },
      cancelToken: new CancelToken((cancel) => setTimeout(cancel, timeout)),
      timeout,
    };
    axios
      .post(this.apiUrl.operatorSessionFetch, {}, options)
      .then((responses) => {
        this.operatorSession = responses.data;
      })
      .catch(() =>
        console.log("Operator session fetch response cannot back within 3 sec")
      );
  }

  operatorSupportUpdate(status, csrfToken = null) {
    const options = {
      headers: { "X-CSRFToken": csrfToken },
    };
    const data = {
      session_id: this.selectedSessionInfo.sessionId,
      platform: this.selectedSessionInfo.platform,
      status: status,
    };
    let promise = axios.post(this.apiUrl.operatorSessionUpdate, data, options);
    return [true, this.i18n.t("general.saving"), promise];
  }
  /**
   * 選択された日付のセッション一覧を読み込み
   */
  loadSessionsByDate(year, month, day) {
    // reset polling-time
    this.polling_time = 15000;
    this.count_polling_time = 0;
    // for loading spinner in conversation_history_session_list component
    this.sessionListTrack = true;
    // Date型はmonthだけ0-indexedのため-1しておく
    this.selectedDate = new Date(year, month - 1, day);
    this.deepCloneSelectedDateUp = new Date(year, month - 1, day);
    this.deepCloneSelectedDateDown = new Date(year, month - 1, day);
    this.year = year;
    this.month = month;
    this.day = day;
    this.selectedSessionId = null;
    this.loadSessions(year, month, day);
    if (this.selectedDate.getTime() === this.today.getTime()) {
      this.setPollingInterval(year, month, day);
    } else {
      clearInterval(this.timeInterval);
    }
  }

  isOperatorActivate() {
    let selectedOperatorSession = this.operatorSession.filter(
      (session) =>
        session.session_id === this.selectedSessionId &&
        [
          operatorStatusReason.STARTED_BY_USER,
          operatorStatusReason.STARTED_BY_OPERATOR,
        ].includes(session.status_reason)
    );
    if (
      selectedOperatorSession.length > 0 &&
      selectedOperatorSession[0].status === operatorStatus.ACTIVE
    ) {
      return true;
    }
    return false;
  }

  setPollingInterval(year, month, day) {
    if (!!this.selectedSessionId && this.isOperatorActivate()) {
      clearInterval(this.timeInterval);
      this.polling_time = 15000;
      this.timeInterval = setInterval(() => {
        this.loadSessions(year, month, day).then(() => {
          clearInterval(this.timeInterval);
          this.setPollingInterval(year, month, day);
        });
      }, 5000);
    } else {
      this.timeInterval = setInterval(() => {
        this.deepCloneOriginalSessionInfo = cloneDeep(this.originalSessionInfo);
        this.loadSessions(year, month, day).then(() => {
          clearInterval(this.timeInterval);
          if (
            isEqual(this.originalSessionInfo, this.deepCloneOriginalSessionInfo)
          ) {
            if (this.count_polling_time >= 2) {
              this.polling_time = 60000;
            } else {
              this.polling_time = 30000;
            }
            this.count_polling_time += 1;
          } else {
            this.polling_time = 15000;
            this.count_polling_time = 0;
          }
          this.setPollingInterval(year, month, day);
        });
      }, this.polling_time);
    }
  }

  /**
   *
   */
  loadSessions(year, month, day) {
    return this.chatLogApi
      .list({
        params: {
          year: year,
          month: month,
          day: day,
          platforms: this.selectedPlatforms,
          language_code: this.language_dict["all"],
        },
      })
      .then((instances) => {
        // 日付選択時、会話表示等をリセット
        if (!this.selectedSessionId) {
          this.logs = [];
        }

        this.filteredSessionInfo = [];
        this.selectedSessionInfo = null;
        this.selectedDateLogs = instances;
        // for stop loading spinner in conversation_history_session_list component
        this.sessionListTrack = false;
        // instancesでは、セッションID,日時でソートされた結果が返される
        this.originalSessionInfo = [];

        // ログがなければ何もしない
        if (this.selectedDateLogs.length == 0) {
          return;
        }

        // ある日付のチャットログの配列を処理する。
        // 各セッションの開始、終了時刻、メッセージ数を数える
        this.handleChatLogsOfDate();

        // filterとsortの実行
        this.generateSessionInfo();
        if (this.sessionId !== null) {
          this.loadConvBySessionIdQueryParameter();
        }

        // すでにセッション選択済みの場合は対象セッションの会話を読み込む
        if (this.selectedSessionId) {
          this.loadSessionConversation(this.selectedSessionId);
        }
      });
  }

  /**
   * 選択されている日付のチャットログの配列を処理する。
   * 各セッションの開始、終了時刻、メッセージ数をセットする
   */
  handleChatLogsOfDate() {
    // 取得したログをセッションIDごとにまとめ、情報を付加
    // セッションID,開始時刻、終了時刻、プラットフォーム、メッセージ数
    let currentSessionId = null;
    // Count messages to show total per session
    let messageCount = 0;
    // welcomeのみのメッセージを除くフィルターで利用するために1セッション内のウェルカムメッセージの数を数える
    let welcomeCount = 0;
    this.selectedDateLogs.forEach((log, idx) => {
      if (currentSessionId == null) {
        // 最初のsessionIdの場合
        currentSessionId = log.sessionId;

        // TODO comment
        this.originalSessionInfo.push({
          sessionId: log.sessionId,
          // セッションの開始時刻は対象セッションの1番最初のchatlogの日付。（chatlogが日付昇順である前提）
          startTime: log.insertDateTime,
          platform: log.platform,
        });

        messageCount += log.messageCount;

        // ウェルカムの回数をカウント
        if (log.action === "input.welcome") {
          welcomeCount++;
        }

        return;
      }

      // 2つ目以降のlogの処理
      if (log.sessionId == currentSessionId) {
        // すでにセッション一覧にあるセッションIDのログの場合、メッセージカウントを増やす
        messageCount += log.messageCount;

        if (log.action === "input.welcome") {
          welcomeCount++;
        }
      } else {
        // 新しいセッションの情報の処理

        // その前に1つ前のsessionIdの情報を追加しておく。終了時刻など。
        // 対象のセッションIDのの会話の最後のメッセージ日時を終了時刻とする。
        this.originalSessionInfo[this.originalSessionInfo.length - 1].endTime =
          this.selectedDateLogs[idx - 1].insertDateTime;

        // 対象のセッション内のメッセージ数を追加
        this.originalSessionInfo[this.originalSessionInfo.length - 1].count =
          messageCount;

        this.originalSessionInfo[
          this.originalSessionInfo.length - 1
        ].welcomeCount = welcomeCount;

        messageCount = 0;
        welcomeCount = 0;

        // 新しいセッション用の情報を追加
        this.originalSessionInfo.push({
          sessionId: log.sessionId,
          startTime: log.insertDateTime,
          platform: log.platform,
        });

        // Count messages to show total by session
        messageCount += log.messageCount;

        if (log.action === "input.welcome") {
          welcomeCount++;
        }

        currentSessionId = log.sessionId;
      }
    }); // end of forEach

    if (this.originalSessionInfo.length === 0) {
      // セッションが1つもない場合は何もしない
      return;
    }

    // セッションの終了時刻は最後のチャットログのinsertDateTimeとする。
    this.originalSessionInfo[this.originalSessionInfo.length - 1].endTime =
      this.selectedDateLogs[this.selectedDateLogs.length - 1].insertDateTime;

    // メッセージカウントを更新
    this.originalSessionInfo[this.originalSessionInfo.length - 1].count =
      messageCount;
  }

  /**
   * Load conversation history by sessionId from query parameter
   */
  loadConvBySessionIdQueryParameter() {
    const log = this.filteredSessionInfo.find(
      (log) => log.sessionId === this.sessionId
    );
    if (log) {
      this.loadSessionConversation(this.sessionId);
      this.sessionId = null;
    }
  }

  /**
   * 指定されたセッションIDの会話をロード
   */
  async loadSessionConversation(sessionId) {
    // clientIdのフィルタをリセット
    this.selectedClientId = null;

    // 最新読み込みメッセージをリセット。（選択中セッションの合計メッセージ数更新のため利用）
    this.latestLoadedLogs = [];

    this.selectedSessionInfo = this.filteredSessionInfo.find(
      (i) => i.sessionId == sessionId
    );

    this.selectedSessionId = sessionId;

    const instances = await this.chatLogJSONApi.list({
      params: {
        session_id: sessionId,
        year: this.year,
        month: this.month,
        day: this.day,
        language_code: this.language_dict[this.selectedLanguage],
      },
    });

    this.logs = [];
    this.logs.push(...instances);

    // TODO ??
    this.selectedDate = new Date(this.year, this.month - 1, this.day);

    // 翻訳処理
    this.handleTranslation(instances);

    // セッションの開始時刻を更新
    if (this.logs.length > 0) {
      this.selectedSessionInfo.startTime = this.logs[0].insertDateTime;
    }

    this.handleRedirectorData();
  }

  /**
   * Fetch redirector access log
   */
  async handleRedirectorData(sessionId) {
    try {
      const response = await this.RedirectorAccessLogAPI.list({
        params: {
          session_id: sessionId,
          created_at: [
            this.year,
            this.month < 10 ? "0" + this.month : this.month,
            this.day < 10 ? "0" + this.day : this.day,
          ].join("-"),
        },
      });
      this.redirector_access_log = response;
    } catch (err) {
      const responseData = err.response.data;
      this.showAlert(responseData.detail, "danger");
    }
  }

  /**
   *  プラットフォームの選択の切り替え
   */
  togglePlatformSelection(platformName) {
    // platformが選択中のリストに入っているかで処理を分ける
    let platformIndex = this.selectedPlatforms.indexOf(platformName);
    if (platformIndex >= 0) {
      // すでにリストにある場合はリストから削除
      this.selectedPlatforms.splice(platformIndex, 1);
    } else {
      // リストにない場合はリストに追加
      this.selectedPlatforms.push(platformName);
    }

    this.loadAllDate(this.year, this.month);
    this.generateSessionInfo();
  }

  /**
   *  全てのプラットフォームの選択の切り替え
   */
  toggleAllPlatformSelection() {
    if (this.selectedPlatforms.length == this.allPlatforms.length) {
      // すべて選択中の場合のみすべて未選択に変更する
      this.selectedPlatforms = [];
    } else {
      // 1つでも未選択のものがあれば全て選択状態にする
      this.selectedPlatforms = JSON.parse(JSON.stringify(this.allPlatforms));
    }
    this.loadAllDate(this.year, this.month);
    this.generateSessionInfo();
  }

  /**
   * ソート項目が変更されたら、sessionInfoを更新
   */
  onSortElementSelected(value) {
    this.selectedSortElement = value;
    this.generateSessionInfo();
  }

  /**
   * ソート順が変更されたら、sessionInfoを更新
   */
  onSortOrderSelected(value) {
    this.selectedSortOrder = value;
    this.generateSessionInfo();
  }

  /**
   *  選択中のソート条件に応じてsessionInfoをソート
   */
  sortLogs(sessionInfo) {
    if (sessionInfo.length == 0) {
      // フィルター済みの会話のリストが空の場合
      return sessionInfo;
    }
    if (this.selectedSortOrder == "asc") {
      sessionInfo.sort((a, b) => {
        return a[this.selectedSortElement] > b[this.selectedSortElement]
          ? 1
          : -1;
      });
    } else {
      sessionInfo.sort((a, b) => {
        return a[this.selectedSortElement] < b[this.selectedSortElement]
          ? 1
          : -1;
      });
    }
    return sessionInfo;
  }

  /**
   * welcomeでのフィルター条件が変更されたら、sessionInfoを更新
   */
  onWelcomeFilterChanged(value) {
    this.isWelcomeOnlyConversationVisible = value;
    this.loadAllDate(this.year, this.month);
    this.generateSessionInfo();
    this.loadConvBySessionIdQueryParameter();
  }

  filterByLanguage() {
    this.loadAllDate(this.year, this.month);
  }

  /**
   * セッション情報を作成。フィルター、ソートも行う
   */
  generateSessionInfo() {
    this.filteredSessionInfo = JSON.parse(
      JSON.stringify(this.originalSessionInfo)
    );
    // TODO comment
    this.filteredSessionInfo = this.updateMessageCountForSelectedSession(
      this.filteredSessionInfo
    );
    this.filteredSessionInfo = this.filterByPlatform(this.filteredSessionInfo);
    this.filteredSessionInfo = this.sortLogs(this.filteredSessionInfo);
    this.filteredSessionInfo = this.filterByWelcomeOnly(
      this.filteredSessionInfo
    );
  }

  /**
   * ウェルカムメッセージフィルターの値に応じてsessionInfoをフィルター
   */
  filterByWelcomeOnly(sessionInfo) {
    if (!this.isWelcomeOnlyConversationVisible) {
      sessionInfo = sessionInfo.filter(
        (i) => !(i.count === 1 && i.welcomeCount === 1)
      );
    }
    return sessionInfo;
  }

  /**
   *  プラットフォームでsessionInfoを絞り込み
   */
  filterByPlatform(sessionInfo) {
    sessionInfo = sessionInfo.filter((i) =>
      this.selectedPlatforms.includes(i.platform)
    );
    return sessionInfo;
  }

  /**
   *  言語毎に絞り込み
   */
  languageTypeSelection(language) {
    this.selectedLanguage = language;
    let currentSessionId = null;
    let messageCount = 0;
    let welcomeCount = 0;
    this.originalSessionInfo = [];
    let temp = [];
    let temp_response_id = [];
    let languageInUse = []; // 各セッション(会話)に使用している言語リスト変数:セッションリストの下に表示するため
    for (let i = 0; i < this.selectedDateLogs.length; i++) {
      if (
        language === this.selectedDateLogs[i].languageCode ||
        language === "all"
      ) {
        temp_response_id.push(this.selectedDateLogs[i].responseId);
        if (currentSessionId === null) {
          languageInUse = [];
          languageInUse = this.checkLanguageInUse(
            this.selectedDateLogs[i],
            languageInUse
          );
          currentSessionId = this.selectedDateLogs[i].sessionId;
          temp.push({
            sessionId: this.selectedDateLogs[i].sessionId,
            startTime: this.selectedDateLogs[i].insertDateTime,
            platform: this.selectedDateLogs[i].platform,
            language: this.selectedDateLogs[i].languageCode,
            languageInUse: languageInUse,
          });
          // Count messages to show total by session
          if (this.selectedDateLogs[i].messageCount > 0) {
            messageCount += this.selectedDateLogs[i].messageCount;
          }
          if (this.selectedDateLogs[i].action === "input.welcome") {
            welcomeCount++;
          }
        } else if (currentSessionId === this.selectedDateLogs[i].sessionId) {
          temp[temp.length - 1].languageInUse = this.checkLanguageInUse(
            this.selectedDateLogs[i],
            languageInUse
          );
          // Count messages to show total by session
          if (this.selectedDateLogs[i].messageCount > 0) {
            messageCount += this.selectedDateLogs[i].messageCount;
          }
          if (this.selectedDateLogs[i].action === "input.welcome") {
            welcomeCount++;
          }
        } else {
          messageCount = 0;
          welcomeCount = 0;
          currentSessionId = this.selectedDateLogs[i].sessionId;
          languageInUse = [];
          languageInUse = this.checkLanguageInUse(
            this.selectedDateLogs[i],
            languageInUse
          );
          temp.push({
            sessionId: this.selectedDateLogs[i].sessionId,
            startTime: this.selectedDateLogs[i].insertDateTime,
            platform: this.selectedDateLogs[i].platform,
            language: this.selectedDateLogs[i].languageCode,
            languageInUse: languageInUse,
          });
          // Count messages to show total by session
          if (this.selectedDateLogs[i].messageCount > 0) {
            messageCount += this.selectedDateLogs[i].messageCount;
          }
          if (this.selectedDateLogs[i].action === "input.welcome") {
            welcomeCount++;
          }
        }
        temp[temp.length - 1].endTime = this.selectedDateLogs[i].insertDateTime;
        temp[temp.length - 1].count = messageCount;
        temp[temp.length - 1].welcomeCount = welcomeCount;
      }
    }
    this.originalSessionInfo = temp;
    this.generateSessionInfo();
  }

  filterSearchSessionId(value, csrfToken = null) {
    this.sessionIdIsLoad = true;
    this.sessionIdSearchResult = [];
    const options = {
      headers: { "X-CSRFToken": csrfToken },
    };
    axios
      .post("/api/agent/chat-session-search", { session_id: value }, options)
      .then((res) => {
        this.sessionIdSearchResult = res.data;
        this.sessionIdIsLoad = false;
      })
      .catch(() => {
        this.sessionIdSearchResult = [];
        this.sessionIdIsLoad = false;
      });
  }
  /**
   *  該当セッションの会話で使用している言語をチェック
   */
  checkLanguageInUse(selectedDateLog, languageInUseList) {
    if (!languageInUseList.includes(selectedDateLog.languageCode)) {
      languageInUseList.push(selectedDateLog.languageCode);
    }
    return languageInUseList;
  }

  /**
   * Push file message to user according to selected session ID
   * @param responseMessage
   * @param csrfToken
   * @returns [Promise<T>]
   */
  sendFileMessageToUser(responseMessage, csrfToken = null) {
    let sendOptions = {};
    let options = {};
    Object.assign(sendOptions, options, {
      headers: { "X-CSRFToken": csrfToken },
    });
    let formData = new FormData();
    formData.append("file", responseMessage.data.file);
    // 送信用responseMessageをコピー
    let payload = JSON.parse(JSON.stringify(responseMessage));
    payload.data.file = null;
    formData.append("payload", JSON.stringify(payload));
    let promise = axios.post(this.apiUrl.pushMessageUrl, formData, sendOptions);
    return [true, this.i18n.t("general.saving"), promise];
  }

  /**
   * Send Push message to user according to selected session ID
   * @param responseMessage
   * @param csrfToken
   * @returns [Promise<T>]
   */
  sendPushMessageToUser(responseMessage, csrfToken = null) {
    if (responseMessage.type === ResponseType.SEND_FILE) {
      return this.sendFileMessageToUser(responseMessage, csrfToken);
    } else {
      let sendOptions = {};
      let options = {};
      Object.assign(sendOptions, options, {
        headers: { "X-CSRFToken": csrfToken },
      });
      let promise = axios.post(
        this.apiUrl.pushMessageUrl,
        responseMessage,
        sendOptions
      );
      return [true, this.i18n.t("general.saving"), promise];
    }
  }

  /**
   * Fetch old conversation by the session ID
   * @param date
   * @param loadOption ('up' or 'down')
   */
  async getConversationByDateAndSession(date, loadOption) {
    if (loadOption === "up") {
      this.isScrollTop = true;
    } else {
      this.isScrollBottom = true;
    }

    // TODO comment
    this.latestLoadedLogs = [];

    const instances = await this.chatLogJSONApi.list({
      params: {
        session_id: this.selectedSessionInfo.sessionId,
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
        scroll_status: loadOption,
        language_code: this.language_dict[this.selectedLanguage],
      },
    });

    this.isScrollTop = false;
    this.isScrollBottom = false;

    const loadedLogCount = instances.length;
    if (loadedLogCount > 0) {
      // Set deepCloneSelectedDateUp or deepCloneSelectedDateDown
      const newDate = new Date(instances[0].insertDateTime);
      newDate.setHours(0, 0, 0, 0);

      // 画面右側に表示されるメッセージ数更新のための処理
      this.selectedSessionInfo.count += loadedLogCount;
      instances[loadedLogCount - 1].insertDateTime;

      if (loadOption === "up") {
        this.deepCloneSelectedDateUp = newDate;
        this.selectedSessionInfo.startTime = instances[0].insertDateTime;

        // 過去のログを配列の最初のほうに挿入
        this.logs.splice(0, 0, ...instances);
      } else {
        // downの場合
        this.deepCloneSelectedDateDown = newDate;
        console.log(instances[loadedLogCount - 1]);
        this.selectedSessionInfo.endTime =
          instances[loadedLogCount - 1].insertDateTime;

        this.logs.push(...instances);
      }

      this.handleRedirectorAccessLog(date);

      this.handleTranslation(instances);

      this.latestLoadedLogs = instances;
    } else {
      // chatlogが読み込めなかった場合、別日のログの読み込みボタン用のフラグを更新
      if (loadOption === "up") {
        // 更に前のメッセージ読み込みをできなくする
        this.existsPreviousLog = false;
      } else {
        // 更に新しいメッセージ読み込みをできなくする
        this.existsNextLog = false;
      }
    }
  }

  async handleRedirectorAccessLog(date) {
    //Fetch old redirector accesslog
    this.RedirectorAccessLogAPI.list({
      params: {
        session_id: this.selectedSessionInfo.sessionId,
        created_at: [
          date.getFullYear(),
          date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1,
          date.getDate() < 10 ? "0" + date.getDate() : date.getDate(),
        ].join("-"),
      },
    })
      .then((responses) => {
        if (loadOption === "up") {
          const filteredResponses = responses.filter((response) => response);
          this.redirector_access_log.splice(0, 0, ...filteredResponses);
        } else {
          this.redirector_access_log.push(response);
        }
      })
      .catch((err) => {
        const responseData = err.response.data;
        this.showAlert(responseData.detail, "danger");
      });
  }

  async handleTranslation(instances) {
    // Fetch translation log
    if (!this.debug) {
      const isTranslationNeeded = instances.some(
        (instance) => instance.languageCode !== this.ui_language  // 言語がUI言語と異なる履歴がある場合
      ) || this.splitTranslationEnabled  // 分割翻訳が有効な場合
      if (!isTranslationNeeded) {
        return;
      }

      if (this.translationObject === null) {
        this.translationObject = {};
      }

      this.isTranslating = true;

      let translateTargets = instances;
      if (!this.splitTranslationEnabled) {
        // 分割翻訳が無効な場合は、UI言語以外言語の履歴は翻訳対象
        translateTargets = instances.filter(
          (instance) => instance.languageCode !== this.ui_language
        )
      }

      const requestBody = {
        data: translateTargets,
        platform: this.selectedSessionInfo.platform,
      };
      const response = await axios.post(
        `${this.apiUrl.Translation}session/`,
        requestBody
      );

      this.translationObject = {
        ...this.translationObject,
        ...response.data,
      }

      this.isTranslating = false;
    }
  }

  setShowAlertFunc(func) {
    this.showAlertFunc = func;
  }

  showAlert(msg, variant = "info") {
    if (this.showAlertFunc && typeof this.showAlertFunc === "function") {
      this.showAlertFunc(msg, variant);
    }
  }

  /**
   * 選択中のセッションの合計メッセージ数を更新する
   * 次の日、前の日のメッセージを読み込んだ場合などに更新が必要となる。
   */
  updateMessageCountForSelectedSession(chatLogs) {
    if (this.latestLoadedLogs.length > 0 && this.selectedSessionInfo) {
      this.selectedSessionInfo.count += this.latestLoadedLogs.reduce(
        (previousValue, chatItem) => {
          if (chatItem.sessionId !== this.selectedSessionInfo.sessionId) {
            return previousValue;
          }
          return previousValue + chatItem.messageCount;
        },
        0
      );
    }

    if (chatLogs.length > 0 && this.selectedSessionInfo) {
      chatLogs.forEach((chatItem) => {
        if (this.selectedSessionInfo.sessionId === chatItem.sessionId) {
          // TODO comment
          chatItem.count = this.selectedSessionInfo.count;
        }
      });
    }
    return chatLogs;
  }

  /**
   * Get list of files through pagination
   *
   * @param params
   * @returns {Promise<unknown>}
   */
  getFileContents(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(this.apiUrl.ListFileContents, {
          params: {
            page: params.page,
          },
        })
        .then(function (response) {
          // handle success
          resolve(response.data);
        })
        .catch(function (error) {
          // handle error
          reject(error);
          console.log(error);
        });
    });
  }

  selectClientId(id) {
    this.selectedClientId = id;
  }

  async translateText(logs, toLanguage) {
    const response = await axios.post(this.apiUrl.Translation, {
      logs,
      to_language: toLanguage,
    });
    return response.data;
  }
}
