import Vue from "vue";
import { UrlBuilder } from "./util/url-builder";
import { API_URL } from "./resource/urls";
import { FAQContentManagementController } from "./controller/faq-content-management-controller";
import FAQContentManagement from "./components/FAQContentManagement.vue";
import FAQCategory from "./components/FAQCategory.vue";
import FAQIntent from "./components/FAQIntent.vue";
import FAQQA from "./components/FAQQA.vue";
import ModalFooter from "./components/ModalFooter.vue";
import ProgressModal from "./components/ProgressModal.vue";
import ErrorModal from "./components/ErrorModal.vue";
import SuccessModal from "./components/SuccessModal.vue";
import DeleteConfirmModal from "./components/DeleteConfirmModal.vue";

import IconCheck from "./components/icons/IconCheck.vue";

export const appFAQContentManagement = (i18n) => {
  const { supportedLanguages, categories, intents, lang, languageLabel } =
    window.requestContext;

  /* コントローラ */
  const controller = new FAQContentManagementController(
    new UrlBuilder(API_URL).build(),
    window.i18nContext,
    supportedLanguages,
    categories,
    intents,
    lang,
    languageLabel
  );

  /* コンポーネント登録 */
  Vue.component("faq-content-management", FAQContentManagement);
  Vue.component("faq-category", FAQCategory);
  Vue.component("faq-intent", FAQIntent);
  Vue.component("faq-qa", FAQQA);
  Vue.component("modal-footer", ModalFooter);
  Vue.component("progress-modal", ProgressModal);
  Vue.component("error-modal", ErrorModal);
  Vue.component("success-modal", SuccessModal);
  Vue.component("delete-confirm-modal", DeleteConfirmModal);

  Vue.component("icon-check", IconCheck);

  new Vue({
    el: "#app-faq-content-management",
    data: {
      controller: controller,
    },
    mounted() {
      this.$nextTick(() => {
        controller.ready();
      });
    },
    i18n: i18n,
  });
};
