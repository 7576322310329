import Vue from "vue";
import { UrlBuilder } from "./util/url-builder";
import { API_URL } from "./resource/urls";
import { FAQClientManagementController } from "./controller/faq-client-management-controller";
import FAQClientManagement from "./components/FAQClientManagement.vue";
import FAQCategory from "./components/FAQCategory.vue";
import FAQIntent from "./components/FAQIntent.vue";
import FAQQA from "./components/FAQQA.vue";
import ModalFooter from "./components/ModalFooter.vue";
import ProgressModal from "./components/ProgressModal.vue";
import ErrorModal from "./components/ErrorModal.vue";
import DeleteConfirmModal from "./components/DeleteConfirmModal.vue";
import IconCheck from "./components/icons/IconCheck.vue";

export const appFAQClientManagement = (i18n) => {
  const { clients, loaders } = window.requestContext;

  /* コントローラ */
  const controller = new FAQClientManagementController(
    new UrlBuilder(API_URL).build(),
    window.i18nContext,
    clients,
    loaders
  );

  /* コンポーネント登録 */
  Vue.component("faq-client-management", FAQClientManagement);
  Vue.component("faq-category", FAQCategory);
  Vue.component("faq-intent", FAQIntent);
  Vue.component("faq-qa", FAQQA);
  Vue.component("modal-footer", ModalFooter);
  Vue.component("progress-modal", ProgressModal);
  Vue.component("error-modal", ErrorModal);
  Vue.component("delete-confirm-modal", DeleteConfirmModal);
  Vue.component("icon-check", IconCheck);

  new Vue({
    el: "#app-faq-client-management",
    data: {
      controller,
    },
    mounted() {
      this.$nextTick(() => {
        controller.ready();
      });
    },
    i18n: i18n,
  });
};
