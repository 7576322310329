<template>
  <div v-if="!translation"></div>
  <div v-else-if="isQueryText"
    class="d-flex align-items-center mr-5"
  >
    <small class="px-1 ml-4 mr-5 text-success">
      {{ translation }}
    </small>
  </div>
  <div
    v-else-if="isResponseText || isResponseCardContent"
    class="text-success text-size-sm-right"
  >
    {{ translation }}
  </div>
</template>

<script>
export default {
  name: 'TranslationLine',
  props: ['translation', 'type'],
  computed: {
    isQueryText() {
      return this.type === 'query_text';
    },
    isResponseText() {
      return this.type === 'response_text';
    },
    isResponseCardContent() {
      return this.type === 'response_card_content';
    },
  }
}
</script>
