<template>
  <td class="cell-scroll-x" :id="`session_cell_${index}`">
    <div v-if="lineGroupId">LINE Group ID [{{ lineGroupId }}]</div>

    <template v-if="matchedUserMeta">
      <a :href="matchedUserMeta.profileUrl ? matchedUserMeta.profileUrl : void(0)"
         target="_blank" :style="'color: '+ getSessionColor(sessionId)">
        <strong>{{ matchedUserMeta.fullName }}</strong>:
      </a>
    </template>

    <a :href="goSessionPage(sessionId, logId)" target="_blank"
       :style="'color: '+ getSessionColor(sessionId)">
      {{ sessionId }}
    </a>
    <template v-if="clientId && clientIdShown">
      <br>
      <span style="font-size: small;">({{ clientId }})</span>
    </template>

    <b-popover
      :target="`session_cell_${index}`"
      triggers="hover"
      placement="top"
      ref="popover"
    >
      <h3 class="popover-header" v-if="matchedUserMeta">
        {{ $t("userMeta.userMeta") }}
      </h3>
      <div class="popover-body" v-if="matchedUserMeta">
        <span>Name: {{ matchedUserMeta.fullName }}</span>
        <br>
        <span>Email: {{ matchedUserMeta.email }}</span>
        <br>
        <span>Profile: {{ matchedUserMeta.profileUrl }}</span>
      </div>
      <h3 class="popover-header">
        {{ $t("userMeta.sessionId") }}
      </h3>
      <div class="popover-body">
        <span>{{ sessionId }}</span>
      </div>
    </b-popover>
  </td>
</template>

<script>
    export default {
        props: ['sessionId', 'index', 'platform', 'userMetaManager', 'queryDate', 'logId', 'clientId', 'clientIdShown', 'lineGroupId'],
        computed: {
            matchedUserMeta() {
              if (!this.userMetaManager) {
                  return null
              }
              return this.userMetaManager.getMatchedUserMeta(this.sessionId, this.platform)
            }
        },
        methods: {
          goSessionPage(id, log){
            return '/history/sessions?session_id=' + id + '&log_id=' + log
          },
          getSessionColor(sessionId) {
            const sessionHexNumber = parseInt(sessionId, 36).toExponential().slice(3, -6)
            const hexNumber = (parseInt(sessionHexNumber, 10) & 0xFFFFFF).toString(16)
            return '#' + ("000000" + hexNumber).slice(-6)
          }
        }
    }
</script>
