import Vue from "vue";
import { UrlBuilder } from "./util/url-builder";
import { API_URL } from "./resource/urls";
import { FAQDatasetManagementController } from "./controller/faq-dataset-management-controller";
import FAQDatasetManagement from "./components/FAQDatasetManagement.vue";
import ModalFooter from "./components/ModalFooter.vue";
import ProgressModal from "./components/ProgressModal.vue";
import ErrorModal from "./components/ErrorModal.vue";
import DeleteConfirmModal from "./components/DeleteConfirmModal.vue";

export const appFAQDatasetManagement = (i18n) => {
  const {
    datasets,
  } = window.requestContext;

  /* コントローラ */
  const controller = new FAQDatasetManagementController(
    new UrlBuilder(API_URL).build(),
    window.i18nContext,
    datasets
  );

  /* コンポーネント登録 */
  Vue.component("faq-dataset-management", FAQDatasetManagement);
  Vue.component("modal-footer", ModalFooter);
  Vue.component("progress-modal", ProgressModal);
  Vue.component("error-modal", ErrorModal);
  Vue.component("delete-confirm-modal", DeleteConfirmModal);

  new Vue({
    el: "#app-faq-dataset-management",
    data: {
      controller,
    },
    mounted() {
      this.$nextTick(() => {
        controller.ready();
      });
    },
    i18n: i18n,
  });
};
