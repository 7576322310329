import { isArray } from "lodash";

/**
 * 会話履歴
 */
class ChatLog {
  constructor(
    id = null,
    responseId = null,
    sessionId = null,
    queryText = "",
    languageCode = "",
    fulfillmentText = "",
    action = "",
    intentName = "",
    platform = "",
    insertDateTime = "",
    location = {},
    logId = null,
    responseJSON = [],
    uploadedFile = null,
    messageCount = 0,
    clientId = "",
    originalFileUrl = null,
    lineGroupId = null,
    lineGroupName = null,
  ) {
    this.id = id;
    this.responseId = responseId;
    this.sessionId = sessionId;
    this.queryText = queryText;
    this.languageCode = languageCode;
    this.fulfillmentText = fulfillmentText;
    this.action = action;
    this.intentName = intentName;
    this.platform = platform;
    this.insertDateTime = insertDateTime;
    this.location = location;
    this.logId = logId;
    this.responseJSON = responseJSON;
    this.uploadedFile = uploadedFile;
    this.messageCount = messageCount;
    this.clientId = clientId;
    this.originalFileUrl = originalFileUrl;
    this.lineGroupId = lineGroupId;
    this.lineGroupName = lineGroupName;

    if (responseJSON && isArray(responseJSON)) {
      this.responseJSON = responseJSON.map((item) => ChatItem.fromData(item));
    }
  }

  toData() {
    return {
      id: this.id,
      response_id: this.responseId,
      session_id: this.sessionId,
      query_text: this.queryText,
      language_code: this.languageCode,
      fulfillment_text: this.fulfillmentText,
      action: this.action,
      display_name: this.intentName,
      platform: this.platform,
      insert_date_time: this.insertDateTime,
      location: this.location,
      log_id: this.logId,
      all_messages: this.responseJSON,
      uploaded_file: this.uploadedFile,
      message_count: this.messageCount,
      client_id: this.clientId,
      original_file_url: this.originalFileUrl,
      line_group_id: this.lineGroupId,
      line_group_name: this.lineGroupName,
    };
  }
}

ChatLog.fromData = function fromData(data) {
  return new ChatLog(
    data.id || null,
    data.response_id || null,
    data.session_id || null,
    data.query_text || "",
    data.language_code || "",
    data.fulfillment_text || "",
    data.action || "",
    data.display_name || "",
    data.platform || "",
    data.insert_date_time || "",
    data.location || "",
    data.log_id || null,
    data.all_messages || "",
    data.uploaded_file || null,
    data.message_count || 0,
    data.client_id || "",
    data.original_file_url || null,
    data.line_group_id || "",
    data.line_group_name || "",
  );
}

class ChatLogJSON {
  constructor(id = null, responseId = null, responseJSON = {}) {
    this.id = id;
    this.responseId = responseId;
    this.responseJSON = responseJSON;
  }

  toData() {
    return {
      id: this.id,
      response_id: this.responseId,
      response_json: this.responseJSON,
    };
  }
}

ChatLogJSON.fromData = function fromData(data) {
  return new ChatLogJSON(
    data.id || null,
    data.response_id || null,
    data.response_json || {}
  );
}

class ChatLogDate {
  constructor(date = null) {
    this.date = date;
  }
}

ChatLogDate.fromData = function fromData(data) {
  return new ChatLogDate(data.insert_date_time || null);
}

/**
 * オペレーター対応セッション
 */
class OperatorHandlingSession {
  constructor(
    id = null,
    createdAt = null,
    lastActionBy = null,
    lastActionOperator = null,
    lastQueryText = null,
    logId = null,
    note = null,
    platform = null,
    sessionId = null,
    status = null,
    statusReason = null,
    updatedAt = null,
    operatorUserId = null
  ) {
    this.id = id;
    this.createdAt = createdAt;
    this.lastActionBy = lastActionBy;
    this.lastActionOperator = lastActionOperator;
    this.lastQueryText = lastQueryText;
    this.logId = logId;
    this.note = note;
    this.platform = platform;
    this.sessionId = sessionId;
    this.status = status;
    this.statusReason = statusReason;
    this.updatedAt = updatedAt;
    this.operatorUserId = operatorUserId;
  }
}

OperatorHandlingSession.fromData = function fromData(data) {
  return new OperatorHandlingSession(
    data.id || "",
    data.created_at || "",
    data.last_action_by || "",
    data.last_action_operator || "",
    data.last_query_text || "",
    data.log_id || "",
    data.note || "",
    data.platform || "",
    data.session_id || "",
    data.status || "",
    data.status_reason || "",
    data.updated_at || "",
    data.operator_user_id || ""
  );
}

/**
 * オペレーター
 */
class Operator {
  constructor(id = null, name = null) {
    this.id = id;
    this.name = name;
  }
}

Operator.fromData = function fromData(data) {
  return new Operator(data.id || "", data.username || "");
}

/**
 * オペレーター対応セッションのブックマーク
 */
class OperatorHandlingSessionBookmark {
  constructor(id = null, operatorHandlingSessionId = null) {
    this.id = id;
    this.operatorHandlingSessionId = operatorHandlingSessionId;
  }
}

OperatorHandlingSessionBookmark.fromData = function fromData(data) {
  return new OperatorHandlingSessionBookmark(
    data.id || "",
    data.operator_handling_session_id || ""
  );
}

/**
 * オペレーター対応セッションのイベントログ
 */
class OperatorHandlingSessionEventLog {
  constructor(
    detail = null,
    eventType = null,
    operatorHandlingSessionId = null,
    createdAt = null
  ) {
    this.detail = detail;
    this.eventType = eventType;
    this.operatorHandlingSessionId = operatorHandlingSessionId;
    this.createdAt = createdAt;
  }
}

OperatorHandlingSessionEventLog.fromData = function fromData(data) {
  return new OperatorHandlingSessionEventLog(
    data.detail || {},
    data.event_type || "",
    data.operator_handling_session_id || "",
    data.created_at || ""
  );
}

/**
 * リファクタリング後のセッションごと会話履歴表示用のアイテム
 */
class ChatItem {
  constructor(
    chat_log_id,
    log_type,
    text,
    insert_date_time,
    options,
    title,
    subtitle,
    buttons,
    image_url,
    is_last_item,
    language_code,
    action,
    intent_name,
    url,
    preview_src,
    name,
    src,
    address,
    latitude,
    longitude,
    source,
    response_json,
    all_messages,
    called_intent_name,
    client_id,
    line_group_id,
    session_id
  ) {
    // すべてに共通する項目
    this.chatLogId = chat_log_id;
    this.logType = log_type;
    // JSTの文字列がくるため、タイムゾーン調整を行う
    this.insertDateTime = new Date(insert_date_time);
    this.isLastItem = is_last_item;
    this.languageCode = language_code;
    this.action = action;
    this.intentName = intent_name;
    this.calledIntentName = called_intent_name;
    this.clientId = client_id
    this.lineGroupId = line_group_id
    this.responseJSON = response_json ? JSON.parse(response_json) : {};
    this.sessionId = session_id

    if (this.logType === "usersay") {
      this.text = text;
    } else if (this.logType === "text") {
      this.text = text;
    } else if (this.logType === "quick_replies") {
      this.text = text;
      this.options = options;
    } else if (this.logType === "card") {
      this.title = title;
      this.subtitle = subtitle;
      this.buttons = buttons;
      this.imageUrl = image_url;
    } else if (this.logType === "image") {
      this.url = url;
    } else if (this.logType === "audio") {
      this.name = name;
      this.src = src;
    } else if (this.logType === "video") {
      this.src = src;
      this.previewSrc = preview_src;
    } else if (this.logType === "location") {
      this.address = address;
      this.latitude = latitude;
      this.longitude = longitude;
      this.source = source;
    } else if (this.logType === "file_usersay") {
      this.url = url;
    }

    if (all_messages) {
      this.allMessages = all_messages.map((item) => ChatItem.fromData(item));
    }
  }
}

ChatItem.fromData = function fromData(data) {
  const {
    chat_log_id,
    log_type,
    text,
    insert_date_time,
    options,
    title,
    subtitle,
    buttons,
    image_url,
    is_last_item,
    language_code,
    action,
    intent_name,
    url,
    preview_src,
    name,
    src,
    address,
    latitude,
    longitude,
    source,
    response_json,
    all_messages,
    called_intent_name,
    client_id,
    line_group_id,
    session_id
  } = data;
  return new ChatItem(
    chat_log_id,
    log_type,
    text,
    insert_date_time,
    options,
    title,
    subtitle,
    buttons,
    image_url,
    is_last_item,
    language_code,
    action,
    intent_name,
    url,
    preview_src,
    name,
    src,
    address,
    latitude,
    longitude,
    source,
    response_json,
    all_messages,
    called_intent_name,
    client_id,
    line_group_id,
    session_id
  );
}

export {
  ChatLog,
  ChatLogJSON,
  ChatLogDate,
  OperatorHandlingSession,
  Operator,
  OperatorHandlingSessionBookmark,
  OperatorHandlingSessionEventLog,
  ChatItem,
};
