<template>
  <div class="feedback mt-1">
    <div>
      <b-form @submit.stop.prevent>
        <div class="row">
          <div class="col-md-2">
            <div>
              <h6>{{ $t('feedback.intentFreetextEnabled') }}</h6>
            </div>
            <div>
              <b-form-checkbox
                v-model="controller.feedback.isFreetextEnabled">
                {{ $t('feedback.intentFreetextEnabled') }}
              </b-form-checkbox>
            </div>
          </div>
        </div>
        <div v-if="controller.feedback.isFreetextEnabled">
          <hr>
          <h6>{{ $t('feedback.placeholder') }}:</h6>
          <div class="table-responsive-md">
            <table class="table table-borderless table-sm">
              <tbody>
                <tr>
                  <td v-for="feedback in controller.feedback.data">
                    <div>
                      <span v-if="'ja' === feedback.lang">{{ $t('languageSelector.japanese') }}</span>
                      <span v-else-if="'en' === feedback.lang">{{ $t('languageSelector.english') }}</span>
                      <span v-else-if="'ko' === feedback.lang">{{ $t('languageSelector.korean') }}</span>
                      <span v-else-if="'zh-cn' === feedback.lang">{{ $t('languageSelector.chineseSimplified') }}</span>
                      <span v-else-if="'zh-tw' === feedback.lang">{{ $t('languageSelector.chineseTraditional') }}</span>
                      <span v-else-if="'th' === feedback.lang">{{ $t('languageSelector.thai') }}</span>
                      <span v-else-if="'ru' === feedback.lang">{{ $t('languageSelector.russian') }}</span>
                      <span v-else-if="'vi' === feedback.lang">{{ $t('languageSelector.vietnamese') }}</span>
                      <span v-else-if="'id' === feedback.lang">{{ $t('languageSelector.indonesian') }}</span>
                      <span v-else-if="'uk' === feedback.lang">{{ $t('languageSelector.ukrainian') }}</span>
                      <span v-else-if="'ne' === feedback.lang">{{ $t('languageSelector.nepali') }}</span>
                      <span v-else-if="'bn' === feedback.lang">{{ $t('languageSelector.bengali') }}</span>
                      <span v-else-if="'fr' === feedback.lang">{{ $t('languageSelector.french') }}</span>
                      <span v-else-if="'es' === feedback.lang">{{ $t('languageSelector.spanish') }}</span>
                      <span v-else-if="'mn' === feedback.lang">{{ $t('languageSelector.mongolian') }}</span>
                      <span v-else-if="'my' === feedback.lang">{{ $t('languageSelector.burmese') }}</span>
                      <span v-else-if="'pt' === feedback.lang">{{ $t('languageSelector.portuguese') }}</span>
                      <span v-else-if="'pt-br' === feedback.lang">{{ $t('languageSelector.portugueseBrazil') }}</span>
                      <span v-else-if="'si' === feedback.lang">{{ $t('languageSelector.sinhala') }}</span>
                    </div>
                    <div>
                      <b-form-input
                        v-model="feedback.placeholder"
                        maxlength="160"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <hr>
        <h6>{{ $t('feedback.title') }}:</h6>
        <div class="table-responsive-md">
          <table class="table table-borderless table-sm">
            <tbody>
              <tr>
                <td v-for="feedback in controller.feedback.data">
                  <div>
                    <span v-if="'ja' === feedback.lang">{{ $t('languageSelector.japanese') }}</span>
                    <span v-else-if="'en' === feedback.lang">{{ $t('languageSelector.english') }}</span>
                    <span v-else-if="'ko' === feedback.lang">{{ $t('languageSelector.korean') }}</span>
                    <span v-else-if="'zh-cn' === feedback.lang">{{ $t('languageSelector.chineseSimplified') }}</span>
                    <span v-else-if="'zh-tw' === feedback.lang">{{ $t('languageSelector.chineseTraditional') }}</span>
                    <span v-else-if="'th' === feedback.lang">{{ $t('languageSelector.thai') }}</span>
                    <span v-else-if="'ru' === feedback.lang">{{ $t('languageSelector.russian') }}</span>
                    <span v-else-if="'vi' === feedback.lang">{{ $t('languageSelector.vietnamese') }}</span>
                    <span v-else-if="'id' === feedback.lang">{{ $t('languageSelector.indonesian') }}</span>
                    <span v-else-if="'uk' === feedback.lang">{{ $t('languageSelector.ukrainian') }}</span>
                    <span v-else-if="'ne' === feedback.lang">{{ $t('languageSelector.nepali') }}</span>
                    <span v-else-if="'bn' === feedback.lang">{{ $t('languageSelector.bengali') }}</span>
                    <span v-else-if="'fr' === feedback.lang">{{ $t('languageSelector.french') }}</span>
                    <span v-else-if="'es' === feedback.lang">{{ $t('languageSelector.spanish') }}</span>
                    <span v-else-if="'mn' === feedback.lang">{{ $t('languageSelector.mongolian') }}</span>
                    <span v-else-if="'my' === feedback.lang">{{ $t('languageSelector.burmese') }}</span>
                    <span v-else-if="'pt' === feedback.lang">{{ $t('languageSelector.portuguese') }}</span>
                    <span v-else-if="'pt-br' === feedback.lang">{{ $t('languageSelector.portugueseBrazil') }}</span>
                    <span v-else-if="'si' === feedback.lang">{{ $t('languageSelector.sinhala') }}</span>
                  </div>
                  <div>
                    <b-form-input
                      v-model="feedback.title"
                      maxlength="160"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr>
        <h6>{{ `${$t('feedback.buttons')}: ${controller.dFeedback.buttons.length === 0 ? $t('feedback.noButtonConfiguredMessage') : ""}` }}</h6>
        <div class="table-responsive-md">
          <table class="table table-borderless table-sm">
            <thead>
              <tr v-show="controller.dFeedback.buttons.length > 0">
                <td v-for="feedback in controller.feedback.data">
                  <span v-if="'ja' === feedback.lang">{{ $t('languageSelector.japanese') }}</span>
                  <span v-else-if="'en' === feedback.lang">{{ $t('languageSelector.english') }}</span>
                  <span v-else-if="'ko' === feedback.lang">{{ $t('languageSelector.korean') }}</span>
                  <span v-else-if="'zh-cn' === feedback.lang">{{ $t('languageSelector.chineseSimplified') }}</span>
                  <span v-else-if="'zh-tw' === feedback.lang">{{ $t('languageSelector.chineseTraditional') }}</span>
                  <span v-else-if="'th' === feedback.lang">{{ $t('languageSelector.thai') }}</span>
                  <span v-else-if="'ru' === feedback.lang">{{ $t('languageSelector.russian') }}</span>
                  <span v-else-if="'vi' === feedback.lang">{{ $t('languageSelector.vietnamese') }}</span>
                  <span v-else-if="'id' === feedback.lang">{{ $t('languageSelector.indonesian') }}</span>
                  <span v-else-if="'uk' === feedback.lang">{{ $t('languageSelector.ukrainian') }}</span>
                  <span v-else-if="'ne' === feedback.lang">{{ $t('languageSelector.nepali') }}</span>
                  <span v-else-if="'bn' === feedback.lang">{{ $t('languageSelector.bengali') }}</span>
                  <span v-else-if="'fr' === feedback.lang">{{ $t('languageSelector.french') }}</span>
                  <span v-else-if="'es' === feedback.lang">{{ $t('languageSelector.spanish') }}</span>
                  <span v-else-if="'mn' === feedback.lang">{{ $t('languageSelector.mongolian') }}</span>
                  <span v-else-if="'my' === feedback.lang">{{ $t('languageSelector.burmese') }}</span>
                  <span v-else-if="'pt' === feedback.lang">{{ $t('languageSelector.portuguese') }}</span>
                  <span v-else-if="'pt-br' === feedback.lang">{{ $t('languageSelector.portugueseBrazil') }}</span>
                  <span v-else-if="'si' === feedback.lang">{{ $t('languageSelector.sinhala') }}</span>
                </td>
                <td>{{ $t('feedback.selectIntent') }}</td>
                <td>{{ $t('feedback.selectLevel') }}</td>
              </tr>
            </thead>
            <draggable v-model="controller.dFeedback.buttons" tag="tbody">
              <tr v-for="(row, index) in controller.dFeedback.buttons" :key="index">
                <td v-for="(column) in row.labels">
                  <b-form-input v-model="column.text" class="mb-1 mr-1" maxlength="20"></b-form-input>
                </td>
                <td>
                  <b-form-select v-model="row.intentId" :options="controller.allIntents" class="mb-1 mr-1"/>
                </td>
                <td>
                  <b-input-group-append>
                    <b-form-select v-model="row.level" :options="options" class="mb-1 mr-1"></b-form-select>
                      <button type="button"
                        class="btn btn-danger"
                        style="height: 38px"
                        :disabled="controller.dFeedback.buttons.length === 1"
                        @click="deleteButton(index)">
                        <i class="fa fa-minus"/>
                      </button>
                  </b-input-group-append>
                </td>
              </tr>
            </draggable>
          </table>
        </div>
        <div class="mb-1">
          <button type="button"
            class="btn btn-success"
            :disabled="controller.dFeedback.buttons.length === 5"
            @click="addButton">
            <i class="fa fa-plus"></i>
          </button>
          ({{ controller.dFeedback.buttons.length + " / 5" }})
        </div>
        <b-button class="mb-2" @click="showConfirmResetFeedbackSetting">
          <i class="fas fa-redo-alt"></i>
          <span class="ml-1">{{ $t('feedback.resetFeedbackSettings') }}</span>
        </b-button>
        <div class="text-center mt-2">
          <button type="button"
            class="btn btn-success"
            @click="saveFeedbackConfirm"
            :disabled="controller.dFeedback.buttons.length ===0">
            {{ $t('feedback.submit') }}
          </button>
        </div>
      </b-form>
    </div>
    <!--Modal end-->
    <error-modal ref="errorModal" :message="message"/>
    <progress-modal ref="progressModal" :message="message"/>
    <b-modal
      :title="$t('feedback.confirmTitle')"
      ref="confirmFeedbackSetting"
      header-class="bg-info text-light"
      body-class="text-info"
      no-close-on-esc
      no-close-on-backdrop>
      <div>
        <p>{{ $t('feedback.confirmFeedbackSetting') }}</p>
      </div>
        <modal-footer
          slot="modal-footer"
          @ok="saveFeedback"
          @cancel="$refs.confirmFeedbackSetting.hide()">
        </modal-footer>
    </b-modal>
    <delete-confirm-modal
      ref="confirmResetFeedbackSetting"
      :title="$t('feedback.resetFeedbackSettings')"
      :bodyMessage="$t('feedback.confirmResetFeedbackSettingsMessage')"
      @ok="resetFeedbackSetting"
      @key-press-enter="pressEnterKey"
      @cancel="$refs.confirmResetFeedbackSetting.hide()">
    </delete-confirm-modal>
  </div>
</template>

<script>
  import {I18n} from "../util/i18n"
  import csrfToken from '../util/csrf-token'
  import draggable from 'vuedraggable'
  export default{
    props:['controller'],
    components: {
      draggable,
    },
    data(){
      const i18n =  new I18n(window.i18nContext)
      return{
        message: '',
        options:[
          {value:'4', text:i18n.t('feedback.best')},
          {value:'3', text:i18n.t('feedback.good')},
          {value:'2', text:i18n.t('feedback.normal')},
          {value:'1', text:i18n.t('feedback.bad')},
          {value:'0', text:i18n.t('feedback.worst')}
        ]
      }
    },
    methods:{
      saveFeedbackConfirm(){
        this.$refs.confirmFeedbackSetting.show()
      },
      saveFeedback(evt){
        let result, message, promise
        [result, message, promise] = this.controller.saveFeedback(csrfToken.getCsrfTokenFromCookie(document.cookie))
        if (!result) {
          this.showError(this.i18n.t('feedback.error'))
        }else{
          this.showProgress(message)
          let self = this
          promise
          .then(()=>{
            this.hideProgress()
          })
          .catch(function(error){
            self.hideProgress()
            self.showError(error.response.data[0])
          })
        }
        this.$refs.confirmFeedbackSetting.hide()
      },
      showConfirmResetFeedbackSetting(){
        this.$refs.confirmResetFeedbackSetting.show();
      },
      async resetFeedbackSetting(){
        // フィードバック設定の初期化処理
        await this.controller.resetFeedbackSetting(csrfToken.getCsrfTokenFromCookie(document.cookie));
        this.$refs.confirmResetFeedbackSetting.hide();
      },
      addButton(){
        this.controller.addButton()
      },
      deleteButton(index){
        this.controller.deleteButton(index)
      },
      showError(message) {
        this.message = message
        this.$refs.errorModal.show()
      },
      showProgress(message) {
        this.message = message
        this.$refs.progressModal.show()
      },
      hideProgress() {
        this.$refs.progressModal.hide()
      }
    }
  }
</script>

<style scoped>
.selected-language{
  border:3px solid #b9bbbe;
  color: #fff;
}
.table td{
  min-width: 120px;
}
</style>
