<template>
  <div
    id="session-list-section"
    class="col-3 px-0 border d-flex flex-column justify-content-between h-100"
  >
    <div class="d-flex flex-column" :style="{ maxHeight: 'calc(100% - 55px)' }">
      <!-- フィルター条件エリア -->
      <div class="d-flex w-100 justify-content-between align-items-center py-1 border-bottom section-label">
        <span class="ml-2 section-label-title"><i class="fas fa-list mr-1"></i>{{ $t('history.Sessions') }}</span>
        <div class="d-flex align-items-center mr-2">
          <b-button
            class="ml-1 section-btn d-flex align-items-center"
            :variant="controller.sortAndFiltering? 'success': 'primary'"
            v-b-modal="'session-filter-modal'"
            v-b-tooltip.hover
            :title="$t('history.FilterAndSortSetting')"
          >
            <i class="fas fa-filter section-btn-icon"></i>
            <span class="section-btn-text">{{ controller.sortAndFiltering ? $t('history.Filtering') : $t('history.DisplaySetting') }}</span>
          </b-button>

          <!-- フィルタ解除ボタン。フィルタ適用時は通常のボタン、フィルタ適用なしのときは塗りつぶしなしのボタンを出す。-->
          <b-button
            class="ml-1 section-btn d-flex align-items-center"
            :variant="controller.sortAndFiltering ? 'secondary' : 'outline-secondary' "
            @click="controller.resetOperatorSessionFilter()"
            :disabled="!controller.sortAndFiltering"
          >
            <span class="section-btn-text">
              {{ $t('history.Cancel') }}
            </span>
          </b-button>
        </div>
      </div>

      <div class="d-flex mr-2 mb-2 pt-2 pl-2">
        <b-badge variant="danger">{{ $t('history.Calling') }}
          <b-badge variant="light">{{ sessionCount.calling }}</b-badge>
        </b-badge>
        <b-badge variant="warning" class="ml-1">{{ $t('history.OperatoSessionActive') }}
          <b-badge variant="light">{{ sessionCount.active }}</b-badge>
        </b-badge>
      </div>

      <!-- セッション一覧 -->
      <b-list-group
        flush
        :style="{
          maxHeight: '100%',
          overflow: 'scroll',
          '-webkit-overflow-scrolling': 'touch',
        }"
      >
        <b-list-group-item
          v-for="(session, idx) in sessions"
          :key="idx"
          href="#"
          class="flex-row align-items-start"
          :style="{
            borderLeft:
              selectedOperatorHandlingSessionId === session.id
                ? '6px solid #67B8ED'
                : '0',
            backgroundColor: getSessionBackgroundColor(session),
          }"
          @click="
            $emit('on-select-session', {
              operatorHandlingSessionId: session.id,
            })
          "
        >
          <div class="d-flex w-100 flex-row justify-content-between align-items-center">
            <operator-handling-session-status-icon :status="session.status"></operator-handling-session-status-icon>
            <div class="d-flex flex-row justify-content-center">
              <small class="mr-2">
                {{ session.updatedAt | moment('YYYY-MM-DD') }}
              </small>
              <span
                v-show="
                  bookmarks.find(
                    (b) => b.operatorHandlingSessionId === session.id
                  )
                "
              >
                <i class="fas fa-bookmark"></i>
              </span>
            </div>
          </div>

          <div>
            <small class="mt-1 font-weight-bold">
              {{ getUserMetaFullName(session.sessionId, session.platform) || session.sessionId.slice(-8) }}
            </small>
          </div>

          <span class="mt-1" v-html="getUserOrOperator(session)"></span>

          <div class="mt-2">
            <h6 :style="{ color: colorFromString(session.sessionId) }">
              {{ getSessionLatestText(session.lastQueryText) }}
            </h6>
          </div>

          <div class="d-flex flex-row justify-content-between align-items-center">
            <small>{{ $t('history.Operator') }}: {{ getOperatorNameById(session.operatorUserId) }}</small>

            <div class="d-flex flex-row justify-content-center">
              <platform-icon :platform="session.platform" />
            </div>
          </div>
        </b-list-group-item>
      </b-list-group>
    </div>

    <!-- ページネーション -->
    <div class="text-white p-2" :style="{ backgroundColor: '#D3D3D3' }">
      <div
        class="d-flex align-items-center justify-content-center"
        :style="{ height: '38px' }"
      >
        <div v-if="totalPageCount > 1" class="bg-light rounded">
          <b-button
            class="px-2 py-1"
            variant="outline-primary"
            @click="onChangePageIdx(controller.sessionListPageIdx-1)"
            :disabled="controller.sessionListPageIdx === 1"
          ><i class="fas fa-chevron-left"></i></b-button>
        </div>
        <span
          v-if="totalPageCount > 0"
          class="mx-1 p-1 text-secondary"
          style="font-size: 1.1rem;"
        >{{ `Page ${controller.sessionListPageIdx} / ${totalPageCount}` }}</span>
        <div v-if="totalPageCount > 1" class="bg-light rounded">
          <b-button
            class="px-2 py-1"
            variant="outline-primary"
            @click="onChangePageIdx(controller.sessionListPageIdx+1)"
            :disabled="controller.sessionListPageIdx === totalPageCount"
          ><i class="fas fa-chevron-right"></i></b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { operatorStatus, lastActionStatus } from '../../util/operatorStatus'
import { truncateText } from '../../util/truncate-text'
import { colorFromString } from '../../util/word-color'

export default {
  name: 'SessionListSection',
  props: [
    'sessions',
    'totalSessionCount',
    'sessionCount',
    'operators',
    'bookmarks',
    'selectedOperatorHandlingSessionId',
    'controller'
  ],
  data() {
    return {
      /** ページネーションの単位 */
      sessionsPerPage: 10,
    }
  },
  computed: {
    /**
     * ページネーションの総ページ数
     */
    totalPageCount() {
      return Math.ceil(this.totalSessionCount / this.sessionsPerPage)
    },
  },
  methods: {
    truncateText: truncateText,
    /**
     * オペレーターステータスによってセッション行の色を変更する。
     */
    getSessionBackgroundColor(session) {
      if (session.status === operatorStatus.ACTIVE && session.lastActionBy === lastActionStatus.OPERATOR) {
        return '#E6FFE9'
      } else if (session.status === operatorStatus.ACTIVE && session.lastActionBy === lastActionStatus.USER) {
        return '#FFCFD9'
      } else if (session.status === operatorStatus.CALLING) {
        return '#FFCFD9'
      } else if (session.status === operatorStatus.INACTIVE) {
        return '#EEEEEE'
      }
      return ''
    },
    /** 数字かつ適切な数値であればemitしてセッション一覧を更新 */
    onChangePageIdx(destinationIdx) {
      if (
        typeof this.controller.sessionListPageIdx === 'number' &&
        destinationIdx >= 1 &&
        destinationIdx <= this.totalPageCount
      ) {
        this.$emit('on-change-page-idx', { pageIdx: destinationIdx });
      }
    },
    /** 担当オペレーターの名前をユーザーIDから取得 */
    getOperatorNameById(operatorUserId) {
      const operator = this.operators.find((o) => o.id === operatorUserId)
      return operator ? operator.name : this.$t('history.Unset')
    },
    colorFromString: colorFromString,
    getUserOrOperator(session) {
      if(session.lastActionBy === lastActionStatus.USER) {
        const translation = this.$t('history.UserMessaged')
        return `<small class="text-muted">[${translation}] </small><small class="text-secondary" style="font-size: 0.5rem">${session.sessionId}</small>`
      }
      if(session.lastActionBy === lastActionStatus.OPERATOR) {
        const translation = this.$t('history.OperatorMessaged')
        return `<small class="text-muted">[${translation}] </small><small class="text-secondary" style="font-size: 0.5rem" style="font-size: 0.5rem">${session.lastActionOperator}</small>`
      }
      return ''
    },
    getSessionLatestText(lastQueryText) {
      return this.truncateText({ text: lastQueryText, displayLength: 45 })
    },
    getUserMetaFullName(sessionId, platform) {
      // ユーザーメタのフルネームを取得する
      let userMeta = null;
      if (this.controller.userMetaManager) {
        userMeta = this.controller.userMetaManager.getMatchedUserMeta(sessionId, platform);
      }
      return userMeta?.fullName || null;
    }
  },
}
</script>

<style>
  .section-label {
    background-color: #dadfe6;
    border-bottom: #c8cbcf;
  }
  .section-label-title {
    font-size: 0.9rem;
  }

  .section-btn-text {
    font-size: 10.5px
  }
  .section-btn-icon {
    font-size: 0.65em
  }
  .section-btn {
    padding: 1px 3px;
  }
  @media only screen and (max-width: 640px) {
    .tooltip,
    .tooltip-inner {
      display: none !important; 
    }
    #session-list-section {
      /*
       * ヘッダー: 56px
       * マージン: 4px
      */
      height: calc(100svh - 56px - 4px) !important;
    }
  }
</style>
