<template>
<div id="intent-detail" class="intent-detail">
  <div class="column-caption my-1 p-1 font-weight-bold">
    <span>{{ $t('intentDetail.title') }}</span>
    <div id="search-question">
       <button v-b-tooltip.hover
         :title="$t('question.searchQuestions')"
         class="btn btn-success btn-xs d-sm-block"
         @click="showSearchModal">
        <i class="fas fa-search"></i>
       </button>
    </div>
  </div>
  <div>
    <!-- fallback, welcome, feedbackGood, feedbackBadの場合はQuestionは非表示 -->
    <div
      class="usersays section mb-3"
      v-if="questionDisplayCheck(intent)">
      <h5
        class="intent-detail-header pointer-cursor"
        v-b-toggle.question_collapse
        @click="foldedQuestion = !foldedQuestion">
        <span
          class="float-none pl-1"
          v-show="foldedQuestion">
          <i class="fas fa-caret-down"></i>
        </span>
        <span
          class="float-none pl-1"
          v-show="!foldedQuestion">
          <i class="fas fa-caret-up"></i>
        </span>
        {{ $t('question.title') }}
        <span class="float-right">
          <b-btn v-b-tooltip.hover
            :title="$t('question.compareModalTitle')"
            class="btn btn-success btn-xs d-sm-block"
            @click="showCompareIntentModal"
            @click.prevent="foldedQuestion =!foldedQuestion"
            v-b-toggle.question_collapse>
            <i class="fas fa-language"></i>
          </b-btn>
        </span>
      </h5>
      <b-collapse visible id="question_collapse">
        <div class="row action-buttons clear" v-if="userType != 'viewer'">
          <div class="col-auto mr-auto" id="usersays-controllers">
            <button
              class="btn btn-success button-text mr-1"
              v-show="!multipleDeleteSelecting"
              @click="addUserSay"
              v-bind:disabled="!intent || wizard">
              <i class="fa fa-plus"></i>
              <span>{{ $t('buttons.add') }}</span>
            </button>
            <button
              class="btn btn-success button-text mr-1"
              v-show="!multipleDeleteSelecting"
              @click="editUserSay"
              v-bind:disabled="!selectedUserSay || wizard">
              <i class="fa fa-edit"></i>
              <span>{{ $t('buttons.edit') }}</span>
            </button>
            <button
              class="btn btn-success button-text mr-1"
              v-show="!multipleDeleteSelecting"
              @click="deleteUserSayConfirm"
              v-bind:disabled="!selectedUserSay || wizard">
              <i class="fas fa-trash-alt"></i>
              <span>{{ $t('buttons.delete') }}</span>
            </button>
            <button
              class="btn btn-danger button-text mr-1"
              v-show="multipleDeleteSelecting"
              @click="toggleMultiSelect">
              <i class="fas fa-check"></i>
              <span>{{ $t('buttons.cancel') }}</span>
            </button>
            <button
              class="btn btn-success button-text mr-1"
              :class="{'btn-danger': isAllSelected}"
              v-show="multipleDeleteSelecting"
              @click="toggleAllSelect">
              <i class="fas fa-arrows-alt-h"></i>
              <span>{{ isAllSelected ? $t('buttons.clearAll') : $t('buttons.selectAll') }}</span>
            </button>
            <button
              class="btn btn-success button-text mr-1"
              v-show="multipleDeleteSelecting"
              @click="confirmDeleteMultipleUserSay"
              :disabled="!multiSelectedUserSays.length">
              <i class="fas fa-trash-alt"></i>
              <span>{{ $t('buttons.deleteAll') }}</span>
            </button>
            <span id="intent-events-warning" v-if="eventIntentCheck(intent)" class="text-warning">
              <i class="fas fa-exclamation-triangle"></i>
            </span>
            <b-tooltip container="usersays-controllers"
                       v-if="eventIntentCheck(intent)" target="intent-events-warning"
            >{{ $t('question.intentEventsWarning') }}</b-tooltip>
          </div>
          <div class="col-auto" v-if="!!intent">
            <b-navbar-nav>
              <b-nav-item-dropdown class="m-auto" right>
                <template slot="button-content">
                  <i class="fas fa-ellipsis-v dropdown-icon mr-3"></i>
                </template>
                <b-dropdown-item
                  :title="$t('question.deleteMultiQuestionsData')"
                  href="#"
                  @click="toggleMultiSelect"
                  v-bind:disabled="!userSaysFilteredLanguage.length || multipleDeleteSelecting">
                  {{ $t('buttons.multiDelete') }}
                </b-dropdown-item>
              </b-nav-item-dropdown>
            </b-navbar-nav>
          </div>
        </div>
        <div
          ref="userSayFocusOn"
          id="scrollY_UserSay"
          class="section-body scroll2"
          tabindex="-1"
          @keyup.delete="userType=='viewer' && selectedUserSay ? null : deleteUserSayConfirm()">
          <div class="alert alert-dismissable alert-warning"
               v-if="keywordBoundCount > 20">
            <i class="fas fa-exclamation-circle"></i> {{ $t('question.keywordBoundWarning') }}
          </div>
          <draggable v-model="userSaysFilteredLanguage" tag="ul" handle=".handle">
            <li v-for="(userSay, userSayIdx) in userSaysFilteredLanguage" :key="userSayIdx">
              <div
                class="label my-1 p-1 text-break"
                :class="{selected: isUserSaySelected(userSay)}"
                @click="clickIsUserSaySelected(userSay)"
                @dblclick="userType=='viewer' ? null : editUserSay()"
                @keyup.delete="userType=='viewer' ? null : deleteUserSayConfirm()"
                tabindex="-1">
                <span
                  v-for="(word, wordIdx) in userSay.data"
                  :class="{'keywordSelect': word.entitySelected}"
                  :style="{background:word.color}" :key="wordIdx">
                  <span>{{ word.text }}</span>
                </span>
                <div class="float-right">
                  <span v-show="isPromptRequired(userSay)" v-b-tooltip.hover :title="$t('question.slotFilling')">
                    <i class="fas fa-tasks"></i>
                  </span>
                  <span class="handle"><i class="fas fa-arrows-alt"></i></span>
                </div>
                <div style="clear: both"></div>
              </div>
            </li>
          </draggable>
        </div>
      </b-collapse>
    </div>

    <div class="response section">
      <h5
        class="intent-detail-header pointer-cursor"
        v-b-toggle.answer_collapse
        @click = "foldedAnswer = !foldedAnswer">
        <span
          class="float-none pl-1"
          v-show="foldedAnswer">
          <i class="fas fa-caret-down"></i>
        </span>
        <span
          class="float-none pl-1"
          v-show="!foldedAnswer">
          <i class="fas fa-caret-up"></i>
        </span>

        {{ $t('answer.title') }}
      </h5>
      <b-collapse visible id="answer_collapse">
        <div class="action-buttons" v-if="userType != 'viewer'">
          <button
            class="btn btn-success button-text mr-1"
            @click="addResponse"
            :disabled="!intent || (wizard && wizard3)">
            <i class="fa fa-plus"></i>
            <span>{{ $t('buttons.add') }}</span>
          </button>
          <button
            class="btn btn-success button-text mr-1"
            @click="editResponseMessage"
            :disabled="!selectedResponseMessage || (wizard && wizard3)">
            <i class="fa fa-edit"></i>
            <span>{{ $t('buttons.edit') }}</span>
          </button>
          <button
            class="btn btn-success button-text mr-1"
            @click="deleteResponseMessageConfirm"
            :disabled="!selectedResponseMessage || (wizard && wizard3)">
            <i class="fas fa-trash-alt"></i>
            <span>{{ $t('buttons.delete') }}</span>
          </button>
          <button v-b-tooltip.hover
            :title="$t('intentDetail.selectPlatformService')"
            class="btn btn-success button-text float-right mr-2"
            @click="selectPlatformServiceModal">
            <i class="fab fa-product-hunt"></i>
          </button>
        </div>
        <div class="section-body scroll2 " tabindex="-1" ref="responseMessageFocusOn" id="scrollY_ResponseMessage" @keyup.delete="userType!='viewer' && selectedResponseMessage ? deleteResponseMessageConfirm() : null">
          <div class="alert alert-dismissable alert-warning"
               v-for="message in showExceptionAlert.split('<br>')"
               v-if="showExceptionAlert">
            <i class="fas fa-exclamation-circle"></i> {{message}}
          </div>
          <div class="alert alert-dismissable alert-warning"
               v-if="showEmptyResponseExceptionAlert">
            <i class="fas fa-exclamation-circle"></i> {{ $t('answer.emptyAnswerWarning') }}
          </div>
          <div class="alert alert-dismissable alert-warning"
               v-if="showQuickRepliesExceptionAlert">
            <i class="fas fa-exclamation-circle"></i> {{ $t('answer.quickRepliesExceptionWarning') }}
          </div>
          <div class="alert alert-dismissable alert-danger"
               v-if="showLineResponseLimitAlert">
            <i class="fas fa-exclamation-circle"></i> {{ $t('answer.checkNumberOfLineResponseMessage') }}
          </div>
            <draggable v-model="responseMessagesFilteredLanguage" tag="ul" handle=".handle">
                <li v-for="(responseMessage, responseMessageIdx) in responseMessagesFilteredLanguage"
                    :key="responseMessageIdx">
                  <div
                    class="label my-1 p-1"
                    :class="{selected: isResponseMessageSelected(responseMessage)}"
                    @click="clickIsResponseMessageSelected(responseMessage)"
                    @dblclick="userType!='viewer' ? editResponseMessage() : null"
                    @keyup.delete="userType!='viewer' ? deleteResponseMessageConfirm() : null"
                    tabindex="-1">
                    <span v-for="(service, serviceIdx) in responseMessage.activeServices" :key="serviceIdx"
                          v-html="getFaIcon(service)" class="mr-1">
                    </span>
                    <span v-if="responseMessage && responseMessage.data && typeof responseMessage.data.contentCheck === 'function' && !responseMessage.data.contentCheck()" style="color: red"><i class="fas fa-exclamation"></i></span>
                    {{ responseMessage.data.display() }}
                    <div class="float-right">
                      <span> {{(responseMessage && responseMessage.data && typeof responseMessage.data.typeDisplay === 'function') ? responseMessage.data.typeDisplay():null }}</span>
                      <span class="handle"><i class="fas fa-arrows-alt"></i></span>
                    </div>
                    <div style="clear: both"></div>
                  </div>
                </li>
            </draggable>
        </div>
      </b-collapse>
    </div>
  </div>

  <b-modal
    :title="modalTitle"
    ref="questionModal"
    size="lg"
    header-class="bg-info text-light"
    body-class="text-info"
    no-close-on-esc
    no-close-on-backdrop
    @shown="modalFocus"
    @cancel="editUserSayCancel"
    @hidden="modalHidden(true)">
    <div class="word-editor modal-intent-detail">
      <b-card no-body>
        <b-tabs
          small card
          v-model="questionTabIndex"
          :no-fade="true"
          @input="selectTab(questionTabIndex)">
          <b-tab :title=" $t('question.inputQuestion') ">
            {{ $t('question.inputQuestionDescription') }}
            <div>
            </div>
            <div class="input-group mb-1">
              <b-form-textarea
                ref="focusTextArea"
                type="text"
                :rows="3"
                :max-rows="6"
                maxlength="768"
                v-model="modalUserSayText">
              </b-form-textarea >
              <div v-if="browserCheck === true" class="input-group-append">
                <b-button v-if="micOn === false" @click="startSpeech" class="btn btn-primary"><i class="fas fa-microphone"></i></b-button>
                <b-button v-else @click="stopSpeech" class="btn btn-danger"><i class="fas fa-microphone-slash"></i></b-button>
              </div>
            </div>
            <b-form-checkbox
                class="multiple-question-checkbox mt-2"
                v-if="wordEditor == null"
                v-model="isMultipleQuestions">
                {{ $t('question.multipleUserSays') }}
                <div>({{ $t('keywordValue.inputWithLineBreakSeparator') }})</div>
            </b-form-checkbox>
          </b-tab>
          <b-tab
            :disabled="isMultipleQuestions"
            :title=" $t('question.selectKeywordTitle') ">
            <div v-if="wordEditor && wordEditor.keywordCategories">
              <div>
                <span>1. {{ $t('question.selectStringCorrespondingToKeyword') }}:</span>
                <b-btn
                  @click="showSelectModal"
                  v-if="isMobile">
                  <span>{{ $t('buttons.select') }}</span>
                </b-btn>
                <b-card @mousedown="isSelecting=true" @mouseup="selectAllText">
                  <div class="text-area">
                    <div class="over" @click="selectWordText">
                      <span>{{ wordEditor.text }}</span>
                    </div>
                    <div class="under">
                      <span
                        v-for="(word, index) in wordEditor.viewWords"
                        :style="{background:word.color}"
                        :class="{'selected': word.selected, 'entitySelected': word.entitySelected, 'selected-in-list': isSelectedInList(word)}">
                        <span>{{ word.text }}</span>
                      </span>
                    </div>
                    &nbsp;
                  </div>
                </b-card>
              </div>
              <div>
                <span>2. {{ $t('question.selectKeyword') }}:</span>
                <b-card>
                  <span class="mr-1">{{ $t('question.keywordCategory') }}:</span>
                  <b-btn
                    @click="addCategory">
                    <span>{{ $t('buttons.add') }}</span>
                  </b-btn>
                  <model-select
                    v-model="selectKeywordCategory"
                    :options="wordEditor.keywordCategories"
                    :placeholder="$t('question.selectCategory')">
                  </model-select>
                  <span class="mr-1">{{ $t('question.keyword') }}:</span>
                  <b-btn
                    @click="addKeyword"
                    :disabled="!wordEditor.selectedCategory || wordEditor.systemEntity">
                    <span>{{ $t('buttons.add') }}</span>
                  </b-btn>
                  <model-select
                    v-model="selectKeyword"
                    :options="wordEditor.keywords"
                    :placeholder="$t('question.selectKeywordTitle')">
                  </model-select>
                </b-card>
              </div>
              <div>
                <span class="mr-1">3. {{ $t('question.addToCorrespondingList') }}:</span>
                <b-btn
                  @click="setKeywordToWord"
                  class="mr-1"
                  :disabled= "!(selectedWord && wordEditor.selectedKeyword) || isSelectionInvalid">
                  {{ $t('buttons.add') }}
                </b-btn>
                <b-btn
                  @click="deleteWordInList()"
                  :disabled= "!this.wordEditor.selectedWordInList">
                  {{ $t('buttons.delete') }}
                </b-btn>
                <p v-if="isSelectionInvalid" style="color: red; padding: 0; margin: 0;"><small>{{ $t('question.selectionContainsParenthesis') }}</small></p>
                <b-card>
                  <div class="text-center bg-danger text-white p-3" v-if="parameterValidate(keywordWords)">
                    <i class="fas fa-exclamation-circle"></i>
                    {{parameterValidate(keywordWords)}}
                  </div>
                  <div class="table-responsive">
                    <table class="table table-sm">
                      <thead>
                        <tr>
                          <th scope="col" class="border-top-0">{{ $t('webhook.required') }}</th>
                          <th scope="col" class="border-top-0">{{ $t('question.correspondingKeywords') }}</th>
                          <th scope="col" class="border-top-0">{{ $t('question.aliasName') }}</th>
                          <th scope="col" class="border-top-0">{{ $t('question.prompt') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-for="(word, index) in keywordWords">
                          <tr :key="index">
                            <td>
                                <b-form-checkbox v-model="word.promptRequired" />
                            </td>
                            <td>
                              <div :class="{'selected-in-list': isSelectedInList(word)}"
                                  @click="selectWordInList(word)">
                                <span :style="{background:word.color}" class="keyword-word">
                                    {{ word.text }}
                                  </span> &rarr;
                                  <span :style="{background:word.color}" class="keyword-word">{{ word.meta }}</span>
                              </div>
                            </td>
                            <td>
                              <b-form-input v-model="word.alias" maxlength=30 :state="validateAlias(word.alias)" />
                            </td>
                            <td>
                              <b-button v-b-tooltip.hover.left="$t('question.definePrompt')"
                                        :disabled="!word.promptRequired"
                                        @click="openSlotFillingModal(word, index)"><i class="fas fa-terminal"></i></b-button>
                            </td>
                          </tr>
                          <tr v-if="word.promptRequired && word.prompts.length===0">
                            <td colspan="4" class="bg-danger text-center text-white p-2">
                              <i class="fas fa-exclamation-circle"></i>
                              {{$t('question.promptRequired')}}
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </b-card>
              </div>
            </div>
            <div v-show="wordEditor && !wordEditor.keywordCategories">
              {{ $t('question.noKeywordCategories') }}
            </div>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>

    <div slot="modal-footer">
      <b-btn
        @click="selectTab(--questionTabIndex)"
        :class="[{disabled:questionTabIndex<=0}, 'mr-1']"
        :disabled="questionTabIndex==0">
        {{ $t('buttons.previous') }}
      </b-btn>
      <b-btn
        @click="selectTab(++questionTabIndex)"
        :class="[{disabled:questionTabIndex>=1}, 'mr-1']"
        :disabled="(questionTabIndex==1) || isMultipleQuestions">
        {{ $t('buttons.next') }}
      </b-btn>
      <b-btn
        variant="primary"
        @click="questionModalOk"
        class="mr-1"
        :disabled= "!isValidUserSaysText || isValidate(keywordWords)">
        OK
      </b-btn>
      <b-btn @click="cancelQuestionModal">
        {{ $t('buttons.cancel') }}
      </b-btn>
    </div>
  </b-modal>

  <intent-detail-answer
    ref="intentDetailAnswerModal"
    :registeredAnswers="responseMessagesFilteredLanguage"
    :selectedResponseMessage="selectedResponseMessage"
    :supportedLanguages="supportedLanguages"
    :currentLanguage="language"
    :selectedLanguageIntentDetail="selectedLanguageIntentDetail"
    :availablePlatform="availablePlatform"
    :defaultPlatform="defaultPlatform"
    @changed-compared-language="onChangedComparedLanguage"
    @save-response-message="editResponseMessageOk"
    @modal-hidden="modalHidden">
  </intent-detail-answer>

  <delete-confirm-modal
    :title="$t('question.deleteTitle')"
    :bodyMessage="$t('intentDetail.deleteQuestionConfirm', { question: this.selectedUserSay && this.selectedUserSay.text })"
    ref="deleteUserSayConfirmModal"
    @keydown.native.enter="deleteUserSayPressEnter"
    @ok="deleteUserSay"
    @cancel="deleteUserSayCancel"
    @hidden="modalHidden(true)">
  </delete-confirm-modal>

  <delete-confirm-modal
    :title="$t('question.deleteTitle')"
    :bodyMessage="$t('intentDetail.deleteQuestionConfirm', { question: 'Multiple' })"
    ref="multipleDeleteUserSayConfirmModal"
    @keydown.native.enter="deleteMultipleUserSay"
    @ok="deleteMultipleUserSay"
    @cancel="multipleDeleteUserSayCancel"
    @hidden="modalHidden(true)">
  </delete-confirm-modal>

  <delete-confirm-modal
    ref="deleteResponseMessageConfirmModal"
    :title="$t('answer.deleteTitle')"
    :bodyMessage="$t('intentDetail.deleteAnswerConfirm', { answer: this.selectedResponseMessage && this.selectedResponseMessage.data.display() })"
    @keydown.native.enter="$emit('delete-response-message')"
    @ok="$emit('delete-response-message')"
    @cancel="deleteResponseMessageCancel"
    @hidden="modalHidden(false)">
  </delete-confirm-modal>

  <b-modal
    :title="modalTitle"
    ref="selectModal"
    header-class="bg-info text-light"
    body-class="text-info"
    centered
    no-close-on-esc
    no-close-on-backdrop
    @ok="selectTextOk"
    :ok-disabled="!isValidModalSelectedText"
    @cancel="selectTextCancel"
    @shown="selectModalFocusOn">
    <form @submit.prevent="selectTextOk">
      <div>
        {{ $t('question.selectFrom') }}
        <p style="text-align:center;word-break:break-all"><span v-html="modalTextForSelect"></span></p>
      </div>
      <b-form-input
        type="text"
        ref="focusOnThis"
        @input="onInputSelectedText"
        v-model="modalSelectedText"
        :state="modalSelectedText ? isValidModalSelectedText : null">
      </b-form-input>
    </form>
    <div slot="modal-footer">
      <b-btn @click="selectNextText" :disabled="!isValidModalSelectedText">{{ $t('buttons.next') }}</b-btn>
      <b-btn @click="selectTextOk" :disabled="!isValidModalSelectedText">OK</b-btn>
      <b-btn @click="selectTextCancel">{{ $t('buttons.cancel') }}</b-btn>
    </div>
  </b-modal>

  <b-modal
    :title="modalTitle"
    ref="categoryModal"
    header-class="bg-info text-light"
    body-class="text-info"
    @shown="keyowrdCategoryModalFocus">
    <form @submit.prevent="addCategoryOk">
      <div>
        {{ $t('category.parent') }}：
      </div>
      <model-select
        v-model="selectKeywordCategoryAdd"
        :options="wordEditor ? parentKeywordCategoryOptions(wordEditor.keywordCategories) : [itemCategory]"
        placeholder="Select Parent Category">
      </model-select>
      <div>
        {{ $t('category.name') }}： {{ $t('general.keywordCategoryAvailableChars') }}
      </div>
      <b-form-input
        type="text"
        id="modal-keyword-category-name-input"
        v-model="modalCategoryName"
        :state="modalCategoryName ? isValidModalKeywordCategoryName : null">
      </b-form-input>
    </form>
    <modal-footer
      slot="modal-footer"
      @keydown.native.enter="addCategoryOk"
      @ok="addCategoryOk"
      :okDisabled="!isValidModalKeywordCategoryName"
      @cancel="categoryModalCancel">
    </modal-footer>
  </b-modal>

  <b-modal
    :title="modalTitle"
    ref="keywordModal"
    header-class="bg-info text-light"
    body-class="text-info"
    @shown="keyowrdModalFocus"
    no-close-on-esc
    no-close-on-backdrop>
    <form @submit.prevent="addKeywordOk">
      <div>
        {{ $t('general.keywordCategoryAvailableChars') }}：
      </div>
      <b-form-input
        type="text"
        id="modal-keyword-name-input"
        v-model="modalKeywordName"
        :state="modalKeywordName ? isValidModalKeywordName : null">
      </b-form-input>
    </form>
    <modal-footer
      slot="modal-footer"
      @ok="addKeywordOk"
      :okDisabled="!isValidModalKeywordName"
      @cancel="keywordModalCancel"
      @keydown.native.enter="addKeywordOk">
    </modal-footer>
  </b-modal>

  <user-says-search-modal
    ref="searchModal"
    :wordEditor="wordEditor"
    :questionSearchResult="questionSearchResult"
    :apiUrl="this.apiUrl"
    :language="this.language"
    :userType="userType"
    :keywordFullNameById="keywordFullNameById"
    :intentDetailKeywordList="intentDetailKeywordList"
    :intentDetailKeywordListBySearch="intentDetailKeywordListBySearch"
    @load-intent-detail-keyword="loadIntentDetailKeywordById"
    @search-question="searchQuestion"
    @set-keyword-to-word="addKeywordValue"
    @update-searched-question="updateSearchedQuestion"
    @reset-search-result="resetSearchResult"
    @started-linking="OnStartLinking"
    @save-keyword-value="saveKeywordValue"
    @add-category="addCategory"
    @add-keyword="addKeyword">
  </user-says-search-modal>

  <error-modal
    ref="duplicateModal"
    @ok="addKeyword"
    :message="$t('keyword.duplicated')">
  </error-modal>

  <compare-intent-modal
    v-if="userSaysFilteredLanguage"
    ref="compareIntentModal"
    :intentDetail="intentDetail"
    :userSaysFilteredLanguage="userSaysFilteredLanguage"
    :currentLanguage="language"
    :supportedLanguages="supportedLanguages"
    :selectedLanguageIntentDetail="selectedLanguageIntentDetail"
    @cancel="cancelCompareIntentModal"
    @copy-user-says="copyUserSays"
    @changed-compared-language="onChangedComparedLanguage">
  </compare-intent-modal>

  <select-platform-service-modal
    ref="selectPlatformModal"
    :availablePlatform="availablePlatform"
    @select-platform-service="selectPlatformService">
  </select-platform-service-modal>

  <b-modal
    :title="$t('answer.existDefaultTextRelatedGoogle')"
    ref="existGoogleResponseMessageModal"
    header-class="bg-danger text-light"
    body-class="text-danger"
    no-close-on-esc
    no-close-on-backdrop
    ok-only
    centered
    @ok="existGoogleResponseMessageModalClose">
    {{$t('answer.checkDeleteDefaultTextRelatedGoogleMessage')}}
  </b-modal>

  <!-- START Slot Filling Modal-->
  <b-modal
    ref="slot-filling-modal"
    header-bg-variant="info"
    header-text-variant="white"
    :title="slotFillingModalTitle"
    scrollable
    hide-footer>
    <b-form @submit="handleSubmitSlotFillingForm">
    <div
      v-for="(formValue, formIndex) in $v.slotFillingForm.$each.$iter"
      :key="formIndex">
      <b-form-group :label="countQuestions(formIndex)+'.'">
        <b-input-group>
          <b-form-textarea
            v-model.trim="formValue.prompt.$model"
            :placeholder="$t('validation.enterQuestion')"
            :state="!formValue.prompt.$error"
            rows="3"
            required>
          </b-form-textarea>
          <b-input-group-append>
            <b-button
              variant="danger"
              :disabled="isDisabledSlotFillingFormDelBtn"
              @click="removeSlotFillingInput(formIndex)">
              <i class="fas fa-minus"></i>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </div>

    <div slot="modal-footer" class="w-100">
      <b-button
        variant="success"
        :disabled="isDisabledSlotFillingFormAddBtn"
        @click="addSlotFillingInput">
        <i class="fas fa-plus"></i>
      </b-button>
      <b-button
        variant="info"
        class="float-right"
        type="submit"
        :disabled="$v.slotFillingForm.$error">
        OK
      </b-button>
    </div>
    </b-form>
  </b-modal>
  <!-- /.END Slot Filling Modal-->
</div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'
import {Word, UserSay, IntentDetailKeyword, TargetService} from '../model/intent'
import { WordsEditor } from '../controller/word-editor'
import { KeywordCategory, Keyword, KeywordValue } from '../model/keyword'
import CategoryItem from './CategoryItem.vue'
import KeywordItem from './KeywordItem.vue'
import {I18n} from "../util/i18n"
import draggable from 'vuedraggable'
import { ModelSelect } from 'vue-search-select'
import { illegalExp } from '../util/illegalRegex'
import { ResponseType } from '../resource/responses'
import PlatformDataResource from '../resource/platforms'
import { cloneDeep } from 'lodash'

export default {
  props: [
    'categories',
    'intent',
    'intentDetail',
    'language',
    'apiUrl',
    'selectedUserSay',
    'selectedResponseMessage',
    'keywords',
    'selectedKeywordValue',
    'selectedKeyword',
    'selectedCategory',
    'keywordCategoryModel',
    'questionSearchResult',
    'userType',
    'compareModalContainer',
    'supportedLanguages',
    'selectedLanguageIntentDetail',
    'intentDetailKeywordList',
    'keywordFullNameById',
    'intentDetailKeywordListBySearch',
    'wizard',
    'wizard3',
    'availablePlatform',
    'defaultPlatform',
    'keywordBoundCount'
  ],
  data() {
    return {
      i18n: new I18n(window.i18nContext),
      modalTitle: '',
      modalUserSayText: '',
      modalUserSay: null,
      modalResponseMessageText: '',
      modalResponseMessage: null,
      questionTabIndex: 0,
      wordEditor: null,
      modalCategoryName: '',
      modalParentCategoryId: null,
      modalParentCategories: [],
      modalKeyword: null,
      modalKeywordName: '',
      modalKeywordCategory: null,
      modalKeywordCategoryId: null,
      modalKeywordValue:null,
      hidden: true,
      selectedWord:null,
      modalSelectedText:null,
      modalTextForSelect:null,
      modalSelectedTextIndex:0,
      keywordValues:[],
      isEntityAdd:false,
      keywordCategorySelect:false,
      keywordSelect:false,
      tempIndex:0,
      changeIndexFlag:false,
      arrowKeyFlag:0,
      originalModalUserSayText: null,
      isNewKeywordCategory:false,
      isNewKeyword:false,
      isMultipleQuestions: false,
      isSelecting:false,
      itemCategory: {value:'', text:''},
      foldedQuestion:false,
      foldedAnswer:false,
      isModalShown:false,
      isModalOK:false,
      scrollY_UserSay:0,          //縦のスクロールバー位置（質問用）
      scrollY_ResponseMessage:0,  //縦のスクロールバー位置（回答用）
      scrollY_Calc_Num:37,         //矢印でアイテム選択中、アイテムがスクロールで隠れないようにスクロール速度調整のための数値（計算用）
      browserCheck : true, //chrome以外のbrowserはマイク表示しない
      SpeechRecognition : window.webkitSpeechRecognition, //browserはchromeのみ対応
      recognition : null,
      micOn: false,
      timeoutID : null,
      speechResultList : [],
      multipleDeleteSelecting: false,
      tempSelectedUserSay: null,
      multiSelectedUserSays: [],
      icons: null,
      slotFillingModalTitle: '',
      slotFillingForm: [],
      wordModel: new Word(),
      currentPromptWordEditorIndex: 0
    }
  },
  validations: {
    slotFillingForm: {
      $each: {
        prompt: {
          required,
          minLength: minLength(1)
        }
      }
    }
  },
  computed: {
    allKeywordWords(){
      let all_keyword_words = {}
      if(this.intentDetail) {
        this.intentDetail.data.userSays.forEach(userSay => {
          userSay.data.forEach(word => {
            if (word.meta) {
              all_keyword_words[word.alias] = word.meta
            }
          })
        })
      }
      return all_keyword_words
    },
    keywordWords() {
      if (!this.wordEditor) {
        return []
      }
      return this.wordEditor.keywordWords()
    },
    userSaysFilteredLanguage: {
      get: function() {
        if (!this.intentDetail) {
          return []
        }
        return this.intentDetail.data.userSays.filter(userSay => userSay.language==this.language)
      },
      set: function(value) {
          if (this.userType !== 'viewer') {
              this.$emit('save-all-user-says', value)
          }
      }
    },
    responseMessagesFilteredLanguage: {
      get:function(){
        if (!this.intentDetail) {
          return []
        }
        return this.intentDetail.data.responseMessages.filter(responseMessage => {
          // fix bug: MOBOT-872
          if(parseInt(responseMessage.type) === parseInt(ResponseType.CUSTOM_PAYLOAD)) {
            if(responseMessage.targetServices.length > 1) {
              const selectTargetService = responseMessage.targetServices.shift()
              responseMessage.targetServices = []
              responseMessage.targetServices.push(selectTargetService)
            }
          }
          return responseMessage.language === this.language
        })
      },
      set:function(value){
        this.$emit('save-all-response-message', value)
      }
    },
    isPromptRequired(){
      return ((userSay) => {
        let flag = false
        for(let word of userSay.data) {
          if (word.promptRequired){
            flag = true
            break
          }
        }
        return flag
      })
    },
    isValidModalKeywordCategoryName() {
      return this.isValidKeywordCategoryName()
    },
    isValidModalKeywordName() {
      return this.isValidKeywordName()
    },
    isValidUserSaysText() {
      return this.modalUserSayText.trim().match(/[\S]/) != null
    },
    isValidModalSelectedText(){
      if(this.wordEditor && this.modalSelectedText){
        if(this.wordEditor.text.indexOf(this.modalSelectedText) >= 0){
          return true
        }
      }
      return false
    },
    isMobile() {
      // デバイスがスマホであるかどうかの簡単なチェック、スマホじゃない場合は選択ボタンを示さない
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    showExceptionAlert() {
      let count_card = 0, count_url = 0, response = ''
      const buttons = []
      this.responseMessagesFilteredLanguage.map(answer => {
        if (parseInt(ResponseType.CARD) === parseInt(answer.type)) {
          if (answer.data.url) {
            count_url++ // total url found in response card
          }
          count_card++ // total response card
          buttons.push(answer.data.cardbuttons.length) // get total buttons in each card
        }
      })
      if (count_card > 0 && count_url > 0 && count_card !== count_url) {
        response = this.i18n.t('answer.numberOfImageURLInCard')
      }
      const hasEqualButtons = buttons.every((value, index, array) => {
        return value === array[0]
      })
      if (!hasEqualButtons) {
        if (response) {
          response += '<br>'
        }
        response += this.i18n.t('answer.numberOfButtonInCard')
      }
      return response
    },
    showEmptyResponseExceptionAlert(){
      if(this.intent && this.userSaysFilteredLanguage.length > 0 && !this.intent.welcomeIntent && !this.intent.fallbackIntent
          && !this.intent.enableWebhook && !this.intent.isFeedbackResponse){
        return this.responseMessagesFilteredLanguage.length === 0
      }
      return false
    },
    isAllSelected() {
      return !this.userSaysFilteredLanguage.some(userSay =>
        this.multiSelectedUserSays.findIndex(msi => userSay.id === msi.id) <= -1)
    },
    isSelectionInvalid() {
      // キーワードカテゴリが選択されてない、あるいはSystem_Entityである場合、チェックを行わない
      if (!(!!this.wordEditor.itemCategory && (['@System_Entity', '---'].indexOf(this.wordEditor.itemCategory.text) >= 0 || !this.wordEditor.itemCategory.text))) {
        // 括弧が入ると、選択内容は無効とみなす
        if (!!this.selectedWord && !!this.selectedWord.text && (/[()]+/g).test(this.selectedWord.text)) {
          return true
        }
      }
      return false
    },
    showQuickRepliesExceptionAlert() {
      let state = false, fb_counter = 0, ln_counter = 0
      const responseMessages = this.responseMessagesFilteredLanguage
      const platformResource = new PlatformDataResource()
      if(responseMessages) {
        const quickRepliesExist = responseMessages.filter(responseMessage => {
          return parseInt(responseMessage.type) === parseInt(ResponseType.QUICK_REPLIES)
        })[0]
        responseMessages.map(responseMessage => {
          if(responseMessage.targetServices.includes(platformResource.TargetService.LINE)) {
            ln_counter++
          }
          if(responseMessage.targetServices.includes(platformResource.TargetService.FACEBOOK)) {
            fb_counter++
          }
          if(quickRepliesExist) {
            let fb_state = false
            if(fb_counter > 1) {
              const facebookResponse = responseMessages.filter(response => {
                return response.targetServices.includes(platformResource.TargetService.FACEBOOK)
              })
              const facebookQuickRepliesExist = facebookResponse.filter(facebookMessage => {
                return parseInt(facebookMessage.type) === parseInt(ResponseType.QUICK_REPLIES)
              })[0]
              if(facebookQuickRepliesExist) {
                const lastFacebookResponse = facebookResponse.slice(-1)[0]
                fb_state = !(parseInt(lastFacebookResponse.type) === parseInt(ResponseType.QUICK_REPLIES))
              }
            }
            let ln_state = false
            if(ln_counter > 1) {
              const lineResponse = responseMessages.filter(response => {
                return response.targetServices.includes(platformResource.TargetService.LINE)
              })
              const lineQuickRepliesExist = lineResponse.filter(lineMessage => {
                return parseInt(lineMessage.type) === parseInt(ResponseType.QUICK_REPLIES)
              })[0]
              if(lineQuickRepliesExist) {
                const lastLineResponse = lineResponse.slice(-1)[0]
                ln_state = !(parseInt(lastLineResponse.type) === parseInt(ResponseType.QUICK_REPLIES))
              }
            }
            state = (fb_state || ln_state)
          }
        })
      }
      return state
    },
    isDisabledSlotFillingFormDelBtn() {
      const slotFillingPrompts = this.wordModel.prompts.filter(model => {
        return !!model.prompt
      })
      const lastPromptExist = (slotFillingPrompts.length <= 0)
      const listPrompts = (parseInt(this.slotFillingForm.length) === 1)
      return lastPromptExist && listPrompts
    },
    isDisabledSlotFillingFormAddBtn() {
      return parseInt(this.slotFillingForm.length) >= 30
    },
    showLineResponseLimitAlert() {
      return this.getLineResponseLimitErrorStatus()
    },
    selectKeywordCategoryAdd: {
      get: function() {
        return this.itemCategory;
      },
      set: function(category) {
        this.itemCategory = category;
        if(category){
          this.modalParentCategoryId = category.value;
        }
      }
    },
    selectKeywordCategory: {
      get: function() {
        return this.wordEditor?.itemCategory;
      },
      set: function(category) {
        if( category.value === 'System_Entity' ){
          this.wordEditor.systemEntity = true;
        }else{
          this.wordEditor.systemEntity = false;
        }
        this.wordEditor.itemCategory = category;
        if(category){
          category = category.value;
        }
        this.wordEditor.selectKeyword(null);
        this.wordEditor.selectKeywordCategory(category);
      }
    },
    selectKeyword: {
      get: function() {
        return this.wordEditor?.itemKeyword;
      },
      set: function(keyword) {
        this.wordEditor.itemKeyword = keyword;
        if(keyword){
          keyword=keyword.value;
          if(this.wordEditor.systemEntity){
            keyword = keyword.name;
          }
        }
        this.wordEditor.selectKeyword(keyword);
      }
    },
  },
  components: {
    CategoryItem, KeywordItem,draggable, ModelSelect
  },
  created() {
    this.recognition = this.SpeechRecognition? new webkitSpeechRecognition() : false
    if (!this.recognition) {
      this.browserCheck = false
        this.error = 'Speech Recognition is not available on this browser. Please use Chrome browser.'
    }
    else{
      this.browserCheck = true
      this.speechRecognize();
    }

    const platformResource = new PlatformDataResource();
    this.icons = platformResource.PlatformIcons
  },
  methods: {
    toggleMultiSelect(){
      this.multipleDeleteSelecting = !this.multipleDeleteSelecting
      if(this.multipleDeleteSelecting) {
        this.startMultiSelectUserSay()
      } else {
        this.cancelMultiSelectUserSay()
      }
    },
    startMultiSelectUserSay(){
      this.tempSelectedUserSay = this.selectedUserSay
      this.$emit('select-user-say', null)
    },
    cancelMultiSelectUserSay(){
      this.$emit('select-user-say', this.tempSelectedUserSay)
      this.tempSelectedUserSay = null
      this.multiSelectedUserSays = []
    },
    toggleAllSelect(){
      const allSelected = this.isAllSelected
      this.userSaysFilteredLanguage.forEach(it => {
        const userSayIndex = this.multiSelectedUserSays.findIndex(msi => msi.id === it.id)
        if (allSelected && userSayIndex > -1) {
          this.multiSelectedUserSays.splice(userSayIndex, 1)
        }
        else if (!allSelected && userSayIndex <= -1) {
          this.multiSelectedUserSays.push(it)
        }
      })
    },
    confirmDeleteMultipleUserSay(){
      this.$emit('load-intent-keyword-dep-list')
      this.$emit('create-keyword-name')
      if (this.multiSelectedUserSays.length <= 0) {
        return
      }
      this.isModalShown = true
      this.$emit('modalPopupCheck-flag',this.isModalShown)
      this.$refs.multipleDeleteUserSayConfirmModal.show()
    },
    deleteMultipleUserSay(){
      if(this.isModalOK)
        return null
      this.isModalOK = true
      this.isModalShown = false
      this.$emit('modalPopupCheck-flag',this.isModalShown)
      this.$emit('delete-multiple-user-say', this.multiSelectedUserSays)
      this.multiSelectedUserSays = []
      this.multipleDeleteSelecting = false
    },
    multipleDeleteUserSayCancel(){
      this.$refs.multipleDeleteUserSayConfirmModal.hide()
    },
    speechRecognize() {
      this.recognition.onstart = () => {
        this.micOn = true
      };
      this.recognition.onend = () => {
        this.micOn = false
      };
      this.recognition.onerror = event => {
       console.log(event.error)
      };
      this.recognition.onresult = event => {
        for(let i = 0; i < event.results.length; i++){
          if (event.results[i].isFinal){
            let resultText = this.speechResultList.indexOf(event.results[i][0].transcript)
            if(resultText < 0){
              this.speechResultList.push(event.results[i][0].transcript)
              let transcript = event.results[i][0].transcript;
              transcript = transcript + "\n";
              this.modalUserSayText += transcript
            }
            window.clearTimeout(this.timeoutID)
            this.timeoutID = window.setTimeout(this.stopSpeech, 5000)
          }else{
            if(this.timeoutID !== null){
              window.clearTimeout(this.timeoutID)
              this.timeoutID = null
            }
          }
        }
      };
    },
    startSpeech() {
      this.speechResultList = []
      this.recognition.lang = this.language;
      this.recognition.interimResults = true;
      this.recognition.continuous = true;
      this.recognition.start();
    },
    stopSpeech(){
      this.recognition.stop();
    },
    isValidate(keywordWords){
      if(keywordWords.length > 0){
        let all_keyword_words = cloneDeep(this.allKeywordWords)
        let flag = false
        for(let word of keywordWords){
          const hasTrailingSpace = word.alias.length - word.alias.trim().length
          if(hasTrailingSpace) {
            return true
          }
          if(word.alias in all_keyword_words && all_keyword_words[word.alias] !== word.meta){
            return true
          }
          else{
            all_keyword_words[word.alias] = word.meta
          }
          if(!this.validateAlias(word.alias) || (word.promptRequired && word.prompts.length===0)){
            flag = true
          }
        }
        return flag
      }
      return false
    },
    validateAlias(alias){
      return alias.trim().match(/^[a-zA-Z][a-zA-Z0-9-_]*[a-zA-Z0-9]$/) != null
    },
    parameterValidate(keywordWords){
      if(keywordWords.length > 0){
        let all_keyword_words = cloneDeep(this.allKeywordWords)
        for(let word of keywordWords){
          const hasTrailingSpace = /\s/g.test(word.alias)
          if(hasTrailingSpace) {
            return this.$t('question.spaceAliasAlert')
          }
          if(word.alias in all_keyword_words && all_keyword_words[word.alias] !== word.meta){
            return this.$t('question.uniqueAlias')
          }
          else{
            all_keyword_words[word.alias] = word.meta
          }
        }
      }
      return false
    },
    loadIntentDetailKeywordById(id){
      this.$emit('load-intent-detail-keyword', id)
    },
    questionDisplayCheck(intent){
      if(intent === null){
        //intentが未選択の場合、詳細の枠だけは表示しておきたいのでtrueを返す
        return true
      } else {
        //選択したintentがfallback、welcome、高評価時のインテント、低評価時のインテントの場合、回答だけ見せたい
        if (intent.fallbackIntent || intent.welcomeIntent || intent.isFeedbackResponse) {
            return false
        } else if (this.eventIntentCheck(intent)) {
            return this.intentDetail && this.intentDetail.data.userSays && this.intentDetail.data.userSays.length > 0
        } else {
            return true
        }
      }
    },
    eventIntentCheck(intent) {
      return intent && intent.events && intent.events.length > 0
    },
    clickIsUserSaySelected(userSay){
      for(let i = 0; i < this.userSaysFilteredLanguage.length; i++){
        if(this.userSaysFilteredLanguage[i].id == userSay.id){
          this.scrollY_UserSay = i*this.scrollY_Calc_Num
        }
      }
      this.changeIndexFlag = true
      this.arrowKeyFlag = 3
      this.isModalShown = false
      this.$emit('modalPopupCheck-flag',this.isModalShown)
      this.$emit('arrowKey-flag',this.arrowKeyFlag)
      if(this.multipleDeleteSelecting){
        const index = this.multiSelectedUserSays.findIndex(msi => msi.id === userSay.id)
        if (index > -1) {
          this.multiSelectedUserSays.splice(index, 1)
        } else {
          this.multiSelectedUserSays.push(userSay)
        }
        return
      }
      this.$emit('select-user-say', userSay)
    },
    clickIsResponseMessageSelected(responseMessage){
      for(let i = 0; i < this.responseMessagesFilteredLanguage.length; i++){
        if(this.responseMessagesFilteredLanguage[i].id == responseMessage.id){
          this.scrollY_ResponseMessage = i*this.scrollY_Calc_Num
        }
      }
      this.changeIndexFlag = true
      this.arrowKeyFlag = 4
      this.isModalShown = false
      this.$emit('modalPopupCheck-flag',this.isModalShown)
      this.$emit('arrowKey-flag',this.arrowKeyFlag)
      this.$emit('select-response-message', responseMessage)
    },
    onArrowUpUserSay(userSay){
      for(let i = 0; i < this.userSaysFilteredLanguage.length; i++){
        if(this.userSaysFilteredLanguage[i].id == userSay.id){
          if(this.changeIndexFlag == true){
            this.tempIndex = i -1
            this.changeIndexFlag = false
            if(this.tempIndex < 0){return}
          }
          else{
            if(this.tempIndex <= 0){
              return
            }else{
              this.$refs.userSayFocusOn.focus()
              this.tempIndex = this.tempIndex -1
            }
          }
        }
      }
      this.scrollY_UserSay = this.scrollY_UserSay - this.scrollY_Calc_Num
      document.getElementById("scrollY_UserSay").scrollTop = this.scrollY_UserSay
      userSay = this.userSaysFilteredLanguage[this.tempIndex]
      this.$emit('select-user-say', userSay)
    },
    onArrowDownUserSay(userSay){
      for(let i = 0; i < this.userSaysFilteredLanguage.length; i++){
        if(this.userSaysFilteredLanguage[i].id == userSay.id){
          if(this.changeIndexFlag == true){
            this.tempIndex = i +1
            this.changeIndexFlag = false
            if(this.tempIndex > this.userSaysFilteredLanguage.length-1){
              return
            }
          }
          else{
            if(this.tempIndex >= this.userSaysFilteredLanguage.length-1){
              if(this.responseMessagesFilteredLanguage[0] != null){
                this.arrowKeyFlag = 4
                this.$emit('arrowKey-flag',this.arrowKeyFlag)
                this.$emit('select-response-message', this.responseMessagesFilteredLanguage[0])
                this.tempIndex = 0
                return
              }
              else{
                return
              }
            }else{
              this.$refs.userSayFocusOn.focus()
              this.tempIndex = this.tempIndex+1
            }
          }
        }
      }
      document.getElementById("scrollY_UserSay").scrollTop = this.scrollY_UserSay
      this.scrollY_UserSay = this.scrollY_UserSay + this.scrollY_Calc_Num
      userSay = this.userSaysFilteredLanguage[this.tempIndex]
      this.$emit('select-user-say', userSay)
    },
    onArrowRightUserSay(userSay){
      this.tempIndex = 0
      this.scrollY_UserSay = 0
      this.$emit('select-user-say', userSay)
      this.$refs.userSayFocusOn.focus()
    },
    onArrowUpResponseMessage(responseMessage){
      for(let i = 0; i < this.responseMessagesFilteredLanguage.length; i++){
        if(this.responseMessagesFilteredLanguage[i].id == responseMessage.id){
          if(this.changeIndexFlag == true){
            this.tempIndex = i -1
            this.changeIndexFlag = false
            if(this.tempIndex < 0){return}
          }
          else{
            if(this.tempIndex <= 0){
              if(this.userSaysFilteredLanguage[0] != null){
                this.arrowKeyFlag = 3
                this.$emit('arrowKey-flag',this.arrowKeyFlag)
                this.$emit('select-user-say', this.userSaysFilteredLanguage[this.userSaysFilteredLanguage.length-1])
                this.tempIndex = this.userSaysFilteredLanguage.length-1
                return
              }
              else{
                return
              }
            }else{
              this.tempIndex = this.tempIndex -1
            }
          }
        }
      }
      this.scrollY_ResponseMessage = this.scrollY_ResponseMessage - this.scrollY_Calc_Num
      document.getElementById("scrollY_ResponseMessage").scrollTop = this.scrollY_ResponseMessage
      responseMessage = this.responseMessagesFilteredLanguage[this.tempIndex]
      this.$emit('select-response-message', responseMessage)
    },
    onArrowDownResponseMessage(responseMessage){
      for(let i = 0; i < this.responseMessagesFilteredLanguage.length; i++){
        if(this.responseMessagesFilteredLanguage[i].id == responseMessage.id){
          if(this.changeIndexFlag == true){
            this.tempIndex = i +1
            this.changeIndexFlag = false
            if(this.tempIndex > this.responseMessagesFilteredLanguage.length-1){return}
          }
          else{
            if(this.tempIndex >= this.responseMessagesFilteredLanguage.length-1){
              return
            }else{
              this.$refs.responseMessageFocusOn.focus()
              this.tempIndex = this.tempIndex+1
            }
          }
        }
      }
      document.getElementById("scrollY_ResponseMessage").scrollTop = this.scrollY_ResponseMessage
      this.scrollY_ResponseMessage = this.scrollY_ResponseMessage + this.scrollY_Calc_Num
      responseMessage = this.responseMessagesFilteredLanguage[this.tempIndex]
      this.$emit('select-response-message', responseMessage)
    },
    isValidKeywordCategoryName() {
      return this.modalCategoryName.trim().match(/^[a-zA-Z]+[a-zA-Z0-9-]*$/) != null
    },
    isValidKeywordName() {
      return this.modalKeywordName.trim().match(/^[a-zA-Z0-9-]+[a-zA-Z0-9]$/) != null
    },
    isKeywordDuplicate(name, cId){
      let duplicate=false
      let keywords = this.wordEditor.allKeywords.filter(allKeywords=>allKeywords.categoryId==cId)
      for (let keyword of keywords) {
        if (keyword.name == name) {
          duplicate=true
          break
        }
      }
      return duplicate
    },
    show() {
      // Select new added keyword category in selection box
      if(this.isNewKeywordCategory){
        this.wordEditor.itemCategory = {value:this.wordEditor.selectedCategory, text:this.wordEditor.selectedCategory.name}
        this.isNewKeywordCategory = false
      }
      //Select new added keyword in selection box
      if(this.isNewKeyword){
        this.wordEditor.itemKeyword = {value:this.wordEditor.selectedKeyword, text:this.wordEditor.selectedKeyword.name}
        this.isNewKeyword = false
      }
      // 更新
      // wordEditor.queryFromWhichModalによる、違う振る舞いをする
      if(this.wordEditor.queryFromWhichModal === "question"){
        this.$refs.questionModal.show()
      }
      else if(this.wordEditor.queryFromWhichModal === "search"){
        this.$refs.searchModal.display()
      }
      this.wordEditor.loadAllKeywords()
    },
    addUserSay() {
      // start for intent and keyword dependency
      this.$emit('load-intent-keyword-dep-list')
      this.$emit('create-keyword-name')
      // end
      this.isModalShown = true
      this.$emit('modalPopupCheck-flag',this.isModalShown)
      this.itemCategory= {value:'', text:''}
      this.modalTitle = this.i18n.t('question.add')
      this.modalUserSay = new UserSay()
      this.modalUserSayText = ''
      this.questionTabIndex = 0
      this.wordEditor = null
      this.isMultipleQuestions = false
      this.$refs.questionModal.show()
    },
    editUserSay() {
      // start for intent and keyword dependency
      this.$emit('load-intent-keyword-dep-list')
      this.$emit('create-keyword-name')
      // end
      this.isModalShown = true
      this.$emit('modalPopupCheck-flag',this.isModalShown)
      this.itemCategory= {value:'', text:''}
      this.modalTitle = this.i18n.t('question.editTitle')
      this.modalUserSay = this.selectedUserSay
      this.modalUserSayText = this.modalUserSay.text
      this.originalModalUserSayText = this.modalUserSay.text
      this.questionTabIndex = 1
      this.selectTab(1)
      this.wordEditor.viewWords = this.modalUserSay.data
      this.wordEditor.words = this.modalUserSay.data
      this.isMultipleQuestions = false
      this.$refs.questionModal.show()
    },
    deleteUserSayConfirm() {
      this.$emit('load-intent-keyword-dep-list')
      this.$emit('create-keyword-name')
      if (this.selectedUserSay==null) {
        return
      }
      this.isModalShown = true
      this.$emit('modalPopupCheck-flag',this.isModalShown)
      this.$refs.deleteUserSayConfirmModal.show()
    },
    deleteUserSayPressEnter(){
      this.deleteUserSay()
    },
    deleteUserSay(){
      if(this.isModalOK)
        return null
      this.isModalOK = true
      this.isModalShown = false
      this.$emit('modalPopupCheck-flag',this.isModalShown)
      this.$emit('delete-user-say')
    },
    questionModalOk() {
      if (this.isMultipleQuestions) {
        this.isMultipleQuestions = false
        this.modalUserSayText = this.modalUserSayText.replace(illegalExp, "")
        this.$emit('save-multiple-user-says', this.modalUserSayText.trim())
        this.$refs.questionModal.hide()
        return
      }
      this.modalUserSayText = this.modalUserSayText.replace(illegalExp, "")
      if (this.wordEditor) {
        // 編集時、または新規作成でキーワード紐付けをするタブに遷移した場合
        if (this.originalModalUserSayText != this.modalUserSayText) {
          // textのみ変更された場合
          this.modalUserSay.data = [new Word(this.modalUserSayText.trim())]
        } else {
          this.modalUserSay.data = this.wordEditor.words
        }
      } else {
        // 新規作成かつtextのみ提出時
        this.modalUserSay.data = [new Word(this.modalUserSayText.trim())]
      }

      if (this.isEntityAdd) {
        if(!this.wordEditor.systemEntity){
          for (let data of this.keywordValues){
            this.modalKeywordValue = new KeywordValue()
            this.modalKeywordValue.value = data.value
            this.modalKeywordValue.language = this.language
            this.modalKeywordValue.keywordId = data.id
            this.modalKeywordValue.setSynonymFromText(data.value)
            this.$emit('save-keyword-value',this.modalKeywordValue)
          }
        }
      }
      this.isModalShown = false
      this.$emit('modalPopupCheck-flag',this.isModalShown)
      this.keywordValues=[]
      this.isEntityAdd=false
      this.$emit('save-user-say', this.modalUserSay)
      this.wordEditor = null
      this.modalUserSayText = ''
      this.$refs.questionModal.hide()
    },
    cancelQuestionModal(){
      this.$refs.questionModal.hide()
      this.$emit('cancel-question-modal')
    },
    updateSearchedQuestion(intentDetails) {
      this.$emit('update-searched-question', intentDetails)
    },
    addResponse() {
      this.isModalShown = true
      this.$emit('modalPopupCheck-flag',this.isModalShown)
      this.$refs.intentDetailAnswerModal.addResponse()
    },
    editResponseMessage() {
      this.isModalShown = true
      this.$emit('modalPopupCheck-flag',this.isModalShown)
      this.$refs.intentDetailAnswerModal.editResponseMessage(this.selectedResponseMessage)
    },
    deleteResponseMessageConfirm() {
      if (this.selectedResponseMessage==null) {
        return
      }
      // google serviceがチェックされたtext responseを削除する時、他のgoogle系レスポンスがあるかどうかチェック
      // google serviceがチェックされたtext responseが2個以上の場合、1個が残るまでは普通に削除可能
      if(this.selectedResponseMessage.type === ResponseType.TEXT && this.selectedResponseMessage.isTargetGoogle === true){
        let textNum = this.responseMessagesFilteredLanguage.filter(text => text.type === ResponseType.TEXT && text.isTargetGoogle === true)
        if(textNum.length === 1){
          let existGoogleResponse = this.responseMessagesFilteredLanguage.some(answer=> answer.type !== ResponseType.TEXT && answer.isTargetGoogle === true)
          if(existGoogleResponse){
            this.$refs.existGoogleResponseMessageModal.show()
            return
          }
        }
      }
      this.isModalShown = true
      this.$emit('modalPopupCheck-flag',this.isModalShown)
      this.$refs.deleteResponseMessageConfirmModal.show()
    },
    existGoogleResponseMessageModalClose(){
      this.$refs.deleteResponseMessageConfirmModal.hide()
      this.$refs.existGoogleResponseMessageModal.hide()
    },
    editResponseMessageOk(responseMessage) {
      this.$emit('save-response-message', responseMessage)
    },
    selectTab(tabIndex) {
      if (tabIndex == 1){
        this.originalModalUserSayText = this.modalUserSayText
        if (this.wordEditor) {
          let displayedUserSays = this.wordEditor.words.map(value => {
            return value.text
          }).join('')
          if (this.modalUserSayText == displayedUserSays) {
          /**
           * ユーザーがuserSaysの値を変更した場合のみ、wordsEditorを再生成。
           * 紐付けられたキーワードはリセットされる。
           */
            return
          }
        }

        this.wordEditor = new WordsEditor(
          this.apiUrl,
          this.language,
          [new Word(this.modalUserSayText)]
        )
        this.wordEditor.ready()
        this.wordEditor.queryFromWhichModal = "question"
        this.selectedWord = null
      }
    },
    selectWordText() {
      // 選択範囲の位置
      const Selection = window.getSelection()
      let startAt = Selection.anchorOffset
      let endAt = Selection.focusOffset
      // トリプルクリックする、または文字列から外側（特に上）の空白にドラッグと、文字列は全てドラッグになるが、
      // anchorOffsetとfocusOffsetの値で選択した文字列を特定できないことがあるので、anchorOffsetとfocusOffsetを使わずに手動で全選択する
      if(Selection && Selection.toString().length >= this.wordEditor.text.length){
        // 全選択する時は、大体div#underも一緒に選択されてるので、長さは常にwordEditor.textより大きい("==="ではうまくいかない)
        this.selectAllText()
        return false
      }
      if (startAt == endAt) {
        return
      }

      if(startAt > endAt){
        startAt = startAt ^ endAt
        endAt = startAt ^ endAt
        startAt = startAt ^ endAt
      }

      // 指定位置で分割して表示を更新
      this.selectedWord = this.wordEditor.split(startAt, endAt)
      if (this.selectedWord != null) {
        this.selectedWord.selected = true
      }
      this.isSelecting = false
    },
    selectAllText() {
      // text-areaの外にドラッグしてしまうと、clickイベントが発生しないので、b-cardのmouseupイベントで全選択する
      if(this.isSelecting){
        let selection = window.getSelection()
        if(selection.toString().length >= this.wordEditor.text.length){
          let startAt = 0
          let endAt = this.wordEditor.text.length
          this.selectedWord = this.wordEditor.split(startAt, endAt)
          if (this.selectedWord != null) {
            this.selectedWord.selected = true
          }
        }
        this.isSelecting = false
      }
    },
    setKeywordToWord() {
      this.isEntityAdd=true
      if (!this.wordEditor.systemEntity) {
        this.keywordValues.push({id:this.wordEditor.selectedKeyword.id, value:this.selectedWord.text})
      }
      this.wordEditor.setKeywordToWord(this.wordEditor.systemEntity, this.wordEditor.itemKeyword.text)
      this.selectedWord = null
      if(this.intent.enableWebhookForSlotFilling) {
        const words = this.wordEditor.words
        if(words.length > 0) {
          words.map(word => {
            if (word.alias) {
              word.promptRequired = true
            }
          })
        }
      }
    },
    addKeywordValue(text) {
      this.keywordValues.push({id:this.wordEditor.selectedKeyword.id, value:text})
    },
    selectWordInList(word) {
      this.wordEditor.selectWordInList(word)
    },
    isSelectedInList(word) {
      if (!this.wordEditor.selectedWordInList) {
        return false
      }
      return this.wordEditor.selectedWordInList.alias == word.alias
    },
    deleteWordInList() {
      if(this.wordEditor.selectedWordInList){
        this.wordEditor.selectedWordInList.alias = null
        this.wordEditor.selectedWordInList.meta = null
        this.wordEditor.selectedWordInList.entitySelected = false
        this.wordEditor.selectedWordInList.color = null
      }
      for(let i = 1; i < this.wordEditor.words.length; i++) {
        if(!this.wordEditor.words[i-1].alias && !this.wordEditor.words[i].alias){
          this.wordEditor.words[i-1].text += this.wordEditor.words[i].text
          this.wordEditor.words.splice(i, 1);
        }
      }
      this.wordEditor.selectedWordInList = null
    },
    isUserSaySelected(userSay) {
      if(this.multipleDeleteSelecting){
        return this.multiSelectedUserSays.findIndex(msi => msi.id === userSay.id) > -1
      }
      return this.selectedUserSay && (this.selectedUserSay.id == userSay.id)
    },
    isResponseMessageSelected(responseMessage) {
      return this.selectedResponseMessage && (this.selectedResponseMessage.id == responseMessage.id)
    },
    addCategory() {
      this.itemCategory = {value: '', text: ''}
      this.modalTitle = this.i18n.t('category.add')
      this.modalCategory = new KeywordCategory()
      this.modalCategoryName = ''
      this.modalParentCategoryId = null
      this.$refs.categoryModal.show()
    },
    addKeyword() {
      this.modalTitle = this.i18n.t('keyword.add')
      this.modalKeyword = new Keyword()
      this.modalKeywordName = ''
      this.modalKeywordCategory = this.wordEditor.selectedCategory
      this.modalKeywordCategoryId = this.wordEditor.selectedCategory.id
      this.$refs.keywordModal.show()
    },
    addCategoryOk() {
      /**
       * modalからのenterでのsubmitはボタンの無効化で弾けないので、validationを行う
       * invalidな時、modal上ではformが赤くなっているので、警告表示はそちらでOKとする
       */
      if (!this.isValidKeywordCategoryName()) {
        return
      }

      this.modalCategory.name = this.modalCategoryName.trim()
      this.modalCategory.name = this.modalCategory.name.replace(illegalExp, "")
      this.modalCategory.parentId = this.modalParentCategoryId

      if(this.modalParentCategoryId){
        this.modalCategory.parentId = this.modalParentCategoryId.id
      }
      this.isNewKeywordCategory = true
      this.questionTabIndex = 1
      this.$emit('save-keyword-category', this.modalCategory)
      this.$refs.categoryModal.hide()
    },
    addKeywordOk() {
      /**
       * modalからのenterでのsubmitはボタンの無効化で弾けないので、validationを行う
       * invalidな時、modal上ではformが赤くなっているので、警告表示はそちらでOKとする
       */
      if (!this.isValidKeywordName()) {
        return
      }
      if(this.isKeywordDuplicate(this.modalKeywordName.trim(), this.modalKeywordCategoryId)){
        this.$refs.duplicateModal.show()
        return
      }
      this.isNewKeyword = true
      this.modalKeyword.name = this.modalKeywordName.trim()
      this.modalKeyword.name = this.modalKeyword.name.replace(illegalExp, "")
      this.modalKeyword.category = this.modalKeywordCategory
      this.modalKeyword.categoryId = this.modalKeywordCategoryId
      this.$emit('save-keyword', this.modalKeyword)
      this.questionTabIndex = 1
      this.$refs.keywordModal.hide()
    },
    showSearchModal() {
      this.$emit('create-keyword-name')
      this.modalPopupCheckFlag = true
      this.$emit('modalPopupCheck-flag',this.modalPopupCheckFlag)
      // 検索モーダルでキーワードカテゴリ・キーワードのリストを表示するために必要
      this.wordEditor = new WordsEditor(
          this.apiUrl,
          this.language,
          [new Word('')]
      )
      this.wordEditor.ready()
      this.wordEditor.queryFromWhichModal = "search"
      this.wordEditor.intentCategories = this.wordEditor.createCategoriesData(this.categories, true)
      this.$refs.searchModal.show()
    },
    searchQuestion(searchingString, intentCategory){
      this.$emit('search-question', searchingString, intentCategory)
    },
    resetSearchResult() {
      this.wordEditor.selectKeywordCategory(null)
      this.wordEditor.selectKeyword(null)
      this.$emit('reset-search-result')
    },
    OnStartLinking(message) {
      this.$emit('started-linking', message)
    },
    modalFocus(){
      this.$refs.focusTextArea.focus()
    },
    saveKeywordValue(keywordValue) {
      this.$emit('save-keyword-value', keywordValue)
    },
    categoryModalCancel() {
      this.$refs.categoryModal.hide()
      if(this.wordEditor.queryFromWhichModal === "question"){
        this.$refs.questionModal.show()
      }
      else if(this.wordEditor.queryFromWhichModal === "search"){
        this.$refs.searchModal.$refs.modal.show()
      }
    },
    keywordModalCancel() {
      this.$refs.keywordModal.hide()
      if(this.wordEditor.queryFromWhichModal === "question"){
        this.$refs.questionModal.show()
      }
      else if(this.wordEditor.queryFromWhichModal === "search"){
        this.$refs.searchModal.$refs.modal.show()
      }
    },
    showSelectModal() {
      // スマホでタッチで文字選択するのは使い勝手があまり良くないので、もう一つのモーダルを開いて、入力の形で選択するように
      this.modalTitle = this.i18n.t('question.selectModalTitle')
      this.modalTextForSelect = this.wordEditor.text
      this.modalSelectedText = null
      this.$refs.selectModal.show()
    },
    selectModalFocusOn() {
      this.$refs.focusOnThis.focus()
    },
    selectTextCancel() {
      this.$refs.selectModal.hide()
      this.$refs.questionModal.show()
    },
    selectTextOk() {
      if(this.modalSelectedTextIndex >= 0){
        let startAt = this.modalSelectedTextIndex
        let endAt = startAt + this.modalSelectedText.length
        this.selectedWord = this.wordEditor.split(startAt, endAt)
        if (this.selectedWord != null) {
          this.selectedWord.selected = true
        }
        this.$refs.questionModal.show()
      }
      this.$refs.selectModal.hide()
    },
    selectText(startAt) {
      let endAt = startAt + this.modalSelectedText.length
      // 選択の部分を赤にする
      let front = this.wordEditor.text.slice(0, startAt)
      let center = this.wordEditor.text.slice(startAt, endAt)
      let back = this.wordEditor.text.slice(endAt, this.wordEditor.text.length)
      this.modalTextForSelect = front + "<span style='color:red'>" + center + "</span>" + back
      // 選択状態になっている位置を保存
      this.modalSelectedTextIndex = startAt
    },
    onInputSelectedText() {
      if(this.isValidModalSelectedText) {
        // 入力した文字列とマッチする部分を選択状態にする
        let startAt = this.wordEditor.text.indexOf(this.modalSelectedText)
        this.selectText(startAt)
      }else{
        this.modalTextForSelect = this.wordEditor.text
        this.modalSelectedTextIndex = -1
      }
    },
    selectNextText() {
      if(this.isValidModalSelectedText) {
        // 次のマッチするところを探す、なければまた一番目のところを指す
        let startAt = this.wordEditor.text.indexOf(this.modalSelectedText, this.modalSelectedTextIndex+1)
        if (startAt < 0)
          startAt = this.wordEditor.text.indexOf(this.modalSelectedText)
        this.selectText(startAt)
      }
    },
    editUserSayCancel(){
      this.isModalShown = false
      this.$emit('modalPopupCheck-flag',this.isModalShown)
      this.$refs.questionModal.hide()
    },
    deleteUserSayCancel(){
      this.isModalShown = false
      this.$emit('modalPopupCheck-flag',this.isModalShown)
      this.$refs.deleteUserSayConfirmModal.hide()
    },
    deleteResponseMessageCancel(){
      this.isModalShown = false
      this.$emit('modalPopupCheck-flag',this.isModalShown)
      this.$refs.deleteResponseMessageConfirmModal.hide()
    },
    showCompareIntentModal() {
      this.isModalShown = true
      this.$emit('modalPopupCheck-flag', this.isModalShown)
      this.$refs.compareIntentModal.show()
    },
    onChangedComparedLanguage(language_code) {
      this.$emit('changed-compared-language', language_code)
    },
    cancelCompareIntentModal() {
      this.isModalShown = false
      this.$emit('modalPopupCheck-flag', this.isModalShown)
    },
    copyUserSays(intentDetail) {
      this.$emit('copy-user-says', intentDetail)
    },
    modalHidden(isFromUserSay){
      this.modalPopupCheckFlag = false
      this.$emit('modalPopupCheck-flag',this.modalPopupCheckFlag)
      if(isFromUserSay){
        if (this.$refs.userSayFocusOn) {
          this.$refs.userSayFocusOn.focus()
        }
      }
      else {
        if (this.$refs.responseMessageFocusOn) {
          this.$refs.responseMessageFocusOn.focus()
        }
      }

      if(!this.isModalOK){
        if(isFromUserSay){
          if(this.selectedUserSay)
            this.clickIsUserSaySelected(this.selectedUserSay)
          else{
            this.arrowKeyFlag = 2
            this.$emit('arrowKey-flag',this.arrowKeyFlag)
          }
        }
        else{
          if(this.selectedResponseMessage)
            this.clickIsResponseMessageSelected(this.selectedResponseMessage)
          else{
            this.arrowKeyFlag = 2
            this.$emit('arrowKey-flag',this.arrowKeyFlag)
          }
        }
      }
      this.isModalOK = false
      this.selectedWord = null
    },
    parentKeywordCategoryOptions(lists) {
      // Remove specific item from the list
      return lists.filter(list => {
        if(list.value !== "System_Entity"){
          return list
        }
      })
    },
    selectPlatformServiceModal() {
      this.isModalShown = true
      this.$emit('modalPopupCheck-flag', this.isModalShown)
      this.$refs.selectPlatformModal.show()
    },
    selectPlatformService(selectedPlatform, platformFlag){
      this.$emit('select-platform-service', selectedPlatform, platformFlag)
    },
    getFaIcon(item) {
      return item ? `<i class="${this.icons[item].faIcon}" title="${this.icons[item].text}"></i>` : ''
    },
    keyowrdCategoryModalFocus() {
      window.setTimeout(() => {
        const inputEl = document.getElementById("modal-keyword-category-name-input")
        if(inputEl) {
          inputEl.focus()
        }
      }, 0)
    },
    keyowrdModalFocus() {
      window.setTimeout(() => {
        const inputEl = document.getElementById("modal-keyword-name-input")
        if(inputEl) {
          inputEl.focus()
        }
      }, 0)
    },
    openSlotFillingModal(word, index) {
      this.slotFillingModalTitle = "Prompts for '"+word.alias+"'"
      this.currentPromptWordEditorIndex = index
      this.initializeSlotFillingForm(word)
      this.$refs['slot-filling-modal'].show()
    },
    addSlotFillingInput() {
      this.wordModel.prompts.push({prompt: ''})
      this.slotFillingForm = this.wordModel.prompts
      this.$v.$touch()
    },
    removeSlotFillingInput(inputElementID) {
      this.wordModel.prompts.splice(inputElementID, 1)
      this.slotFillingForm = this.wordModel.prompts
      this.$v.$touch()
    },
    countQuestions(current) {
      return parseInt(current) + 1
    },
    initializeSlotFillingForm(word){
      this.wordModel.prompts = word.prompts.length > 0 ? word.prompts : [{prompt: ''}]
      this.slotFillingForm = this.wordModel.prompts
      this.$v.$touch()
    },
    handleSubmitSlotFillingForm(e) {
      e.preventDefault() // Prevent default form submission
      const currentWordEditor = this.keywordWords[this.currentPromptWordEditorIndex]
      currentWordEditor.prompts = this.$v.slotFillingForm.$model
      currentWordEditor.promptRequired = currentWordEditor.prompts.length > 0
      this.$refs['slot-filling-modal'].hide()
    },
    getLineResponseLimitErrorStatus() {
      let state = false
      const lineAnswerLimit = 5
      if(this.responseMessagesFilteredLanguage) {
        const lineAnswers = this.responseMessagesFilteredLanguage.filter(responseMessage => {
          return responseMessage.targetServices.includes(TargetService.LINE)
        })
        if(lineAnswers.length > lineAnswerLimit) {
          let countAnsObj = 1
          lineAnswers.forEach((ans, idx) => {
            let prevAns = idx > 0 ? lineAnswers[idx-1] : null
            if(prevAns) {
              if(prevAns.type !== ans.type || parseInt(ans.type) !== parseInt(ResponseType.CARD)) {
                countAnsObj++
              }
            }
          })
          if(countAnsObj > lineAnswerLimit) {
            state = true
          }
        }
      }
      return state
    }
  },
  watch: {
    'intent'(){
      if(this.intent)
        return
      this.multipleDeleteSelecting = false
      this.tempSelectedUserSay = null
      this.cancelMultiSelectUserSay()
    }
  }
}
</script>
