import { I18n } from '../util/i18n'
import {Feedback, FeedbackData, DummyButton, Label, DummyFeedback, ButtonText} from '../model/feedback'
import { RestApi } from '../util/rest-api'
import {Intent, IntentCategory} from "../model/intent"

class FeedbackConfigController {
  constructor(apiUrl, i18nContext, requestContext) {
    this.i18n = new I18n(i18nContext)
    this.languages = requestContext.languages
    this.feedbackApi = new RestApi(apiUrl.FeedbackConfig, Feedback)
    this.feedback = new FeedbackData()
    this.dFeedback = new DummyFeedback()
    this.allIntentApi = new RestApi(apiUrl.AllIntent, Intent)
    this.intentCategoryApi = new RestApi(apiUrl.IntentCategory, IntentCategory)
    this.allIntents = [{value: 0, text: "---"}]
  }
  ready(){
    this.loadFeedbackConfig()
    this.loadAllIntentWithFullPath()
  }

  /**
   * Sorting
   */
  dynamicSort(property) {
    let sortOrder = 1
    if(property[0] === "-") {
        sortOrder = -1
        property = property.substr(1)
    }
    return function (a,b) {
        if(sortOrder === -1){
            return b[property].localeCompare(a[property])
        }else{
            return a[property].localeCompare(b[property])
        }
    }
  }

  resolveCategoryPath(category, categoryIdToCategory) {
    if (category.parentId == null) {
      return [category]
    } else {
      const parentCategory = categoryIdToCategory[category.parentId]
      return this.resolveCategoryPath(parentCategory, categoryIdToCategory).concat([category])
    }
  }

  /**
   * カテゴリ名のインデックス生成
   */
  createCategoryPathIndex(categories) {
    let flat = IntentCategory.categoriesAsFlat(categories)
    // categoryId -> categoryを作成
    let categoryIdToCategory = {}
    for (const [nestedLevel, category] of flat) {
      categoryIdToCategory[category.id] = category
    }
    // category -> カテゴリツリーのパス生成
    let categoryIdToPath = {}
    for (const [nestedLevel, category] of flat) {
      const categoryPath = this.resolveCategoryPath(category, categoryIdToCategory)
      categoryIdToPath[category.id] = categoryPath.map((c) => {return c.name}).join("_")
    }
    return categoryIdToPath
  }

  /**
   * make intent full name by id
   */
  loadAllIntentWithFullPath(){
    this.intentCategoryApi.list()
      .then((instance)=>{
        const category =this.createCategoryPathIndex(instance)
        this.allIntentApi.list()
          .then((intents)=>{
            for(const  intent of intents){
              if(!(intent.fallbackIntent || intent.welcomeIntent || intent.isFeedbackActive || intent.scenarioId!==null)){
                this.allIntents.push({
                  value: intent.id,
                  text: category[intent.categoryId]+'_'+intent.name
                })
              }
            }
            this.allIntents.sort(this.dynamicSort("text"))
          })
      })
  }

  // Return Common Feedback data compare with register languages
  comparer(otherArray){
    return function(current){
      return otherArray.filter(function(other){
        return other === current.lang
      }).length !== 0
    }
  }
  // Return languages list compare with feedback data
  comparer2(otherArray){
    return function(current){
      return otherArray.filter(function(other){
        return other.lang === current
      }).length === 0
    }
  }

  loadFeedbackConfig(){
    this.feedbackApi.list()
      .then(instance => {
        if(instance.length > 0){
          this.feedback = instance[0]
          // If language added or delete from dataset then update feedback data
          if(this.feedback.data.length !== this.languages.length){
            if(this.feedback.data.length < this.languages.length){
              // If new language added to dataset then push new feedback data for that language
              let langs = this.languages.filter(this.comparer2(this.feedback.data))
              for(const lang of langs){
                let feedbackData = new FeedbackData()
                feedbackData.lang = lang
                for(let i=0; i < this.feedback.data[0].buttons.length; i++){
                  feedbackData.buttons.push(new ButtonText())
                }
                this.feedback.data.push(feedbackData)
              }
            }else{
            //  If language delete from dataset then delete that language feedback data
              this.feedback.data = this.feedback.data.filter(this.comparer(this.languages))
            }
          }
        }else{
          // When feedback data not registered then create default feedback data
          this.feedback = new Feedback()
          for(let lang of this.languages){
            let feedbackData = new FeedbackData()
            feedbackData.lang = lang
            this.feedback.data.push(feedbackData)
          }
        }
        // Convert Feedback model to DummyFeedback model
        let dummyFeedback = new DummyFeedback()
        if(this.feedback.id !== null){
          for(let index=0; index < this.feedback.data[0].buttons.length; index++){
            let dummyButton = this.genarateNewButton()
            let level = this.feedback.data[0].buttons[index].level
            let intentId = this.feedback.data[0].buttons[index].intentId
            for(const fb of this.feedback.data){
              let btn = fb.buttons[index]
              dummyButton.labels.forEach(label => {
                if(label.lang === fb.lang){
                  label.text = btn.text
                }
              })
            }
            dummyButton.level = level
            dummyButton.intentId = intentId
            dummyFeedback.buttons.push(dummyButton)
          }
        }else{
          // Create at least one button if feedback not registered
          dummyFeedback.buttons.push(this.genarateNewButton())
        }
        this.dFeedback = dummyFeedback
      })
      .catch(err => console.log(err))
  }

  saveFeedback(csrfToken=null){
    // Delete previous feedback buttons for update
    this.feedback.data.forEach(fb => {
      fb.buttons=[]
    })
    // Convert DummyFeedback to Feedback model
    for(const lang of this.languages){
      for(const button of this.dFeedback.buttons){
        let data = button.labels.filter(b => b.lang === lang)
        let btn = new ButtonText()
        btn.text = data[0].text
        btn.level = button.level
        btn.intentId = button.intentId
        this.feedback.data.forEach(fb => {
          if(fb.lang === lang){
            fb.buttons.push(btn)
          }
        })
      }
    }
    let promise = this.feedbackApi.save(
      this.feedback,
      csrfToken
    )
    .then((instance) => {
      this.feedback = instance
    })
    return [true, this.i18n.t("general.saving"), promise]
  }

  /** フィードバック設定を初期化する */
  async resetFeedbackSetting(csrfToken=null) {
    this.dFeedback.buttons = [];
    this.feedback.isFreetextEnabled = false;
    this.feedback.data = this.feedback.data.map((feedback) => {
      feedback.title = "";
      feedback.placeholder = "";
      return feedback;
    });
    try {
      await this.feedbackApi.destroy(this.feedback, csrfToken);
    } catch (err) {
      console.error(err);
    }
  }

  genarateNewButton(){
    let dummyButton = new DummyButton()
    for(let lang of this.languages){
      let label = new Label()
      label.lang = lang
      dummyButton.labels.push(label)
    }
    return dummyButton
  }

  addButton(){
    this.dFeedback.buttons.push(this.genarateNewButton())
  }

  deleteButton(index){
    this.dFeedback.buttons.splice(index, 1)
  }
}

export {
  FeedbackConfigController
}
