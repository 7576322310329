<template>
  <b-card no-body>
    <http-request-header-data-list></http-request-header-data-list>
    <b-tabs card>
      <b-tab :title="$t('account.emailNotification')">
        <b-container fluid class="pt-2">
          <b-row class="mb-2">
            <b-col>
              <b-form-checkbox v-model="context.isReportEnabled"
                name="is_report_enabled"
              >
                {{ $t('account.weeklyReportEnabled') }}
              </b-form-checkbox>
            </b-col>
          </b-row>
          <h6 class="mt-2">{{ $t('account.eventsCheckedWillBeSentToEmail') }}</h6>
          <b-row class="mb-2">
            <b-col>
              <b-form-checkbox v-model="context.isCallOperatorNotificationEnabled"
                name="is_call_operator_notification_enabled"
              >
                {{ $t('account.callOperatorNotification') }}  
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col>
              <b-form-checkbox v-model="context.isOperatorModeMessageNotificationEnabled"
                name="is_operator_mode_message_notification_enabled"
              >
                {{ $t('account.operatorModeMessageNotification') }}
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col>
              <b-form-checkbox v-model="context.isOperatorNotificationUserMessageEnabled"
                name="is_operator_notification_user_message_enabled"
              >
                {{ $t('account.operatorNotificationUserMessage') }}
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-container>
      </b-tab>
      <b-tab :title="$t('account.externalNotification')">
        <b-container fluid class="pt-2">
          <b-row class="mb-2">
            <b-col cols="12" md="6" lg="3">
              <label>{{ $t('account.externalNotifyType') }}</label>
              <b-form-select v-model="context.externalNotifyType" :options="externalNotifyTypeOptions"
                name="external_notify_type"
              ></b-form-select>
            </b-col>
          </b-row>
          <!-- 1: Line Notify -->
          <b-row class="mb-2" v-show="context.externalNotifyType === 1">
            <b-col>
              {{ $t('account.lineConnectionStatus') }}: 
              <template v-if="context.lineConnected">
                <span class="text-success mr-2">{{ $t('account.connected') }}</span>
                <b-button variant="danger" size="sm" @click.prevent.stop="disconnectLineNotify">
                  {{ $t('account.disconnect') }}
                </b-button>
              </template>
              <template v-else>
                <span class="text-danger mr-2">{{ $t('account.notConnected') }}</span>
                <b-button variant="success" size="sm" @click.prevent.stop="connectToLineNotify">
                  {{ $t('account.connect') }}
                </b-button>
              </template>
            </b-col>
          </b-row>
          <!-- 101: External URL Call -->
          <b-row class="mb-2" v-show="context.externalNotifyType === 101">
            <b-col>
              <div :class="displayMessageInfoForExternalCall.textClass">
                {{ displayMessageInfoForExternalCall.msg }}
                <b-button variant="primary" v-b-toggle.external-call-settings class="ml-2" size="sm">{{ $t('buttons.change') }}</b-button>
              </div>
              <b-collapse id="external-call-settings" class="mt-2">
                <b-card>
                  <b-container fluid>
                    <b-row class="mb-2">
                      <b-col>
                        <label :class="{'text-danger': $v.context.externalCallEndpoint.$invalid}">
                          {{ $t('account.externalCallURL') }}
                          <span class="text-danger">*</span>
                        </label>
                        <b-form-input v-model="context.externalCallEndpoint"
                          name="external_call_endpoint"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col>
                        <label :class="{'text-danger': $v.context.externalCallContentType.$invalid}">
                          Content-Type
                          <span class="text-danger">*</span>
                        </label>
                        <b-form-select v-model="context.externalCallContentType" :options="externalCallContentTypeOptions"
                          name="external_call_content_type"
                        ></b-form-select>
                      </b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col>
                        <label>Headers</label>
                        <b-row v-for="(header, index) in externalCallEditingHeaders" :key="index" class="mb-2">
                          <b-col>
                            <b-input-group>
                              <b-form-input v-model="header.headerKey" @input="onHeaderInput" placeholder="Key" list="datalist-common-request-headers"></b-form-input>
                              <b-form-input v-model="header.headerValue" @input="onHeaderInput" placeholder="Value"></b-form-input>
                              <b-input-group-append>
                                <b-button variant="danger" size="sm" @click.prevent.stop="() => { externalCallEditingHeaders.splice(index, 1); onHeaderInput(); }">{{ $t('buttons.delete') }}</b-button>
                              </b-input-group-append>
                          </b-col>
                        </b-row>
                        <div class="text-center">
                          <b-button variant="success" size="sm" @click.prevent.stop="externalCallEditingHeaders.push({ headerKey: '', headerValue: '' })">{{ $t('buttons.add') }}</b-button>
                        </div>
                        <input type="hidden" name="external_call_headers" v-model="context.externalCallHeaders">
                      </b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col>
                        <label>Body <span :class="{'text-danger': $v.context.externalCallBody.$invalid}">(Json)</span></label>
                        <code-editor
                          editor-id="externalCallBodyInput"
                          autoHeight="true"
                          :content="externalCallEditingBody"
                          class="mb-2"
                          @change-content="onBodyInput"
                          :placeholder="externalCallBodyPlaceholder"
                        />
                        <input type="hidden" name="external_call_body" v-model="context.externalCallBody">
                      </b-col>
                    </b-row>
                  </b-container>
                </b-card>
              </b-collapse>    
            </b-col>
          </b-row>
          <b-container fluid v-show="context.externalNotifyType !== 0">
            <h6 class="mt-2">{{ $t('account.eventsCheckedWillBeSentToExternalService') }}</h6>
            <b-row class="mb-2">
              <b-col>
                <b-form-checkbox v-model="context.isCallOperatorExternalNotificationEnabled"
                  name="is_call_operator_external_notification_enabled"
                >
                  {{ $t('account.callOperatorNotification') }}
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col>
                <b-form-checkbox v-model="context.isOperatorModeMessageExternalNotificationEnabled"
                  name="is_operator_mode_message_external_notification_enabled"
                >
                  {{ $t('account.operatorModeMessageNotification') }}
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col>
                <b-form-checkbox v-model="context.isOperatorExternalNotificationUserMessageEnabled"
                  name="is_operator_external_notification_user_message_enabled"
                >
                  {{ $t('account.operatorNotificationUserMessage') }}
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-container>
        </b-container>
      </b-tab>
    </b-tabs>

    <hr class="my-2">
    <div class="text-center mb-2">
      <b-button variant="success" type="submit" :disabled="$v.$invalid">{{ $t('buttons.save') }}</b-button>
    </div>
  </b-card>
</template>

<script>
export default {
  name: 'UserNotificationSettings',
  data() {
    return {
      externalNotifyTypeOptions: [
        { text: this.$t('account.notNotify'), value: 0 },
        { text: this.$t('account.lineNotify'), value: 1 },
        { text: this.$t('account.externalUrlCall'), value: 101 }
      ],
      externalCallContentTypeOptions: [
        { text: '------', value: null },
        { text: 'application/json', value: 1 },
        { text: 'application/x-www-form-urlencoded', value: 2 },
        { text: 'multipart/form-data', value: 3 }
      ],
      externalCallBodyPlaceholder: `{
  "message": "<message>"
}`,
      context: {
        isReportEnabled: false,
        isCallOperatorNotificationEnabled: false,
        isOperatorModeMessageNotificationEnabled: false,
        isOperatorNotificationUserMessageEnabled: false,
        isCallOperatorExternalNotificationEnabled: false,
        isOperatorModeMessageExternalNotificationEnabled: false,
        isOperatorExternalNotificationUserMessageEnabled: false,

        externalNotifyType: 0,
        lineConnected: false,
        externalCallEndpoint: '',
        externalCallContentType: null,
        externalCallHeaders: '',
        externalCallBody: '',

        connectToLineNotifyAction: '',
        disconnectLineNotifyAction: '',
      },

      displayMessageInfoForExternalCall: {
        msg: '',
        status: ''
      },
      externalCallEditingHeaders: [],
      externalCallEditingBody: ''
    }
  },
  validations() {
    return {
      context: {
        lineConnected: {
          requireIfLineNotifySelected: value => {
            return this.context.externalNotifyType === 1 ? !!value : true
          }
        },
        externalCallEndpoint: {
          requireIfExternalCallSelected: value => {
            return this.context.externalNotifyType === 101 ? !!value : true
          }
        },
        externalCallContentType: {
          requireIfExternalCallSelected: value => {
            return this.context.externalNotifyType === 101 ? !!value : true
          }
        },
        externalCallBody: {
          isEmptyOrValidJson: value => {
            if (value) {
              try {
                JSON.parse(value)
                return true
              } catch (e) {
                return false
              }
            }
            return true
          }
        }
      }
    }
  },
  created() {
    this.context = {
      ...this.context,
      ...window.requestContext
    }

    if (this.context.externalCallEndpoint) {
      this.displayMessageInfoForExternalCall = {
        msg: this.context.externalCallEndpoint,
        textClass: ''
      }
    }
    else {
      this.displayMessageInfoForExternalCall = {
        msg: this.$t('account.externalEndpointNotSet'),
        textClass: 'text-danger'
      }
    }

    if (typeof this.context.externalCallHeaders === 'string' && this.context.externalCallHeaders !== '') {
      const parsedContextHeaders = JSON.parse(this.context.externalCallHeaders)
      this.externalCallEditingHeaders = Object.keys(parsedContextHeaders).map(key => {
        return {
          headerKey: key,
          headerValue: parsedContextHeaders[key]
        }
      })
    }
    if (typeof this.context.externalCallBody === 'string' && this.context.externalCallBody !== '') {
      this.externalCallEditingBody = JSON.stringify(
        JSON.parse(this.context.externalCallBody),
        null,
        2
      )
    }
  },
  methods: {
    onHeaderInput() {
      const validHeaders = {}
      this.externalCallEditingHeaders.forEach(header => {
        if (header.headerKey && header.headerValue) {
          validHeaders[header.headerKey] = header.headerValue
        }
      })
      this.context.externalCallHeaders = JSON.stringify(validHeaders)
    },
    onBodyInput(content) {
      this.context.externalCallBody = content
      this.$v.$touch()
    },
    connectToLineNotify() {
      window.location.href = this.context.connectToLineNotifyAction;
    },
    disconnectLineNotify() {
      window.location.href = this.context.disconnectLineNotifyAction;
    }
  }
}
</script>
