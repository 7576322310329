<template>
  <b-row v-if="controller.isReady" class="col-12 mt-1 mx-0 px-0 outer-row">
    <!-- PCの表示用 -->
    <session-list-section
      class="col-lg-3 col-12 border section sp-hidden"
      :total-session-count="controller.totalSessionCount"
      :sessions="controller.operatorHandlingSessions"
      :operators="controller.operators"
      :session-count="controller.sessionCount"
      :bookmarks="controller.bookmarks"
      :selected-operator-handling-session-id="
        (controller.selectedOperatorHandlingSession || {}).id
      "
      :controller="controller"
      @on-select-session="controller.onSelectSession($event)"
      @on-change-page-idx="controller.loadOperatorSession($event)"
      @on-update-operator-setting="controller.updateOperatorSetting($event)"
    />
    <message-section
      ref="messageSection"
      class="col-lg-6 col-12 border section sp-hidden"
      :session-chat-logs="controller.sessionChatLogs"
      :session="controller.selectedOperatorHandlingSession"
      :bookmarks="controller.bookmarks"
      :translation-logs="controller.translationLogs"
      :ui-language-code="controller.uiLanguageCode"
      :controller="controller"
      :user-meta="controller.selectedSesssionUserMeta"
      :allow-user-notification="controller.allowUserNotification"
      @on-push-message-confirm="sendPushMessage"
      @on-click-load-older-message="loadOlderMessage('messageSection')"
      @on-confirm-operator-handling-session-status-update="
        controller.updateOperatorHandlingSessionStatus()
      "
      @on-call-translate="onCallTranslate"
    />
    <detail-section
      class="col-lg-3 col-12 border section sp-hidden"
      :session-chat-logs="controller.sessionChatLogs"
      :session="controller.selectedOperatorHandlingSession"
      :bookmarks="controller.bookmarks"
      :user-meta="controller.selectedSesssionUserMeta"
      :operator-name="(controller.selectedSessionOperator || {}).name"
      :selected-operator-handling-session-event-logs="
        controller.selectedOperatorHandlingSessionEventLogs
      "
      :operators="controller.operators"
      @on-submit-note="saveNote"
      @on-click-toggle-bookmark="controller.toggleBookmark()"
      @on-confirm-operator-handling-session-status-update="controller.updateOperatorHandlingSessionStatus()"
      @on-update-operator-setting="controller.updateOperatorSetting($event)"
    />

    <!-- SPの表示用 -->
    <sp-detail-section
      v-if="controller.selectedOperatorHandlingSession"
      class="col-lg-3 col-12 mb-2 px-0 pc-hidden"
      :session-chat-logs="controller.sessionChatLogs"
      :session="controller.selectedOperatorHandlingSession"
      :bookmarks="controller.bookmarks"
      :user-meta="controller.selectedSesssionUserMeta"
      :operator-name="(controller.selectedSessionOperator || {}).name"
      :selected-operator-handling-session-event-logs="
        controller.selectedOperatorHandlingSessionEventLogs
      "
      :operators="controller.operators"
      @on-submit-note="saveNote"
      @on-click-toggle-bookmark="controller.toggleBookmark()"
      @on-confirm-operator-handling-session-status-update="controller.updateOperatorHandlingSessionStatus()"
      @on-update-operator-setting="controller.updateOperatorSetting($event)"
    />
    <session-list-section
      v-if="!controller.selectedOperatorHandlingSession"
      class="col-lg-3 col-12 border section pc-hidden"
      :total-session-count="controller.totalSessionCount"
      :sessions="controller.operatorHandlingSessions"
      :operators="controller.operators"
      :session-count="controller.sessionCount"
      :bookmarks="controller.bookmarks"
      :selected-operator-handling-session-id="
        (controller.selectedOperatorHandlingSession || {}).id
      "
      :controller="controller"
      @on-select-session="controller.onSelectSession($event)"
      @on-change-page-idx="controller.loadOperatorSession($event)"
      @on-update-operator-setting="controller.updateOperatorSetting($event)"
    />
    <message-section
      v-if="controller.selectedOperatorHandlingSession"
      ref="spMessageSection"
      class="col-lg-6 col-12 border section pc-hidden"
      :session-chat-logs="controller.sessionChatLogs"
      :session="controller.selectedOperatorHandlingSession"
      :bookmarks="controller.bookmarks"
      :translation-logs="controller.translationLogs"
      :ui-language-code="controller.uiLanguageCode"
      :controller="controller"
      :user-meta="controller.selectedSesssionUserMeta"
      :allow-user-notification="controller.allowUserNotification"
      @on-push-message-confirm="sendPushMessage"
      @on-click-load-older-message="loadOlderMessage('spMessageSection')"
      @on-confirm-operator-handling-session-status-update="
        controller.updateOperatorHandlingSessionStatus()
      "
      @on-call-translate="onCallTranslate"
      @cancel-selected-operator-session="controller.cancelSelectedOperatorSession()"
    />

    <!-- フィルターモーダル
      session-list-section内で利用する
    -->
    <session-filter-modal
      :operators="controller.operators"
      :controller="controller"
      @on-update-session-filter="controller.updateOperatorSessionFilter($event)"
    />

    <!-- detail-section・sp-detail-section内で利用するモーダル -->
    <!-- 対応ステータス変更確認モーダル -->
    <operator-handling-session-status-change-modal
    v-if="controller.selectedOperatorHandlingSession"
      :current-operator-handling-session-status="
        controller.selectedOperatorHandlingSession.status
      "
      @on-confirm-operator-handling-session-status-update="controller.updateOperatorHandlingSessionStatus()"
    />
    <!-- ノート編集モーダル -->
    <edit-note-modal
      v-if="controller.selectedOperatorHandlingSession"
      :original-value="controller.selectedOperatorHandlingSession.note"
      @on-submit-note="saveNote"
    />
    <!-- 担当者設定モーダル -->
    <operator-setting-modal
      v-if="controller.selectedOperatorHandlingSession"
      ref="operator-setting-modal"
      :operators="controller.operators"
      :initial-operator-user-id="controller.selectedOperatorHandlingSession.operatorUserId"
      @on-update-operator-setting="controller.updateOperatorSetting($event)"
    />

    <error-modal ref="errorModal" :message="message" />

    <progress-modal ref="progressModal" :message="message" />
  </b-row>
</template>

<script>
import * as ObotAIPubSub from "../../../obot-pubsub-client/dist/obotai-pubsub.module"
import { BroadcastTypes } from "../../util/broadcast-types"

export default {
  name: 'OperatorSession',
  props: ['controller'],
  data() {
    return {
      message: ''
    }
  },
  async created() {
    const pubsubInfo = await this.controller.getPubsubServiceInfo()
    // obot-pubsubのクライアントインスタンスを作成
    this.pubsubClient = new ObotAIPubSub.ObotAIPubSubClient({
      platform: pubsubInfo.data.platform,
      serviceAddress: pubsubInfo.data.ws_host
    })
    // リスナーメソッドを紐づけ
    this.pubsubClient.bind({
      receive: (data) => {
        this.handleBroadcast(data)
      }
    })
    this.pubsubClient.subscribe(`TRANSLATION_CHAT_HANDLING_SESSION_${requestContext.agentGid}`)

    this.controller.bindOperatorSessionComponent(this)
  },
  methods: {
    showProgress(message) {
      this.message = message
      this.$refs.progressModal.show()
    },
    hideProgress() {
      this.$refs.progressModal.hide()
    },
    showError(message) {
      this.message = message
      this.$refs.errorModal.show()
    },
    async sendPushMessage(responseMessage, isToBeOperator, isSetOperatorStatusActive, isUserNotificationEnabled) {
      // TODO 翻訳
      this.showProgress('送信しています。')
      try {
        responseMessage["isToBeOperator"] = isToBeOperator
        responseMessage["isSetOperatorStatusActive"] = isSetOperatorStatusActive
        responseMessage["is_user_notification_enabled"] = isUserNotificationEnabled
        await this.controller.sendPushMessageToUser(responseMessage)
      } catch (e) {
        this.showError(message)
      }
      this.hideProgress()
    },
    /**
     * ノートを保存する。
     *
     * @param {object} data emitされたデータ
     * @param {string} data.note 保存対象のノート
     */
    async saveNote(data) {
      this.showProgress()
      try {
        await this.controller.saveNote(data)
      } catch (e) {
        // TODO 翻訳
        this.showError('保存に失敗しました。')
      }
      this.hideProgress()
    },
    async loadOlderMessage(refValue) {
      const messageCount = this.controller.sessionChatLogs.length
      await this.controller.loadOlderMessage()
      const existsOlderMessage =
        messageCount !== this.controller.sessionChatLogs.length
      this.$refs[refValue].onCompleteLoadOlderMessage(existsOlderMessage)
    },
    async onCallTranslate(params, vm) {
      // api側で必要になるのでdummyのid
      const id = 'translation'
      // translationのapiが受け付けている形に加工してapi呼び出し
      const response = await this.controller.translateText(
        [
          {
            id,
            language_code: params.fromLanguage,
            query_text: params.originalText,
            fulfillment_text: ''
          }
        ],
        params.toLanguage
      )

      vm.applyTranslationResult({
        idx: params.idx,
        translatedText: response.query_text.find(t => t.id === id)?.text ?? ''
      })
    },
    async handleBroadcast(data) {
      const dataType = data.type
      switch (dataType) {
        case BroadcastTypes.TRANSLATION_CHAT_OPERATOR_SEND_MESSAGE:
          await this.updateOperatorSession(data)
          break
        case BroadcastTypes.TRANSLATION_CHAT_USER_SEND_MESSAGE:
          await this.updateOperatorSession(data)
          break
        case BroadcastTypes.TYPE_UPDATE_HANDLING_SESSION_STATUS:
          await this.updateOperatorSession(data)
          break
        case BroadcastTypes.TYPE_OPERATOR_SETTING_UPDATE:
          await this.updateOperatorSession(data)
          break
        case BroadcastTypes.TYPE_AGENT_STATE_UPDATE:
          await this.controller.loadAgentState()
          break
        case BroadcastTypes.TYPE_AGENT_OPERATOR_SESSION_CONFIG_UPDATE:
          await this.controller.loadAgentOperatorSessionConfig()
          await this.controller.loadAgentState()
          break
        case BroadcastTypes.TYPE_AGENT_HOLIDAYS_UPDATE:
          await this.controller.loadAgentHolidays()
          await this.controller.loadAgentState()
          break
        default:
          console.error('Unknown Broadcast Data type.')
      }
    },
    async updateOperatorSession(data) {
      const timestamp = data.timestamp
      /*
      PubSub前回の受信がない場合、または今度の受信が前回より新しい場合、
      更新処理を行う。
      */
      if(this.controller.translationChatPusSubTimeStamp === null ||
        timestamp > this.controller.translationChatPusSubTimeStamp) {
        // 左の一覧を更新
        await this.controller.loadOperatorSession({ pageIdx: this.controller.sessionListPageIdx })
        // 選択中のセッションを更新
        if(this.controller.selectedOperatorHandlingSession) {
          await this.controller.updateSelectedSession()
        }
        this.controller.translationChatPusSubTimeStamp = timestamp
      }
    }
  }
}
</script>

<style scoped>
.section {
  max-height: 100%;
}

@media screen and (min-width: 992px) {
  /*　画面サイズが480pxからはここを読み込む　*/
  .outer-row {
    height: calc(100vh - 114px);
  }
}
</style>
