<template>
  <div class="row mt-3">
    <div class="col-md-12">
      <div class="d-flex w-100 justify-content-between">
        <h5 class="mb-1">{{ $t('intent.intentLabel') }}</h5>
        <b-button variant="info" @click="openIntentLabelAddModal">
          <i class="fa fa-plus"></i>
          <span>{{ $t('buttons.add') }}</span>
        </b-button>
      </div>
      <!-- List Intent Label -->
      <div class="table-responsive mt-2">
        <table class="table table-hover text-center">
          <thead class="table-info">
            <tr>
              <th scope="col">#</th>
              <th scope="col">{{ $t('intent.labelName') }}</th>
              <th scope="col">{{ $t('intent.backgroundColor') }}</th>
              <th scope="col">{{ $t('intent.ordering') }}</th>
              <th scope="col">{{ $t('validation.action') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(intentLabel, index) in controller.listIntentLabels" :key="index">
              <th scope="row">{{(index + 1)}}</th>
              <td>{{intentLabel.name}}</td>
              <td>{{intentLabel.bgcolor}}</td>
              <td>{{intentLabel.ordering ? intentLabel.ordering : 0 }}</td>
              <td>
                <button v-b-tooltip.hover
                  :title="$t('buttons.edit')"
                  class="btn btn-info mr-1"
                  @click="openIntentLabelEditModal(intentLabel)">
                  <i class="fa fa-edit"></i>
                </button>
                <button v-b-tooltip.hover
                  :title="$t('buttons.delete')"
                  class="btn btn-danger"
                  @click="openIntentLabelDeleteModal(intentLabel)">
                  <i class="fas fa-trash-alt"></i>
                </button>
              </td>
            </tr>
            <tr v-if="controller.intentLabelLoader">
              <td colspan="5">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Loading...
              </td>
            </tr>
            <tr v-if="!controller.intentLabelLoader && controller.listIntentLabels.length === 0">
              <td colspan="5">
                <span class="text-danger">{{ $t('validation.noRecordFound') }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- Modal Intent Label Form -->
      <b-modal
        :title="intentLabelFormModalTitle"
        ref="intentLabelFormModal"
        header-class="bg-info text-light"
        no-close-on-esc
        no-close-on-backdrop
        @cancel="cancelIntentLabelFormModal">
        <div class="intentLabelForm">
          <b-form-group :label="$t('intent.labelName')">
            <b-form-input v-model="intentLabelForm.labelName"
                          :state="!$v.intentLabelForm.labelName.$error">
            </b-form-input>
          </b-form-group>
          <b-form-group :label="$t('intent.backgroundColor')">
            <color-pick-slider :labelColor="intentLabelForm.labelColor"
                                @on-select-color="selectLabelColor($event)">
            </color-pick-slider>
          </b-form-group>
          <b-form-group :label="$t('intent.ordering')">
            <b-form-input v-model="intentLabelForm.labelOrder"
                          :state="!$v.intentLabelForm.labelOrder.$error">
            </b-form-input>
          </b-form-group>
        </div>
        <div slot="modal-footer" class="w-100">
          <b-button variant="info"
                    class="float-right"
                    @click="submitIntentLabelForm"
                    :disabled="$v.intentLabelForm.$error">
            OK
          </b-button>
        </div>
      </b-modal>
      <!-- Delete Modal Intent Label -->
      <delete-confirm-modal
        ref="deleteHistoryModal"
        :title="intentLabelFormModalTitle"
        :bodyMessage="$t('intent.deleteIntentLabelConfirmMessage', {intent_label: this.intentLabelForm && this.intentLabelForm.name})"
        @keydown.native.enter="removeIntentLabel"
        @ok="removeIntentLabel">
      </delete-confirm-modal>
      <!-- Additional Modal -->
      <error-modal
        ref="errorModal"
        :message="message">
      </error-modal>
      <progress-modal
        ref="progressModal"
        :message="message">
      </progress-modal>
    </div>
  </div>
</template>

<script>
import {required, maxLength, integer} from 'vuelidate/lib/validators'
import { IntentLabel } from '../model/intent'

export default {
  name: 'IntentLabel',
  props: ['controller'],
  data() {
    return {
      message: "",
      intentLabelFormModalTitle: "",
      intentLabelForm: [],
      isEditIntentLabelFormModal: false,
      initIntentLabelBgcolor: ""
    }
  },
  validations: {
    intentLabelForm: {
      labelName: {
        required,
        maxLength: maxLength(30)
      },
      labelColor: {
        maxLength: maxLength(7)
      },
      labelOrder: {
        integer
      }
    }
  },
  methods: {
    openIntentLabelAddModal() {
      this.$v.intentLabelForm.$touch()
      this.isEditIntentLabelFormModal = false
      let modalTitle = this.$t('buttons.add') + this.$t('intent.intentLabel')
      if(this.controller.getLanguageCode() === 'en') {
        modalTitle = this.$t('buttons.add') +' '+ this.$t('intent.intentLabel')
      }
      this.intentLabelFormModalTitle = modalTitle
      this.resetIntentLabelForm()
      this.$refs.intentLabelFormModal.show()
    },
    cancelIntentLabelFormModal() {
      this.resetIntentLabelForm()
      this.$refs.intentLabelFormModal.hide()
    },
    openIntentLabelEditModal(item) {
      this.$v.intentLabelForm.$touch()
      this.isEditIntentLabelFormModal = true
      let modalTitle = this.$t('buttons.edit') + this.$t('intent.intentLabel')
      if(this.controller.getLanguageCode() === 'en') {
        modalTitle = this.$t('buttons.edit') +' '+ this.$t('intent.intentLabel')
      }
      this.intentLabelFormModalTitle = modalTitle
      // Store bgcolor if it is untouched
      this.initIntentLabelBgcolor = item.bgcolor
      // Set form values
      this.resetIntentLabelForm()
      this.intentLabelForm.id = item.id
      this.intentLabelForm.labelName = item.name
      this.intentLabelForm.labelColor = item.bgcolor
      this.intentLabelForm.labelOrder = item.ordering > 0 ? item.ordering : 0
      this.$refs.intentLabelFormModal.show()
    },
    openIntentLabelDeleteModal(item) {
      let modalTitle = this.$t('buttons.delete') + this.$t('intent.intentLabel')
      if(this.controller.getLanguageCode() === 'en') {
        modalTitle = this.$t('buttons.delete') +' '+ this.$t('intent.intentLabel')
      }
      this.intentLabelFormModalTitle = modalTitle
      this.intentLabelForm = item
      this.$refs.deleteHistoryModal.show()
    },
    bindIntentLabelFormData(form) {
      const intentLabel = new IntentLabel()
      intentLabel.id = form.hasOwnProperty('id') ? form.id : null
      intentLabel.name = form.labelName
      intentLabel.bgcolor = form.labelColor
      intentLabel.ordering = form.labelOrder > 0 ? form.labelOrder : 0
      return intentLabel
    },
    resetIntentLabelForm() {
      this.intentLabelForm = []
      this.intentLabelForm.labelColor = '#FFFFFF'
      this.intentLabelForm.labelOrder = 0
    },
    submitIntentLabelForm() {
      let result, message, promise
      if(this.isEditIntentLabelFormModal) {
        if(!this.intentLabelForm.labelColor) {
          this.intentLabelForm.labelColor = this.initIntentLabelBgcolor
        }
        [result, message, promise] = this.controller.updateIntentLabel(this.bindIntentLabelFormData(this.intentLabelForm))
      } else {
        if(!this.intentLabelForm.labelColor) {
          this.intentLabelForm.labelColor = '#FFFFFF'
        }
        [result, message, promise] = this.controller.createIntentLabel(this.bindIntentLabelFormData(this.intentLabelForm))
      }
      if (!result) {
        this.showError(message)
      } else {
        this.showProgress(message)
        let self = this
        promise.then(this.hideProgress)
          .catch(function (error) {
            this.showError(error.response.data[0])
            self.hideProgress()
          })
      }
      this.$refs.intentLabelFormModal.hide()
    },
    removeIntentLabel() {
      let result, message, promise
      [result, message, promise] = this.controller.deleteIntentLabel(this.intentLabelForm)
      if (!result) {
        this.showError(message)
      } else {
        this.showProgress(message)
        let self = this
        promise.then(this.hideProgress)
          .catch(function (error) {
            this.showError(error.response.data[0])
            self.hideProgress()
          })
      }
    },
    showProgress(message) {
      this.message = message
      this.$refs.progressModal.show()
    },
    hideProgress() {
      this.$refs.progressModal.hide()
    },
    showError(message) {
      this.message = message
      this.$refs.errorModal.show()
    },
    selectLabelColor({ color }) {
      // カラーが選択されたときにintentLabelFormを更新する
      this.intentLabelForm.labelColor = color;
    },
  }
}
</script>

<style scoped>

</style>
