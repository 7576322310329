/*
 * Webhook設定画面
 */
import Vue from 'vue'
import {UrlBuilder} from './util/url-builder'
import {API_URL} from './resource/urls'
import {WebhookController} from './controller/webhook'

import Synchronize from './components/Synchronize.vue'
import SuccessModal from "./components/SuccessModal.vue"
import Webhook from './components/Webhook.vue'
import ProgressModal from './components/ProgressModal.vue'
import ErrorModal from './components/ErrorModal.vue'
import ModalFooter from "./components/ModalFooter.vue"
import DeleteConfirmModal from "./components/DeleteConfirmModal.vue"
import ChatBox from './components/ChatBox.vue'
import LanguageChangeChatWindow from "./components/LanguageChangeChatWindow.vue"
import ErrorModalFooter from './components/ErrorModalFooter.vue'
import TestChat from './components/TestChat.vue'
import draggable from 'vuedraggable'


export const appWebhook = (i18n) => {
  /* コントローラ */
  const controller = new WebhookController(
    (new UrlBuilder(API_URL)).build(),
    window.requestContext.language,
    window.requestContext.userType,
    window.i18nContext,
    window.requestContext.auto_input_predict,
    window.requestContext.signature_key,
    window.requestContext.test_chat_platform,
    window.requestContext.available_platform,
  )

  /* コンポーネント登録 */
  Vue.component('progress-modal', ProgressModal)
  Vue.component('error-modal', ErrorModal)
  Vue.component('webhook', Webhook)
  Vue.component('draggable', draggable)
  Vue.component('modal-footer', ModalFooter)
  Vue.component('synchronize', Synchronize)
  Vue.component('success-modal', SuccessModal)
  Vue.component('delete-confirm-modal', DeleteConfirmModal)
  Vue.component('chat-box', ChatBox)
  Vue.component('language-change-chat-window', LanguageChangeChatWindow)
  Vue.component('error-modal-footer', ErrorModalFooter)
  Vue.component('test-chat', TestChat)

  new Vue({
    el: '#app-webhook',
    data: {
      controller: controller
    },
    mounted() {
      this.$nextTick(() => {
        controller.ready()
      })
    },
    i18n: i18n
  })
}
