<template>
  <!-- カード形式の回答表示用コンポネント -->
  <div class="mb-1">
    <div class="message-bg-grey">
      <div class="card-header">
        <div>
          {{ chatItem.title }}

          <!-- 翻訳表示領域 -->
          <chat-item-translation-line
            v-if="translationObject"
            :translation="translatedText(chatItem.title, chatItem.languageCode)"
            type="response_card_content"
          ></chat-item-translation-line>
        </div>

        <div class="card-subtitle">
          {{ chatItem.subtitle }}

          <!-- 翻訳表示領域 -->
          <chat-item-translation-line
            v-if="translationObject"
            :translation="translatedText(chatItem.subtitle, chatItem.languageCode)"
            type="response_card_content"
          ></chat-item-translation-line>
        </div>
      </div>

      <b-img
        v-if="chatItem.imageUrl"
        thumbnail
        fluid
        :src="chatItem.imageUrl"
        alt="Image"
      />

      <div class="card-button-container">
        <div v-for="(button, idx) in chatItem.buttons" :key="idx" class="ml-2">
          <div class="d-flex align-items-center">
            <div>
              {{ button.text }}

              <!-- 翻訳表示領域 -->
              <chat-item-translation-line
                v-if="translationObject"
                :translation="translatedText(button.text, chatItem.languageCode)"
                type="response_card_content"
              ></chat-item-translation-line>
            </div>
            <a
              v-if="button.url"
              :href="button.url"
              target="_blank"
              class="text-dark"
            >
              <i class="fas fa-external-link-alt ml-3"></i>
            </a>
          </div>
        </div>
      </div>
    </div>

    <chat-item-bottom-detail
      :chat-item="chatItem"
      :redirector-access-logs="redirectorAccessLogs"
    />
  </div>
</template>

<script>
export default {
  props: ["chatItem", "redirectorAccessLogs", "translationObject"],
  computed: {
    translatedText() {
      return (text, languageCode) => {
        if (!this.translationObject) {
          return "";
        }
        const translateKey = `${languageCode}__${text}`;
        let translated = this.translationObject[translateKey];
        if (translated && !translated.is_same_language && translated.text !== text) {
          return translated.text;
        }
        return "";
      }
    }
  }
};
</script>
