
import Vue from 'vue'
import {UrlBuilder} from "./util/url-builder"
import {API_URL} from "./resource/urls"
import { FeedbackConfigController } from './controller/feedback-config'

import Feedback from './components/Feedback.vue'
import ModalFooter from './components/ModalFooter.vue'
import ProgressModal from './components/ProgressModal.vue'
import ErrorModal from './components/ErrorModal.vue'
import DeleteConfirmModal from './components/DeleteConfirmModal.vue'
import { BasicSelect } from 'vue-search-select'


export const appFeedback = (i18n) => {
  /* コントローラ */
  const controller = new FeedbackConfigController(
    (new UrlBuilder(API_URL)).build(),
    window.i18nContext,
    window.requestContext
  )

  /* コンポーネント登録 */
  Vue.component('feedback', Feedback)
  Vue.component('modal-footer', ModalFooter)
  Vue.component('progress-modal', ProgressModal)
  Vue.component('error-modal', ErrorModal)
  Vue.component('basic-select', BasicSelect)
  Vue.component('delete-confirm-modal', DeleteConfirmModal)

  new Vue({
    el: '#app-feedback',
    data: {
      controller: controller
    },
    mounted() {
      this.$nextTick(() => {
        controller.ready()
      })
    },
    i18n: i18n
  })
}

