/*
 * ファイル管理画面
 */
import Vue from 'vue'
import {UrlBuilder} from './util/url-builder'
import {API_URL} from './resource/urls'
import {FileManagementController} from './controller/file-management'

import FileManagement from './components/FileManagement.vue'
import ModalFooter from './components/ModalFooter.vue'
import ErrorModal from './components/ErrorModal.vue'
import Pagination from './components/Pagination.vue'
import { formatDate } from './util/dayjs'


export const appFileManagement = (i18n) => {
  // TODO: Vue3対応あり(MOBOT-3295を確認)
  Vue.filter('moment', formatDate);

  /* コントローラ */
  const controller = new FileManagementController(
    (new UrlBuilder(API_URL)).build(),
    window.requestContext,
    window.i18nContext
  )

  /* コンポーネント登録 */
  Vue.component('file-management', FileManagement)
  Vue.component('modal-footer', ModalFooter)
  Vue.component('error-modal', ErrorModal)
  Vue.component('pagination', Pagination)

  new Vue({
    el: '#app-file-management',
    data: {
      controller: controller
    },
    mounted() {
      this.$nextTick(() => {
        controller.ready()
      })
    },
    i18n: i18n
  })
}
