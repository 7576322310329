<template>
  <div class="answer-text">
    <div>{{ $t('answer.content') }}({{ $t('answer.typeText') }}):</div>
    <div v-for="(reply, index) in responseMessage.text" :key="index">
      <div class="input-group mb-1">
        <div v-if="fromLanguage" class="input-group-append">
          <b-button
            :class="[recognizing && voiceInputTarget===index ? 'btn-success' : 'btn-primary', 'btn px-0']"
            style="width: 37px"
            @click="clickRecognitionButton(index)">
            <span v-show="recognizing && voiceInputTarget===index" class="mx-auto">
              <div class="d-flex flex-column">
                <b-spinner
                  variant="white"
                  type="grow"
                  class="mb-1 mx-auto"
                  style="width: 0.5rem; height: 0.5rem; animation-duration: 1.5s"
                />
                <b-spinner
                  variant="white"
                  type="grow"
                  class="mb-1 mx-auto"
                  style="width: 0.5rem; height: 0.5rem; animation-delay: 0.4s; animation-duration: 1.5s"
                />
                <b-spinner
                  variant="white"
                  type="grow"
                  class="mx-auto"
                  style="width: 0.5rem; height: 0.5rem; animation-delay: 0.8s; animation-duration: 1.5s"
                />
              </div>
            </span>
            <span v-show="!recognizing || voiceInputTarget!=index" class="mx-auto">
              <i class="fas fa-microphone"></i>
            </span>
          </b-button>
        </div>

        <b-form-textarea
          :value="reply.text"
          @input="
            reply.text = $event
            googleCheck($event)
          "
          rows="3"
          :disabled="isTranslating"
          :state="!$v.responseMessage.text.$each[index].text.$error"
        />
        <div class="input-group-append">
          <button
            class="btn btn-danger"
            :disabled="responseMessage.text.length == 1 || isTranslating || voiceInputTarget===index"
            @click.prevent="deleteTextReplyText(index)"
          >
            <i class="fa fa-minus" />
          </button>
        </div>
        <div v-if="isForPush" class="input-group-prepend" style="width: calc(100% + 1px); margin-left: -1px;">
          <button
            class="btn btn-primary w-100"
            @click.prevent="translateText(index)"
            :disabled="isTranslating"
          >
            <i class="fa fa-language"></i>
          </button>
        </div>
        <b-form-invalid-feedback
          v-if="$v.responseMessage.text.$each[index].text.$error"
        >
          {{ $t('webhook.required') }} &
          {{
            $t('answer.maxTxtLen', {
              counter:
                $v.responseMessage.text.$each[index].text.$params.maxLength.max
            })
          }}
        </b-form-invalid-feedback>
      </div>
    </div>
    <div class="mb-2">
      <button
        class="btn btn-success"
        :disabled="responseMessage.text.length == 20"
        @click.prevent="addText"
      >
        <i class="fa fa-plus" />
      </button>
      {{ responseMessage.text.length + ' / 20' }}
    </div>
  </div>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators'
import { TextData, ReplyText } from '../../model/intent'
import { illegalExp } from '../../util/illegalRegex'
import { loadSpeechInfo } from '../../util/service-connect-api';
import * as ObotAISpeech from '../../../obot-speech-client/dist/obot-speech-client.js';

export default {
  props: ['googleCheck', 'isForPush', 'fromLanguage'],
  data() {
    return {
      responseMessage: {
        text: [new ReplyText()]
      },
      answerText: null,
      isLineSelected: false,
      isTranslating: false,
      speechRecognizer: null,
      obotSpeechConnected: false,  // obot-speechへの接続が成功しているかどうか
      obotSpeechSTTOptions: {},
      voiceInputTarget: null,
      recognizing: false,
      recognitionStarting: false,  // 「音声入力」ボタンが押されるから、認識開始するまでの間を示すフラグ
      forceStopRecognitionTimer: null,  // 強制終了するためのタイマー
      currentTextLength: 0,
    }
  },
  validations() {
    return {
      responseMessage: {
        text: {
          $each: {
            text: {
              required,
              maxLength: maxLength(this.isForPush ? 5000 : 2000)
            }
          }
        }
      }
    }
  },
  async created () {
    // 音声入力を使用しない場合は何もしない
    if (!this.fromLanguage) return;
    
    // obot-speechのホスト情報を取得
    const speechInfoResponse = await loadSpeechInfo();
    const speechInfoData = speechInfoResponse.data;
    const obotSpeechWsHost = speechInfoData.ws_host;
    const obotSpeechPlatformName = speechInfoData.platform;
    const obotSpeechSessionId = speechInfoData.ssid;
    const obotSpeechServiceId = speechInfoData.svid;
    const obotSpeechToken = speechInfoData.tk;

    const self = this
    // 受信を開始する
    this.speechRecognizer = new ObotAISpeech.ObotAISpeechRecognizer({
      version: 2,
      models: this.recognizerModels
    })
    if (this.speechRecognizer) {
      this.speechRecognizer.init({
        serviceAddress: obotSpeechWsHost,
        sessionId: obotSpeechSessionId,
        serviceId: obotSpeechServiceId,
        platform: obotSpeechPlatformName,
        token: obotSpeechToken,
        onconnect () {
          self.obotSpeechConnected = true;
        },
        onbeforereconnect (retryTimes) {
          self.obotSpeechConnected = false;
          console.log(`retrying...${retryTimes}`);
        },
        onreconnect() {
          self.obotSpeechConnected = true;
        }
      })
    }
  },
  methods: {
    addText() {
      this.responseMessage.text.push(new ReplyText())
      // テキスト欄が増える度にバリデーションチェック
      // 使用サービスにGoogleが含まれる場合のみ
      this.googleCheck()
    },
    deleteTextReplyText(index) {
      this.responseMessage.text.splice(index, 1)
      // テキスト欄が減る度にバリデーションチェック
      // 使用サービスにGoogleが含まれる場合のみ
      this.googleCheck()
    },
    inputValue() {
      for (let msg of this.responseMessage.text) {
        msg.text = msg.text.replace(illegalExp, '').trim()
      }
      this.answerText = new TextData(this.responseMessage.text)
      return this.answerText
    },
    setValue(selectedResponseMessage) {
      this.responseMessage.text = []
      //this.responseMessageText = selectedResponseMessage.data.replies
      selectedResponseMessage.data.replies.forEach(rp => {
        let nrp = new ReplyText()
        Object.assign(nrp, rp)
        this.responseMessage.text.push(nrp)
      })
    },
    initializeValue() {
      this.responseMessage.text = [new ReplyText()]
      this.answerText = null
    },
    validationCheck() {
      const validation = this.$v
      validation.$touch() // Initialize the fields
      this.$emit('is-valid', !validation.$invalid) // Declare to the parent
    },
    /**
     * 渡されたidxの回答内容を翻訳し、翻訳後テキストを末尾に付加する
     */
    translateText(idx) {
      this.isTranslating = true
      // 翻訳api呼び出し
      this.$emit('on-call-translate', {
        idx,
        originalText: this.responseMessage.text[idx].text
      })
    },
    // 翻訳後テキストの付加
    applyTranslationResult({ idx, translatedText }) {
      if (translatedText) {
        this.responseMessage.text[idx].text = `${translatedText}
${this.responseMessage.text[idx].text}`
      }
      this.isTranslating = false
    },
    // obot-speech連携関連のメソッド
    clickRecognitionButton (idx) {
      if (this.recognizing || !this.speechRecognizer) {
        this.speechRecognizer.stopRecognizing();
      } else {
        this.voiceInputTarget = idx;
        this.currentTextLength = this.responseMessage.text[idx].text.length;
        this.startRecognize();
      }
    },
    startRecognize() {
      if (this.fromLanguage) {
        this.recognitionStarting = true;
        const self = this;
        const onStart = () => {
          self.refreshForceStopRecognitionTimer();
          self.recognizing = true;
          self.recognitionStarting = false;
        }
        const onEnd = () => {
          self.recognizing = false;
          self.recognitionStarting = false;
          self.clearForceStopRecognitionTimer();
        }
        const onFinalResult = (texts) => {
          const currentText = self.responseMessage.text[self.voiceInputTarget].text.slice(0, self.currentTextLength);
          let newValue = "";
          if (currentText) {
            newValue = `${currentText}\n${texts}`;
          } else {
            newValue = texts;
          }
          self.responseMessage.text[self.voiceInputTarget].text = newValue;
          self.voiceInputTarget = null;
          self.currentTextLength = 0;
          self.googleCheck();
          self.refreshForceStopRecognitionTimer();
        }
        const onIntermediateResult = async (text) => {
          const currentText = self.responseMessage.text[self.voiceInputTarget].text.slice(0, self.currentTextLength);
          let newValue = "";
          if (currentText) {
            newValue = `${currentText}\n${text}`;
          } else {
            newValue = text;
          }
          self.responseMessage.text[self.voiceInputTarget].text = newValue;
          self.refreshForceStopRecognitionTimer();
        }
        const onError = (e) => {
          console.error(e);
          self.recognizing = false;
          self.recognitionStarting = false;
          self.clearForceStopRecognitionTimer();
        }
        this.speechRecognizer.startRecognizing(
          this.fromLanguage,
          onStart,
          onEnd,
          onFinalResult,
          onIntermediateResult,
          onError,
          {
            ...this.obotSpeechSTTOptions,
            deviceId: this.meetingConfig ? this.meetingConfig.audioDeviceId : null,
            preTimeout: 5,
            sufTimeout: 3,
          }
        )
      }
    },
    stopRecognize() {
      if (!this.recognizing || !this.speechRecognizer) {
        return
      }
      this.speechRecognizer.stopRecognizing();
    },
    refreshForceStopRecognitionTimer() {
      this.clearForceStopRecognitionTimer();
      const self = this;
      this.forceStopRecognitionTimer = window.setTimeout(() => {
        self.stopRecognize();
      }, 3600000);
    },
    clearForceStopRecognitionTimer() {
      window.clearTimeout(this.forceStopRecognitionTimer);
    },
  }
}
</script>
