<template>
  <div class="mt-1 page-category col-md-3">
    <div
      class="column-caption my-1 p-1 font-weight-bold pointer-cursor"
      v-b-toggle.category_collapse
      @click="folded = !folded"
    >
      <span class="float-none pl-1" v-show="folded"
        ><i class="fas fa-caret-down"></i
      ></span>
      <span class="float-none pl-1" v-show="!folded"
        ><i class="fas fa-caret-up"></i
      ></span>
      <span class="pl-1">{{ $t("category.title") }}</span>
    </div>

    <b-collapse visible id="category_collapse">
      <!-- 追加ボタンなど -->
      <div class="row mb-1" v-if="userType != 'viewer'">
        <div class="col-auto mr-auto">
          <button
            v-show="!isMultiSelectMode"
            class="btn btn-success button-text mr-1"
            @click="startMultiSelectMode"
            :disabled="isMultiSelectMode"
          >
            <i class="fas fa-list-ul"></i>
            <span>{{ $t("faq.bulkOperations") }}</span>
          </button>

          <!-- 一括操作キャンセルボタン -->
          <button
            v-show="isMultiSelectMode"
            class="btn btn-secondary button-text mr-1"
            @click="cancelMultiSelect"
          >
            <i class="fas fa-list-ul"></i>
            <span>{{ $t("buttons.cancel") }}</span>
          </button>

          <!-- 全選択ボタン -->
          <button
            v-show="isMultiSelectMode && !isSelectedAll()"
            class="btn btn-success button-text mr-1"
            @click="selectAllCategory()"
          >
            <i class="fas fa-arrows-alt-h"></i>
            <span>{{ $t("buttons.selectAll") }}</span>
          </button>

          <!-- 全解除ボタン -->
          <button
            v-show="isMultiSelectMode && isSelectedAll()"
            class="btn btn-danger button-text mr-1"
            @click="clearAllCategory()"
          >
            <i class="fas fa-arrows-alt-h"></i>
            <span>{{ $t("buttons.clearAll") }}</span>
          </button>

          <!-- 追加ボタン -->
          <button
            v-show="!isMultiSelectMode"
            class="btn btn-success button-text mr-1"
            @click="openAddCategoryModal"
          >
            <i class="fa fa-plus"></i>
            <span>{{ $t("buttons.add") }}</span>
          </button>

          <!-- 編集ボタン -->
          <button
            class="btn btn-success button-text mr-1"
            @click="
              isMultiSelectMode
                ? openMultiEditCategoryModal()
                : openEditCategoryModal()
            "
            :disabled="
              isMultiSelectMode
                ? multiSelectedCategoryIds.length === 0
                : !selectedCategory
            "
          >
            <i class="fa fa-edit"></i>
            <span>{{ $t("buttons.edit") }}</span>
          </button>

          <!-- 削除ボタン -->
          <button
            class="btn btn-success button-text mr-1"
            @click="
              isMultiSelectMode
                ? showDeleteMultiCategoryConfirmation()
                : showDeleteCategoryConfirmation()
            "
            :disabled="deleteDisabled()"
          >
            <i class="fas fa-trash-alt"></i>
            <span>{{ $t("buttons.delete") }}</span>
          </button>
        </div>
      </div>

      <!-- 検索インプット -->
      <b-form-input
        type="text"
        v-model="searchCategoryWord"
        class="mb-1"
        :placeholder="$t('intent.searchCategory')"
        :formatter="format"
      />

      <!-- 一括操作中の警告 -->
      <div
        v-if="isMultiSelectMode && isSelectedNotDisplayCategory()"
        class="alert alert-dismissable alert-warning"
      >
        <i class="fas fa-exclamation-circle"></i>
        {{ $t("faq.selectedNotDisplayCategory") }}
      </div>

      <!-- カテゴリ一覧 -->
      <ul style="padding-left: 0">
        <!-- すべて -->
        <li class="category-item">
          <div
            class="label my-1 p-1"
            :class="{
              selected: isMultiSelectMode ? false : !selectedCategory,
            }"
            @click="selectCategory(null)"
          >
            {{ $t("category.all") }}
          </div>
        </li>
      </ul>

      <ul
        class="scroll-category pl-0"
        tabindex="-1"
        ref="focusOn"
        id="scrollY_IntentCategory"
      >
        <!-- カテゴリ -->
        <li
          v-for="category in categories"
          :key="category.id"
          class="category-item"
          :style="{
            paddingLeft: getItemPadding(category),
            display: filterCategory(category),
          }"
          :title="
            category.active
              ? $t('faq.validCategory')
              : $t('faq.invalidCategory')
          "
        >
          <div
            class="label my-1 p-1"
            :class="{ selected: isItemSelected(category) }"
            @click="
              isMultiSelectMode
                ? multiSelectCategory(category.id)
                : selectCategory(category.id)
            "
          >
            <span v-if="category.active">
              <icon-check></icon-check>
            </span>
            <span :class="[!category.active && 'text-black-50', 'badge']">
              <!-- 選択中の言語での表示名があればそちらを表示。なければ共通の名称を使用 -->
              {{ category.display_names?.[selectedLanguage] || category.name }}
            </span>
          </div>
        </li>
      </ul>
    </b-collapse>

    <error-modal ref="errorModal" :message="message" />
    <progress-modal ref="progressModal" :message="message" />

    <!-- カテゴリ作成・編集モーダル -->
    <b-modal
      :title="
        categoryModalType === 'CREATE'
          ? $t('category.add')
          : $t('category.edit')
      "
      ref="categoryModal"
      header-class="bg-info text-light"
      body-class="text-info"
      no-close-on-esc
      no-close-on-backdrop
    >
      <div>
        <div>{{ $t("category.title") }}：</div>

        <model-select
          v-model="modalData.parentCategory"
          :options="
            [
              {
                text: '---',
                value: null,
              },
            ].concat(
              categories
                .filter(
                  (c) =>
                    categoryModalType === 'CREATE' ||
                    (selectedCategory &&
                      c.id !== selectedCategory.id &&
                      !c.full_name.startsWith(
                        `${selectedCategory.full_name} > `
                      ))
                )
                .map((c) => ({
                  text: getFormattedCategoryFullName(c),
                  value: c.id,
                }))
            )
          "
          :is-error="!modalData.parentCategory"
          :placeholder="$t('faq.selectCategory')"
        />

        <div>{{ $t("faq.categoryName") }}({{ $t("faq.common") }}):</div>
        <b-form-input
          v-model="modalData.categoryName"
          name="category-name-common"
          type="text"
          ref="focusInput"
          :maxlength="categoryNameMaxLength"
          :state="modalData.categoryName ? isValidModalCategoryName() : null"
        />

        <div>
          {{ $t("faq.categoryDisplayName") }}({{ selectedLanguageLabel }}):
        </div>
        <b-form-input
          v-model="modalData.categoryNameLanguage"
          name="category-name-lang"
          type="text"
          :maxlength="categoryNameMaxLength"
          :state="
            modalData.categoryNameLanguage
              ? isValidModalCategoryNameLanguage()
              : null
          "
        />

        <div>{{ $t("faq.state") }}:</div>
        <b-form-radio-group v-model="modalData.active">
          <b-form-radio value="true">{{ $t("faq.valid") }}</b-form-radio>
          <b-form-radio value="false">{{ $t("faq.invalid") }}</b-form-radio>
        </b-form-radio-group>

        <div>{{ $t("faq.priority") }}:</div>
        <b-form-group :invalid-feedback="$t('faq.priorityErrorMessage')">
          <b-form-input
            v-model.number="modalData.priority"
            name="category-priority"
            type="number"
            max="32767"
            placeholder="0~32767"
            :state="isPriorityValid()"
          />
        </b-form-group>

        <div>{{ $t("faq.default") }}:</div>
        <b-form-radio-group v-model="modalData.isDefault">
          <b-form-radio value="true">{{ $t("faq.setUp") }}</b-form-radio>
          <!-- 編集時はデフォルトTrueの場合はFalseにできないようにする -->
          <b-form-radio
            value="false"
            :disabled="
              categoryModalType === 'EDIT' && selectedCategory?.default
            "
          >
            {{ $t("faq.noSet") }}
          </b-form-radio>
        </b-form-radio-group>
      </div>

      <modal-footer
        slot="modal-footer"
        @ok="saveCategory"
        :okDisabled="
          !(
            modalData.categoryName &&
            isValidModalCategoryName() &&
            modalData.categoryName.length <= 100 &&
            isPriorityValid()
          )
        "
        @cancel="$refs.categoryModal.hide()"
      >
      </modal-footer>
    </b-modal>

    <!-- カテゴリ一括編集モーダル -->
    <b-modal
      :title="$t('category.edit')"
      ref="multiCategoryModal"
      header-class="bg-info text-light"
      body-class="text-info"
      no-close-on-esc
      no-close-on-backdrop
    >
      <div>
        <div>{{ $t("category.title") }}：</div>

        <model-select
          v-model="multiModalData.operation"
          :options="
            [
              {
                text: $t('faq.selectOperations'),
                value: null,
              },
            ].concat(
              multiModalOperations.map(({ text, value }) => ({
                text,
                value,
              }))
            )
          "
          :state="!!multiModalData.operation"
          :placeholder="$t('faq.selectOperations')"
        />

        <div v-if="multiModalData.operation.value === 'CHANGE_CATEGORY'">
          <div>{{ $t("faq.category") }}:</div>
          <model-select
            v-model="multiModalData.category"
            :options="getMultiEditCategoryOptions()"
            :is-error="!multiModalData.category"
            :placeholder="$t('faq.selectCategory')"
          />
        </div>

        <div v-if="multiModalData.operation.value === 'CHANGE_STATE'">
          <div>{{ $t("faq.state") }}:</div>
          <b-form-radio-group v-model="multiModalData.active">
            <b-form-radio value="true">{{ $t("faq.valid") }}</b-form-radio>
            <b-form-radio value="false">{{ $t("faq.invalid") }}</b-form-radio>
          </b-form-radio-group>
        </div>
      </div>

      <modal-footer
        slot="modal-footer"
        :okDisabled="!multiModalData.operation.value"
        @ok="saveMultiCategory"
        @cancel="$refs.multiCategoryModal.hide()"
      >
      </modal-footer>
    </b-modal>

    <delete-confirm-modal
      ref="deleteCategoryConfirmModal"
      :title="$t('category.delete')"
      :bodyMessage="`カテゴリ ${
        this.selectedCategory?.[this.selectedLanguage] ??
        this.selectedCategory?.name
      } を削除しますか？`"
      :bodySubMessage="[$t('category.deleteOtherLanguagesWarning')]"
      @ok="deleteCategory"
      @key-press-enter="$refs.deleteCategoryConfirmModal.hide()"
    />

    <!-- 一括削除確認モーダル -->
    <delete-confirm-modal
      ref="deleteMultiCategoryConfirmModal"
      :title="$t('category.delete')"
      :bodyMessage="`${this.multiSelectedCategoryIds.length}件のカテゴリを削除しますか？`"
      :bodySubMessage="[$t('category.deleteOtherLanguagesWarning')]"
      @ok="$emit('delete-multi-category', multiSelectedCategoryIds)"
      @key-press-enter="$refs.deleteMultiCategoryConfirmModal.hide()"
    />
  </div>
</template>

<script>
import { I18n } from "../util/i18n";
import { ModelSelect } from "vue-search-select";

export default {
  props: [
    "categories",
    "selectedCategory",
    "selectedLanguageLabel",
    "selectedLanguage",
    "multiSelectedCategoryIds",
  ],
  components: { ModelSelect },
  data() {
    const i18n = new I18n(window.i18nContext);
    return {
      message: "",
      folded: false,
      searchCategoryWord: "",
      /** カテゴリ名の最大文字数 */
      categoryNameMaxLength: 50,
      /** モーダルのデータ */
      modalData: {
        /** 親カテゴリ */
        parentCategory: { value: null, text: "---" },
        /** 共通のカテゴリ名 */
        categoryName: "",
        /** 選択中言語のカテゴリ名 */
        categoryNameLanguage: "",
        /** カテゴリ優先度 */
        priority: 0,
        /** 有効・無効の設定 */
        active: true,
        /** デフォルトの設定 */
        isDefault: false,
      },
      /** 単体操作時のカテゴリの種別。作成or更新 */
      categoryModalType: "CREATE",
      /** 複数選択モードかどうか */
      isMultiSelectMode: false,
      /** 一括操作モーダルのデータ */
      multiModalData: {
        /** 操作種別 */
        operation: {
          text: this.$t("faq.selectOperations"),
          value: null,
        },
        /** カテゴリ変更の場合の選択 */
        category: {
          text: "---",
          value: null,
        },
        active: false,
      },
      /**
       * 一括操作モーダルの操作一覧
       */
      multiModalOperations: [
        {
          text: this.$t("faq.changeCategory"),
          value: "CHANGE_CATEGORY",
        },
        {
          text: this.$t("faq.changeState"),
          value: "CHANGE_STATE",
        },
      ],
    };
  },
  methods: {
    showError(message) {
      this.message = message;
      this.$refs.errorModal.show();
    },
    showProgress(message) {
      this.message = message;
      this.$refs.progressModal.show();
    },
    hideProgress() {
      this.$refs.progressModal.hide();
    },
    /**
     * カテゴリ作成モーダルを表示
     */
    openAddCategoryModal() {
      // モーダルの種別の設定
      this.categoryModalType = "CREATE";

      // モーダルデータを初期化
      this.modalData = {
        parentCategory: { value: null, text: "---" },
        categoryName: "",
        categoryNameLanguage: "",
        priority: 0,
        active: true,
        isDefault: false,
      };

      // モーダルを開く
      this.$refs.categoryModal.show();
    },
    /**
     * カテゴリ編集モーダルを表示
     */
    openEditCategoryModal() {
      // モーダルの種別の設定
      this.categoryModalType = "EDIT";

      // モーダルデータの更新
      const { parent, name, display_names, priority, active } =
        this.selectedCategory;
      const parentCategory = this.categories.find((c) => c.id === parent);
      this.modalData = {
        parentCategory: parentCategory
          ? {
              value: parentCategory.id,
              text: this.getFormattedCategoryFullName(parentCategory),
            }
          : { value: null, text: "---" },
        categoryName: name,
        categoryNameLanguage: display_names?.[this.selectedLanguage] ?? "",
        priority: priority,
        active: active ?? true,
        isDefault: this.selectedCategory.default ?? false,
      };

      // モーダルを開く
      this.$refs.categoryModal.show();
    },
    /**
     * カテゴリ保存処理
     */
    async saveCategory() {
      // 追加、編集の処理
      if (this.categoryModalType === "CREATE") {
        // 追加処理
        this.$emit("create-category", {
          category: this.modalData,
          // 親コンポネントからモーダルを閉じるためにコールバックを渡す
          closeModal: () => {
            this.modalData = {
              parentCategory: { value: null, text: "---" },
              categoryName: "",
              categoryNameLanguage: "",
              priority: 0,
              active: false,
              isDefault: false,
            };

            this.$refs.categoryModal.hide();
          },
        });
      } else {
        // 更新処理
        this.$emit("update-category", {
          category: this.modalData,
          // 親コンポネントからモーダルを閉じるためにコールバックを渡す
          closeModal: () => {
            this.modalData = {
              parentCategory: { value: null, text: "---" },
              categoryName: "",
              categoryNameLanguage: "",
              priority: 0,
              active: false,
              isDefault: false,
            };

            this.$refs.categoryModal.hide();
          },
        });
      }
    },
    /**
     * カテゴリ削除時の確認を表示
     */
    showDeleteCategoryConfirmation() {
      this.$refs.deleteCategoryConfirmModal.show();
    },
    /**
     * カテゴリ一括削除時の確認を表示
     */
    showDeleteMultiCategoryConfirmation() {
      this.$refs.deleteMultiCategoryConfirmModal.show();
    },
    /**
     * カテゴリ削除を親コンポネントで行う
     */
    deleteCategory() {
      this.$emit("delete-category", [this.selectedCategory.id]);
    },
    /**
     * 検索処理
     */
    filterCategory(category) {
      let displayStatus = "";
      if (this.searchCategoryWord) {
        const categoryName =
          category.display_names?.[this.selectedLanguage] || category.name;
        const lowercaseCategoryName = categoryName.toLowerCase();
        displayStatus = lowercaseCategoryName.includes(this.searchCategoryWord)
          ? ""
          : "none";
      }
      return displayStatus;
    },
    format(value, event) {
      return value.toLowerCase();
    },
    filteredCategoryIds() {
      const categoryIds = [];
      this.categories.forEach((category) => {
        if (this.filterCategory(category) === "") {
          categoryIds.push(category.id);
        }
      });
      return categoryIds;
    },
    /**
     * カテゴリ選択時の処理
     */
    selectCategory(categoryId) {
      this.$emit("on-select-category", categoryId);
    },
    /**
     * 複数選択処理
     */
    multiSelectCategory(categoryId) {
      if (categoryId === null) {
        // すべては選択してもなにもしない
        return;
      }

      this.$emit("on-multi-select-category", categoryId);
    },
    selectAllCategory() {
      this.filteredCategoryIds().forEach((id) => {
        if (!this.multiSelectedCategoryIds.includes(id)) {
          this.multiSelectCategory(id);
        }
      });
    },
    clearAllCategory() {
      this.multiSelectedCategoryIds.forEach((id) => {
        this.multiSelectCategory(id);
      });
    },
    isSelectedAll() {
      const filteredCategoryIds = this.filteredCategoryIds();
      return (
        filteredCategoryIds.length > 0 &&
        filteredCategoryIds.every((id) =>
          this.multiSelectedCategoryIds.includes(id)
        )
      );
    },
    isSelectedNotDisplayCategory() {
      const filteredCategoryIds = this.filteredCategoryIds();
      const selectedNotDisplayCategoryIds =
        this.multiSelectedCategoryIds.filter(
          (id) => !filteredCategoryIds.includes(id)
        );
      return (
        selectedNotDisplayCategoryIds.length > 0 &&
        !!selectedNotDisplayCategoryIds
      );
    },
    /**
     * カテゴリ名が正しい形式かどうか
     */
    isValidModalCategoryName() {
      return this.isCategoryNameValid(this.modalData.categoryName);
    },
    /**
     * 言語別カテゴリ名が正しい形式かどうか
     */
    isValidModalCategoryNameLanguage() {
      return this.isCategoryNameValid(this.modalData.categoryNameLanguage);
    },
    /**
     * 100文字以内
     * > を用いて親子関係を表しているため >を名前にいれるのを禁止する。
     */
    isCategoryNameValid(categoryName) {
      return categoryName.trim().match(/^[^_>]+$/) != null;
    },
    /**
     * 一括選択開始
     */
    startMultiSelectMode() {
      this.isMultiSelectMode = true;
    },
    /**
     * アイテムが選択中かどうか
     */
    isItemSelected(category) {
      if (this.isMultiSelectMode) {
        // 一括操作モードの場合は選択中の配列を参照
        return this.multiSelectedCategoryIds.includes(category.id);
      } else {
        // 通常モードの場合は選択中のカテゴリを参照
        return category.id === this.selectedCategory?.id;
      }
    },
    /**
     * 一括選択解除
     */
    cancelMultiSelect() {
      this.$emit("on-cancel-multi-select");

      this.isMultiSelectMode = false;
    },
    /**
     * 一括編集モーダルを表示
     */
    openMultiEditCategoryModal() {
      this.$refs.multiCategoryModal.show();
    },
    /**
     * 一括編集の保存処理
     */
    saveMultiCategory() {
      this.$emit("update-multi-category", {
        operation: this.multiModalData.operation.value,
        categoryId: this.multiModalData.category.value,
        active: this.multiModalData.active,
      });

      // モーダルを閉じる
      this.$refs.multiCategoryModal.hide();
      this.cancelMultiSelect();
    },
    /**
     * リストのアイテムの左側のパディングをカテゴリ階層数に応じて返す
     */
    getItemPadding(category) {
      return `${20 * (category.full_name.match(/>/g)?.length ?? 0)}px`;
    },
    deleteDisabled() {
      if (this.isMultiSelectMode) {
        return (
          this.multiSelectedCategoryIds.length === 0 ||
          this.multiSelectedCategoryIds.some(
            (id) => this.categories.find((c) => c.id === id)?.default
          )
        );
      }
      return !this.selectedCategory || this.selectedCategory?.default;
    },
    getMultiEditCategoryOptions() {
      // 選択中のカテゴリデータを取得
      const selectedCategories = this.multiSelectedCategoryIds.map((id) =>
        this.categories.find((c) => c.id === id)
      );

      // 無効なカテゴリがあった場合は空配列を返す
      if (selectedCategories.some((c) => c === undefined)) {
        console.warn("invalid category exists");
        return [];
      }

      return [
        {
          text: "---",
          value: null,
        },
      ].concat(
        this.categories
          .filter(
            (c) =>
              // 選択中のカテゴリは除く
              !this.multiSelectedCategoryIds.includes(c.id) &&
              // 選択中のカテゴリの子孫カテゴリは除く
              !selectedCategories.some((sc) =>
                c.full_name.startsWith(`${sc.full_name} > `)
              )
          )
          .map((c) => ({
            text: this.getFormattedCategoryFullName(c),
            value: c.id,
          }))
      );
    },
    getFormattedCategoryFullName(category) {
      let fullpath = "";
      const categoryFullNames = category.full_name.split(" > ");
      categoryFullNames.forEach((name, idx) => {
        const c = this.categories.find((c) => c.name === name);
        const displayName = c.display_names?.[this.selectedLanguage];
        if (displayName) {
          fullpath += `${displayName}`;
        } else {
          fullpath += `${name}`;
        }
        if (idx + 1 !== categoryFullNames.length) {
          fullpath += " > ";
        }
      });
      return fullpath;
    },
    /**
     * 優先度が正しい形式かどうか
     * 0~32767であること
     */
    isPriorityValid() {
      let isValid = true;
      const priority = this.modalData.priority;
      if (!(typeof priority === "number")) {
        /** 入力されていない */
        isValid = false;
      }
      if (priority !== Math.round(priority)) {
        /** 整数でない */
        isValid = false;
      }
      if (priority < 0 || priority > 32767) {
        /** 範囲外 */
        isValid = false;
      }
      return isValid;
    },
  },
};
</script>

<style scoped>
.selected-language {
  border: 3px solid #b9bbbe;
  color: #fff;
}
.table td {
  min-width: 120px;
}
</style>
