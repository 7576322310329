<template>
  <!--TODO add translation-->
  <b-modal
    ref="modal"
    :title="$t('question.searchQuestions')"
    header-class="bg-info text-light"
    body-class="text-info"
    no-close-on-backdrop
    :ok-only="true"
    ok-title="Close"
    ok-variant="secondary"
    @shown="focusSearchWindow">
    <div class="mb-2" v-if="wordEditor">
      <model-select
        v-model="selectIntentCategory"
        :options="wordEditor.intentCategories"
        :placeholder="$t('question.selectCategoryForSearchFilter')">
      </model-select>
    </div>
    <form
      @submit.prevent="searchQuestion">
      <b-form-input
        ref="initialFocusItem"
        v-model="searchingString"
        :placeholder="$t('intent.searchKeyword')"
        v-bind:class="{'is-invalid':resultsCheck === false}"
        aria-describedby="inputWordCheck">
      </b-form-input>
      <b-form-invalid-feedback id="inputWordCheck">{{ $t('intent.searchIncorrectResult') }}</b-form-invalid-feedback>
      <div class="row">
        <div class="col-auto mr-auto">
          <b-form-checkbox
            v-model="groupBinding">
            {{ $t('intent.groupBind') }}
          </b-form-checkbox>
        </div>
        <div class="col-auto">
          <div class="action-buttons">
            <button
              class="btn btn-primary"
              :disabled="!isValidSearchingString">
              <span><i class="fas fa-search"></i></span>
            </button>
          </div>
        </div>
      </div>
    </form>
    <div>
      <div  v-if="userType !== 'viewer'">
        <span v-show="resultsCheck === true">{{ $t('intent.selectQuestion') }}</span>
        <b-btn
          v-show="resultsCheck === true"
          @click="toggleQuestionsChecked">
          <span>{{ $t('intent.selectUnselectAll') }}</span>
        </b-btn>
      </div>
      <ul class="user-says-search-results">
        <li v-for="(viewer, index) in resultsViewer">
          <div>
            <div>
              <div class="row column-caption my-1 p-1">
                <div class="col-auto mr-auto">
                  <span class="question-title">{{ viewer.title }}</span>
                  <input
                    type="checkbox"
                    :checked="isIntentChecked(viewer.userSays)"
                    @input="OnIntentCheck(viewer.userSays)"/>
                </div>
                <div class="col-auto">
                  <span class="badge badge-light badge-pill">
                    <a v-bind:href="goToIntentDetail(viewer.title)"
                       class="text-dark"
                       target="_blank"
                       :title="$t('question.moveToIntent')">
                      <i class="fas fa-external-link-alt"></i>
                    </a>
                  </span>
                </div>
              </div>
              <div class="my-1 p-1">
                <user-says-search-result
                  :userSays="viewer.userSays"
                  :selectedOptions="selectedOptions"
                  :searchedString="searchedString"
                  :userType="userType"
                  :language="language"
                  :question-title="viewer.title"
                  @on-user-say-checked="OnUserSayChecked">
                </user-says-search-result>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div v-if="userType !== 'viewer'">
      <div v-if="wordEditor">
        <span>{{ $t('intent.selectKeywordInfo') }}</span>
        <b-card>
          <span>{{ $t('question.keywordCategory') }}:</span>
          <b-btn
            class="mb-1"
            @click="addCategory">
            <span>{{ $t('buttons.add') }}</span>
          </b-btn>
          <model-select
            v-model="selectKeywordCategory"
            :options="wordEditor.keywordCategories"
            :placeholder="$t('question.selectCategory')">
          </model-select>
          <span>{{ $t('question.keyword') }}:</span>
          <b-btn
            class="mt-1 mb-1"
            @click="addKeyword"
            :disabled="!wordEditor.selectedCategory || wordEditor.systemEntity">
            <span>{{ $t('buttons.add') }}</span>
          </b-btn>
          <model-select
            v-model="selectKeyword"
            :options="wordEditor.keywords"
            :placeholder="$t('question.selectKeywordTitle')">
          </model-select>
          <div class="action-buttons">
            <b-btn
              :title="$t('question.bindToKeyword')"
              variant="success"
              @click="linkKeyword"
              :disabled="!isReadyForlink">
              <span><i class="fas fa-link"></i></span>
            </b-btn>
            <b-btn
              :title="$t('question.unbindKeyword')"
              variant="danger"
              @click="unlinkKeyword"
              :disabled="!isReadyForUnlink">
              <span><i class="fas fa-unlink"></i></span>
            </b-btn>
            <p v-if="isSelectionInvalid" style="color: red; padding: 0; margin: 0;"><small>{{ $t('question.selectionContainsParenthesis') }}</small></p>
          </div>
        </b-card>
      </div>
    </div>
  </b-modal>
</template>

<script>
  import {I18n} from "../util/i18n";
  import {KeywordValue} from "../model/keyword";
  import { ModelSelect } from 'vue-search-select';
  import {str_z2h} from "../util/string-z2h";
  import {IntentDetailFormatter} from "../controller/intent-detail-formatter";

  export default {
    props: [
      'wordEditor',
      'questionSearchResult',
      'apiUrl',
      'language',
      'userType',
      'keywordFullNameById',
      'intentDetailKeywordList',
      'intentDetailKeywordListBySearch'
    ],
    components: { ModelSelect },
    data() {
      return {
        i18n: new I18n(window.i18nContext),
        searchingString: null,
        searchedString: [],
        // 質問検索モーダルで選択されているuserSayのidを保持する
        selectedOptions: [],
        selectedKeywordCategory: null,
        selectedKeyword: null,
        selectedWord: null,
        keywordCategoryAdd: false,
        keywordAdd: false,
        intentDetailFormatter:null,
        resultsViewer:[],
        resultsCheck: null,
        firstDelete:true,
        groupBinding:false,
        selectedIntentCategory: null
      }
    },
    watch: {
      questionSearchResult: function(){
        delete this.intentDetailFormatter
        this.resultsViewer = null
        if(this.questionSearchResult)
          this.intentDetailFormatter = new IntentDetailFormatter(this.apiUrl, this.language, this.questionSearchResult, this.searchedString, this.wordEditor)
        if(this.intentDetailFormatter){
          this.resultsViewer = this.intentDetailFormatter.resultsViewer
          if(this.resultsViewer.length === 0){
            this.resultsCheck = false
          }else{
            this.resultsCheck = true
          }
        }
      }
    },
    computed: {
      searchCheckboxOptions() {
        let checkboxOptions = null
        for(let i = 0; i < this.searchedString.length; i++){
          let compareString = str_z2h(this.searchedString[i])
          checkboxOptions = this.questionSearchResult.map(value => {
            let userSays = value.intentDetail.data.userSays.map(userSay => {
              if (str_z2h(userSay.text.toLowerCase()).includes(compareString)) {
                return {text: userSay.text, value: userSay.id}
              } else {
                return null
              }
            })
            return userSays
          })
          checkboxOptions = checkboxOptions.map(value => {
            return value.filter(innerValue => {
              return innerValue != null
            })
          })
        }
        return checkboxOptions
      },
      isReadyForlink() {
        return this.selectedOptions.length > 0 && !!this.selectedKeywordCategory && !!this.selectedKeyword && !this.isSelectionInvalid
      },
      isReadyForUnlink() {
        return this.selectedOptions.length > 0
      },
      isValidSearchingString(){
        return this.searchingString&&this.searchingString.match(/[\S]/)!=null
      },
      isSelectionInvalid() {
        if (!this.selectedKeywordCategory || this.selectedKeywordCategory === 'System_Entity' || this.selectedOptions.length === 0) {
          return false
        } else {
          return this.searchedString.some(str => (/[()]+/g).test(str))
        }
      },
      selectIntentCategory: {
        get: function() {
          return this.wordEditor.itemIntentCategory;
        },
        set: function(category) {
          this.wordEditor.itemIntentCategory = category;
          if(category){
            category = category.value;
          }
          this.selectedIntentCategory = category;
        }
      },
      selectKeywordCategory: {
        get: function() {
          return this.wordEditor.itemCategory;
        },
        set: function(category) {
          if( category.value === 'System_Entity' ){
            this.wordEditor.systemEntity = true;
          }else{
            this.wordEditor.systemEntity = false;
          }
          this.wordEditor.itemCategory = category;
          if(category){
            category = category.value;
          }
          this.selectedKeywordCategory = category;
          this.selectedKeyword = null;
          this.wordEditor.selectKeywordCategory(this.selectedKeywordCategory);
        }
      },
      selectKeyword: {
        get: function() {
          return this.wordEditor.itemKeyword;
        },
        set: function(keyword) {
          this.wordEditor.itemKeyword = keyword;
          if(keyword){
            if (!!keyword.value) {
              keyword=keyword.value;
              if(this.wordEditor.systemEntity){
                keyword = keyword.name;
              }
              if(typeof keyword === 'object'){
                this.$emit('load-intent-detail-keyword', keyword.id);
              }
            } else {
              keyword = null;
            }
          }
          this.selectedKeyword = keyword;
          this.wordEditor.selectKeyword(this.selectedKeyword);
        }
      },
    },
    methods: {
      getResultsViewer(){
        return this.intentDetailFormatter&&this.intentDetailFormatter.resultsViewer ? this.intentDetailFormatter.resultsViewer : false
      },
      show() {
        this.$emit('reset-search-result')
        this.selectedKeywordCategory = null
        this.selectedKeyword = null
        this.selectedOptions = []
        this.resultsCheck = null
        this.$refs.modal.show()
      },
      display() {
        // キーワードカテゴリやキーワードを追加した時、違う振る舞いのshowメソッドが必要
        if(this.keywordCategoryAdd) {
          this.selectedKeywordCategory = this.wordEditor.selectedCategory
          this.selectedKeyword = null
          this.keywordCategoryAdd = false
        }
        else if(this.keywordAdd){
          this.selectedKeyword = this.wordEditor.selectedKeyword
          this.keywordAdd = false
        }
        this.$refs.modal.show()
      },
      searchQuestion() {
        this.searchedString = []
        if(!this.groupBinding){
          let splitStr = this.searchingString.trim().split(/\s+/g)
          for(let i = 0; i < splitStr.length; i++){
            this.searchedString[i] = str_z2h(splitStr[i].toLowerCase())
          }
        }
        else{
          this.searchedString[0]=str_z2h(this.searchingString.replace(/\s+/g,' ').trim().toLowerCase())
        }

        this.$emit('search-question', this.searchedString, this.selectedIntentCategory)
      },
      saveKeywordValue() {
        let modalKeywordValue = new KeywordValue()
        for(let i = 0; i < this.searchedString.length; i++){
          modalKeywordValue.value = this.searchedString[i]
          modalKeywordValue.language = this.language
          modalKeywordValue.keywordId = this.selectedKeyword.id
          modalKeywordValue.setSynonymFromText(this.searchedString[i])
          this.$emit('save-keyword-value', modalKeywordValue)
        }
      },
      linkKeyword() {
        this.$emit('started-linking', this.i18n.t("general.saving"))
        if(!this.wordEditor.systemEntity){
          this.saveKeywordValue()
        }
        this.intentDetailFormatter.setSelectedOptions(this.selectedOptions)
        this.intentDetailFormatter.setSelectedKeywordInfo(this.selectedKeywordCategory, this.selectedKeyword)
        this.intentDetailFormatter.setWordEditor(this.wordEditor)
        let promises = this.intentDetailFormatter.linkKeyword()
        Promise.all(promises)
        .then(intentDetails => {
          this.$emit('update-searched-question', intentDetails)
        })
        this.$refs.modal.hide()
      },
      unlinkKeyword() {
        this.$emit('started-linking', this.i18n.t('general.saving'))
        this.intentDetailFormatter.setSelectedOptions(this.selectedOptions)
        let newIntentDetails = this.intentDetailFormatter.unlinkKeyword()
        this.$emit('update-searched-question', newIntentDetails)
        this.$refs.modal.hide()
      },
      isIntentChecked(userSays){
        let userIds = userSays.map( userSay => {
          return userSay.id
        })
        return !userIds.some(val => this.selectedOptions.indexOf(val) === -1)
      },
      toggleQuestionsChecked() {
        let optionLength = [].concat.apply([], this.searchCheckboxOptions).length
        if (this.selectedOptions.length == optionLength) {
          this.selectedOptions = []
        } else {
          this.selectedOptions = this.searchCheckboxOptions.map(item => {
            return item.map(innerItem => {
              return innerItem.value
            })
          })
          this.selectedOptions = [].concat.apply([], this.selectedOptions)
        }
      },
      focusSearchWindow(e) {
        this.$refs.initialFocusItem.focus()
      },
      OnIntentCheck(userSays){
        let userIds = userSays.map( userSay => {
          return userSay.id
        })
        if(!userIds.some(val => this.selectedOptions.indexOf(val) === -1)){
          userIds.forEach(id =>{
            let index = this.selectedOptions.indexOf(id)
            this.selectedOptions.splice(index, 1)
          })
        }else{
          userIds.forEach(id => {
            if(this.selectedOptions.indexOf(id) === -1){
              this.selectedOptions.push(id)
            }
          })
        }
      },
      OnUserSayChecked(userSayId) {
        let userSayIdIndex = this.selectedOptions.indexOf(userSayId)
        if (userSayIdIndex >= 0) {
          this.selectedOptions.splice(userSayIdIndex, 1)
        } else {
          this.selectedOptions.push(userSayId)
        }
      },
      addCategory() {
        this.keywordCategoryAdd = true
        //　互い影響がないよう
        this.keywordAdd = false
        this.$emit('add-category')
      },
      addKeyword() {
        this.keywordAdd = true
        this.keywordCategoryAdd = false
        this.$emit('add-keyword')
      },
      getIntentName(intent){
        return intent ? intent.split('>>').map(text => text.trim()).join('_') : ''
      },
      goToIntentDetail(name){
        const intentName = this.getIntentName(name)
        if(intentName){
          const currentUrl = location.href
          const regex = RegExp('agent.+')
          const destination = `agent/${this.language}/intent?intent_name=${intentName}`
          return currentUrl.match(regex) ? currentUrl.replace(regex, destination) : destination
        }
        return '#'
      }
    },
  }
</script>
